@media print {

  * {
    color: black!important;
  }

  .ef-kicker {
    padding-left: 0;

    &::before {
      content: "";
      width: 0;
      height: 0;
    }
  }

  .ef-header__items--secondary,
  .ef-breadcrumbs,
  .ef-hamburger,
  .ef-header__logo-minimal,
  .ef-share,
  .ef-article__footer,
  .ef-stadium-ad,
  .ef-submenu,
  .ef-match-header__expansion-toggle,
  .ef-footer,
  .ef-blockquote:before,
  .ef-blockquote:after,
  .ef-content__block--video,
  video,
  iframe {
    opacity: 0;
    display: none;
    height: 0;
  }

  .ef-header,
  .ef-header__bar,
  .ef-header__items,
  .ef-header__logo {
    position: absolute!important;
    top: 5px!important;
    left: 0!important;
    margin-left: 0!important;
  }

  .ef-header__logo-full,
  .ef-header__logo {
    display: block!important;
    opacity: 1!important;
    border: 0!important;
  }

  .ef-content {
    -webkit-box-shadow: none!important;
    -moz-box-shadow: none!important;
    box-shadow: none!important;
  }


  .ef-header__logo-wordmark path {
    fill: black;
    color: black;
  }
}
