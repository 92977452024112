.ef-player-card {
  @extend %card;
  @extend %card--has-line;

  &__visual {
    @include transition;
    background-color: #1e1e1e;

    [data-theme="light"] & {
      background-color: var(--tone-back-800);
    }

    .ef-player-card:hover & {
      background-color: var(--color-black);
    }

    [data-theme="light"] .ef-player-card:hover & {
      background-color: var(--tone-back-800);
    }
  }

  &__marquee {
    @include transition;
    position: absolute;
    top: spacing(4);
    right: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    --offset: 100px;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));

    .ef-player-card:hover & {
      opacity: 1;
    }
  }

  &__marquee-row {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    --speed: '0s';
    transform: translate3d(var(--move-initial), 0, 0);
    animation: b-marquee var(--speed) linear infinite;
    animation-play-state: paused;

    .ef-player-card:hover & {
      animation-play-state: running;
    }

    &--reverse {
      animation-direction: reverse;
    }
  }

  &__marquee-item {
    @include type-scale(900);
    padding: 0 .125ch;
    font-family: font($font--headline);
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
  
    &--filled {
      color: var(--color-front);
    }
  
    &--outlined {
      color: transparent;
      -webkit-text-stroke: 1px var(--color-front);
      text-stroke: 1px var(--color-front);
    }
  }

  &__media {
    @extend .ef-media--3x4;
  }

  &__background,
  &__branding {

    .ef-player-card--simple & {
      display: none;
    }
  }

  &__background {
    position: relative;
    background: transparent !important;
    //opacity: .3;
    background-position: center;
    background-size: cover;
    @include fill;
    @include transition;

    @include break(desktop-s) {

      .ef-player-card:hover & {
        //opacity: 1;
        transform: scale(1.0125);
      }
    }
  }

  &__image {
    @include transition($property: transform);

    @include break(desktop-s) {

      .ef-player-card:hover & {
        transform: scale(1.025);
      }
    }
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    padding-top: spacing(2);
    padding-right: spacing(4);
    padding-bottom: spacing(2);
    @include type-scale(300);
  }

  // &__number,
  // &__name {
  //   display: inline;
  // }

  &__number {
    font-weight: 700;

    &:after {
      content: "/";
      padding-right: spacing(1);
      padding-left: spacing(1);
      opacity: .5;
      color: var(--color-front);
    }
  }

  &__name {
    flex: 1;
    color: var(--color-front);
  }
}
