.ef-live-video-and-comments {

  @include break(desktop-s) {
    display: grid;
    grid-template-columns: $max-width 1fr;
    grid-gap: spacing(3);
  }

  &__video {
    margin-bottom: spacing(2);

    @include break(desktop-s) {
      margin-bottom: 0;
    }
  }
}
