.ef-mainpay-cta {
  @extend %boxed-padding;
  position: relative;
  padding-top: spacing(8);
  padding-bottom: 240px;
  overflow: hidden;
  background-color: black;
  background-image: url(https://design.eintracht.de/assets/img/mainpay/cta_mobile.jpg);
  background-size: 320px;
  background-repeat: no-repeat;
  background-position: right bottom;

  @include break(tablet) {
    padding-bottom: spacing(8);
    background-image: url(https://design.eintracht.de/assets/img/mainpay/cta.jpg);
    background-size: cover;
  }

  @include break(desktop-l) {
    background-size: 1600px;
  }

  .ef-content--shop & {
    @include boxed-margin;
    margin-bottom: spacing(12);
  }

  &__logo {
    width: auto;
    height: 32px;

    @include break(tablet) {
      height: 48px;
    }
  }

  &__text {

    @include break(tablet) {
      max-width: 50vw;
    }

    @include break(desktop-s) {
      flex: 1;
      max-width: none;
      padding-right: spacing(8);
    }
  }

  .ef-container {
    position: relative;
    z-index: 1;

    // @include break(desktop-s) {
    //   display: flex;
    //   align-items: center;
    // }
  }

  &__heading {
    color: var(--color-white);
  }

  .ef-paragraph {
    color: var(--color-white);

    // @include break(desktop-s) {
    //   margin-bottom: 0;
    // }
  }
}
