.ef-sticky-bar {
  @extend %boxed-padding;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  background-color: var(--color-white);

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--light-gray-700);
  }

  &__items {
    display: grid;
    gap: spacing(2);

    @include break(tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__heading {
    margin-bottom: 0;
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: spacing(2);
  }
}
