.ef-newsflash-card {
  @extend %card;
  @extend %card--has-line;
  margin-right: auto;
  margin-left: auto;

  &--external {
    padding-right: 16px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--white)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: right bottom spacing(2.75);

    @include theme(light) {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--black)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
    }
  }

  &--external:hover {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
  }

  &__media {
    @extend .ef-media--3x2;
  }

  &__image {
    @include transition;
  }

  @include break(desktop-s) {

    &:hover {

      .ef-newsflash-card__image {
        transform: scale(1.05);
      }
    }
  }

  &.--is-active:after {
    width: 100%;
  }

  &__text {
    @extend %text-container;
    padding-top: spacing(2);
    padding-right: spacing(3);
    padding-bottom: spacing(2);
  }

  &__heading {
    //hyphens: auto;
    @include transition($duration-faster, $property: color);

    @include break(desktop-s) {

      .ef-newsflash-card:hover & {
        color: var(--color-accent);
      }
    }

    span {
      @include card-blank-link;
    }
  }
}
