.ef-form-submit {

  &--stacked {

    .ef-button:not(:last-child) {
      margin-bottom: spacing(1);
    }
  }

  &__text {
    padding-top: spacing(2);
    @include type-scale(200);
  }
}
