.ef-post-card {
  position: relative;

  &:not(:last-child) {
    margin-bottom: spacing(8);
    padding-bottom: spacing(4);
    border-bottom: 1px solid var(--tone-back-600);
  }

  &__inner {
    @include max-width(m);
  }

  &__day-time {
    display: block;
    position: absolute;
    top: 0;
    right: calc(#{spacing-no-calc(3)} + 100%);
    padding: spacing(1.5) spacing(3) spacing(.5);
    background-color: var(--color-white);
    color: var(color--black);
    font-weight: 700;
    @include type-scale(700);

    @include break(desktop-s) {
      display: block;
    }
  }

  &__media {
    margin-bottom: spacing(4);
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    @include transition($duration-slower, $property: max-height);

    &.--is-active {
      max-height: 2500px;
    }
  }

  &__expand {
    display: inline-block;
    @include transition;

    &.--is-active {
      margin-top: spacing(4);
    }
  }

  &__expand-more {

    .ef-post-card__expand.--is-active & {
      display: none;
    }
  }

  &__expand-less {
    display: none;

    .ef-post-card__expand.--is-active & {
      display: inline;
    }
  }
}
