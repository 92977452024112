.ef-stadium-ad {

  &__backdrop {
    position: relative;

    @include theme(light) {
      opacity: .3;
    }

    &--top {
      margin-top: -18vw;
      padding-top: 26.350851%;

      .ef-forum-wrapper & {
        margin-top: -12vw;

        @include break(desktop-xl) {
          margin-top: spacing(-24);
        }
      }
    }

    &--bottom {
      padding-top: 11.650485%;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
    }

    &--top:after {
      top: 0;
      height: 80%;
      background-image: linear-gradient(to bottom, $dark-gray-800, rgba($dark-gray-800, 1), rgba($dark-gray-800, .9), rgba($dark-gray-800, 0));

      @include theme(light) {
        background-image: linear-gradient(to bottom, $color--white, rgba($color--white, 1), rgba($color--white, 0));
      }
    }

    &--bottom:after {
      bottom: 0;
      height: 100%;
      background-image: linear-gradient(to top, $dark-gray-800, rgba($dark-gray-800, .75), rgba($dark-gray-800, 0));

      @include theme(light) {
        background-image: linear-gradient(to top, $color--white, rgba($color--white, .75), rgba($color--white, 0));
      }
    }
  }

  &__backdrop-image {
    @include fill;
    width: 100%;
  }

  &__backdrop-image,
  &__banner > * {
    vertical-align: bottom;
  }

  &__banners {
    position: relative;
    width: 100%;
  }

  &__banner {
    position: relative;
    padding-left: spacing(3);
    background-color: var(--tone-back-700);

    @include theme(light) {
      background-color: var(--tone-medium);
    }

    @include break(desktop-s) {
      padding-left: 0;
    }

    &--main,
    &--right {
      position: relative;

      &:before {
        @include type-scale(200);
        content: attr(data-label);
        position: absolute;
        bottom: 0;
        //left: spacing(-6.25);
        left: spacing(-2.875);
        margin-bottom: spacing(-3);
        //padding-left: spacing(4.5);
        //width: 12.903225%;
        width: 15%;
        color: var(--tone-medium);
        font-weight: 700;
        text-align: center;
        transform: translateX(spacing(3)) rotate(270deg);
        transform-origin: 0 0;

        @include break(tablet) {
          width: 12.903225%;
        }

        @include break(desktop-s) {
          left: spacing(-6.25);
        }
      }
    }

    &--left,
    &--right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &--left {
      right: calc(#{spacing-no-calc(4)} + 100%);
    }

    &--right {
      left: calc(#{spacing-no-calc(4)} + 100%);
    }
  }

  &__banner-wrapper {
    position: relative;
    width: 100%;
    //max-width: 100vw;
    padding-top: 12.903225%;
    @include max-width(l);

    &--small {
      display: none;
    }

    // &--small {
    //   //width: 640px;
    //   padding-top: 15.625%;
    //
    //   @include break(phablet) {
    //     display: none;
    //   }
    // }
    //
    // &--large {
    //   display: none;
    //   //width: 1280px;
    //   padding-top: 12.903225%;
    //
    //   @include break(phablet) {
    //     display: block;
    //   }
    // }
  }

  &__banner-container {
    @include fill;
    vertical-align: bottom;
    background: url(../img/patterns/organisms/stadium-ad/banner-fallback.png) no-repeat center;
    background-size: cover;

    iframe {
      @include fill;
    }
  }

  &__banner-start,
  &__banner-end {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: spacing(6);
    //background-color: var(--tone-back-700);
  }

  &__banner-start {
    right: 100%;
  }

  &__banner-end {
    left: 100%;
  }

  &__banner-logo {
    width: spacing(3.5);
    opacity: .9;
    filter: grayscale(1);
  }

  .ef-header__logo-monogram-front {
    fill: var(--color-white) !important;
  }

  .ef-header__logo-monogram-back {
    fill: transparent !important;
  }

  .ef-container {
    display: flex;
    justify-content: center;
  }
}
