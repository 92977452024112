.ef-file-previews {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include break(tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__item {
    grid-column: span 1;
  }
}

.ef-file-preview {
  @extend %card;
  @extend %card--has-line;
  display: block;
  padding-bottom: spacing(3);
  background-color: var(--tone-back-750);

  &__media-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 1px;
      height: 0;
      padding-top: 100%;
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    bottom: spacing(2);
    left: spacing(2);
    overflow: hidden;

    @include break(tablet) {
      top: spacing(3);
      right: spacing(3);
      bottom: spacing(3);
      left: spacing(3);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    padding: 0 spacing(2);

    @include break(tablet) {
    padding: 0 spacing(3);
    }
  }

  &:hover .ef-download__link {
    text-decoration-color: var(--color-accent);
  }
}
