@keyframes show-menu {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

@keyframes show-menu-section-primary {
  100% {
    transform: translateX(0);
  }
}

@keyframes show-menu-section-secondary-mobile {
  0% {
    transform: translateX(100%);
  } 100% {
    transform: none;
  }
}

@keyframes show-menu-section-secondary-desktop {
  0% {
    transform: translateX(-100%);
  } 100% {
    transform: none;
  }
}

@mixin menu-toggle-icon {
  @include transition($property: transform);
  position: relative;
  width: 24px;
  height: 24px;
  margin: -4px;
  cursor: pointer;
  color: var(--color-front);

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: var(--color-front);
  }

  &:before {
    top: calc(50% - 1px);
    right: 6px;
    left: 6px;
    height: 2px;
    background-color: var(--color-front);
  }

  &:after {
    top: 6px;
    bottom: 6px;
    left: calc(50% - 1px);
    width: 2px;
    background-color: var(--color-front);
    @include transition($property: transform);

    .ef-primary-menu__items-toggle.--is-open &,
    .ef-secondary-menu__item.--is-open .ef-secondary-menu__item-wrapper &,
    .ef-secondary-menu__subitem.--is-active & {
      transform: scaleY(0);
    }
  }
}

.ef-menu {
  display: none;
  //display: flex;
  //flex: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: z(menu);
  width: 100%;
  opacity: 0;
  pointer-events: auto;

  @include break(real-tablet) {
    background-color: rgba($color--black, .7);
  }

  &.--is-active {
    display: block;
    animation: show-menu .3s ease-in-out forwards;
  }

  &__logo-link-mobile {
    display: flex;
    align-items: center;
    width: spacing(6);
    height: spacing(10);
    margin-top: 4px;
    margin-left: calc(#{spacing-no-calc(6)} + 24px);

    @include break(real-tablet) {
      display: none;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
    height: spacing(10);
    padding-top: 4px;
    background-color: var(--color-back);

    .ef-link-button {
      display: flex;
      align-items: center;
      height: spacing(10);
    }

    @include break(real-tablet) {
      display: none;
    }
  }

  &__inner-close-area {
    display: none;
    position: absolute;
    top: spacing(10);
    bottom: 0;
    left: 0;
    width: calc(24px + #{spacing-no-calc(9)});

    @include break(real-tablet) {
      display: none;
    }

    &.--is-active {
      display: block;

      @include break(real-tablet) {
        display: none;
      }
    }
  }

  &__items-header {
    position: relative;
    margin-bottom: spacing(4);
  }

  &__items-heading {
    @include type-scale(500);
    padding-top: spacing(1.5);

    @include break(real-tablet) {
      padding-top: spacing(3.5);
    }
  }

  &__sport {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sport-icon {
    width: spacing(12);
    height: spacing(12);
    //background-color: var(--tone-back-750);
    color: var(--color-front);
    @include transition;

    &--header {
      position: absolute;
      right: 0;
      top: 0;
      width: spacing(6);
      margin-top: spacing(1);
      margin-right: 0;
      border: 1px solid var(--color-front);
      background-color: var(--color-back);
      color: var(--color-front);

      @include break(real-tablet) {
        width: spacing(6);
        height: spacing(6);
      }
    }

    .ef-primary-menu-extension__item:hover & {
      background-color: var(--color-front);
      color: var(--color-back);
    }
  }

  &__sport-label {
    @include type-scale(200);
    flex: 1;
    color: var(--color-front);
    hyphens: auto;
    text-align: center;
  }
}

.ef-primary-menu {
  width: 100%;
}

.ef-primary-menu,
.ef-primary-menu-extension,
.ef-secondary-menu {
  position: absolute;
  bottom: 0;
  padding-right: spacing(3);
  padding-left: spacing(3);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  pointer-events: auto;

  @include break(real-tablet) {
    right: auto;
    width: spacing(60);
  }

  @include break(desktop-s) {
    width: spacing(60);
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.ef-primary-menu-extension {
  @include break(desktop-s) {
    width: 640px;
  }
}

.ef-primary-menu {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  top: calc(#{spacing-no-calc(10)} + 4px);
  left: 0;
  padding-top: spacing(4);
  background-color: var(--color-back);
  transform: translateX(-100%);

  @include break(real-tablet) {
    top: 0;
    z-index: 11;
    padding-top: calc(#{spacing-no-calc(2)} + 4px);
  }

  .ef-menu.--is-active & {
    animation: show-menu-section-primary .3s $ease-in-out-expo forwards;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__logo {
    display: none;

    @include break(real-tablet) {
      display: block;
      width: spacing(8 / 216 * 723.6);
      height: spacing(8);
      margin-bottom: spacing(4);
    }

    @include break(desktop-s) {
      transform: translateY(-6px);
    }
  }

  &__back {
    padding-bottom: spacing(4);
  }

  &__sections {
    flex: 1;
    padding-bottom: spacing(4);
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: spacing(3);
    }

    &:nth-child(2) > .ef-primary-menu__items > .ef-primary-menu__item,
    &:nth-child(2) > .ef-primary-menu__items > .ef-primary-menu__items-collapsible > .ef-primary-menu__item {
      @include type-scale(350);
    }

    &--stores {
      margin: 0 spacing(-3);
      padding: spacing(3);
      background-color: var(--tone-back-750);

      @include break(real-tablet) {
        margin: 0 calc(#{spacing-no-calc(-6)} - 24px);
        padding: spacing(3) calc(#{spacing-no-calc(6)} + 24px);
      }

      .ef-primary-menu__sub-items {
        margin-bottom: 0;
        border: none;
      }

      > .ef-primary-menu__items > .ef-primary-menu__item {
        @include type-scale(300);
      }

      .ef-primary-menu__item--sub:last-child {
        padding-bottom: 0;
      }
    }

    // &--stores:before {
    //   content: "";
    //   position: absolute;
    //   top: -2px;
    //   right: 0;
    //   left: 0;
    //   height: 4px;
    //   background-color: var(--tone-back-600);
    // }
  }

  &__items {
    display: grid;
    // gap: spacing(1.5);
    color: var(--tone-front-500);

    &--extra {
      padding-top: spacing(4);
    }

    &--shopping {
      flex: none;
      margin-right: spacing(-3);
      margin-bottom: spacing(.75);
      margin-left: spacing(-3);
      padding-top: spacing(1.5);
      padding-right: spacing(3);
      padding-left: spacing(3);
      background-color: var(--tone-back-700);

      @include break(real-tablet) {
        margin-right: calc(#{spacing-no-calc(-6)} - 24px);
        margin-left: calc(#{spacing-no-calc(-6)} - 24px);
        padding-right: calc(#{spacing-no-calc(6)} + 24px);
        padding-left: calc(#{spacing-no-calc(6)} + 24px);
      }
    }
  }

  &__sub-items {
    margin-bottom: spacing(1.5);
    border-bottom: 1px solid var(--tone-back-600);
  }

  &__item {
    @include type-scale(300);
    display: flex;
    align-items: center;
    gap: spacing(2);
    padding-bottom: spacing(1.5);
    border-bottom: 1px solid var(--tone-back-600);
    color: var(--color-front);

    &:not(:last-child) {
      margin-bottom: spacing(1.5);
    }

    &.--is-faded {
      opacity: .6;
    }

    &.--has-icon {
      @include type-scale(200);
    }

    &--sub,
    &--extra {
      @include type-scale(250);
      font-weight: 400 !important;
    }

    &--extra:not(:last-child) {
      margin-bottom: spacing(1.5);
    }

    &--sub {
      padding-left: calc(#{spacing-no-calc(2)} + 24px);
      border: none;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &.--has-sub-items {
      border: none;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__item:before {

    .ef-primary-menu__items--shopping & {
      background-color: var(--tone-back-500);
    }
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    .ef-primary-menu__item--extra & {
      color: var(--color-accent);
    }
  }

  &__item-sublabel {
    @include type-scale(300);
    display: block;
    color: var(--tone-front-300);
    font-weight: 400;
  }

  &__item-action {

    @include break(desktop-s) {

      .ef-primary-menu__item:hover &,
      .ef-primary-menu__item.--is-active & {
        opacity: 1;
      }
    }
  }

  &__ctas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: spacing(2);
    padding-top: spacing(3.75);

    @include break(real-tablet) {
      display: block;
    }
  }

  &__cta:not(:last-child) {

    @include break(real-tablet) {
      margin-bottom: spacing(2);
    }
  }

  &__cta-button {
    width: 100%;
  }

  &__partners {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // z-index: 2;
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    background-color: var(--color-back);
    color: var(--color-front);

    @include break(real-tablet) {
      display: none;
    }
  }

  &__partner {
    margin-right: spacing(2);
    margin-left: spacing(2);

    &--main {
      height: 24px;
      width: 87.600px;
    }

    &--outfitter {
      height: 18px;
      width: 88.888888px;
    }
  }

  &__languages {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    margin: 0 spacing(-3);
    padding: spacing(3) spacing(3) spacing(4);
    background-color: var(--color-black);

    @include break(real-tablet) {
      justify-content: flex-start;
      margin: 0 calc(#{spacing-no-calc(-6)} - 24px);
      padding: spacing(3) calc(#{spacing-no-calc(6)} + 24px) spacing(4);
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 100%;
      left: 0;
      height: spacing(3);
      background-image: linear-gradient(to top, rgba($color--black, .3), rgba($color--black, .0001));
    }
  }

  &__language {
    @include type-scale(300);
    @include transition;
    cursor: pointer;
    color: var(--tone-medium);
  }

  &__language:hover,
  &__language.--is-current {
    color: var(--tone-front-600);
  }

  &__language.--is-current {
    pointer-events: none;
    border-bottom: 2px solid var(--color-accent);
  }

  &__language:not(:last-child) {
    margin-right: spacing(2);
  }
}

.ef-primary-menu {

  &__item {
    display: flex;
    gap: spacing(2);
    position: relative;
    font-weight: 700;

    &.--is-active {
      color: var(--color-front);
    }

    &.--is-active:after {
      width: 100%;
    }
  }

  &__item-link {
    flex: 1;
    display: block;
    position: relative;
    cursor: pointer;
    @include transition($duration-faster, $property: color);

    &:hover {
      color: var(--color-front) !important;
    }
  }

  &__item-link-secondary {
    font-weight: 400;
  }

  &__item-action {
    flex-basis: 16px;
    height: 16px;
    cursor: pointer;
    //border-left: 2px solid var(--tone-back-600);
    background-repeat: no-repeat;
    background-position: center;

    @include break(desktop-s) {
      flex-basis: 16px;
      opacity: 0;
      border: none;
      background-position: right center;
      @include transition($duration-faster);

      .ef-primary-menu__item:hover &,
      .ef-primary-menu__item.--is-active & {
        opacity: 1;
      }
    }

    &--expand {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--white)}"><polygon points="4.9,15.7 3.4,14.3 9.7,8 3.4,1.7 4.9,0.3 12.6,8"></polygon></g></svg>');

      .ef-primary-menu__item.--is-active &,
      &:hover {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="4.9,15.7 3.4,14.3 9.7,8 3.4,1.7 4.9,0.3 12.6,8"></polygon></g></svg>');
      }
    }

    &--external {
      pointer-events: none;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--white)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
    }
  }
}

.ef-primary-menu-extension {
  border-left: 2px solid var(--tone-back-600);
  background-color: var(--color-back);

  @include break(real-tablet) {
    z-index: 10;
  }

  &__item {
    @include type-scale(300);
  }

  &__item-link {
    width: 100%;
    cursor: pointer;
    color: var(--color-front);

    &:visited {
      color: var(--color-front);
    }
  }

  &__item-action {
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 4px;
    background-position: right bottom spacing(2.25);

    @include break(desktop-s) {

      .ef-primary-menu-extension__item:hover &,
      .ef-primary-menu-extension__item.--is-active & {
        opacity: 1;
      }
    }
  }
}

.ef-primary-menu-extension,
.ef-secondary-menu {
  display: none;
  top: 0;
  right: 0;
  width: calc(100% - 24px - #{spacing-no-calc(9)});
  box-shadow: spacing(-4) spacing(4) spacing(8) var(--color-black);
  background-color: var(--color-back);

  &.--is-active {
    animation: show-menu-section-secondary-mobile .3s $ease-in-out-expo forwards;

    @include break(desktop-s) {
      animation: show-menu-section-secondary-desktop .3s $ease-in-out-expo forwards;
    }
  }
}

.ef-primary-menu-extension {

  &.--is-active {
    display: block;
  }

  @include break(real-tablet) {
    width: 360px;
  }

  @include break(desktop-s) {
    width: 540px;
  }
  
  @include break(desktop-m) {
    width: 720px;
  }
}

.ef-primary-menu-extension__items {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: spacing(4);
  padding-bottom: spacing(4);

  @include break(desktop-s) {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  &.--is-active {
    display: block;
  }
}

.ef-secondary-menu {
  display: none;
  z-index: 9;

  &.--is-active {
    display: flex;
    flex-direction: column;
  }

  @include break(real-tablet) {
    width: spacing(60);
  }
  
  @include break(desktop-s) {
    width: calc(100vw - #{spacing-no-calc(72)});
    max-width: spacing(120);
  }

  &__items {
    display: none;

    &.--is-active {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: spacing(4);
    }
  }

  &__inner {
    flex: 1;
  }

  &__item {
    color: var(--color-front);
    font-weight: 700;
    @include type-scale(300);

    &:not(:last-child) {
      margin-bottom: spacing(4);
    }
  }

  &__item-wrapper {
    @include transition;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: spacing(2);
    padding-bottom: spacing(1.5);
    border-bottom: 1px solid var(--tone-back-700);

    &.--is-active {
      color: var(--color-accent);
      border-color: var(--color-accent);

      .ef-secondary-menu__item-toggle-icon:before,
      .ef-secondary-menu__item-toggle-icon:after {
        background-color: var(--color-accent);
      }
    }
  }

  &__item-link {
    @include transition;

    &:hover {
      color: var(--color-accent);
    }
  }

  &__item-toggle-icon {
    @include menu-toggle-icon;

    &:hover:before,
    &:hover:after {
      background-color: var(--color-accent);
    }
  }

  &__subitems {
    @include transition($easing: $ease-in-out-expo);
    display: grid;
    column-gap: spacing(3);
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    .ef-secondary-menu__item.--is-open & {
      max-height: 1000px;
      opacity: 1;
    }

    @include break(desktop-s) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  // &__subitems,
  // &__expansion-items {
  //
  //   @include break(desktop-s) {
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     grid-column-gap: calc(#{spacing-no-calc(3)} + 12px);
  //   }
  //
  //   @include break(desktop-m) {
  //     grid-template-columns: repeat(4, 1fr);
  //   }
  // }

  &__subitem {
    @include transition($duration-faster);
    position: relative;
    padding: spacing(1.5) 0;
    cursor: pointer;
    border-bottom: 1px solid var(--tone-back-700);
    font-weight: 400;
    word-wrap: break-word;
    @include type-scale(250);

    &:hover,
    &.--is-active {
      border-color: var(--color-accent);
      color: var(--color-accent);
    }

    &.--is-expandable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: spacing(2);
    }
  }

  &__subitem-link {
    display: block;
    position: relative;
    &--external {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--black)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
      background-repeat: no-repeat;
      background-position: right center;
    }

    .ef-secondary-menu__subitem.--is-active & {
      color: var(--color-accent);
    }

    &:after {

      .ef-secondary-menu__subitem.--is-active & {
        width: 100%;
      }
    }
  }

  &__subitem-expander {
    @include menu-toggle-icon;

    &:hover:before,
    &:hover:after {
      background-color: var(--color-accent);
    }
  }

  &__expansion {
    min-height: 0;
    max-height: 0;
    margin: 0 spacing(-3);
    padding: 0 spacing(3);
    overflow: hidden;
    background-color: var(--tone-back-700);
    transition: all .7s $ease-in-out-expo;

    @include break(real-tablet) {
      margin: 0 calc(#{spacing-no-calc(-6)} - 24px);
      padding: 0 calc(#{spacing-no-calc(6)} + 24px);
    }

    &.--is-expanded {
      max-height: 2000px;
    }
  }

  &__expansion-inner {
    padding: spacing(4) 0;
  }

  &__expansion-heading {
    padding-top: 0;
    color: var(--tone-front-300);
    font-weight: 400;
    @include type-scale(200);
  }

  &__expansion-items {
    display: grid;
    gap: spacing(1.5);

    &:not(:last-child) {
      margin-bottom: spacing(4);
    }

    @include break(desktop-s) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__expansion-item {
    @include type-scale(200);
    display: flex;
    align-items: stretch;
  }

  &__actions {
    display: grid;
    gap: spacing(2);
    position: sticky;
    bottom: 0;
    margin-right: calc(#{spacing-no-calc(-6)} - 24px);
    margin-left: calc(#{spacing-no-calc(-6)} - 24px);
    padding: spacing(4) calc(#{spacing-no-calc(6)} + 24px);
    background-color: var(--tone-back-750);

    .ef-button {
      display: block;
    }
  }

  &__actions-group {
    display: grid;
    gap: spacing(2) spacing(3);

    @include break(desktop-s) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__action-banner {

    .ef-media {
      @include transition;
    }

    &:hover .ef-media {
      transform: scale(1.02);
    }
  }
}

.ef-menu {

  &__player {
    @include transition;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding-bottom: spacing(1.5);
    border-bottom: 1px solid var(--tone-back-600);

    &:hover,
    &.--is-active {
      border-color: var(--color-accent);
    }
  }

  &__player-number {
    color: var(--color-accent);
    font-weight: 700;
  }

  &__player-name {
    flex: 1;
    position: relative;
    padding-left: spacing(3);
    color: var(--color-front);

    &:before {
      content: "/";
      position: absolute;
      top: 0;
      left: 0;
      padding-right: spacing(1);
      padding-left: spacing(1);
      color: var(--tone-500);
    }
  }
}


// MENU SIZING

.ef-primary-menu,
.ef-primary-menu-extension,
.ef-secondary-menu {

  @include break(real-tablet) {
    padding-top: spacing(2.5);
    padding-right: calc(#{spacing-no-calc(6)} + 24px);
    padding-left: calc(#{spacing-no-calc(6)} + 24px);
  }
}

.ef-primary-menu {

  @include break(real-tablet) {
    transform: translateX(calc(#{spacing-no-calc(-40)} - 48px));
  }
}

.ef-primary-menu-extension,
.ef-secondary-menu {

  @include break(real-tablet) {
    left: spacing(60);
  }

  @include break(desktop-s) {
    left: spacing(60);
  }
}

// DBP STYLES

.ef-menu {

  &__logo-link-mobile--dbp {
    width: spacing(22);
    background-color: var(--tone-back-800);

    @media (min-width: 374px) {
      width: spacing(30);
    }

    @include break(phablet) {
      width: spacing(41);
    }
  }
}

.ef-primary-menu {

  &__items-collapsible {
    @include transition($easing: $ease-in-out-expo);
    display: grid;
    max-height: 0;
    overflow: hidden;
    margin-right: calc(#{spacing-no-calc(-6)} - 24px);
    margin-left: calc(#{spacing-no-calc(-6)} - 24px);
    padding-right: calc(#{spacing-no-calc(6)} + 24px);
    padding-left: calc(#{spacing-no-calc(6)} + 24px);
    opacity: 0;

    &.--is-open {
      max-height: 1000px;
      opacity: 1;
    }
  }

  &__items-toggle {
    @include transition;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: spacing(2);
    padding: spacing(1) spacing(2);
    cursor: pointer;
    background-color: var(--tone-back-750);
    // border-bottom: 1px solid var(--tone-back-600);
    cursor: pointer;

    &.--is-open {
      margin-top: spacing(1.5);
      // padding-bottom: spacing(3);
      border-color: transparent;
    }

    &.--is-faded {
      opacity: .6;
    }
  }

  &__items-toggle-icon {
    @include menu-toggle-icon;
  }

  &__items-toggle-label {
    @include secondary-heading;
    @include type-scale(350);
    color: var(--color-front);

    &--collapse {
      display: none;

      .ef-primary-menu__items-toggle.--is-open & {
        display: block;
      }
    }

    &--expand {
      display: block;

      .ef-primary-menu__items-toggle.--is-open & {
        display: none;
      }
    }
  }
}

.ef-secondary-menu {

  &__logo-link {
    display: block;
    margin-bottom: spacing(3);
    padding-top: spacing(1.5);

    @include break(real-tablet) {
      padding-top: spacing(3.5);
    }
  }

  &__logo {
    height: spacing(4);

    &--dbp {
      width: spacing(32);
    }

    &--touchdown {
      width: spacing(12 / 25.7 * 34.24);
      height: spacing(12);
    }
  }
}
