.ef-video-header {
  @extend %boxed-padding;
  margin-bottom: spacing(12);
  //padding-top: spacing(8);

  &__video {
    margin-bottom: spacing(4);
  }

  &__text {
    position: relative;
  }

  &__kicker,
  &__heading {
    padding-right: calc(#{spacing-no-calc(4)} + 48px);
  }

  &__heading {
    @include type-scale(700);
  }

  .ef-share {
    position: absolute;
    top: 0;
    right: 0;
  }
}
