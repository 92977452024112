[data-theme-2="s59"],
[data-theme-2="s59"] [data-theme="light"],
[data-theme-2="s59"] [data-theme="dark"],
[data-theme-2="s59"] [data-theme="inverted"] {
  --color-accent: var(--color-white);
  --color-accent-shade: var(--color-white);
  --color-accent-contrast: var(--color-black);
}

[data-theme-2="s59"] {

  h1,
  h2,
  .ef-heading--0,
  .ef-heading--1,
  .ef-heading--2,
  .ef-marquee__item,
  blockquote:before,
  .ef-blockquote:before,
  blockquote p,
  .ef-blockquote__quote,
  .ef-fullscreen-hero__marquee-item,
  .ef-icon-and-text__item,
  .ef-text--secondary,
  .ef-heading--subline,
  .ef-heading--sub,
  .ef-kicker,
  .ef-button,
  .ef-logo-card__title,
  .ef-magazine-card__heading,
  .ef-snackbar__title,
  .ef-footer__bar,
  .ef-featured-list__item-title {
    font-family: font($font--mono) !important;
  }

  .ef-kicker,
  .ef-logo-card__title {
    @include type-scale(400);
    padding-left: spacing(3);
    margin-bottom: spacing(8);
    text-transform: none;

    &:before {
      top: 50%;
      width: spacing(1.5);
      height: spacing(1.5);
      transform: translateY(-50%);
    }
  }

  .ef-logo-card__title {
    @extend .ef-kicker;
    margin-bottom: spacing(8);
  }

  .ef-featured-hero__subline {
    @include type-scale(500);
    font-family: font($font--primary);
  }

  h3, h4, h5, h6,
  .ef-heading--3,
  .ef-heading--4,
  .ef-heading--5,
  .ef-heading--6 {
    font-weight: 400;
  }

  .ef-heading--1 {
    @include type-scale(1000);
  }

  .ef-heading--2,
  .ef-content__header .ef-heading--1 {
    @include type-scale(950);
  }

  .ef-header,
  .ef-hamburger,
  .ef-share {
    display: none;
  }

  .ef-paragraph--lead,
  .ef-paragraph--featured {
    font-weight: 400;
  }

  .ef-footer {
    position: relative;
    z-index: 999;
    border: none;
    background-color: var(--tone-back-800);
  }

  .ef-page {
    position: relative;
    padding-top: 0;

    &:after {
      content: "";
      position: absolute;
      z-index: z(header);
      top: spacing(3);
      left: spacing(3);
      width: spacing(16);
      height: spacing(10.1637);
      background-image: url(../img/s59/s59-logo.svg);
      background-repeat: no-repeat;

      @include break(tablet) {
        top: spacing(6);
        left: spacing(6);
      }

      @include break(desktop-s) {
        left: spacing(9);
      }
    }
  }

  .ef-container--m, 
  .ef-container--l, 
  .ef-content__expansion-content, 
  .ef-content__section-header, 
  .ef-fullscreen-hero__content, 
  .ef-header__bar, 
  .ef-hl-media-and-text-boxed__media, 
  .ef-newsflash-grid--small, 
  .ef-section-header {
    max-width: none;
  }

  .ef-content {
    padding-top: spacing(28);
  }

  .ef-content__header {
    margin-bottom: spacing(12);
  }

  .ef-content__block--separator .ef-container__narrow-s {
    max-width: none;
  }

  .ef-separator {
    height: 2px;
  }

  .ef-fullscreen-hero__content {
    @extend %boxed-padding;
  }

  .ef-featured-hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 50vw;
    margin-top: spacing(-28);
    padding-top: spacing(56);
  }

  .ef-featured-hero__content {
    align-items: start;
    text-align: left;
  }

  .ef-featured-hero__title {
    min-height: 0;
  }

  .ef-featured-hero__title-back {
    display: none;
  }

  .ef-featured-hero__heading {
    @include type-scale(950);
    margin-bottom: spacing(3);
  }

  .ef-featured-hero__subline {
    margin-bottom: 0;
  }

  .ef-featured-list {
    gap: spacing(8);

    &__item {
      padding-bottom: spacing(8);
    }

    &__item-content {
      @include break(tablet) {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
      }
    }

    &__item-title {
      @include type-scale(800);
      max-width: spacing(68);
      margin-bottom: spacing(4);
    }
  
    &__item-text {
      @include type-scale(400);
      color: var(--color-front);
      font-family: font($font--mono);
    }
  }

  .ef-logo-grid--secondary {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: spacing(8);

    @include break(tablet) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  
    @include break(desktop-s) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .ef-logo-card__image {
      top: 0;
      right: spacing(2);
      bottom: 0;
      left: spacing(2);
      width: calc(100% - #{spacing-no-calc(4)});
      height: calc(100% - #{spacing-no-calc(0)});

      @include break(tablet) {
        top: spacing(1);
        right: spacing(4);
        bottom: spacing(1);
        left: spacing(4);
        width: calc(100% - #{spacing-no-calc(8)});
        height: calc(100% - #{spacing-no-calc(2)});
      }

      @include break(desktop-s) {
        top: spacing(1);
        right: spacing(6);
        bottom: spacing(1);
        left: spacing(6);
        width: calc(100% - #{spacing-no-calc(12)});
        height: calc(100% - #{spacing-no-calc(2)});
      }
    }
  }

  .ef-logo-card__container {
    background-color: transparent;
  }

  .ef-logo-card__link {
    display: none;
  }

  .ef-info-box {
    @include max-width(s);
    padding: 0;
    background-color: transparent;

    p {
      @include type-scale(400);
      font-family: font($font--mono);
    }
  }

  .ef-link-button {

    &:before,
    &:after {
      height: 1px;
    }

    &--back {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E') !important;
    }

    &--more {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E') !important;
    }

    &--more-top,
    &--more-bottom.--is-active {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--white)}"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>') !important;
    }

    &--more-bottom {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--white)}"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>') !important;
    }

    &--logout {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--white)}"><polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" data-color="color-2"></polygon> <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z"></path></g></svg>') !important;
    }

    &--ticket {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g fill="#{url-encoded-color($color--white)}"><path fill-rule="evenodd" d="M.65 16.257l1.433 1.432c1.181-1.181 3.097-1.181 4.278 0 1.181 1.181 1.181 3.097 0 4.278L7.793 23.4c.39.39 1.023.39 1.414 0l8.392-8.392c.39-.39.39-1.023 0-1.414l-7.142-7.142c-.39-.39-1.024-.39-1.414 0L.65 14.843c-.39.39-.39 1.024 0 1.414zM11.736 5.173l7.142 7.142c.39.39 1.023.39 1.414 0l3.058-3.058c.39-.39.39-1.024 0-1.414L21.917 6.41c-1.181 1.181-3.097 1.181-4.278 0-1.181-1.181-1.181-3.097 0-4.278L16.207.7c-.39-.39-1.023-.39-1.414 0l-3.058 3.058c-.39.39-.39 1.024 0 1.414z"></path></g></svg>') !important;
    }
  }

  .ef-accordion__header:after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  }
}
