.ef-logo-card {
  @extend %card;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__title {
    font-family: font($font--subline);
    font-weight: 400;
    text-transform: uppercase;
  }

  &__container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);

    .ef-logo-card--color & {
      padding: spacing(1);
    }
  }

  &__media {
    @include transition;

    .ef-logo-card--color & {
      border-radius: 9px;
    }
  }

  &__image {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    bottom: spacing(2);
    left: spacing(2);
    width: calc(100% - #{spacing-no-calc(4)});
    height: calc(100% - #{spacing-no-calc(4)});
    object-fit: contain;

    .ef-logo-grid--secondary & {
      top: spacing(1);
      right: spacing(1);
      bottom: spacing(1);
      left: spacing(1);
      width: calc(100% - #{spacing-no-calc(2)});
      height: calc(100% - #{spacing-no-calc(2)});

      @include break(tablet) {
        top: spacing(1.5);
        right: spacing(2);
        bottom: spacing(1.5);
        left: spacing(2);
        width: calc(100% - #{spacing-no-calc(4)});
        height: calc(100% - #{spacing-no-calc(3)});
      }
    }
  }

  &__link {
    @include fill;
  }

  @include break(desktop-s) {

    &--link:hover {

      .ef-logo-card__media {
        transform: scale(1.05);
      }
    }
  }
}
