.ef-bookmark {
  @include transition($property: color);
  display: flex;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    --color-front: var(--color-accent);
  }

  &.--is-active {
    --color-front: var(--color-accent);
    --color-back: var(--color-accent);
  }
}
