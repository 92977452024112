.ef-social-consent {
  padding: spacing(6);
  background-color: var(--tone-back-750);
  text-align: center;

  &__logo {
    margin-bottom: spacing(3);
    color: var(--color-front);
  }

  .ef-switch__label {
    @include type-scale(400);
    display: inline-flex;
    grid-gap: 0;
  }

  .ef-switch__label-text {
    padding-right: spacing(2);
  }
}
