.ef-alexa-cta {
  @extend %boxed-padding;
  position: relative;
  padding-top: spacing(20);
  padding-bottom: spacing(8);
  overflow: hidden;
  background-image: url(https://design.eintracht.de/assets/img/alexa/cta_mobile.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;

  @include break(tablet) {
    padding-top: spacing(8);
  }

  @include break(desktop-s) {
    background-image: url(https://design.eintracht.de/assets/img/alexa/cta.jpg);
    background-position: center;
  }

  &__text {

    @include break(tablet) {
      max-width: 50vw;
    }

    @include break(desktop-s) {
      flex: 1;
      max-width: none;
      padding-right: spacing(8);
    }
  }

  .ef-container {
    position: relative;
    z-index: 1;

    // @include break(desktop-s) {
    //   display: flex;
    //   align-items: center;
    // }
  }

  &__heading {
    color: var(--color-white);
  }

  .ef-paragraph {
    color: var(--color-white);

    @include break(desktop-s) {
      padding-right: spacing(8);
    }
  }
}
