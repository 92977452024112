.ef-featured-hero {
  position: relative;
  margin-top: spacing(-12);
  margin-bottom: spacing(12);
  padding-top: spacing(42);
  padding-bottom: spacing(12);
  overflow: hidden;

  &__media {
    @include fill;

    &:after {
      content: "";
      position: absolute;
      inset: 0 0 -1px 0;
      background: linear-gradient(to top, rgba($grayscale--800, 1), rgba($grayscale--800, 0));
    }
  }

  &__media-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    object-position: center;
    animation: hero-ken-burns 19s ease-in-out forwards;
  }

  &__content {
    @extend %boxed-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
  }

  &__title {
    display: flex;
    align-items: center;
    position: relative;
    min-height: spacing(24);

    @include break(desktop-s) {
      min-height: spacing(42);
    }
  }

  &__title-back {
    @include type-scale(1100);
    position: absolute;
    left: 50%;
    opacity: .7;
    color: transparent;
    font-family: font($font--headline);
    -webkit-text-stroke: 1px var(--color-white);
    text-stroke: 1px var(--color-white);
    transform: translateX(-50%);

    @include break(desktop-s) {
      -webkit-text-stroke-width: 2px;
      text-stroke-width: 2px;
    }
  }

  &__heading {
    @include type-scale(900);
    position: relative;
    margin-bottom: 0;
    opacity: 0;
    animation: ef-slide-fade-in-btt 1s ease-in-out .7s forwards;
  }

  &__subline {
    opacity: 0;
    font-family: font($font--subline);
    font-weight: 400;
    animation: ef-slide-fade-in-btt .7s ease-in-out 1.1s forwards;
  }
}

.ef-grain {
  position: fixed;
  inset: 0;
  z-index: z(snackbar);
  pointer-events: none;
  mix-blend-mode: screen;

  &__video {
    @include fill;
    object-fit: cover;
    object-position: center;
  }
}
