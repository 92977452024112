.ef-finale {
  @extend %boxed-padding;
  @include fill(fixed);
  display: flex;
  justify-content: center;
  z-index: 9999999;
  min-height: 600px;
  background-color: var(--color-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: start;

  &.--is-hidden {
    display: none;
  }

  &:before {
    @include fill;
    content: "";
    background-color: rgba(0, 0, 0, .4);
  }

  &__visual {
    position: relative;
    max-width: calc(100% - #{spacing-no-calc(8)});
    max-height: 480px;
    margin: spacing(8) 0 spacing(32) 0;
    object-fit: contain;
    object-position: top;

    @include break(tablet) {
      max-width: 480px;
    }

    @include break(desktop-s) {
      max-width: 840px;
    }
  }

  &__buttons {
    position: absolute;
    bottom: spacing(8);
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      transform: translateX(-50%);
    }
  }

  &__button {

    width:100%;

    @include break(desktop-s){
      width: auto;
    }

    &:not(:last-of-type){
      margin-right: spacing(2);
      margin-bottom: spacing(2);
    }

    &.ef-button--secondary{
      background-color: var(--color-accent-contrast);
    }

  }
}
