.ef-product-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include break(tablet) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include break(desktop-xl) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  &--featured {
    grid-template-columns: repeat(1, 1fr);

    @include break(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break(desktop-xl) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__sub-items {
    grid-template-columns: repeat(2, 1fr);
    order: 2;

    @include break(tablet) {
      order: 0;
    }
  }

  &__item {
    grid-column: span 1;
  }
}
