.ef-event {
  @include type-scale(400);
  margin-bottom: spacing(2);

  @include break(tablet) {
    display: flex;
  }

  @include break(desktop-s) {
    max-width: calc(100% - #{spacing-no-calc(50)});
  }

  &__link {
    display: block;

    @include break(tablet) {
      display: flex;
      width: 100%;
    }
  }

  &__link .ef-event__title-main {
    @include transition;
    padding-right: calc(#{spacing-no-calc(1)} + 12px);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="black" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right top spacing(1);
  }

  &:hover .ef-event__link .ef-event__title-main {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($light-gray--300)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
  }

  &__date {
    @include type-scale(300);
    flex-basis: spacing(24);
    padding-right: spacing(3);
  }

  &__title {
    flex: 1;
  }

  &__title-main {
    display: inline;
    font-weight: 700;
  }

  &__title-sub {
    @include type-scale(300);
  }
}
