.ef-hl-media-and-text-full-width {
  margin-bottom: spacing(20);

  @include break(tablet) {
    margin-bottom: spacing(30);
  }

  @include break(desktop-s) {
    margin-bottom: spacing(40);
  }

  &__media {

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: spacing(36);
      background-image: linear-gradient(to top, $grayscale--800, rgba($grayscale--800, .001));
    }
  }

  &__description {
    @extend %boxed-padding;
    margin-top: spacing(-12);
    text-align: center;

    @include break(tablet) {
      margin-top: spacing(-24);
    }

    @include break(desktop-s) {
      margin-top: spacing(-48);
    }

    .ef-heading,
    .ef-paragraph {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
