.ef-story-post {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: spacing(6);
    padding: 0 spacing(4);

    .ef-story-post--story & {
      background-color: var(--color-accent);
    }

    .ef-story-post--ogp & {
      background-color: $color--facebook;
    }

    .ef-story-post--twitter & {
      background-color: $color--twitter;
    }

    .ef-story-post--instagram & {
      background-image: $gradient--insta;
    }

    .ef-story-post--youtube & {
      background-color: $color--white;

      .ef-kicker {
        color: $color--youtube;
      }

      .ef-story-post__header-control {
        color: $color--black;
      }
    }
  }

  &__header-controls {
    display: flex;
    align-items: center;
    color: var(--color-white);
  }

  &__header-control {
    cursor: pointer;

    &:not(:first-child) {
      padding-left: spacing(3);
    }
  }

  &__content {
    flex: 1;
    padding-top: spacing(6);
    overflow-y: scroll;

    .ef-story-post--vertical & {

      @include break(tablet) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }

  &__kicker {
    margin-bottom: 0;
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  &__media {

    .ef-story-post--story & {
      @extend .ef-media;
      @extend .ef-media--3x2;
    }

    .ef-story-post--vertical & {

      @include break(tablet) {
        grid-column: span 4;
      }
    }
  }

  &__video {
    width: 100%;
  }

  &__text {
    //order: 2;
    padding: spacing(4);

    @include break(tablet) {
      display: flex;
      flex-direction: column;
    }

    .ef-story-post--vertical & {

      @include break(tablet) {
        grid-column: span 4;
      }
    }

    .ef-paragraph {
      margin-bottom: 0;
    }
  }

  &__text-inner {
    flex: 1;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: spacing(3);
    padding-top: spacing(3);
    padding-right: calc(#{spacing-no-calc(2)} + 48px);
    border-top: 1px solid $grayscale--200;
  }

  &__footer-item:not(:last-child) {
    margin-right: spacing(3);
  }

  &__sender {
    @include type-scale(300);
  }

  &__share {
    cursor: pointer;

    &--ios {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 9999px;
    cursor: pointer;
    background-repeat: no-repeat;
    @include transition($property: opacity);

    @include hide-text;

    &--prev {
      right: 100%;
      opacity: 0;
      background-position: right spacing(2) center;
    }

    &--next {
      left: 100%;
      background-position: left spacing(2) center;
    }
  }
}
