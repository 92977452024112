.ef-featured-list {
  display: grid;
  gap: spacing(3) spacing(4);

  &--grid {

    @include break(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__item {
    display: flex;
    gap: spacing(4);
    padding-bottom: spacing(3);
    border-bottom: 1px solid var(--tone-back-600);

    &--link {
      @include transition($property: border-color);
      position: relative;
      padding-right: calc(#{spacing-no-calc(4)} + 24px);
    }

    &--link:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: spacing(3);
      width: 24px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" stroke="#{url-encoded-color($color--red-400)}" stroke-miterlimit="10"><line data-cap="butt" x1="2" y1="12" x2="22" y2="12" stroke-linecap="butt"></line> <polyline points="15,5 22,12 15,19 "></polyline></g></svg>');
      background-repeat: no-repeat;
      background-position: right center;
    }

    &:link:hover {
      border-color: var(--color-accent);
    }
  }

  &__item-content {
    width: 100%;
  }

  &__item-icon {
    color: var(--color-front);
  }

  &__item-title {
    margin-bottom: spacing(1);
  }

  &__item-text {
    margin-bottom: spacing(0);
  }
}
