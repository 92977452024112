.select2 {

  &-container {
    display: block;
    padding: 0;
    appearance: none;
    border-radius: 0;
    border: 0;
  }

  & &-selection {
    border: 1px solid rgba(black, 0.4) !important;
  }

  & &-selection--single {
    border: none;
    outline: none;
    border-radius: 0;
    padding: spacing(1) spacing(2) spacing(1);
    height: auto;
  }

  & &-selection__rendered {
    padding: 0 !important;
    color: var(--color-black) !important;
  }

  &-container--default &-results__option--highlighted[aria-selected] {
    background-color: var(--color-accent);
  }

  &-results__option {
    padding: spacing(1) spacing(2);

    &--highlighted {
      background-color: var(--color-accent);
    }

    i {
      position: relative;
      width: spacing(4);
      height: spacing(4);
      overflow: hidden;
      border-radius: 100%;
      display: inline-block;
      vertical-align: bottom;
      margin-right: spacing(1.5);
    }

    svg {
      position: relative;
      width: spacing(4);
      height: spacing(4);
      display: inline-block;
      vertical-align: bottom;
      margin-right: spacing(1.5);
    }

    img {
      vertical-align: top;
      max-width: spacing(4);
      height: auto;
      display: inline-block;
    }
  }

  & &-selection__arrow {
    display: none;
  }
}
