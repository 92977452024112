.ef-live-badge {
  display: inline;
  position: relative;
  padding: spacing(.5) spacing(1);
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
  font-family: font($font--subline);
  // font-weight: 700;
  // text-transform: uppercase;
  white-space: nowrap;
  @include type-scale(200);

  &--card {
    position: absolute;
    top: 0;
    left: 0;
  }
}
