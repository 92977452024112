[data-theme-2="gold"],
[data-theme-2="gold"] [data-theme="light"],
[data-theme-2="gold"] [data-theme="dark"],
[data-theme-2="gold"] [data-theme="inverted"] {
  --color-accent: var(--color-125-gold);
  --color-accent-shade: var(--color-125-gold);
  --color-accent-contrast: var(--color-white);
}

[data-theme-2="gold"] .ef-header,
[data-theme-2="gold"] .ef-account[data-theme="light"],
[data-theme-2="gold"] .ef-cart[data-theme="light"],
[data-theme-2="gold"] .ef-search[data-theme="light"],
[data-theme-2="gold"] .ef-menu,
[data-theme-2="gold"] .ef-primary-menu[data-theme="dark"],
[data-theme-2="gold"] .ef-secondary-menu[data-theme="light"],
[data-theme-2="shop"] .ef-page--account {
  --color-accent: var(--color-red-400);
  --color-accent-shade: var(--color-red-500);
  --color-accent-contrast: var(--color-white);
}

[data-theme-2="gold"] {

  .ef-link-button {

    &:before,
    &:after {
      height: 1px;
    }

    &--back {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--125-gold)}" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E') !important;
    }

    &--more {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--125-gold)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E') !important;
    }

    &--more-top,
    &--more-bottom.--is-active {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--125-gold)}"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>') !important;
    }

    &--more-bottom {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--125-gold)}"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>') !important;
    }

    &--logout {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--125-gold)}"><polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" data-color="color-2"></polygon> <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z"></path></g></svg>') !important;
    }

    &--ticket {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g fill="#{url-encoded-color($color--125-gold)}"><path fill-rule="evenodd" d="M.65 16.257l1.433 1.432c1.181-1.181 3.097-1.181 4.278 0 1.181 1.181 1.181 3.097 0 4.278L7.793 23.4c.39.39 1.023.39 1.414 0l8.392-8.392c.39-.39.39-1.023 0-1.414l-7.142-7.142c-.39-.39-1.024-.39-1.414 0L.65 14.843c-.39.39-.39 1.024 0 1.414zM11.736 5.173l7.142 7.142c.39.39 1.023.39 1.414 0l3.058-3.058c.39-.39.39-1.024 0-1.414L21.917 6.41c-1.181 1.181-3.097 1.181-4.278 0-1.181-1.181-1.181-3.097 0-4.278L16.207.7c-.39-.39-1.023-.39-1.414 0l-3.058 3.058c-.39.39-.39 1.024 0 1.414z"></path></g></svg>') !important;
    }
  }

  .ef-accordion__header:after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="#{url-encoded-color($color--125-gold)}" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  }
}
