.ef-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--left {

    .ef-toggle__label--right {
      color: var(--tone-medium);
    }
  }

  &--right {

    .ef-toggle__label--left {
      color: var(--tone-medium);
    }

    .ef-toggle__button-handle {
      transform: translateX(#{spacing(2)});
    }
  }

  &__label {
    font-weight: 700;
    text-transform: uppercase;
    @include type-scale(200);

    &--left {
      padding-right: spacing(1);
    }

    &--right {
      padding-left: spacing(1);
    }
  }

  &__button {
    width: spacing(5);
    height: spacing(3);
    padding: spacing(.5);
    border-radius: spacing(1.5);
    background-color: var(--color-accent);
  }

  &__button-handle {
    width: spacing(2);
    height: spacing(2);
    border-radius: 50%;
    background-color: var(--color-front);
    @include transition($property: transform);
  }
}
