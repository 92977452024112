.ef-icon-grid {

  @include break(tablet) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.ef-icon-card {
  @extend %card;
  @extend %card--has-line;
  margin-right: auto;
  margin-left: auto;
  padding-top: spacing(1.5);
  padding-bottom: spacing(1.5);
  cursor: pointer;
  text-align: center;

  &--no-link {
    cursor: auto;

    &:before,
    &:after {
      display: none;
    }
  }

  &__icon {
    @include transition($property: color);
    position: relative;
    width: 48px;
    margin-right: auto;
    margin-left: auto;
    color: var(--color-front);

    &--return {
      transform: scaleX(-1);
    }

    @include break(desktop-s) {

      .ef-icon-card:hover & {
        color: var(--color-accent);
      }
    }

    .ef-icon-card--no-link & {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: spacing(2);
      color: var(--color-accent);
    }

    .ef-shop-badge {
      width: 48px;
      height: 48px;
    }
  }

  &__text {
    @extend %text-container;
    padding-right: spacing(2);
    padding-left: spacing(2);
    color: var(--color-front);
    word-wrap: break-word;
    hyphens: auto;
    @include type-scale(300);

    .ef-icon-card--shop & {
      @include secondary-heading;
    }
  }

  .ef-badge {
    position: absolute;
    top: -8px;
    right: -8px;
  }
}
