@keyframes hero-more-button {
  0% {
    transform: translateX(0);
  } 20% {
    transform: translateX(4px);
  } 40% {
    transform: translateX(-4px);
  } 60% {
    transform: translateX(4px);
  } 80% {
    transform: translateX(-4px);
  } 100% {
    transform: translateX(0);
  }
}

@keyframes fullscreen-hero-player-bg {
  100% { opacity: 1 };
}

@keyframes fullscreen-hero-live-badge {
  0% { max-width: 0; }
  100% { max-width: 200px; }
}

section.ef-fullscreen-hero {
  margin-top: 0;
}

.ef-fullscreen-hero {
  @extend %boxed-padding;
  position: relative;
  margin-top: spacing(-12);
  margin-bottom: spacing(12);
  overflow: hidden;
  background-color: var(--dark-gray-800);

  &--player {
    background-color: var(--color-black);
  }

  &__marquee {
    position: absolute;
    top: 31.8%;
    right: 0;
    left: 0;
    overflow: hidden;
    transform: translateY(-50%);
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
  }

  &__marquee-row {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    --speed: '0s';
    transform: translate3d(var(--move-initial), 0, 0);
    animation: b-marquee var(--speed) linear infinite;

    &--reverse {
      animation-direction: reverse;
    }
  }

  &__marquee-item {
    @include type-scale(1000);
    padding: 0 .125ch;
    font-family: font($font--headline);
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
  
    &--filled {
      color: var(--color-white);
    }
  
    &--outlined {
      color: transparent;
      -webkit-text-stroke: 1px var(--color-white);
      text-stroke: 1px var(--color-white);

      @include break(desktop-s) {
        -webkit-text-stroke-width: 2px;
        text-stroke-width: 2px;
      }
    }

    .ef-fullscreen-hero__marquee--l & {
      @include type-scale(1100);
    }
  }

  // &--live {
  //   position: relative;

  //   .ef-fullscreen-hero__heading:before {
  //     content: "• ";
  //     color: var(--color-accent);
  //   }
  // }

  .ef-article & {
    margin-bottom: 0;
  }

  .ef-article__header & {
    @extend %reset-boxed-padding;
    margin-top: spacing(-12);
    //margin-bottom: 0;
  }

  &__live {
    @extend %boxed-padding;
    position: absolute;
    z-index: 1;
    top: spacing(6);
    left: 0;
    width: 100%;

    .ef-container {
      position: relative;
    }
  }

  &__live-badge {
    max-width: 0;
    overflow: hidden;
    animation: fullscreen-hero-live-badge 2s ease-in-out 2s forwards;
  }

  .ef-live-badge {
    @include type-scale(400);
    display: inline-block;
    padding: spacing(1) spacing(2) spacing(1);
  }

  &__link {
    display: block;
    position: relative;
    z-index: 3;
  }

  &__media {
    @extend %reset-boxed-padding;
    position: relative;
    height: 42.553191vh;
    min-height: 42vw;
    max-height: 100vw;
    overflow: hidden;

    .ef-fullscreen-hero--small & {

      @include break(desktop-s) {
        height: 25.96vw;
        min-height: 25.96vw;
      }
    }

    // @include break(desktop-s) {
    //
    //   .ef-fullscreen-hero--video & {
    //     height: 56.25vw;
    //   }
    // }

    //&:before,
    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
    }

    // &:before {
    //   display: none;
    //   top: 0;
    //   height: spacing(12);
    //   background-image: linear-gradient(to bottom, rgba($dark-gray-800, .8), rgba($dark-gray-800, 0));
    // }

    &:after {
      top: 50%;
      bottom: 0;
      background-image: linear-gradient(to top, rgba($dark-gray-800, 1), rgba($dark-gray-800, 0));

      .ef-fullscreen-hero--player & {
        background-image: linear-gradient(to top, rgba(black, 1), rgba(black, 0));
      }

      @include break(tablet) {
        top: 31.8%;
      }

      @include break(desktop-s) {

        .ef-fullscreen-hero--small & {
          top: 0;
        }
      }
    }
  }

  &__image,
  &__video {
    //@include fill;
    @include image-filter;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-position: center;
    object-fit: cover;
    animation: hero-ken-burns 19s ease-in-out forwards;

    .ef-fullscreen-hero--player & {
      opacity: .3;
      animation: fullscreen-hero-player-bg 4s ease-in-out forwards;
    }
  }

  &__foreground {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 10%;
    right: 0;
    bottom: 0;
    left: 0;

    .ef-container {
      @include fill;
      display: flex;
      justify-content: center;
      // display: flex;
      // flex-direction: column;
      // justify-content: flex-end;
      // align-items: center;
      // position: relative;
      // width: 100%;
      // flex-basis: 90%;
    }
  }

  &__foreground-image {
    position: absolute;
    bottom: 0;
    max-height: 100%;
  }

  &__content {
    @extend %ef-max-width-l-center;
    display: block;
    position: relative;
    z-index: 2;
    margin-top: -31.8vw;
    padding-bottom: spacing(6);

    @include break(tablet) {
      margin-top: -20vw;
    }

    @include ie11() {
      display: flex;
    }

    // .ef-article__header & {
    //   padding-bottom: 0;
    // }
  }

  &__main-link {
    @extend %card--has-line;
    @include fill;
    z-index: 1;

    @include break(desktop-s) {

      &:hover + .ef-fullscreen-hero__title .ef-fullscreen-hero__heading {
        color: var(--color-accent) !important;
      }
    }
  }

  &__title {
    overflow: hidden;
  }

  &__heading,
  &__paragraph,
  &__kicker {
    color: var(--color-white);
  }

  &__kicker,
  &__heading-text,
  &__text {
    opacity: 0;
    transform: translateY(24px);
    animation: ef-slide-fade-in-btt .7s ease-in-out forwards;
  }

  &__kicker {
    animation-delay: .5s;
  }

  &__heading-text {
    @include transition($property: color);
    padding-top: spacing(1);
    animation-delay: .8s;
  }

  &__text {
    animation-delay: 1.1s;

    .ef-fullscreen-hero--video & {
      position: relative;
      padding-right: calc(64px + #{spacing-no-calc(3)});
    }
  }

  &__paragraph {

    .ef-fullscreen-hero__text:last-child & {
      margin-bottom: 0;
    }

    .ef-fullscreen-hero--live & {
      @include type-scale(400);
    }

    // .ef-article__header & {
    //   margin-bottom: spacing(4) !important;
    // }
  }

  .ef-link-buttons {
    padding-top: spacing(2);
  }

  &__link-button {
    //display: inline-block;
    position: relative;
    // padding: spacing(2) calc(#{spacing-no-calc(6)} + 12px) spacing(2) spacing(4);
    // background-color: var(--color-accent);
    // background-image: none;
    color: var(--color-white) !important;
    //text-align: center;
    //@include transition($property: background-color);

    &:before {

      @include theme(light) {
        background-color: var(--color-white) !important;
      }
    }

    // @include break(desktop-s) {
    //   padding: spacing(3) spacing(4) spacing(5);
    // }
    //
    // &:hover {
    //   background-color: var(--color-red-500);
    //   color: var(--color-white) !important;
    // }
    //
    // &:hover:after {
    //   width: 16px;
    //   animation: hero-more-button 1s ease-in-out forwards;
    // }
    //
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   right: spacing(4);
    //   bottom: 0;
    //   left: auto;
    //   width: 16px;
    //   height: 100%;
    //   background-color: transparent;
    //   background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
    //   background-repeat: no-repeat;
    //   background-position: center;
    //
    //   @include break(desktop-s) {
    //     top: auto;
    //     right: auto;
    //     bottom: 16px;
    //     left: 50%;
    //     height: 16px;
    //     margin-left: -8px;
    //   }
    // }
    //
    // &:before {
    //   background-color: var(--color-white);
    // }
  }

  &__play {
    @include transition($duration: $duration-slower, $easing: $ease-out-back);
    display: inline-block;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 64px;
    height: 64px;
    color: var(--color-white);

    @include break(desktop-s) {

      &:hover {
        color: var(--color-accent);
        transform: scale(1.1);
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: rgba($color--black, .4);
    }

    svg {
      position: relative;
      z-index: 1;
    }
  }

  &__player {
    display: flex;
  }

  &__player-number {
    font-weight: 700;
  }

  &__player-name {
    flex: 1;
    position: relative;
    padding-left: 1ch;
    color: var(--color-white);
    @include ie11() {
      color: white!important;
    }

    &:before {
      content: "/";
      position: absolute;
      top: 0;
      left: 0;
      width: 1ch;
      opacity: .3;
      text-align: center;

      @include ie11() {
        display: none;
      }
    }
  }

  @include break(desktop-s) {
    margin-bottom: spacing(12) !important;

    // &--video {
    //   margin-bottom: spacing(2);
    // }

    &__media:after {
      background-image: linear-gradient(to top, rgba($dark-gray-800, 1), rgba($dark-gray-800, 0));
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 2fr;
      grid-template-areas:
        "title title title title ."
        "text  text  text  text text";
      grid-column-gap: spacing(3);
      align-items: baseline;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 0;

      @include ie11() {
        left: 25%;
      }

      //margin-bottom: spacing(12);
      //padding-bottom: 0;

      // .ef-article__header & {
      //   padding-bottom: spacing(6);
      // }
    }

    &__main-link {
      right: 33.333333%;
    }

    // &__kicker {
    //   grid-area: kicker;
    // }

    &__title,
    &__text {
      display: inline-block;
    }

    &__text {

      .ef-fullscreen-hero--video & {
        padding-right: 0;
      }
    }

    &__title {
      grid-area: title;
      @include max-width;
    }

    &__heading,
    &__paragraph {
      display: inline-block;
    }

    &__heading {
      //margin-bottom: 0;
    }

    &__text {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 2fr;
      grid-column-gap: spacing(3);
      justify-content: space-between;
      align-items: flex-end;
      grid-area: text;
      position: relative;
      @include ie11() {
        color: white!important;
      }
    }

    &__paragraph {
      grid-column: span 4;
      margin-bottom: 0;
    }

    .ef-link-buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 0;
    }

    .ef-link-button {
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-bottom: spacing(1);
      }
    }

    &__play {
      position: relative;
      bottom: auto;

      .ef-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
}
