.ef-video-card {
  @extend %card;
  display: flex;
  flex-direction: column;

  &--ad {
    @include ad;
  }

  &__banner {
    @include ad-banner(640, 512);
  }

  &__link {
    @include fill;
  }

  // &__media,
  // &__text {
  //   pointer-events: none;
  // }

  &__media {
    @extend .ef-media--16x9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ef-media__image {
    @include transition;

    @include break(desktop-s) {

      .ef-video-card:hover & {
        transform: scale(1.05);
      }
    }
  }

  &__video {
    opacity: 0;
    @include transition($property: opacity);

    @include break(desktop-s) {

      .ef-video-card:hover & {
        opacity: 1;
      }
    }
  }

  &__icon {
    @include transition($duration: $duration-slower, $easing: $ease-out-back);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    // right: spacing(4);
    // bottom: spacing(4);
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: rgba($color--black, .4);
    }

    svg {
      position: relative;
      z-index: 1;
    }

    // * {
    //   @include transition($duration: $duration-faster);
    // }

    // use [data-color="color-2"] {
    //   fill: $color--white !important;
    // }

    @include break(desktop-s) {

      .ef-video-card__media:hover & {
        color: var(--color-accent);
        transform: scale(1.1);
      }
    }
  }

  &__text {
    @extend %card;
    @extend %card--has-line;
    //position: absolute;
    flex: 1;
    padding-top: spacing(3);
    padding-right: spacing(3);
    padding-bottom: spacing(3);
    cursor: pointer;
    //left: 0;
    //width: 100%;
    //padding-right: calc(#{spacing-no-calc(8)} + 48px);
    //padding-left: spacing(3);

    @include break(desktop-s) {

      &:hover .ef-video-card__heading,
      &:hover .ef-heading {
        color: var(--color-accent);
      }
    }
  }

  &__kicker,
  &__heading,
  .ef-heading {
    color: var(--color-white);
  }

  &__heading,
  .ef-heading {
    @include transition;
    //@include type-scale(300);
    margin-bottom: spacing(1);
    padding-top: 0;

    span {
      @include card-blank-link;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // max-height: 0;
    margin-bottom: 0;
    //padding-top: spacing(1);
    //overflow: hidden;
    // @include transition($duration-slower, $property: max-height, $easing: ease-in-out);
    //
    // @include break(desktop-s) {
    //
    //   .ef-video-card:hover & {
    //     max-height: 100px;
    //   }
    // }
  }
}

#ef-icon--play-16 polygon,
#ef-icon--play-32 polygon,
#ef-icon--play-48 polygon,
#ef-icon--play-64 polygon {
  fill: var(--color-accent);
}
