@mixin ad() {
  display: flex !important;
  align-items: center;
  padding: spacing(4);
  background-color: var(--tone-back-700);
  background-image: url(../../assets/img/general/logo_monogram_1c_grey.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: spacing(12);
}

@mixin ad-banner($width: false, $height: false) {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &:before {
    content: attr(data-label);
    position: absolute;
    top: spacing(-4);
    left: 0;
    color: var(--tone-medium);
    line-height: spacing(4) !important;
    font-weight: 700;
    @include type-scale(200);
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;

    // @if $width {
    //   padding-top: calc(#{$height} / #{$width} * 100%);
    // }
  }

  > div > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
