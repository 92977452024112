.ef-match-table-grid {
  @extend %ef-max-width-l-center;
  align-items: center;

  > .ef-grid__item:last-child {

    @include break(desktop-s) {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: spacing(-2);
        width: 2px;
        background-color: var(--tone-700);
      }
    }
  }
}
