[type="search"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.ef-search-overlay {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - (#{spacing-no-calc(8)} + 4px));
  background-color: rgba($color--black, .7);

  &.--is-active {
    display: block;
    animation: ef-fade-in .4s ease-in-out forwards;
  }
}

.ef-search {
  @include fill(fixed);
  display: none;
  flex-direction: column;
  position: fixed;
  top: calc(#{spacing-no-calc(10)} + 4px);
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: z(search);

  &.--is-active {
    display: flex;
    //animation: ef-fade-in .4s ease-in-out forwards;
  }
}

.ais-SearchBox {

  .ef-header & {
    flex: 1;
    position: relative;
  }

  .ef-filter & {
    padding: 0;
  }
}

.ais-SearchBox-form {

  .ef-header & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    height: 100%;
    overflow: hidden;
    @include transition;
  }

  .ef-header__search.--is-active & {
    width: 100%;
  }
}

.ais-SearchBox-input,
.react-autosuggest__input {
  z-index: 1;
  border-bottom: 2px solid transparent;
  background-color: var(--tone-back-300);
  color: var(--color-front);
  @include type-scale(400);
  @include transition($property: border-color);

  &:focus {
    border-color: var(--color-accent);
  }

  .ef-header & {
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // width: 0;
    height: 100%;
    padding-top: spacing(1);
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
    @include transition;
  }

  .ef-header__search.--is-active & {
    width: 100%;
    padding-right: calc(#{spacing-no-calc(6)} + 16px);
    padding-left: calc(#{spacing-no-calc(6)} + 16px);
  }

  .ef-filter & {
    width: auto;
    margin-bottom: 0;
    appearance: none;
    border-bottom: 2px solid var(--color-front);
    border-radius: 0;

    &:focus {
      outline: 0;
      border-color: var(--color-accent);
    }
  }
}

// @keyframes quicklinks-morph {
//   100% {
//     right: -100vh;
//     bottom: -100vh;
//     left: -100vh;
//   }
// }

// Quicklinks/Autocomplete
.ef-search-dropdown,
.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  min-height: 1px;
  padding: spacing(3) 0;
  opacity: 0;
  cursor: auto;
  background-color: var(--color-white);
  @include type-scale(300);
  @include transition;

  &.--is-active {
    display: block;
    animation: ef-fade-in .3s ease-in-out forwards;
  }

  &.--is-morphing {
    right: -100vh;
    min-height: 100vh;
    left: -100vh;
  }

  .ef-search-quicklinks__heading {
    @include type-scale(200);
    margin-bottom: 0;
    padding: spacing(1) spacing(4) spacing(1) calc(#{spacing-no-calc(6)} + 16px);
    color: var(--color-black);
    text-transform: uppercase;
  }

  .ef-search-quicklink,
  .ais-Highlight {
    display: block;
    padding: spacing(1) calc(#{spacing-no-calc(6)} + 16px) spacing(1) calc(#{spacing-no-calc(6)} + 16px);
    cursor: pointer;
    color: var(--color-black);
    @include transition($property: background-color);

    &:hover {
      background-color: var(--tone-front-800);
    }
  }

  .ais-Highlight-highlighted,
  .ais-Highlight-nonHighlighted {
    // color: var(--color-black);
    @include transition($property: color);
  }
}

.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  right: spacing(4);
  bottom: 0;
  width: 16px;
  cursor: pointer;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11.5,10.1l-1.4,1.4L8,9.4l-2.1,2.1l-1.4-1.4L6.6,8 L4.5,5.9l1.4-1.4L8,6.6l2.1-2.1l1.4,1.4L9.4,8L11.5,10.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}

.ais-SearchBox-submit {
  display: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
