html {
  font-size: $rem-base / 16 * 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: font($font--primary);
  font-weight: 400;

  @include type-scale(400);
}
