.ef-tabs {

  &-items {
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--tone-back-600);
    margin-bottom: spacing(6);
  }

  &-item {
    margin-right: spacing(4);
    cursor: pointer;

    &__label {
      display: inline-block;
      color: var(--tone-front-300);
      padding: calc((0.3375rem + .1vw) * 1) 0;
      border-bottom: 4px solid transparent;
    }

    &.--is-active {
      .ef-tabs-item__label {
        color: var(--color-front);
        border-color: var(--color-accent);
      }
    }
  }

  &-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;

    &.--is-active {
      position: relative;
      opacity: 1;
      pointer-events: all;
    }
  }

}
