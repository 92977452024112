.ef-download-grid {

  @include break(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    grid-column: span 1;
  }
}
