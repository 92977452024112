.ef-mobile-sticky-ad {
  position: fixed;
  z-index: z(mobile-sticky-ad);
  bottom: 0;
  left: 0;
  width: 100%;
  //padding-left: spacing(3);
  overflow: hidden;
  background-color: var(--tone-back-700);
  @include transition;

  .--footer-visible & {
    transform: translateY(100%);
  }

  &__banner {
    display: none;
    position: relative;

    @media (max-width: 900px) and (orientation: portrait) {
      display: block;
    }

    // &:before {
    //   @include type-scale(200);
    //   content: attr(data-label);
    //   position: absolute;
    //   bottom: 0;
    //   left: spacing(-5.875);
    //   margin-bottom: spacing(-3);
    //   //padding-left: spacing(4.5);
    //   //width: 15%;
    //   color: var(--tone-medium);
    //   font-weight: 700;
    //   text-align: center;
    //   transform: translateX(spacing(3)) rotate(270deg);
    //   transform-origin: 0 0;
    //
    //   @media (max-width: 767px) and (orientation: portrait) {
    //     width: 15%;
    //   }
    //
    //   @media (max-width: 767px) and (orientation: landscape) {
    //     width: 11.111111%;
    //   }
    // }

    // &--portrait {
    //
    //   @media (max-width: 767px) and (orientation: portrait) {
    //     display: block;
    //   }
    //
    //   &:before {
    //     width: 15%;
    //   }
    // }
    //
    // &--landscape {
    //
    //   @media (max-width: 767px) and (orientation: landscape) {
    //     display: block;
    //   }
    //
    //   &:before {
    //     width: 11.111111%;
    //   }
    // }

    > * {
      width: 100%;
      vertical-align: bottom;
    }
  }
}
