*, *:before, *:after {
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
}

html {
  scroll-behavior: smooth;
}

body {
  display: block;
  background-color: var(--tone-back-800);

  @include theme(light) {
    background-color: var(--color-back);
  }

  &.--is-frozen,
  &.--no-scroll {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
*:focus,
:link:active,
:visited:active {
  outline: 0 !important;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
button {
  border: none;
  font-family: inherit;
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    outline: 0;
  }
}
