@for $i from 0 through 34 {

  .ef-league-table__stats-item[data-stats="#{$i}"] {
    flex: $i + 1;
  }
}

.ef-league-table {
  @include ie11() {
    display: table;
  }
  &__header,
  &__row {
    display: grid;
    grid-template-columns: spacing(10) spacing(3.5) repeat(4, 1fr);
    grid-column-gap: spacing(2);
    align-items: center;

    @include ie11() {
      display: table-row;
    }

    @include break(phablet) {
      grid-template-columns: spacing(10) spacing(3.5) repeat(5, 1fr);
    }

    @include break(tablet) {
      grid-template-columns: spacing(10) spacing(3.5) 1fr spacing(10) spacing(10) spacing(10) spacing(10);
    }

    @include break(desktop-s) {
      grid-template-columns: spacing(10) spacing(3.5) spacing(24) spacing(10) 3fr spacing(10) spacing(10) spacing(10) spacing(16);
    }

    &:nth-child(even) {
      background-color: rgba($color--white, .05);

      @include theme(light) {
        background-color: rgba($color--black, .05);
      }
    }
  }

  &__header {
    // position: sticky;
    // top: 0;
    // z-index: 3;
    // background-color: var(--tone-back-800);

    @include ie11() {
      display: table-header-group;
    }
  }

  &__header-item,
  &__cell {
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);
    @include ie11() {
      display: table-cell;
    }

    &--position,
    &--matches,
    &--goals,
    &--difference,
    &--points {
      text-align: center;
    }

    &--position {
      padding-left: spacing(2);
    }

    &--team:before {
      content: attr(data-token);

      @include break(tablet) {
        display: none;
      }
    }

    &--goals {
      display: none;

      @include break(phablet) {
        display: table-cell;
      }
    }

    &--form,
    &--stats {
      display: none;

      @include break(desktop-s) {
        display: table-cell;
      }
    }
  }

  &__row {
    position: relative;

    @include ie11() {
      display: table-row;
    }

    &--eintracht {
      color: var(--color-front);
      //background-color: var(--color-back);
    }

    &--eintracht:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: var(--color-accent);
    }

    &--marked {
      border-bottom: 1px solid var(--tone-back-300) !important;
    }

    &:first-child,
    &:last-child {
      border: none;
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(16),
    &:nth-child(17) {
      border-bottom: 1px solid var(--tone-medium);

      .ef-league-table--no-lines & {
        border-bottom: none;
      }
    }
  }

  &__header-item {
    font-weight: 700;
    text-transform: uppercase;
    @include type-scale(200);
  }

  &__cell {
    @include type-scale(300);

    &--position {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;

      .ef-league-table__row--eintracht & {
        color: var(--color-front);
      }
    }

    &--position,
    &--matches,
    &--goals,
    &--difference,
    &--points {

      .ef-league-table__row--live & {
        color: var(--color-accent);
        font-weight: 700;
      }
    }

    &--stats {
      font-weight: 700;
      @include type-scale(200);

      @include break(desktop-s) {
        display: flex;
      }
    }

    &--points {
      //color: var(--color-front);
      //font-weight: 700;
    }

    &--form {
      position: relative;
      padding-right: spacing(2);

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        right: spacing(2);
        left: 0;
        height: 1px;
        background-color: var(--tone-back-600);
      }

      @include break(desktop-s) {
        display: flex;
      }
    }
  }

  &__position {
    width: spacing(3);
    margin-right: spacing(1);
    text-align: center;
  }

  &__prev-position {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    color: var(--tone-back-300);

    &--up {
      color: var(--color-green);
    }

    &--same {
      color: var(--tone-back-300);
    }

    &--down {
      color: var(--color-red-400);
    }
  }

  &__prev-position-same {
    width: 10px;
    height: 10px;
    border: 2px solid var(--tone-back-300);
    border-radius: 50%;
    //background-color: var(--tone-back-300);
  }

  &__logo {
    display: flex;
    justify-content: center;
    //width: spacing(3);
    height: spacing(3.5);
  }

  &__logo-image {
    object-fit: contain;
  }

  &__team {
    display: none;

    .ef-league-table__row--eintracht & {
      font-weight: 700;
    }

    @include break(tablet) {
      display: block;
    }
  }

  &__stats-item {
    position: relative;
    z-index: 1;
    padding-right: spacing(1);
    padding-left: spacing(1);
    //font-weight: 700;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      //height: 4px;
    }

    &--won {
      color: var(--color-back);

      .ef-league-table__row--live & {
        color: var(--color-accent-contrast);
      }
    }

    &--drawn,
    &--lost {
      color: var(--color-front);

      .ef-league-table__row--live & {
        color: var(--color-accent-contrast);
      }
    }

    &--won:before {
      background-color: var(--color-front);

      .ef-league-table__row--live & {
        background-color: var(--color-accent);
      }
    }

    &--drawn:before {
      background-color: var(--tone-medium);

      .ef-league-table__row--live & {
        background-color: rgba($color--red-400, .6);
      }
    }

    &--lost:before {
      background-color: var(--tone-back-700);

      .ef-league-table__row--live & {
        background-color: rgba($color--red-400, .2);
      }
    }

    // &[data-stats="0"] {
    //   display: none;
    // }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    flex: 1;
    position: relative;
    height: spacing(4);

    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      height: 1px;
      background-color: var(--tone-back-600);
    }

    &:before {
      top: 3px;
    }

    &:after {
      bottom: 4px;
    }
  }

  &__form-dot {
    flex-basis: 23px;
    position: relative;
    z-index: 2;
    height: 23px;
    margin: -8px;
    padding: 8px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border-radius: 50%;
      background-color: var(--color-accent);
      @include transition($property: transform);
    }

    &:hover:after {
      transform: scale(1.5);
    }

    &--won {
      align-self: flex-start;
    }

    &--drawn {
      align-self: center;
    }

    &--lost {
      align-self: flex-end;
    }
  }

  &__form-line-wrapper {
    flex: 1;
    align-self: stretch;
    position: relative;
    z-index: 1;
  }

  &__form-line {
    position: absolute;
    right: -6px;
    left: -6px;

    &--top-to-top,
    &--middle-to-middle,
    &--bottom-to-bottom {
      height: 1px;
      background-color: var(--color-accent);
    }

    &--top-to-top {
      top: 3px;
    }

    &--middle-to-middle {
      top: calc(50% - 1px);
    }

    &--bottom-to-bottom {
      bottom: 3px;
    }

    &--top-to-bottom {
      top: 2px;
      bottom: 3px;
    }

    &--bottom-to-top {
      top: 3px;
      bottom: 1px;
    }

    &--middle-to-top,
    &--bottom-to-top,
    &--bottom-to-middle {
      background: linear-gradient(to bottom right, transparent calc(50% - 1px), $color--red-400 calc(50% - 1px), $color--red-400 50%, transparent 50%);
    }

    &--top-to-middle,
    &--top-to-bottom,
    &--middle-to-bottom {
      background: linear-gradient(to top right, transparent calc(50% - 1px), $color--red-400 calc(50% - 1px), $color--red-400 50%, transparent 50%);
    }

    &--top-to-middle {
      top: 2px;
      bottom: calc(50% - 1px);
    }

    &--middle-to-top {
      top: 3px;
      bottom: calc(50% - 2px);
    }

    &--middle-to-bottom {
      top: calc(50% - 1px);
      bottom: 3px;
    }

    &--bottom-to-middle {
      top: calc(50% - 1px);
      bottom: 3px;
    }
  }
}
