.ef-mainaqila-cta {
  @extend %boxed-padding;
  position: relative;
  padding-top: spacing(20);
  padding-bottom: spacing(8);
  overflow: hidden;
  background-image: url(https://design.eintracht.de/assets/img/mainaqila/cta_mobile.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;

  &--women {
    background-image: url(https://design.eintracht.de/assets/img/mainaqila/cta_mobile_women.jpg);
  }

  @include break(tablet) {
    padding-top: spacing(8);
  }

  @include break(desktop-s) {
    background-image: url(https://design.eintracht.de/assets/img/mainaqila/cta.jpg);
    background-position: center;

    &--women {
      background-image: url(https://design.eintracht.de/assets/img/mainaqila/cta_women.jpg);
    }
  }

  .ef-content--shop & {
    @include boxed-margin;
    margin-bottom: spacing(12);
  }

  // &:after {
  //   @include fill;
  //   content: "";
  //   background-color: rgba($grayscale--800, .6);
  // }

  &__video {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    @include break(desktop-s) {
      display: block;
    }
  }

  &__text {

    @include break(tablet) {
      max-width: 50vw;
    }

    @include break(desktop-s) {
      flex: 1;
      max-width: none;
      padding-right: spacing(8);
    }
  }

  .ef-container {
    position: relative;
    z-index: 1;

    // @include break(desktop-s) {
    //   display: flex;
    //   align-items: center;
    // }
  }

  &__heading {
    color: var(--color-white);
  }

  .ef-paragraph {
    color: var(--color-white);

    // @include break(desktop-s) {
    //   margin-bottom: 0;
    // }
  }
}
