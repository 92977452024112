.ef-video {

  &--mobile {

    @include break(desktop-s) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include break(desktop-s) {
      display: block;
    }
  }
}
