.ef-hero {
  padding-top: spacing(8);
  padding-bottom: spacing(4);

  .ef-container > :last-child {
    margin-bottom: 0;
  }

  &--low-level {
    @include row-gap(m);
  }

  &__title,
  &__intro {
    @extend %boxed-padding;
    position: relative;
    z-index: 2;
  }

  &__media {
    position: relative;
    margin-bottom: spacing(5);

    @include break(desktop-s) {
      margin-top: spacing(-15);
      margin-bottom: spacing(-10);

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        width: 100%;
        height: 15vw;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    .ef-media {
      overflow: hidden;
    }

    .ef-media__image,
    .ef-media__video {
      animation: ken-burns 19s ease-in-out forwards;
    }
  }

  &__content {
    padding-top: spacing(10);
  }
}
