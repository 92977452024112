@keyframes donut-circle-fill {
  to { stroke-dasharray: 0 100; }
}

.ef-donut {
  transform: rotate(-90deg);
  transform-origin: center;

  &__circle {
    opacity: 0;
    transform: scale(.94);
    transform-origin: center;

    .ef-player-stats__unit:nth-child(-n + 2).--is-in-view &,
    .ef-clipped__items.--is-active .ef-player-stats__unit:not(:nth-child(-n + 2)).--is-in-view & {
      opacity: 1;
      animation: donut-circle-fill 1s $ease-out-quad reverse;
    }

    .ef-player-stats__unit.--is-in-view & {

      @include break(tablet) {
        opacity: 1;
        animation: donut-circle-fill 1s $ease-out-quad reverse;
      }
    }

    .ef-player-stats--grid .ef-player-stats__unit.--is-in-view & {
      opacity: 1;
      animation: donut-circle-fill 1s $ease-out-quad reverse;
    }

    &--default {
      stroke: var(--tone-back-600);
    }

    &--primary {
      stroke: var(--color-accent);
    }

    &--secondary {
      stroke: var(--color-white);
      //animation-duration: 2.5s;
    }

    &--success {
      stroke: var(--color-green);
    }

    &--success-alternate {
      stroke: var(--color-green-tint);
    }

    &--failure {
      stroke: var(--color-red-400);
      //animation-duration: 2.5s;
    }

    &--yellow-card,
    &--2nd-yellow-card-yellow {
      stroke: var(--color-yellow);
    }

    &--red-card,
    &--2nd-yellow-card-red {
      stroke: var(--color-red-400);
      //animation-duration: 3s;
    }

    &--2nd-yellow-card-red {
      transform: scale(.88);
    }
  }
}
