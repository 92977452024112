.ef-gallery-modal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: z(overlay);
  overflow: hidden;
  background-color: rgba($color--black, .85);

  @include fill(fixed);

  &__close {
    position: fixed;
    top: spacing(4);
    right: spacing(4);
    cursor: pointer;
    color: var(--color-white);
  }

  &__inner {
    position: relative;
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    cursor: auto;
    color: var(--color-black);
  }

  .ef-content__header {
    padding-top: 0;
  }
}
