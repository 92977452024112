.ef-live {
  display: grid;
  gap: spacing(3);
  position: fixed;
  left: spacing(4);
  bottom: spacing(4);
  z-index: z(share);

  .--mobile-sticky-ad & {
    @media (max-width: 900px) and (orientation: portrait) {
      bottom: calc(#{spacing-no-calc(4)} + 15.733333vw);
    }
  }
}
