.ef-text-badge {
  @include type-scale(300);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 spacing(1);
  border-radius: spacing(.5);
  background-color: var(--color-front);
  color: var(--color-back);

  &--warn {
    background-color: var(--color-accent);
    color: var(--color-accent-contrast);
  }
}
