[data-theme-2="women"] {

  .ef-fullscreen-hero__branding {

    &:before {
      clip-path: inset(0 0 0 100%);
    }

    &:after {
      clip-path: inset(0 100% 0 0);
    }

    &--back-1:before {
      top: spacing(-1);
      left: spacing(-12);
      width: 47%;
      height: calc(#{spacing-no-calc(4)} + 30%);
      border: 3px solid var(--color-red-400);
      background-color: transparent;
      mix-blend-mode: normal;
      transition-delay: .4s;

      @include break(desktop-s) {
        width: 37%;
      }
    }

    &--back-1:after {
      bottom: spacing(-1);
      left: spacing(-12);
      width: 42%;
      height: spacing(16);
      background-color: var(--color-red-400);
      //mix-blend-mode: multiply;
      clip-path: inset(0 100% 0 0);

      @include break(desktop-s) {
        width: 33%;
      }
    }

    &--back-2:before {
      display: none;
      bottom: 0;
      left: 7%;
      width: 5%;
      height: 30%;
      background-color: var(--color-black);
      transition-delay: .2s;
      clip-path: inset(100% 0 0 0);
      clip-path: inset(0 0 100% 0);
    }

    &--back-2:after {
      top: 30%;
      left: spacing(-12);
      width: 30%;
      height: spacing(8);
      border: 3px solid var(--color-white);
      background-color: transparent;
      transition-delay: .2s;
      clip-path: inset(0 0 0 100%);

      @include break(desktop-s) {
        width: 20%;
      }
    }

    &--front-1:before {
      top: spacing(-1);
      right: spacing(-12);
      width: 40%;
      height: spacing(16);
      background-color: var(--color-red-400);
      mix-blend-mode: multiply;

      @include break(desktop-s) {
        width: 24%;
      }
    }

    &--front-1:after {
      bottom: 25%;
      right: spacing(-12);
      width: 26%;
      height: spacing(10);
      border: 3px solid var(--color-white);
      background-color: transparent;
      transition-delay: .2s;

      @include break(desktop-s) {
        width: 16%;
      }
    }

    &--front-2:before {
      top: auto;
      bottom: spacing(-1);
      right: spacing(-12);
      width: 40%;
      height: calc(25% + #{spacing-no-calc(7)});
      border: 3px solid var(--color-red-400);
      background-color: transparent;
      transition-delay: .2s;

      @include break(desktop-s) {
        width: 30%;
      }
    }

    &--front-2:after {
      display: none;
      top: 0;
      right: 16%;
      width: 1%;
      height: 33%;
      background-color: var(--color-red-400);
      transition-delay: .4s;
    }
  }
}
