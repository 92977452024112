.ef-calendar-subscription {
  display: grid;
  gap: spacing(2);

  &__item {
    display: grid;

    &:not(:last-child) {
      padding-bottom: spacing(2);
      border-bottom: 1px solid var(--tone-back-600);
    }

    @include break(phablet) {
      display: flex;
      gap: spacing(2);
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    color: var(--color-front);
  }

  &__actions {
    display: flex;
    gap: spacing(2);
  }
}
