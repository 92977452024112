.ef-shop-infos {
  padding-bottom: spacing(8);
  border-bottom: 1px solid var(--tone-back-500);

  @include break(tablet) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 
      "payment payment payment payment payment payment shipping shipping shipping shipping shipping shipping"
      "return return return return return return return return return return return return";
    grid-gap: spacing(4);
  }

  @include break(desktop-s) {
    grid-template-areas: "payment payment payment payment shipping shipping shipping shipping return return return return";
  }
}

.ef-shop-info {
  margin-bottom: spacing(4);

  &:not(:last-child) {
    padding-right: spacing(4);
  }

  @include break(tablet) {
    margin-bottom: 0;
  }

  &--payment {
    grid-area: payment;
  }

  &--shipping {
    grid-area: shipping;
  }

  &--return {
    grid-area: return;
  }

  &__heading {
    @include secondary-heading;
  }

  &__label {
    @include type-scale(200);
    margin-bottom: spacing(2);
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

.ef-payment-grid {
  display: inline-grid;
  grid-template-columns: 82px auto;
  gap: spacing(4);
  justify-content: start;
  align-items: start;
}

.ef-payment-logos,
.ef-shipping-logos {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: start;
  column-gap: 7px;
}

.ef-payment-logo {
  margin-top: -7px;

  &--amex {
    width: 82px;
    height: 82px;
    margin-top: 0;
  }

  // &--mastercard .ef-payment-logo__image {
  //   width: 56px / 90.61px * 116.5px;
  //   height: 56px;
  // }

  // &--mainpay .ef-payment-logo__image {
  //   width: 34px / 37.62px * 109.56px;
  //   height: 34px;
  // }
}

.ef-shipping-logo {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: spacing(2);
  margin-bottom: spacing(2);

  &__image {

    .ef-shipping-logo--dhl & {
      width: 80px;
      height: 12px;
    }

    .ef-shipping-logo--dpd & {
      width: 82.285714px;
      height: 36px;
    }
  }
}
