.ef-search-results {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  &__amount {
    margin-bottom: spacing(4);
    color: var(--tone-medium);
    @include type-scale(300);
  }
}

.ef-search-results-empty-state {
  @extend %reset-boxed-padding;
}

.ef-search-grid {
  grid-row-gap: spacing(4);

  &--shop {

    @include break(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include break(desktop-xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    &__item {
      grid-column: span 1;
    }
  }
}

.ais-Snippet {
  color: var(--tone-front-800);
}

.ais-Snippet-highlighted {
  background-color: rgba($color--red-400, .2);
  color: var(--color-front);
}
