.ef-hl-block {
  position: relative;
  margin-bottom: spacing(16);

  @include break(desktop-s) {
    margin-bottom: spacing(24);
  }

  &--light {
    padding-top: spacing(16);
    padding-bottom: spacing(16);
    background-color: $color--white;

    @include break(desktop-s) {
      padding-top: spacing(24);
      padding-bottom: spacing(24);
    }
  }

  &--center {
    text-align: center;

    .ef-heading,
    .ef-paragraph {
      @extend %boxed-padding;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
