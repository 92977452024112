.ef-list,
.ef-copy-block .ef-list {
  padding-left: spacing(6);

  &--s {
    @include type-scale(300);
  }

  &--l {
    @include type-scale(500);

    .ef-list__item {
      margin-bottom: spacing(2);
    }
  }

  &--grid {

    @include break(desktop-s) {
      display: flex;
      flex-wrap: wrap;

      .ef-list__item {
        flex-basis: 50%;
        padding-right: spacing(4);
      }
    }

    .ef-list__item {
      margin-bottom: spacing(2);
    }
  }
}

.ef-copy-block ul,
.ef-copy-block ol,
.ef-list {
  //@extend %boxed-padding;
  @extend p;

  > li,
  > .ef-list__item {
    position: relative;
    padding-left: spacing(4);

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: spacing(4);
    }
  }
}

.ef-copy-block ol,
.ef-list--ordered {
  counter-reset: list-counter;

  > li,
  > .ef-list__item {
    counter-increment: list-counter;
  }

  > li:before,
  > .ef-list__item:before {
    content: counters(list-counter, ".") ". ";
    padding-right: spacing(1);
    color: var(--color-accent);
    font-weight: 700;
  }
}

.ef-copy-block ul,
.ef-list--unordered {

  > li:before,
  > .ef-list__item:before {
    content: "—";
    padding-right: spacing(1);
    color: var(--color-accent);
    font-weight: 700;
  }
}

.ef-list--check,
.ef-copy-block .ef-list--check {

  > li:before,
  > .ef-list__item:before {
    content: "\2713";
  }
}
