.--has-tooltip:hover {
  z-index: 9999;
}

@keyframes tooltip-top-bottom {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(.8);
  } 100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.ef-tooltip {
  display: none;
  position: absolute;
  z-index: 9999;
  //width: spacing(24);
  padding: spacing(1.5) spacing(2);
  //opacity: 0;
  pointer-events: none;
  cursor: default;
  background-color: var(--color-front);
  color: var(--color-back);
  @include type-scale(200);
  line-height: spacing(2.5);

  .--has-tooltip:hover & {
    display: table;
    pointer-events: auto;
  }

  &--top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    .--has-tooltip:hover & {
      animation: tooltip-top-bottom .4s $ease-out-quad forwards;
    }
  }

  // &--top-left {
  //   bottom: calc(100% + #{spacing-no-calc(1.5)});
  //   left: 0;
  // }

  &--right,
  &--left {
    top: 50%;
    transform: translateY(-50%);
  }

  &--right {
    left: 100%;
  }

  &--left {
    right: 100%;
  }

  &--link {
    cursor: pointer;
    color: var(--color-back) !important;
    @include transition($duration-faster);

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-accent-contrast) !important;
    }

    &:hover b,
    &:hover strong {
      color: var(--color-accent-contrast);
    }
  }

  &__item {
    display: table;

    .ef-tooltip--auto & {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  b, strong {
    @include transition($duration-faster);
    color: var(--color-back);
  }
}
