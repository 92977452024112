// BREAKPOINTS
// ----------------------------------------------------------------------------

$breakpoints: (
  "phablet":     480px,
  "tablet":      600px,
  "real-tablet": 768px,
  "desktop-s":   990px,
  "desktop-m":   1280px,
  "desktop-l":   1600px,
  "desktop-xl":  1921px
);

:root {
  --break-phablet: (min-width: 480px);
  --break-tablet: (min-width: 600px);
  --break-real-tablet: (min-width: 600px);
  --break-desktop-s: (min-width: 990px);
  --break-desktop-m: (min-width: 1440px);
  --break-desktop-l: (min-width: 1680px);
  --break-desktop-xl: (min-width: 1921px);
}

@mixin break($breakpoint, $max: false) {

  @if map-has-key($breakpoints, $breakpoint) {

    $minmax: "min-width";
    $value: inspect(map-get($breakpoints, $breakpoint));

    @if $max {
      $minmax: "max-width";
      $value: inspect(map-get($breakpoints, $breakpoint) - 1px);
    }

    @media (#{$minmax}: #{$value}) {
      @content;
    }
  } @else {
    @error "No value found for `#{$breakpoint}` in `$breakpoints` map.";
  }
}

@mixin show($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @include break($breakpoint) {
      display: block;
    }
  } @else {
    @error "No value found for `#{$breakpoint}` in `$breakpoints` map.";
  }
}

@mixin hide($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @include break($breakpoint) {
      display: none;
    }
  } @else {
    @error "No value found for `#{$breakpoint}` in `$breakpoints` map.";
  }
}

@mixin ios {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin ie11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin webkitTouch() {
  @supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
    @include break(mobile-only) {
      @content;
    };
  }
}

@mixin hover($parent: false, $child: false, $pseudo: false) {
  @if $pseudo {
    @media (hover: hover) {
      &:hover:#{$pseudo} {
        @content;
      }
    }
  } @else if $parent {
    @media (hover: hover) {
      #{$parent}:hover & {
        @content;
      }
    }
  } @else if $child {
    @media (hover: hover) {
      &:hover #{$child} {
        @content;
      }
    }
  } @else {
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  }
}

.--mobile-only {

  @include break(desktop-s) {
    display: none !important;
  }
}

.--desktop-only {
  display: none;

  @include break(desktop-s) {
    display: block;
  }
}
