.ef-downloads {
  display: flex;
  flex-wrap: wrap;
  row-gap: spacing(3);
  //justify-content: center;
}

.ef-download {

  &:not(:last-child) {

    &:after {
      content: "/";
      padding-right: spacing(2);
      padding-left: spacing(2);
      color: var(--tone-back-600);
    }
  }

  &__link {
    padding-left: 20px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M8,12c0.3,0,0.5-0.1,0.7-0.3L14.4,6L13,4.6l-4,4V0H7v8.6l-4-4L1.6,6l5.7,5.7C7.5,11.9,7.7,12,8,12z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left top .333333em;
    font-weight: 700;
    @include type-scale(400);
  }
}
