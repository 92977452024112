.ef-link-button {
  //@extend .ef-text--link;
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: var(--color-front) !important;
  font-weight: 700;
  text-decoration: none !important;
  white-space: nowrap;
  @include type-scale(300);

  &:not(:last-child) {

    .ef-link-buttons & {
      margin-right: spacing(4);
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: var(--color-accent);
  }

  &:before {
    right: 0;
    width: 100%;
    background-color: var(--color-front);
    @include transition($property: width);
  }

  &:after {
    left: 0;
    width: 0;
    @include transition($property: width, $easing: $ease-in-quad, $delay: .3s);
  }

  @include break(desktop-s) {

    &:hover:before {
      width: 0;
    }

    &:hover:after {
      width: 100%;
    }
  }

  &--back,
  &--more-top,
  &--more-bottom {

    &:before,
    &:after {
      display: none;
    }
  }

  &--back {
    padding-left: 20px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: 400;
  }

  &--logout {
    padding-left: 20px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><path d="M11,14H3V2h8a1,1,0,0,0,0-2H3A2,2,0,0,0,1,2V14a2,2,0,0,0,2,2h8a1,1,0,0,0,0-2Z"></path><path d="M14.707,7.293l-3-3a1,1,0,0,0-1.414,1.414L11.586,7H6A1,1,0,0,0,6,9h5.586l-1.293,1.293a1,1,0,1,0,1.414,1.414l3-3A1,1,0,0,0,14.707,7.293Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: left center;
  }

  &--more {
    padding-right: calc(#{spacing-no-calc(1)} + 12px);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right center;
  }

  &--more-top,
  &--more-bottom.--is-active {
    padding-left: 20px;
    //padding-top: 16px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--red-400)}"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: left center;
    //background-position: center top;
  }

  &--more-bottom {
    padding-left: 20px;
    //padding-bottom: 16px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--red-400)}"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: left center;
    //background-position: center bottom;

    // &:before,
    // &:after {
    //   bottom: 14px;
    // }
  }

  &--logout {
    padding-left: 20px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" data-color="color-2"></polygon> <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: left center;
  }

  &--ticket {
    padding-left: 20px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g fill="#{url-encoded-color($color--red-400)}"><path fill-rule="evenodd" d="M.65 16.257l1.433 1.432c1.181-1.181 3.097-1.181 4.278 0 1.181 1.181 1.181 3.097 0 4.278L7.793 23.4c.39.39 1.023.39 1.414 0l8.392-8.392c.39-.39.39-1.023 0-1.414l-7.142-7.142c-.39-.39-1.024-.39-1.414 0L.65 14.843c-.39.39-.39 1.024 0 1.414zM11.736 5.173l7.142 7.142c.39.39 1.023.39 1.414 0l3.058-3.058c.39-.39.39-1.024 0-1.414L21.917 6.41c-1.181 1.181-3.097 1.181-4.278 0-1.181-1.181-1.181-3.097 0-4.278L16.207.7c-.39-.39-1.023-.39-1.414 0l-3.058 3.058c-.39.39-.39 1.024 0 1.414z"></path></g></svg>');
    background-size: 12px;
    // background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="#{url-encoded-color($color--red-400)}"><path d="M11,1H1A1,1,0,0,0,0,2v8a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V2A1,1,0,0,0,11,1ZM6,8H2V7H6Zm4-3H2V4h8Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: left center;
  }
}
