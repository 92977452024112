.ef-logo {
  margin-left: spacing(2);

  &--has-breadcrumbs {

    .ef-logo__monogram {
      display: none;
    }
  }

  &__image {
    width: spacing(28.782226);
    height: spacing(6);
  }

  &__monogram-front {
    fill: var(--color-red-400);
  }

  &__monogram-back {
    fill: var(--color-white);
  }

  &--has-breadcrumbs .ef-logo__wordmark,
  &__wordmark {
    display: none;
  }

  &__wordmark {
    fill: var(--color-white);

    @include break(desktop-s) {
      display: block;
    }
  }
}
