.ef-cta-group {

  &__top,
  &__bottom {

    // @include break(tablet) {
    //   text-align: right;
    // }
  }

  &__top {
    margin-bottom: spacing(2);
  }

  &__bottom {
    padding-top: spacing(2);
  }

  &__buttons {

    @include break(tablet) {
      display: flex;

      .ef-cart-wrapper & {
        display: block;
      }
    }
  }

  &__payment {
    padding-top: spacing(4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ef-cart & {
      display: grid;
      gap: spacing(2);
      justify-content: center;
    }

    .ef-payment-logos {
      flex-wrap: nowrap;
    }

    .ef-payment-logo {
      margin-bottom: 0;
    }

    .ef-payment-logo--amex {
      margin: 0 auto !important;
    }

    .ef-payment-logo--mastercard {
      margin-right: spacing(2);
    }
  }

  .ef-button {
    width: 100%;

    @include break(tablet) {
      width: auto;

      .ef-cart-wrapper & {
        width: 100%;
      }
    }

    &:not(:last-child) {
      margin-bottom: spacing(2);

      @include break(tablet) {
        margin-right: spacing(2);
        margin-bottom: 0;

        .ef-cart-wrapper & {
          margin-right: 0;
          margin-bottom: spacing(2);
        }
      }
    }
  }

  @include break(tablet) {

    &--flex .ef-cta-group__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: spacing(2);
    }

    &--flex .ef-button:first-child {
      order: 1;
      margin-bottom: 0;
    }

    &--flex .ef-button:last-child {
      order: 0;
    }
  }
}
