.ef-select {
  position: relative;

  select {
    padding: spacing(2.5);
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    width: 100%;
    outline: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: spacing(6);
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='%23000' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
  }
}
