.ef-clipped {

  &__items {
    position: relative;
    max-height: spacing(28);
    overflow: hidden;
    @include transition($duration-slower, $property: max-height);

    .ef-content__block--timeline & {
      max-height: spacing(42);
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 9999;
      right: 0;
      bottom: 0;
      left: 0;
      height: spacing(8);
      pointer-events: none;
      background-image: linear-gradient(to top, $dark-gray-800, rgba($dark-gray-800, 0));
      @include transition($property: opacity);

      @include theme(light) {
        background-image: linear-gradient(to top, $color--white, rgba($color--white, 0));
      }
    }

    &.--is-active {
      max-height: 2500px;
    }

    &.--is-active:after {
      opacity: 0;
    }
  }

  &__trigger {
    padding-top: spacing(2);
  }

  &__trigger-more {
    display: none;

    .ef-clipped__trigger.--is-active & {
      display: block;
    }
  }

  &__trigger-less {
    display: block;

    .ef-clipped__trigger.--is-active & {
      display: none;
    }
  }
}

@include break(desktop-s) {

  .ef-clipped--to-desktop-s {

    .ef-clipped__items {
      max-height: none;
      overflow: visible;
    }

    .ef-clipped__items:after {
      display: none;
    }

    .ef-clipped__trigger.--is-active {
      display: none;
    }
  }
}
