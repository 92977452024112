.ef-scrolltop {
  position: fixed;
  right: spacing(2);
  bottom: calc(#{spacing-no-calc(12)} + 48px);
  z-index: z(share);
  opacity: 0;
  pointer-events: none;
  transform: scale(.375);
  @include transition;
  transition-property: opacity, transform;

  &.--is-visible {
    opacity: 1;
    pointer-events: auto;
    transform: scale(.75);
  }

  .--share & {
    margin-bottom: calc(#{spacing-no-calc(2)} + 36px);
  }

  .--mobile-sticky-ad & {
    @media (max-width: 900px) and (orientation: portrait) {
      bottom: calc(#{spacing-no-calc(4)} + 15.733333vw);
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--color-front);
    color: var(--color-accent);
    @include transition($easing: $ease-out-back);

    @include theme(light) {
      color: var(--color-back);
    }

    &:hover {
      transform: scale(1.1) !important;
    }
  }
}
