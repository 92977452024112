.ef-order-confirmation {
  @extend %boxed-padding;
  @include fill;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  text-align: center;

  &__check {
    margin-bottom: spacing(2);
    color: var(--color-green);
  }

  &__backdrop {
    @include fill;
    @include image-filter;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:after {
      @include fill;
      content: "";
      background-color: rgba($color--black, .7);

      @include theme(light) {
        background-color: rgba($color--white, .9);
      }
    }
  }

  &__content {
    position: relative;
  }
}
