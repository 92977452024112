.ef-quotes {

  @include break(tablet) {
    display: grid;
    grid-template-columns: 1fr spacing(24);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "heading image"
      "text    image";
    grid-column-gap: spacing(4);

    @include ie11() {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__block {

    .ef-container__narrow-s {
      max-width: none;
    }
  }

  &__block ~ .ef-quotes__block {
    padding-top: spacing(6);
  }

  &__heading,
  &-heading {
    @include type-scale(700);
    margin-bottom: spacing(3);
    font-family: font($font--subline);
    font-weight: 400;
    letter-spacing: normal;
    text-align: center;

    @include break(tablet) {
      grid-area: heading;
      padding-left: spacing(6);
      text-align: left;
    }

    @include ie11() {
      order: 1;
      display: block;
    }

  }

  &__media-wrapper {
    width: 50%;
    margin: 0 auto spacing(4) auto;

    @include break(tablet) {
      grid-area: image;
      width: 100%;
      margin-bottom: 0;
      //padding-top: 23px;
    }

    @include ie11() {
      max-width: 33.3%;
      order: 2;
      display: block;
      margin: 0;
    }

  }

  &__media {
    @extend .ef-media--3x4;
  }

  &__text {

    @include break(tablet) {
      grid-area: text;
    }

    @include ie11() {
      order: 3;
      display: block;
    }

  }
}
