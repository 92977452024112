$container-mb-size: spacing(5);

.ef-content__block--order-review,
.ef-content__block--order-details {
  @extend .ef-content__header;
}

.ef-orders-details-container {
  border-bottom: 1px solid var(--tone-back-600);
  padding-bottom: $container-mb-size;
  .ef-content__block--order-details:last-child & {
    padding-bottom: 0;
  }
}

.ef-fast-checkout__item.--orders-details {
  padding-bottom: $container-mb-size;
  margin-bottom: $container-mb-size;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.ef-orders-review-container,
.ef-orders-details-container {
  max-width: spacing(100);
}

.ef-products--order-review {
  .ef-product {
    max-width: 100%;
    @include type-scale(300);

    &:last-child {
      margin-bottom: 0;
    }

    &--checkout-review,
    &--ticket {
      padding-bottom: 0;
      border: none;
    }
  }

  .ef-product__heading,
  .ef-product__infos {
    margin: 0;
    @include type-scale(300);
  }

  .ef-product__summary {
    display: block;

    @include break(tablet) {
      display: grid;
      grid-template-columns: 1fr spacing(25);
      grid-column-gap: spacing(2);
      grid-row-gap: spacing(.5);
      grid-template-areas:
      "a b"
      "c d";
    }
  }

  .ef-product__amount {
    @include type-scale(400);
    text-align: right;
    margin: 0;

    @include break(tablet) {
      align-self: start;
    }
  }

  .ef-product__actions {
    grid-area: d;
    @include type-scale(300);
  }
}

.ef-orders-review-item {
  border-bottom: 1px solid var(--color-front);
  margin-bottom: spacing(3);
  padding-bottom: spacing(3);

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.--tickets-overview {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.ef-order-summaries {
  margin-bottom: spacing(5);

  display: grid;
  grid-column-gap: spacing(1);
  grid-row-gap: spacing(1);
  grid-template-columns: 1fr spacing(15);
  grid-template-areas:
  "header header"
  "item-1 item-2";

  @include break(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  .ef-order-summary {

    &__top {
      grid-area: header;
      @include type-scale(300);
    }

    &__item {
      @include type-scale(300);
      &:last-child {
        text-align: right;
      }
    }
  }

  .ef-heading--order-review {
    text-transform: uppercase;
    max-width: 100%;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    svg {
      display: inline-block;
    }
  }
}

.ef-orders-details {
  &-items {
    &:not(:last-child) {
      margin-bottom: spacing(7);
    }
  }

  &-item {
    display: flex;
    @include type-scale(300);
    justify-content: space-between;
  }
}

.ef-cart__checkout--orders-details {
  @extend .ef-cart__checkout--checkout-review;
  margin-bottom: 0;
}

.ef-heading {
  &.--orders-details {
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.ef-fast-checkout__item {
  &.--orders-details {
    cursor: inherit;
  }
}

.ef-form-buttons.--account-orders-details {
  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
  .ef-button {
    width: 100%;
    @include break(phablet) {
      width: auto;
    }
  }
}

.ef-text--orders-details {
  margin-bottom: spacing(3);
}
