.ef-heading-and-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: spacing(4);

  @include break(tablet) {
    display: flex;
    justify-content: space-between;
  }

  .ef-section-header & {
    margin-bottom: 0;
  }

  .ef-heading {
    margin-bottom: spacing(1);
    
    &:not(:first-child) {
      padding-top: 0;
    }

    @include break(tablet) {
      margin-bottom: 0;
    }
  }

  &--women .ef-heading:not(:first-child) {
    padding-top: spacing(1.4);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: spacing(3);
    margin-bottom: spacing(1);

    @include break(tablet) {
      margin-bottom: 0;
    }

    .ef-heading {
      margin-bottom: 0;
    }
  }

  &__logo {
    max-width: spacing(24);
    max-height: spacing(8);
  }

  &__logo--pos {

    [data-theme="dark"] & {
      display: none;
    }
  }

  &__logo--neg {

    [data-theme="light"] & {
      display: none;
    }
  }
}
