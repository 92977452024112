.ef-embed {

  &--video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
  }

  .ef-copy-block & {
    margin-bottom: spacing(4);
  }

  iframe {
    max-width: 100%;

    &[src*="facebook.com"] {
      background: white;
    }
  }

  &--video iframe,
  &--video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
