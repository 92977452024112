.ef-checkout-addressitem {
  margin-bottom: spacing(4);

  .ef-form-item {
    margin: 0;
    width: auto;
    display: inline-block;

    .checkout-address-grid__item & {
      display: block;
    }
  }
}

.checkout-address-grid {
  @include break(tablet) {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: spacing(3);
  }
}

.ef-heading--checkout-address {
  margin-bottom: spacing(5);
}


.ef-fast-checkout.--checkout-address {
  margin-bottom: spacing(4);

  .ef-fast-checkout__text {
    font-size: inherit;
    line-height: inherit;
  }
}
