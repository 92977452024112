.ef-stepper {
  @include type-scale(400);
  width: 100%;
  position: relative;
  width: 100%;
  margin-bottom: spacing(2.5);
  padding: 0 spacing(2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include break(phablet) {
    max-width: spacing(24);
  }

  &__min,
  &__max {
    display: block;
    padding: spacing(1) 0;
    cursor: pointer;
    background: transparent;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.--is-disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  &__input {
    min-width: 0;
    padding: spacing(1) spacing(2);
    background-color: transparent;
    font-family: font($font--primary);
    text-align: center;
    @include type-scale(400);
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance:textfield;
}
