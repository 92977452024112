.ef-plus-badge {
  display: inline;
  position: relative;
  padding: spacing(.5) spacing(1);
  background-color: var(--color-white);
  color: var(--color-accent);
  font-weight: 700;
  text-transform: uppercase;
  @include type-scale(200);

  &--tv {

    &:before {
      content: "TV+";
    }
  }

  &--flex {

    &:before {
      content: attr(data-label);
    }
  }

  &--card {
    position: absolute;
    top: 0;
    right: 0;
  }
}
