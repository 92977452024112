.ef-shop-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: spacing(7);
  height: spacing(7);
  border: 2px solid var(--color-back);
  border-radius: 50%;
  font-family: font($font--subline-condensed);
  text-transform: uppercase;

  &--primary {
    @include type-scale(300);
    background-color: var(--color-accent);
    color: var(--color-accent-contrast);
  }

  &--secondary {
    @include type-scale(300);
    background-color: var(--color-front);
    color: var(--color-back);
  }

  &--competition,
  &--competition-white {
    border-color: transparent;
    border-radius: 0;

    .ef-competition-logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--competition {
    color: var(--color-front);
  }

  &--competition-white {
    opacity: .7;
    color: var(--color-white);
  }
}
