@keyframes ef-live-line {
  0% {
    right: 100%;
    left: 0;
  } 50% {
    right: 0;
    left: 0;
  } 100% {
    left: 100%;
  }
}

@keyframes ef-live-gradient-1 {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes ef-live-gradient-2 {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.ef-header,
.ef-header__hamburger {

  &.--scroll-down {
    transform: translateY(-100%);
  }
}

.ef-header {
  @extend %boxed-padding;
  // position: sticky;
  // top: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: z(header);
  pointer-events: auto;
  border-top: 4px solid var(--color-accent);
  background-color: var(--color-back);
  @include transition($property: transform);

  @include theme(light) {
    background-color: var(--tone-back-800);
  }

  &--login {
    right: calc(#{spacing-no-calc(4)} + 16px);
    border-color: transparent;
    background-color: transparent !important;
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   right: 0;
  //   left: 0;
  //   z-index: 1;
  //   height: spacing(15);
  //   background-image: linear-gradient(to bottom, $color--black, rgba($color--black, 0));
  // }

  &__bar {
    @extend %ef-max-width-l-center;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    position: relative;
    z-index: 1;
    height: spacing(10);
    // padding-top: spacing(1.5);
    // padding-bottom: spacing(1.5);
    //border-bottom: 1px solid var(--tone-back-600);

    .ef-header--login & {
      max-width: none;
    }
  }

  &__items {
    display: flex;
    align-items: center;

    &--primary {
      padding-right: spacing(4);

      @include break(tablet) {
        padding-right: spacing(16);
      }
    }

    &--secondary {
      position: relative;
      flex: 1;
      justify-content: flex-end;
      padding-right: 16px;

      .ef-header--cart & {
        padding-right: 0;
      }
    }
  }

  &__hamburger {
    // align-items: center;
    // position: absolute;
    // top: 0;
    // left: spacing(4);
    // bottom: 0;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    height: calc(#{spacing-no-calc(10)} + 4px);
    padding-right: spacing(3);
    padding-left: spacing(3);
    pointer-events: all;

    // @include break(tablet) {
    //   padding-right: spacing(6);
    //   padding-left: spacing(6);
    // }

    @include break(desktop-s) {
      padding-right: spacing(4);
      padding-left: spacing(4);
    }
  }

  &__story {
    @include type-scale(100);
    @include transition;
    display: flex;
    justify-content: center;
    align-items: center;
    width: spacing(8);
    height: spacing(8);
    margin-right: spacing(4);
    cursor: pointer;
    border: 2px solid var(--color-front);
    border-radius: 50%;
    background-color: transparent;
    color: var(--color-front);
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;

    @include break(desktop-s) {

      &:hover {
        border-color: var(--color-accent);
      }
    }
  }

  &__login,
  &__account,
  &__search {
    display: flex;
    position: relative;
    cursor: pointer;
    color: var(--color-front);
  }

  &__login {
    //display: none;
    opacity: 0;
    animation: ef-fade-in .0001s linear 1s forwards;
  }

  &__account {
    display: none;
    align-items: center;

    .ef-badge {
      position: absolute;
      top: -8px;
      right: -12px;
    }
  }

  &__account-name {
    display: none;
    padding-top: 0 !important;
    padding-left: spacing(1);
    font-weight: 700;
    @include type-scale(300);
    line-height: 16px !important;

    @include break(tablet) {
      display: block;
    }
  }

  // &__logo,
  // &__login,
  // &__search {
  //   @include transition(.7s, $property: transform, $easing: $ease-in-out-back);
  //
  //   &:hover {
  //     transform: scale(1.2);
  //   }
  // }

  &__logo {
    display: flex;
    margin-left: calc(#{spacing-no-calc(3)} + 24px);

    &--no-menu {
      margin-left: 0 !important;
    }

    @include break(tablet) {
      margin-left: 24px;
    }

    .ef-header--login & {
      margin-left: 0 !important;
    }

    @include break(desktop-s) {
      margin-left: 0;
    }
  }

  &__logo-tv,
  &__logo-minimal,
  &__logo-dbp-minimal,
  &__logo--women,
  &__logo-eintrachttech,
  &__logo-sge-ffc {
    height: spacing(6);
  }

  &__logo-full,
  &__logo-minimal {
    overflow: visible;
  }

  &__logo-full {
    display: none;

    &[data-theme="light"] {

      .ef-header--login & {
        display: block;

        @include break(desktop-s) {
          display: none;
        }
      }
    }

    &[data-theme="dark"] {

      .ef-header--login & {
        display: none;

        @include break(desktop-s) {
          display: block;
        }
      }
    }

    @include break(desktop-s) {
      display: block;
    }
  }

  &__logo-full {
    width: spacing(8 / 216 * 723.6);
    height: spacing(8);

    .ef-header.--has-breadcrumbs &,
    .ef-header--checkout & {

      @include break(desktop-s) {
        display: none;
      }
    }
  }

  &__logo-tv {
    width: spacing(12.510638);
  }

  &__logo-minimal {
    width: spacing(6);

    @include break(desktop-s) {
      display: none;
    }

    .ef-header:not(.ef-header--tv).--has-breadcrumbs &,
    .ef-header--checkout & {

      @include break(desktop-s) {
        display: block;
      }
    }
  }

  &__logo-women {
    width: spacing(29.039298);
  }

  &__logo-dbp {
    width: 24px / 5px * 40px;
    height: 24px;
    opacity: 0;

    @include break(tablet) {
      opacity: 1;
    }
  }

  &__logo-dbp-minimal {
    width: spacing(13.728351);

    @include break(desktop-s) {
      display: none;
    }
  }

  &__logo-touchdown {
    @include transition;
    width: spacing(12 / 448.73 * 597.75);
    height: spacing(12);
    margin-bottom: spacing(-4);

    .ef-header.--scroll-up &,
    .ef-header.--scroll-down & {
      width: spacing(8 / 448.73 * 597.75);
      height: spacing(8);
      margin-bottom: 0;
    }
  }

  &__logo-monogram-front {
    fill: var(--color-red-400);
  }

  &__logo-monogram-back {
    fill: var(--color-white);
  }

  &__logo-wordmark {
    fill: var(--color-front);
  }

  &__breadcrumbs {
    display: none;
    font-family: font($font--subline-condensed);
    font-weight: 400;
    @include type-scale(400);

    .ef-page--forum & {
      display: flex;
    }

    .ef-header.--has-breadcrumbs & {

      @include break(desktop-s) {
        display: flex;
      }
    }

    .ef-breadcrumb:first-child {
      padding-left: spacing(4);
    }

    .ef-breadcrumb:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: spacing(4);
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><polygon fill="#{url-encoded-color($grayscale--500)}" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8"></polygon></svg>');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__login,
  &__account,
  &__cart,
  &__search {
    display: flex;
    cursor: pointer;
    color: var(--color-front);
  }

  //&__fm,
  &__tv {
    position: relative;
    //width: 32px;
    //height: 32px;
    margin-right: spacing(4);
    overflow: hidden;
    cursor: pointer;
    //border-radius: 50%;
    background-color: var(--color-front);
    color: var(--color-accent);

    @include break(phablet) {
      margin-right: spacing(8);
    }

    &.--is-hidden {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 4px;
    }

    &:before {
      background-image: linear-gradient(to right, rgba($color--white, 0), rgba($color--white, .7));
      animation: ef-live-gradient-1 1.4s ease-in-out infinite;
    }

    &:after {
      //background-color: var(--color-accent-contrast);
      background-image: linear-gradient(to right, rgba($color--white, .7), rgba($color--white, 0));
      animation: ef-live-gradient-2 1.4s ease-in-out infinite;
    }

    &.--is-active:after,
    &.--is-active:before {
      display: none;
    }

    .ef-button__label {
      display: none;
      position: relative;
      white-space: nowrap;
      //padding-left: 14px;

      @include break(phablet) {
        display: block;
      }
    }
  }

  // //&__fm-play,
  // //&__fm-pause {
  //   width: 20px;
  //   height: 20px;
  //   margin-right: 0;
  //
  //   @include break(tablet) {
  //     margin-right: spacing(.75);
  //     margin-left: -4px;
  //   }
  // }

  &__tv {

    .ef-button__icon {
      width: 20px;
      height: 20px;
    }
  }

    // .ef-button__label:before {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   left: 0;
    //   width: 8px;
    //   height: 8px;
    //   margin-top: -4px;
    //   border-radius: 50%;
    //   background-color: var(--color-accent-contrast);
    //   animation: ef-pulse 1s ease-in-out infinite alternate-reverse;
    // }

    // .ef-button__icon {
    //   margin-left: 0;
    //
    //   @include break(phablet) {
    //     margin-left: spacing(.75);
    //   }
    // }

  // &__fm-progress {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   height: 2px;
  //   background-color: var(--color-accent-contrast);
  //   animation: ef-live-line 1s ease-in-out infinite;
  // }

  // &__fm-play,
  // &__fm-pause {
  //   //display: flex;
  //   //justify-content: center;
  //   //align-items: center;
  //   //@include fill;
  // }

  // &__fm-play {
  //
  //   .ef-header__fm.--is-active & {
  //     display: none;
  //   }
  // }

  // &__fm-pause {
  //   display: none;
  //
  //   .ef-header__fm.--is-active & {
  //     display: block;
  //   }
  // }

  &__tv-play {

    .ef-header__tv.--is-active & {
      display: none;
    }
  }

  &__tv-pause {
    display: none;

    .ef-header__tv.--is-active & {
      display: block;
    }
  }

  &__account {
    align-items: center;
  }

  &__account-name {
    padding-top: .2em;
    padding-left: spacing(1);
    font-weight: 700;
    @include type-scale(300);
  }

  &__login,
  &__account {
    position: relative;
    //margin-right: calc(#{spacing(2)} + 16px);
    margin-right: spacing(4);
    margin-left: spacing(2);

    .ef-page--forum &,
    .ef-header--checkout & {
      margin-right: 0;
    }
  }

  &__cart {
    position: relative;
    margin-right: spacing(4);
  }

  &__cart-badge {
    position: absolute;
    top: -8px;
    right: -12px;
  }

  &__cart {
    position: relative;
    margin-right: calc(#{spacing(2)} + 16px);
  }

  &__cart-badge {
    position: absolute;
    top: -8px;
    right: -12px;
  }

  &__search {
    //align-self: stretch;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 16px;
    //margin-left: spacing(2);
    //overflow: hidden;

    &.--is-active {
      @include transition;
      width: 100%;

      [data-theme-2="dbp"] & {
        width: 80vw;

        @include break(tablet) {
          width: 100%;
        }
      }
    }
  }

  &__search-icon,
  &__search-close {
    display: flex;
    align-items: center;
  }

  &__search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% - 16px);
    z-index: 1;
    @include transition;

    .ef-header__search.--is-active & {
      left: spacing(4);
    }
  }

  &__search-close {
    display: none;
    right: 0;
    padding-right: spacing(2);
    padding-left: spacing(2);
    //opacity: .7;
    cursor: pointer;
    background-color: var(--tone-back-600);
    color: var(--tone-front-400);
    @include type-scale(200);
    @include transition;

    .ef-header__search.--is-active & {
      display: flex;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__partners {
    display: none;
    align-items: center;
    margin-right: spacing(4);
    color: var(--color-front);

    @include break(tablet) {
      display: flex;
    }
  }

  &__partner {
    margin-right: spacing(2);
    margin-left: spacing(2);

    &--main {
      width: 102.200355px;
      height: 28px;
      margin-top: -4px;
    }

    &--outfitter {
      width: 55.555555px;
      height: 20px;
    }
  }

  &__languages {
    display: flex;
    align-items: center;
    gap: spacing(4);
  }

  &__language {
    @include transition;
    display: flex;
    align-items: center;
    gap: spacing(2);
    opacity: .7;
    color: var(--color-front);
    font-weight: 700;
    @include type-scale(300);
    cursor: pointer;

    &:hover,
    &.--is-active {
      opacity: 1;
    }

    .ef-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__language-label {
    display: none;

    @include break(phablet) {
      display: block;
    }
  }
}
