.ef-product {
  position: relative;
  display: flex;
  margin-bottom: spacing(4);
  padding-bottom: spacing(4);
  border-bottom: 1px solid var(--tone-back-700);

  @include break(tablet) {
    max-width: spacing(64);
  }

  &--tickets-overview {
    margin-bottom: spacing(3) !important;
    border-radius: spacing(1);
    background-color: var(--color-front);

    .ef-product__title,
    b, strong,
    .ef-product__arrow {
      color: var(--color-back);
    }

    .ef-product__info {
      color: var(--tone-back-300);
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    left: 0;
    top: 0;
  }

  &__media {
    position: relative;
    flex-basis: spacing(12);
    height: spacing(12);
    margin-right: spacing(2);
    padding: spacing(1);
    background-color: var(--tone-back-800);

    .ef-product--ticket & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__badge {
    @include type-scale(200);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(1);
    right: spacing(1);
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--tone-back-700);
    color: var(--color-front);
    font-weight: 700;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__ticket-logo {
    flex-basis: spacing(4.75);
    height: spacing(4.75);
    text-align: center;
  }

  &__ticket-logo-image {
    max-width: 100%;
    max-height: 100%;
  }

  &__content {
    flex: 1;
  }

  &__title {
    margin-bottom: spacing(1);
  }

  &__infos {
    @include type-scale(200);
    margin-bottom: spacing(2);
    color: var(--tone-front-600);
  }

  &__summary {
    display: grid;
    grid-template-columns: 1fr spacing(12);
    grid-gap: spacing(2);
    align-items: baseline;

    .ef-product--tickets-overview & {
      display: block !important;
    }

    .ef-form-item {
      margin-bottom: 0;
    }
  }

  &__season {
    padding-right: spacing(.5);
    padding-left: spacing(.5);
    background-color: var(--color-accent);
    color: var(--color-accent-contrast);
    font-weight: 700;
  }

  &__amount-label {

    .ef-product--ticket & {
      margin-bottom: 0;
    }
  }

  &__amount-field-wrapper {
    width: spacing(12);
  }

  &__price {
    @include type-scale(300);
    color: var(--color-front);
    font-weight: 700;
    text-align: right;

    &--new {
      color: var(--color-accent);
      font-weight: 700;
    }

    &--new:after {
      content: " ";
    }

    &--old {
      color: var(--tone-back-300);
      text-decoration: line-through;
    }
  }

  &__shipping {
    grid-column: span 2;
  }

  &__availability {

    &--high {
      color: var(--color-green);
    }

    &--low {
      color: var(--color-orange);
    }

    &--sold-out {
      color: var(--color-red-400);
    }

    &--info {
      color: var(--color-blue-gray);
    }

    &:before {
      content: "• ";
    }
  }

  &__actions {
    @include type-scale(200);
    text-align: right;
  }
}
