.ef-story-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-right: spacing(2);
  padding-left: spacing(2);
  cursor: pointer;

  &--featured {
    @extend %card;
    @extend %card--has-line;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;

    .ef-story-card__image:after {
      content: "";
      @include fill;
      background-color: rgba($color--black, .4);
    }
  }

  &__image {
    @include transition($property: border-color);
    position: relative;
    width: spacing(12);
    height: spacing(12);
    margin-bottom: spacing(2);
    overflow: hidden;
    border: 2px solid var(--tone-back-600);
    border-radius: 50%;

    @include break(tablet) {
      width: spacing(16);
      height: spacing(16);
    }

    @include break(desktop-s) {

      .ef-story-card:hover & {
        border-color: var(--color-accent);
      }
    }

    .ef-story-card--featured & {
      width: 100%;
      height: 0;
      margin-bottom: 0;
      padding-top: 100%;
      border: none;
      border-radius: 0;

      @include break(tablet) {
        width: 100%;
        height: 0;
        padding-top: percentage(2/3);
      }

      @include break(desktop-s) {
        padding-top: percentage(1/2.35);
      }
    }

    img {
      @include transition($property: transform);
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;

      @include break(desktop-s) {

        .ef-story-card:hover & {
          transform: scale(1.1);
        }
      }

      .ef-story-card--featured & {
        @include fill;
        width: 100%;
        height: 100%;
        border-radius: 0;

        @include break(desktop-s) {

          .ef-story-card:hover & {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  &__label {
    @include type-scale(300);
    color: var(--color-front);
    text-align: center;

    .ef-story-card--featured & {
      @include type-scale(500);
      position: absolute;
      right: spacing(4);
      bottom: spacing(3);
      left: spacing(4);
      color: var(--color-white);
      font-weight: 700;
      text-align: left;
    }
  }

  &__badge {
    @include type-scale(200);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(3);
    left: spacing(3);
    width: spacing(10);
    height: spacing(10);
    border: 2px solid var(--color-white);
    border-radius: 50%;
    color: var(--color-front);
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
  }
}
