.ef-person-card {
  @extend %card;

  &--has-link {
    @extend %card--has-line;
  }

  &__media {
    @extend .ef-media--1x1;
  }

  &__image {
    @include transition($property: transform);
    border-radius: 50%;

    @include break(desktop-s) {

      .ef-person-card--has-link:hover & {
        transform: scale(1.05);
      }
    }
  }

  &__text {
    @extend %text-container;
    padding-top: spacing(2);
    // padding-right: spacing(4);
    padding-bottom: spacing(4);
    text-align: center;

    a {
      @extend .ef-text--link;
    }
  }

  &__name {
    color: var(--color-front);
    font-weight: 700;
    @include type-scale(400);

    .ef-person-grid--l & {
      @include type-scale(600);
    }
  }

  &__role {
    @include type-scale(300);
  }

  &__contact {
    padding-top: spacing(2);
  }

  &__contact-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: spacing(1);

    @include break(desktop-s) {
      grid-template-columns: auto;
    }
  }

  &__contact-item {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include type-scale(300);

    // &:not(:last-child) {
    //   padding-bottom: spacing(1);
    //   border-bottom: 1px solid var(--tone-back-600);
    // }

    &--grid {

      @include break(desktop-s) {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: spacing(2);
      }
    }
  }

  &__contact-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: spacing(2);
  }

  &__contact-icon {
    @include transition($property: transform);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--color-accent);

    // @include hover {
    //   transform: scale(1.1);
    // }

    .ef-icon {
      color: var(--color-accent-contrast);
    }
  }

  &__copy {
    @include transition($property: opacity);
    display: none;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    opacity: .7;

    @include break(desktop-s) {
      display: flex;
    }

    @include hover {
      opacity: 1;
    }
  }

  &__contact-label {
    display: none;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    @include break(desktop-s) {
      display: block;
    }
  }
}
