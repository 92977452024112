// Brand colors
$color--red-400: #e30615;
$color--red-500: #c40812;
$color--red-600: #a40b0f;
$color--white: #fff;
//$color--black: #1b1a19;
$color--black: #000;

// --grayscale-100: #f5f5f5;
// --grayscale-200: #d6d6d6;
// --grayscale-300: #b8b8b8;
// --grayscale-400: #999;
// --grayscale-500: #323130;
// --grayscale-600: #292827;
// --grayscale-700: #252423;
// --grayscale-800: #201f1e;

// Grayscale
$grayscale--100: #f3f3f3;
$grayscale--200: #d4d5d7;
$grayscale--300: #b4b5b7;
$grayscale--400: #949597;
$grayscale--500: #747577;
$grayscale--600: #545557;
$grayscale--700: #343537;
$grayscale--800: #121212;
$grayscale--medium: #848587;

// Light grayscale
$light-gray--100: #9d9d9f;
$light-gray--200: #a9aaab;
$light-gray--300: #b5b6b7;
$light-gray--400: #c2c2c3;
$light-gray--500: #cececf;
$light-gray--600: #dadadb;
$light-gray--700: #e7e7e7;
$light-gray--800: #f3f3f3;

// Dark grayscale
$dark-gray--100: #656565;
$dark-gray--200: #595959;
$dark-gray--300: #4d4d4d;
$dark-gray--400: #717171;
$dark-gray--500: #595959;
//--dark-gray-600: #2a2a2a;
$dark-gray--600: #414141;
$dark-gray--700: #2a2a2a;
$dark-gray--750: #1e1e1e;
$dark-gray--800: #121212;
$dark-gray-800: #111;

// Light grayscale
$light-gray--100: #9d9d9f;
$light-gray--200: #a9aaab;
$light-gray--300: #b5b6b7;
$light-gray--400: #c2c2c3;
$light-gray--500: #cececf;
$light-gray--600: #dadadb;
$light-gray--700: #e7e7e7;
$light-gray--800: #f3f3f3;

// UI colors
$color--green: #009740;
$color--yellow: #ffca28;

// Social media colors
$color--facebook: #3b5998;
$color--twitter: #1da1f2;
$color--youtube: #ff0000;
$gradient--insta: linear-gradient(to right, #c13584, #f56040);

// Partner colors
$color--indeed: #2557A7;
$color--indeed-shade: #1d4586;

// VIP colors
$color--vip: #85754e;

// 125 years colors
$color--125-gold: #cfb257;

:root {

  // Brand colors
  --color-red-400: #e30615;
  --color-red-500: #c40812;
  --color-red-600: #a40b0f;
  --color-white: #FFF;
  --color-black: #000;

  // Grayscale
  --grayscale-100: #f5f5f5;
  --grayscale-200: #d6d6d6;
  --grayscale-300: #b8b8b8;
  --grayscale-400: #949597;
  --grayscale-500: #747577;
  --grayscale-600: #545557;
  --grayscale-700: #343537;
  --grayscale-800: #141517;

  // Dark grayscale
  --dark-gray-100: #656565;
  --dark-gray-200: #595959;
  --dark-gray-300: #4d4d4d;
  --dark-gray-400: #717171;
  --dark-gray-500: #595959;
  //--dark-gray-600: #2a2a2a;
  --dark-gray-600: #414141;
  --dark-gray-700: #2a2a2a;
  --dark-gray-750: #1e1e1e;
  --dark-gray-800: #121212;
  // --dark-gray-100: #77787a;
  // --dark-gray-200: #6a6a6c;
  // --dark-gray-300: #5c5d5f;
  // --dark-gray-400: #4f5051;
  // --dark-gray-500: #424344;
  // --dark-gray-600: #353536;
  // --dark-gray-700: #191919;
  // --dark-gray-800: #111;

  // Medium gray
  --medium-gray: #848587;

  // Light grayscale
  --light-gray-100: #9d9d9f;
  --light-gray-200: #a9aaab;
  --light-gray-300: #b5b6b7;
  --light-gray-400: #c2c2c3;
  --light-gray-500: #cececf;
  --light-gray-600: #dadadb;
  --light-gray-700: #e7e7e7;
  --light-gray-800: #f3f3f3;

  // UI colors
  --color-yellow: #ffeb3b;
  --color-orange: #fb8c00;
  --color-green: #0c8235;
  --color-green-tint: #3EAC63;
  --color-blue-gray: #90a4ae;

  // 125 years colors
  --color-125-gold: #cfb257;
}

:root,
[data-theme="dark"],
[data-theme="light"] [data-theme="inverted"],
[data-theme="light"] .ef-404,
[data-theme="light"] .ef-match-preview,
[data-theme="light"][data-theme-2="dbp"] .ef-content__block--image-and-text {
  --color-accent: var(--color-red-400);
  --color-accent-shade: var(--color-red-500);
  --color-accent-shade-2: var(--color-red-600);
  --color-accent-contrast: var(--color-white);
  --color-front: var(--color-white);
  --color-back: var(--color-black);
  --tone-100: var(--grayscale-100);
  --tone-200: var(--grayscale-200);
  --tone-300: var(--grayscale-300);
  --tone-400: var(--grayscale-400);
  --tone-500: var(--grayscale-500);
  --tone-600: var(--grayscale-600);
  --tone-700: var(--grayscale-700);
  --tone-800: var(--grayscale-800);
  --tone-back-800: var(--dark-gray-800);
  --tone-back-750: var(--dark-gray-750);
  --tone-back-700: var(--dark-gray-700);
  --tone-back-600: var(--dark-gray-600);
  --tone-back-500: var(--dark-gray-500);
  --tone-back-400: var(--dark-gray-400);
  --tone-back-300: var(--dark-gray-300);
  --tone-medium: var(--medium-gray);
  --tone-front-300: var(--light-gray-300);
  --tone-front-400: var(--light-gray-400);
  --tone-front-500: var(--light-gray-500);
  --tone-front-600: var(--light-gray-600);
  --tone-front-700: var(--light-gray-700);
  --tone-front-800: var(--light-gray-800);
}

[data-theme="light"],
[data-theme="dark"] [data-theme="inverted"],
[data-theme="dark"] .ef-forum-wrapper {
  --color-accent: var(--color-red-400);
  --color-accent-shade: var(--color-red-500);
  --color-accent-shade-2: var(--color-red-600);
  --color-accent-contrast: var(--color-white);
  --color-front: var(--color-black);
  --color-back: var(--color-white);
  --tone-100: var(--grayscale-800);
  --tone-200: var(--grayscale-700);
  --tone-300: var(--grayscale-600);
  --tone-400: var(--grayscale-500);
  --tone-500: var(--grayscale-400);
  --tone-600: var(--grayscale-300);
  --tone-700: var(--grayscale-200);
  --tone-800: var(--grayscale-100);
  --tone-back-800: var(--light-gray-800);
  --tone-back-750: var(--light-gray-800);
  --tone-back-700: var(--light-gray-700);
  --tone-back-600: var(--light-gray-600);
  --tone-back-500: var(--light-gray-500);
  --tone-back-400: var(--light-gray-400);
  --tone-back-300: var(--light-gray-300);
  --tone-medium: var(--medium-gray);
  --tone-front-300: var(--dark-gray-300);
  --tone-front-400: var(--dark-gray-400);
  --tone-front-500: var(--dark-gray-500);
  --tone-front-600: var(--dark-gray-600);
  --tone-front-700: var(--dark-gray-700);
  --tone-front-700: var(--dark-gray-750);
  --tone-front-800: var(--dark-gray-800);
}

[data-theme-2="dbp"],
[data-theme-2="dbp"] [data-theme="dark"],
[data-theme-2="dbp"] [data-theme="light"],
[data-theme-2="dbp"] [data-theme="inverted"],
[data-theme-2="waldstadion"],
[data-theme-2="waldstadion"] [data-theme="dark"],
[data-theme-2="waldstadion"] [data-theme="light"],
[data-theme-2="waldstadion"] [data-theme="inverted"] {
  --color-accent: var(--color-front);
  --color-accent-shade: var(--color-front);
  --color-accent-contrast: var(--color-back);
}

$color--dbp-accent: #0018A8;
$color--dbp-accent-shade: #000C47;

// [data-theme-2="dbp"] {
//   --color-accent: #0018A8;
//   --color-accent-shade: #001487;
// }

[data-theme-2="europa"],
[data-theme-2="europa"] [data-theme="dark"],
[data-theme-2="europa"] [data-theme="light"],
[data-theme-2="europa"] [data-theme="inverted"] {
  --color-accent: #FF6900;
  --color-accent-shade: #EB5500;
  --color-accent-contrast: var(--color-black);
}

// [data-theme-3="vip"],
// [data-theme-3="vip"] [data-theme="dark"],
// [data-theme-3="vip"] [data-theme="light"],
// [data-theme-3="vip"] [data-theme="inverted"] {
//   --color-accent: #85754e;
//   --color-accent-shade: #9a885b;
//   --color-accent-contrast: var(--color-white);
// }

@mixin theme($theme) {
  [data-theme="#{$theme}"] & {
    @content;
  }
}
