$base-unit: "(#{rem(5.4)} + .1vw)";

@function spacing($value) {
  @return calc(#{$base-unit} * #{$value});
}

@function spacing-no-calc($value) {
  @return #{$base-unit} * #{$value};
}

$grid-container: rem(1600);
$grid-gutter: rem(32);
$grid-unit: ($grid-container - $grid-gutter) / 12 - $grid-gutter;

@function grid-units($value) {
  @return $grid-unit * $value + $grid-gutter * ($value - 1);
}

// MAX-WIDTH
// ----------------------------------------------------------------------------

$max-width: #{spacing(96)};
$max-width--s: #{spacing(59)};

// Calculate the exact average between max-width-m and full-width dynamically
$max-width--l: calc(#{spacing-no-calc(90)} + ((100vw - #{spacing-no-calc(90)}) / 2));

$max-width--l: spacing(136);

$max-width--xl: spacing(176);

@mixin max-width($size: m) {

  @if $size == "m" {
    max-width: $max-width;
  }

  @if $size == "s" {
    max-width: $max-width--s;
  }

  @if $size == "l" {
    max-width: $max-width--l;
  }

  @if $size == "xl" {
    max-width: $max-width--xl;
  }
}

// ROW GAP
// ----------------------------------------------------------------------------

@mixin row-gap($size: m) {

  @if $size == "m" {
    padding-top: spacing(4);
    padding-bottom: spacing(4);
  }

  @if $size == "s" {
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  @if $size == "l" {
    padding-top: spacing(6);
    padding-bottom: spacing(6);
  }

  @if $size == "xl" {
    padding-top: spacing(12);
    padding-bottom: spacing(12);
  }
}

// BOTTOM GAP
// ----------------------------------------------------------------------------

@mixin bottom-gap($size: m) {

  @if $size == "m" {
    margin-bottom: spacing(4);
  }

  @if $size == "s" {
    margin-bottom: spacing(2);
  }

  @if $size == "l" {
    margin-bottom: spacing(6);
  }

  @if $size == "xl" {
    margin-bottom: spacing(12);
  }
}
