.ef-story-hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  //height: calc(100vh - #{spacing-no-calc(10)} - 3px);
  //min-height: 640px;
  height: 100%;
  max-height: 960px;
  margin-top: spacing(-6);
  padding-top: spacing(6);
  padding-bottom: spacing(6);
  overflow: hidden;

  @include break(desktop-s){
    height: calc(100vh - #{spacing-no-calc(10)} - 3px);
    min-height: 640px;
  }

  &__media {
    @include fill;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to top, rgba(black, .8), rgba(black, .0001));
    }

    img,
    video {
      @include fill;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    img {
      animation: hero-ken-burns 19s ease-in-out forwards;
    }
  }

  &__cta {
    @include center(absolute);
    @include type-scale(450);
    display: flex;
    justify-content: center;
    align-items: center;
    width: spacing(16);
    height: spacing(16);
    cursor: pointer;
    color: var(--color-white);
    font-family: font($font--subline);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;

    @keyframes story-cta {
      0% {
        transform: none;
      } 100% {
        transform: scale(1.1);
      }
    }

    &:before {
      @include fill;
      @include transition($property: background-color);
      content: "";
      border: 2px solid var(--color-accent);
      border-radius: 50%;
      animation: story-cta 1s ease-in-out infinite alternate-reverse;
    }

    &:hover:before {
      background-color: var(--color-accent);
    }
  }

  &__cta-label {
    @include transition($property: color);
    position: relative;

    .ef-story-header__cta:hover & {
      color: var(--color-accent-contrast);
    }
  }

  &__content {
    @extend %boxed-padding;
    position: relative;

    > :last-child {
      margin-bottom: 0;
    }
  }

  .ef-kicker,
  &__heading {
    opacity: 0;
    transform: translateY(24px);
    animation: ef-slide-fade-in-btt .7s ease-in-out forwards;
  }

  .ef-kicker {
    animation-delay: .5s;
  }

  &__heading {
    @include max-width(l);
    @include type-scale(950);
    animation-delay: .8s;
  }
}
