.ef-raffle-win {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(3) 0;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    margin-bottom: spacing(3);
    border-radius: 50%;
    background-color: var(--color-green);
    color: var(--color-white);
  }

  &__message {
    text-align: center;
  }
}
