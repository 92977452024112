.ef-skeleton {
  @include skeleton-svg;

  &--live-ticker {
    @include skeleton-live(24);

    &:not(:last-child) {
      margin-bottom: spacing(4);
    }
  }

  &--formation {
    padding-top: percentage(516.05 / 293.32);
    @include skeleton-live(0);

    @include break(desktop-s) {
      padding-top: percentage(293.32 / 516.05);
    }
  }

  &--line-up {
    @include skeleton-live(128);
  }

  &__spinner {
    @include skeleton-spinner;
  }
}
