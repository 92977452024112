@keyframes ef-snackbar {
  0% {
    opacity: 0;
    transform: scale(.9);
  } 33.333333% {
    opacity: 0;
    transform: scale(.9);
  } 75% {
    transform: scale(1.04);
    opacity: 1;
  } 100% {
    transform: scale(1);
  }
}

.ef-snackbars {
  position: fixed;
  z-index: z(snackbar);
  right: spacing(2);
  bottom: spacing(2);
  pointer-events: none;

  &--center {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: spacing(2);
    pointer-events: all;
    background-color: rgba(black, .8);
  }

  &--bottom-left {
    left: spacing(2);
    bottom: spacing(2);
  }

  .ef-forum-wrapper & {
    top: calc(#{spacing-no-calc(2)} + 72px);
  }
}

.ef-snackbar {
  max-width: spacing(64);
  margin-left: spacing(2);
  padding: spacing(3) calc(#{spacing-no-calc(4)} + 16px) spacing(3) calc(#{spacing-no-calc(4)} + 4px);
  border-left: 4px solid var(--color-accent);
  pointer-events: all;
  animation: ef-snackbar .9s $ease-in-back forwards;

  .ef-snackbars--bottom-left & {
    margin-right: spacing(2);
    margin-left: 0;
  }

  .ef-forum-wrapper & {
    max-width: spacing(48);
  }

  &:not(:last-child) {
    margin-bottom: spacing(1);
  }

  &--inverted {
    max-width: spacing(72);
    background-color: var(--color-back);
    color: var(--color-front);
  }

  &--info {
    background-color: var(--light-gray-800);
    color: var(--color-black);
  }

  &--success {
    border-color: $color--green;
    background-color: #cee6d7;
  }

  &--error {
    border-color: var(--color-red-400);
    background-color: #f9ced0;
  }

  &--forum {
    background-color: #f8f2e0;
  }

  &__title {
    font-family: font($font--subline);
    letter-spacing: normal;
    text-transform: uppercase;
  }

  &__subheading {
    padding-top: spacing(2);
    margin-bottom: spacing(1);
  }

  &__actions,
  &__action {
    display: inline;
  }

  &__text {
    @include type-scale(300);
    display: block;
    color: var(--color-black);

    .ef-snackbar--inverted & {
      color: var(--color-front);
    }

    &--s {
      @include type-scale(200);
      margin-bottom: spacing(1);
    }
  }

  &__action {
    @include type-scale(300);
    //margin-left: spacing(2);
    cursor: pointer;
    font-weight: 700;

    &:not(:last-child) {
      margin-right: spacing(4);
    }

    .ef-snackbar--info & {
      color: var(--color-back);
    }

    .ef-snackbar--success & {
      color: var(--color-green);
    }

    .ef-snackbar--error & {
      color: var(--color-red-400);
    }
  }

  &__simple-cta {
    padding-top: spacing(2);

    .ef-text--accent-strong {
      cursor: pointer;
    }
  }

  &__close {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    cursor: pointer;

    .ef-snackbar--info & {
      color: var(--color-black);
    }

    .ef-snackbar--success &,
    .ef-snackbar--error &,
    .ef-snackbar--forum & {
      color: var(--color-black);
    }

    .ef-snackbar--inverted & {
      color: var(--color-front);
    }
  }

  .ef-heading,
  .ef-icon-and-text__item,
  .ef-icon-and-text__item strong {
    color: var(--color-black);
  }

  .ef-button {
    margin-top: spacing(2);
  }
}

.ef-snackbar--inverted {
  
  .ef-heading,
  .ef-icon-and-text__item,
  .ef-icon-and-text__item strong {
    color: var(--color-front);
  }
}
