.ef-sport-grid {
  grid-template-columns: repeat(3, 1fr);

  @include break(phablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include break(tablet) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(8, 1fr);
  }

  // @include break(desktop-m) {
  //   grid-template-columns: repeat(10, 1fr);
  // }
  //
  // @include break(desktop-l) {
  //   grid-template-columns: repeat(12, 1fr);
  // }

  &__item {
    grid-column: span 1;
  }
}
