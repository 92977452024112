.ef-fast-checkout {
  @include max-width(m);

  &__item {
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: spacing(2);
      padding-bottom: spacing(2);
      border-bottom: 1px solid var(--tone-back-700);
    }

    &--coupon {
      border: none !important;
    }
  }

  &__heading {
    margin-bottom: 0;
  }

  &__text {
    @include type-scale(300);
    padding-top: spacing(1);
    color: var(--color-front);
  }

  &__icon {
    @include transition($property: color);
    position: absolute;
    top: 50%;
    right: 0;
    width: 16px;
    height: 16px;
    margin-top: -16px;
    color: var(--color-front);

    .ef-fast-checkout__item:hover & {
      color: var(--color-accent);
    }
  }

  &-coupon {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: spacing(4);
    align-items: baseline;

    &__item {
      &:last-child {
        text-align: right;
      }
    }
  }
}
