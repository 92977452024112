.ef-prices-summary {
  padding-bottom: spacing(3);

  &--subtotal {
    margin-bottom: spacing(3);
    border-bottom: 2px solid var(--tone-back-600);
  }

  &__item {
    display: flex;
    color: var(--color-front);

    &:not(:last-child) {
      margin-bottom: spacing(2);
    }

    &--standard,
    &--subtotal {
      @include type-scale(300);
    }

    &--subtotal {
      font-weight: 700;
    }

    &--legal {

      .ef-hint {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &--sum {
      @include type-scale(400);
      font-weight: 700;
    }
  }

  &__legal {

    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }

  &__label {
    flex: 1;
    padding-right: spacing(4);
  }

  &__price {
    flex-basis: spacing(16);
    text-align: right;
  }
}
