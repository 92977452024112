.ef-squad-stats {
  @media(min-width:990px) {
    td .ef-icon {
      display: none!important;
    }

    &__last-name-mobile {
      display: none;
    }
  }

  @media(max-width:989px) {
    [data-name]:before {
      position: relative;
    }
    [data-name="Nr."]:before,
    [data-name="Name"]:before,
    [data-name="Vorname"]:before {
      display: none;
    }
    .ef-data-table__row {
          &:nth-of-type(2n) {
            background: var(--color-black);
          }
    }
    &__cell {
      padding-left: spacing(1) !important;
      &--number {
        grid-area: number;
        padding: 0;
        position: absolute!important;
      }
      &--last-name {
        grid-area: last-name;
      }
      &--first-name {
        grid-area: first-name;
        left: 0;
        margin-left: spacing(10);
        margin-bottom: spacing(1);
      }
      &--position {
        grid-area: position;
      }
      &--nationality {
        grid-area: nationality;
      }
      &--matches {
        grid-area: matches;
      }
      &--minutes {
        grid-area: minutes;
      }
      &--goals {
        grid-area: goals;
      }
      &--assists {
        grid-area: assists;
      }
      &--yellow-cards {
        grid-area: yellow-cards;
      }
      &--2nd-yellow-cards {
        grid-area: second-yellow-cards;
      }
      &--red-cards {
        grid-area: red-cards;
      }
      &--yellow-cards svg,
      &--2nd-yellow-cards svg,
      &--red-cards svg {
        margin-right: spacing(1);
      }
    }
  }
  @media(max-width:599px) {
    .ef-data-table__row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
          "first-name first-name first-name"
          "position nationality matches"
          "minutes goals assists"
          "yellow-cards second-yellow-cards red-cards";
    }
  }
  @media(min-width:600px) and (max-width:989px) {
    .ef-data-table__row {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr 2fr 2fr 2fr;
        grid-template-areas:
          "first-name first-name first-name . . ."
          "position nationality matches minutes goals assists"
          "yellow-cards second-yellow-cards red-cards . . .";
    }
  }

  &__header-cell,
  &__cell {

    @include break(desktop-s) {
      &--number,
      &--matches,
      &--minutes,
      &--goals,
      &--assists,
      &--yellow-cards,
      &--2nd-yellow-cards,
      &--red-cards {
        text-align: center;
      }
    }
  }

  &__cell {
    //white-space: nowrap;
    position: relative;
    padding-left: 50%;

    @include break(tablet) {
      padding-left: 33.333333%;
    }

    @include break(desktop-s) {
      padding-left: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      padding-right: spacing(2);

      @include break(tablet) {
        width: 33.333333%;
      }
    }

    &--number {
      color: var(--color-front);
      font-weight: 700;
    }

    &--last-name,
    &--first-name {
      color: var(--color-front);
      font-weight: 700;
    }

    &--last-name {
      display: none;

      @include break(desktop-s) {
        display: table-cell;
      }
    }

    &--nationality {
      position: relative;
    }
  }

  &__nationality-flag {
    position: absolute;
    top: spacing(1.75);
    left: 0;
    width: 24px;
    height: 24px;
  }

  &__nationality-name {
    padding-left: calc(#{spacing-no-calc(2)} + 24px);
  }
}
