.ef-page {
  min-height: 100%;
  padding-top: calc(#{spacing-no-calc(10)} + 4px);
  //overflow-x: hidden;
  //background-color: var(--tone-back-800);
  color: var(--tone-200);

  &--product-detail {
    padding-top: 0;
  }

  .ef-gallery-modal {
    padding-top: calc(#{spacing-no-calc(10)} + 4px);
  }

  .ef-content {
    min-height: calc(100vh - #{spacing-no-calc(10)} - 3px);
  }

  &--product-detail .ef-content {
    min-height: 100vh;
    padding-top: 0;
  }

  &.ef-webview {
    padding-top: 0;
  }

  &.--has-submenu,
  &.--has-submenu .ef-gallery-modal {
    padding-top: calc(#{spacing-no-calc(16.5)} + 3px);

    .ef-content {
      min-height: calc(100vh - #{spacing-no-calc(16.5)} - 3px);
    }
  }
}
