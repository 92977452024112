.ef-container {

  &--m {
    @extend %ef-max-width-l-center;
  }

  &--l {
    @extend %ef-max-width-l-center;
  }

  &--xl {
    @extend %ef-max-width-xl-center;
  }

  &__narrow-m {
    @include max-width(m);
  }

  &__narrow-s {
    @include max-width(s);
  }

  &__narrow-center {
    margin: 0 auto;
  }
}
