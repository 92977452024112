.ef-search-card {
  @extend %card;
  @extend %card--has-line;
  // display: grid;
  // grid-template-columns: 1fr spacing(20);
  // grid-column-gap: spacing(8);
  padding-bottom: spacing(2);
  cursor: pointer;
  @include max-width(m);

  &__link {
    @include fill;
  }

  &__continent {
    flex: 1;
    text-align: right;

    &:before {
      display: none;
    }
  }

  &__heading,
  &__text,
  &__breadcrumbs {

    // @include break(tablet) {
    //   padding-right: spacing(16);
    // }
  }

  &__heading {
    @include transition($property: color);

    @include break(desktop-s) {

      .ef-search-card:hover & {
        color: var(--color-accent);
      }
    }
  }

  &__media {
    @extend .ef-media--3x2;
    display: none;
    background-color: var(--color-back);

    @include break(tablet) {
      display: block;
    }
  }

  &__text {
    margin-bottom: spacing(2);
  }
}
