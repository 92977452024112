::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-back);
  opacity: .6; /* Firefox */

  [data-theme="light"] & {
    color: var(--color-front);
  }

  [data-theme="dark"] .ef-forum-wrapper & {
    color: var(--color-black);
  }
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--color-back);
  opacity: .6;

  [data-theme="light"] & {
    color: var(--color-front);
  }

  [data-theme="dark"] .ef-forum-wrapper & {
    color: var(--color-black);
  }
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-back);
  opacity: .6;

  [data-theme="light"] & {
    color: var(--color-front);
  }

  [data-theme="dark"] .ef-forum-wrapper & {
    color: var(--color-black);
  }
}

select::-ms-expand {
    display: none;
}

@include ie11() {
  select option {
      background: white !important;
      color: black !important;
  }
}
// .ef-form-item {
//
//   &__field {
//     padding-top: spacing(.5);
//     padding-bottom: spacing(.5);
//     cursor: pointer;
//     appearance: none;
//     border: none;
//     border-bottom: 1px solid var(--color-front);
//     border-radius: 0;
//     background-color: transparent;
//     color: var(--color-front);
//     @include type-scale(300);
//     @include transition($duration-faster);
//
//     &:focus {
//       outline: 0;
//       border-color: var(--color-accent);
//     }
//   }
// }

.ef-form-item {
  flex: 1;
  position: relative;
  margin-bottom: spacing(3);
  @include max-width(m);

  &--password {

    .ef-form-item__icon {
      pointer-events: all;
      cursor: pointer;
    }

    .ef-form-item__icon .ef-icon--pw-show,
    .ef-form-item__icon .ef-icon--pw-hide {
      display: none;
    }

    .ef-form-item__icon.--pw-hidden {

      .ef-icon--pw-show {
        display: block;
      }
    }

    .ef-form-item__icon.--pw-visible {

      .ef-icon--pw-hide {
        display: block;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: spacing(1);
    color: var(--color-front);
    font-weight: 700;

    @include type-scale(300);

    .ef-form-item--disabled & {
      opacity: .4;
    }

    // .ef-form-item--has-error & {
    //   color: var(--color-red-400);
    // }
  }

  &__field-wrapper {
    position: relative;
  }

  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: spacing(2);
    bottom: 0;
    pointer-events: none;
  }

  &__text {
    color: var(--color-front);
    @include type-scale(200);

    &:not(:last-child) {
      margin-bottom: spacing(1);
    }

    &--right {
      text-align: right;
    }
  }

  &__check--separate {
    margin-top: spacing(2);
    padding-top: spacing(2);
    border-top: 1px solid var(--tone-back-600);
  }

  &__radio-button,
  &__check-box {
    position: absolute;
    opacity: 0;

    &:focus + .ef-form-item__radio-label:before,
    &:focus + .ef-form-item__check-label:before {
      //border-color: var(--color-front);
    }

    &:disabled + .ef-form-item__radio-label,
    &:disabled + .ef-form-item__check-label {
      opacity: .4;

      &:before {
        opacity: .4;;
      }
    }

    &:checked + .ef-form-item__radio-label:before,
    &:checked + .ef-form-item__check-label:before {
      background-color: var(--color-accent);
    }

    &:checked + .ef-form-item__radio-label:after {
      opacity: 1;
      background-color: var(--color-accent-contrast);
      transform: translateY(-50%) scale(0.5);
    }

    &:checked + .ef-form-item__check-label:after {
      opacity: 1;
      background-color: var(--color-accent);
      transform: translateY(-50%) scale(1);
    }
  }

  &__check-box {
    -webkit-appearance: checkbox;
  }

  &__radio-button {
    -webkit-appearance: radio;
  }

  &__radio-label,
  &__check-label {
    display: inline-block;
    position: relative;
    padding-left: calc(#{spacing-no-calc(1)} + 16px);
    color: var(--color-front);
    @include type-scale(400);

    .ef-form-item--s & {
      @include type-scale(300);
      padding-top: .075em;
    }

    .ef-form-item--has-error & {
      color: var(--color-red-400);
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
    }

    &:before {
      top: 6px;
      border: 1px solid transparent;
      background-color: var(--tone-front-800);
      @include transition($duration-faster);

      .ef-checkout-slider & {
        top: 4px;
      }

      .ef-form-item--has-error & {
        border-color: var(--color-red-400) !important;
      }

      @include theme(light) {
        background-color: rgba($color--black, .1);
        color: var(--color-front);
        // background-color: var(--color-front);
        // color: var(--color-back);
        border: 1px solid rgba($color--black, .4);
        background-color: rgba($color--black, .05);
        background-color: transparent;
      }
    }

    &:after {
      opacity: 0;
      top: 14px;
      @include transition($easing: $ease-in-out-back);
    }
  }

  &__radio-label {

    &:before {
      border-radius: 50%;
    }

    &:after {
      border-radius: 50%;
      background-color: var(--color-accent);
      transform: translateY(-50%) scale(0.125);
    }
  }

  &__check-label {

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateY(-50%) scale(0.25);
    }
  }

  &__options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(20% - #{spacing-no-calc(1)}), 1fr));
    gap: spacing(1);
  }

  &__option {
    position: relative;
  }

  &__option-label {
    display: flex;
    justify-content: center;
    padding: spacing(1) spacing(2) spacing(1);
    border: 1px solid var(--tone-back-600);
  }

  &__option-button {
    position: absolute;
    inset: 0;
  }

  &__option-button:checked + .ef-form-item__option-label {
    border-color: var(--color-accent);
  }

  &__option-button:disabled + .ef-form-item__option-label {
    opacity: .4;
  }

  &__error {
    @include type-scale(300);
    padding-top: spacing(1);
    color: var(--color-red-400);
  }

  &__value-display {
    color: var(--color-front);
    @include type-scale(400);
  }
}

.ef-form-item__field,
.ef-forum-wrapper input[type="text"],
.ef-forum-wrapper input[type="email"],
.ef-forum-wrapper input[type="number"],
.ef-forum-wrapper input[type="password"],
.ef-forum-wrapper input[type="search"],
.ef-forum-wrapper textarea,
.ef-forum-wrapper select {
  width: 100%;
  padding: spacing(1) spacing(2) spacing(1);
  border-bottom: 2px solid transparent;
  background-color: var(--tone-front-700);
  color: var(--color-back);
  font-family: font($font--primary);
  @include type-scale(400);
  @include transition($property: border-color);

  [data-theme="light"] & {
    border: 1px solid rgba($color--black, .4);
    background-color: transparent;
    color: var(--color-front);
  }

  &:focus {
    outline: 0;
    border-color: var(--color-accent);

    [data-theme="light"] & {
      border-color: rgba($color--black, .4);
      border-bottom-color: var(--color-accent);
    }
  }

  &:disabled {
    opacity: .4;
  }

  .ef-form-item--has-error & {
    border-color: var(--color-red-400) !important;
    color: var(--color-red-400);
  }

  .ef-form-item--small & {
    @include type-scale(300);
    padding-top: spacing(1);
    padding-bottom: spacing(1);
  }

  .ef-form-item--textarea & {
    min-height: spacing(16);
    resize: vertical;
  }
}

[data-theme="dark"] .ef-forum-wrapper input[type="text"],
[data-theme="dark"] .ef-forum-wrapper input[type="email"],
[data-theme="dark"] .ef-forum-wrapper input[type="number"],
[data-theme="dark"] .ef-forum-wrapper input[type="password"],
[data-theme="dark"] .ef-forum-wrapper input[type="search"],
[data-theme="dark"] .ef-forum-wrapper textarea,
[data-theme="dark"] .ef-forum-wrapper select {
  border: 1px solid rgba($color--black, .4) !important;
  background-color: transparent !important;
  color: var(--color-black) !important;
}

[data-theme="dark"] .ef-forum-wrapper input[type="text"]:focus,
[data-theme="dark"] .ef-forum-wrapper input[type="email"]:focus,
[data-theme="dark"] .ef-forum-wrapper input[type="number"]:focus,
[data-theme="dark"] .ef-forum-wrapper input[type="password"]:focus,
[data-theme="dark"] .ef-forum-wrapper input[type="search"]:focus,
[data-theme="dark"] .ef-forum-wrapper textarea:focus,
[data-theme="dark"] .ef-forum-wrapper select:focus {
  border-color: rgba($color--black, .4) !important;
  border-bottom-color: var(--color-accent) !important;
}

.ef-form-item--select {

  .ef-form-item__field-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(#{spacing-no-calc(2)} + 16px);
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='#{url-encoded-color($color--black)}' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right spacing(2) center;
  }

  &.ef-form-item--disabled .ef-form-item__field-wrapper:after {
    opacity: .4;
  }

  .ef-form-item__field {
    padding-right: calc(#{spacing-no-calc(4)} + 16px);
    appearance: none;
    //border: none;
    border: 2px solid transparent;
    border-radius: 0;

    @include theme(light) {
      border: 1px solid rgba($color--black, .4);
    }
  }
}

select option {
  background-color: white;
  color: black;

  .ef-submenu & {
    background-color: var(--light-gray-700);
  }
}

select[multiple] option {
  background-color: var(--light-gray-700);
}

.ef-form-item--file {

  .ef-form-item__label {
    @extend .ef-button;
    @extend .ef-button--secondary;
    @extend .ef-button--small;
  }

  .ef-form-item__file-upload {
    // display: inline-flex;
    // align-items: center;
    position: absolute;
    top: 0;
    // bottom: 0;
    left: 0;
    // padding: spacing(1) 0 spacing(.5);
    // @include type-scale(300);
    opacity: 0;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ef-form-item--address {
  @extend %card;
  @extend %card--has-line;
  width: 500px;

  .ef-content--shopping-account-profile &,
  .ef-content__block--checkout-payments & {
    margin-bottom: 0;
    padding-top: spacing(2);
    padding-bottom: spacing(3);
  }

  .ef-form-item__check-label {
    padding-left: spacing(8.5);
    display: block;

    &:before,
    &:after {
      height: spacing(6.5);
      width: spacing(6.5);
      border-radius: 100%;
    }

    &:after {
      top: spacing(3.85);
      background-size: 85% 85%;
    }
  }

  .ef-form-item__edit {
    display: block;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    font-weight: 400;
    @include type-scale(200);

    @include break('tablet') {
      position: relative;
      //margin-top: spacing(2);
    }
  }
}

.ef-form-item__label-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
