.ef-match-slider {
  @include skeleton(12);
  @include ie11() {
    padding-top: spacing(8);
    border: 5px solid green;
  }

  .ef-slider__arrow {
    top: spacing(-1);
    bottom: spacing(1);
  }
}
