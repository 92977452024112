.ef-table-grid {
  @extend %card--has-line;
  display: block;

  @include break(desktop-s) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: spacing(1);
  }

  // @include break(desktop-m) {
  //   grid-template-columns: repeat(5, 1fr);
  // }
}
