.ef-search-filters {
  // display: flex;
  // align-items: baseline;

  &__label {
    @include type-scale(300);
    margin-right: spacing(2);
    color: var(--color-front);
    font-weight: 700;
  }
}

.ais-Menu {
  padding-left: spacing(3);
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  @include break(tablet) {
    padding-left: 0;
  }

  &::-webkit-scrollbar {
    display: none;  /* Chrome Safari */
  }
}

.ais-Menu-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-Menu-item {
  margin-bottom: spacing(2);

  &:last-child {
    padding-right: spacing(3);

    @include break(desktop-s) {
      padding-right: 0;
    }
  }

  &:not(:last-child) {
    margin-right: spacing(2);
  }
}

.ais-Menu-link,
.ais-Menu-item a {
  display: block;
  padding: spacing(.5) spacing(2) spacing(.5);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-front);
  border-radius: spacing(1.75);
  color: var(--color-front) !important;
  font-weight: 700;
  white-space: nowrap;
  @include type-scale(200);
  @include transition($duration-faster);

  .ais-Menu-item:hover & {
    border-color: var(--color-accent);
  }

  .ais-Menu-item--selected & {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
    color: var(--color-accent-contrast) !important;
  }
}

.ais-Menu-count {
  font-weight: 400;

  &:before {
    content: " (";
  }

  &:after {
    content: ")";
  }
}
