.ef-payments {
  margin-bottom: spacing(4);

  .ef-payment {
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    border-bottom: 1px solid var(--tone-back-700);

    &:first-child {
      padding-top: spacing(2);
    }

    label {
      font-weight: 700;

      .ef-form-item__radio-preset {
        font-weight: 400;
        margin-left: spacing(2);
        color: var(--grayscale-500);
      }
    }

    .ef-form-item__radio-label {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-left: spacing(5);

      svg {
        margin-right: spacing(4);
        max-width: spacing(5);
        height: auto;
        width: auto;
      }

      &:before, &:after {
        top: 50%;
      }

      &:before {
        margin-top: -8px;
      }
      &:after {
        margin-top: 0;
      }
    }
  }

  .ef-form-item__radio-button:checked {
    ~ .ef-expand__payment-info {
      animation: ef-accordion-overflow 1s linear forwards;

      .ef-expand__payment-info-item {
        max-height: spacing(64);
        overflow: visible;
      }
      .ef-expand__payment-info__content {
        opacity: 1;
      }
    }
  }

  .ef-expand__payment-info {
    position: relative;
    font-weight: 400;
    overflow: hidden;

    &-item {
      max-height: 0;
      overflow-y: hidden;
      will-change: max-height;
      @include transition($duration: .55s, $property: max-height, $easing: $ease-in-quad);
    }

    &__content {
      padding-top: spacing(4);
      padding-left: spacing(5);
      opacity: 0;
      @include transition($property: opacity);
    }

    p {
      margin-bottom: spacing(1);
      @include type-scale(300);
    }
  }

}

.ef-content__block--checkout-payments-buttons {
  padding-top: 0 !important;

  .ef-form-buttons {
    display: inline-block;
    margin-right: spacing(2.5);
  }
}

.ef-form--checkout-payments {
  @include max-width(m);
}

.ef-form--textcontainer {
  height: 180px;
  overflow: auto;
}

.ef-payment-options__wrapper {
  @include type-scale(200);
  color: var(--tone-front-300);
  font-weight: 400;
}

.ef-payment-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  span {
    margin-right: spacing(2);
  }
}

.ef-payment-options__item {
  margin-right: spacing(4);
  &--preferred {
    margin-right: spacing(12);
  }
  .ef-payment-logo__image {
    height: 35px;
  }
}


