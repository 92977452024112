.ef-stats-grid {
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: spacing(4);
  justify-content: center;

  @include break(tablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    grid-column: span 1;
  }
}
