.ef-content__block--tickets-overview,
.ef-content__block--tickets-details {
  @extend .ef-content__header;
}

.ef-product__arrow.--tickets-overview,
.ef-product__arrow.--tickets-details {
  position: absolute;
  right: spacing(3);
  top: 0;
  bottom: 0;
  margin: auto;

  svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: color .3s;
  }
}

.ef-fast-checkout__item.--tickets-details {
  .ef-heading {
    text-transform: uppercase;
  }
}

.ef-product.ef-product--tickets-overview,
.ef-product.ef-product--tickets-details {
  @extend .ef-product--abo-review;
  padding-right: calc(#{spacing-no-calc(3)} + 16px);
  padding-left: spacing(3);
}

.ef-orders-review-items.--tickets-overview,
.ef-orders-review-items.--tickets-details {
  margin-bottom: spacing(6);
}

.ef-cart__checkout--tickets-details {
  @extend .ef-cart__checkout--abo-details;
}

.ef-cart__checkout--tickets-details {
  @extend .ef-cart__checkout--abo-details;
}

.ef-fast-checkout__item.--tickets-details {
  @extend .ef-fast-checkout__item.--abo-details;
}

.ef-orders-details-container.--tickets-details,
.ef-orders-details-items.--tickets-details {
  @extend .ef-orders-details-container.--abo-details;
}

.ef-orders-details-container.--tickets-details {
  @extend .ef-orders-details-container.--abo-details;
}

.ef-product.ef-product--tickets-details {
  @extend .ef-product.ef-product--abo-details;
}

.ef-form-buttons.--tickets-details {
  @extend .ef-form-buttons.--abo-details;
  &:not(:last-child) {
    margin-bottom: spacing(6);
  }
}

.ef-order-confirmation.--tickets-tranfer {
  position: relative;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
}

// .ef-orders-review-item.--tickets-overview {
//   &:first-child {
//     .ef-product.ef-product--tickets-overview {
//       padding-top: 0;
//     }
//   }
// }
