.ef-content__block--abo-review,
.ef-content__block--abo-details {
  @extend .ef-content__header;
}

.ef-product__arrow.--abo-review,
.ef-product__arrow.--abo-details {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: color .3s;
  }
}

.ef-product.ef-product--abo-review {
  padding: spacing(3) 0;
  margin-bottom: 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    .ef-product__arrow.--abo-review {
      svg {
        color: var(--color-red-400);
      }
    }
  }

  .ef-product__image {
    object-fit: cover;
    object-position: center;
  }

  .ef-product__amount {
    align-self: end;
    text-align: left;
  }
}

.ef-product--abo-review,
.ef-product--abo-details {
  .ef-product__media {
    padding: 0;
  }
  .ef-product__summary {
    display: grid;
    grid-template-columns: 1fr spacing(25);
  }
}

.ef-data-table--abo-details {

  .ef-data-table__row.--abo-details {
    @include break(tablet, true ) {
      display: flex;
      justify-content: space-between;
      padding-top: 0;
    }
  }

  .ef-abo-details__cell {
    padding-top: spacing(2);
    padding-bottom: spacing(2);

    &:last-child {
      text-align: right;
      padding-right: spacing(6);
    }
  }

  .ef-data-table__row--highlight {
    font-weight: 700;
    .ef-abo-details__cell {
      &:last-child {
        &:after {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          margin: auto;
          right: spacing(1);
          width: spacing(4.5);
          height: spacing(4.5);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23e00914' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.ef-heading--abo-details {
  @include type-scale(600);
}

.ef-cart__checkout--abo-details {
  @extend .ef-cart__checkout--checkout-review;
  margin-bottom: 0;

  .ef-heading {
    &.ef-heading--abo-details {
      margin-bottom: 0;
    }
  }
}

.ef-fast-checkout__item.--abo-details {
  padding: spacing(6) 0;
  margin: 0;
}

.ef-orders-details-container.--abo-details,
.ef-orders-details-items.--abo-details {
  margin-bottom: 0;
}

.ef-orders-details-container.--abo-details {
  padding: spacing(5) 0;
}

.ef-product.ef-product--abo-details {
  &:hover {
    .ef-product__arrow.--abo-details {
      svg {
        color: var(--color-red-400);
      }
    }
  }
}

.ef-form-buttons.--abo-details {
  padding-top: spacing(6);
  border-top: 1px solid var(--tone-back-600);

  .ef-button {
    &:not(:last-child) {
      margin-bottom: spacing(2);
    }

    @include break(phablet, true) {
      width: 100%;
    }
    @include break(phablet) {
      margin-right: spacing(2);
    }
  }
}
