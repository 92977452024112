.ef-hint {
  @include type-scale(200);
  color: var(--tone-medium);
  line-height: spacing(2.5) !important;

  &:not(:last-child) {
    margin-bottom: spacing(2);
  }

  .ef-product--checkout-review & {
    @include type-scale(300);
    line-height: spacing(3) !important;
  }
}
