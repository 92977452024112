.ef-article-card {
  @extend %card;
  @extend %card--has-line;
  cursor: pointer;

  &--promo {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
    grid-gap: spacing(4);
    background-color: var(--tone-back-750);
  }

  &--ad {
    @include ad;
  }

  &__banner {
    @include ad-banner(640, 853);

    .ef-video-slider & {
      @include ad-banner(640, 512);
    }

    .ef-product-slider & {
      @include ad-banner(320, 480);
    }
  }

  &__media {
    @extend .ef-media--16x9;

    .ef-article-card--video & {
      display: flex;
      align-items: center;
    }

    .ef-article-card--promo & {
      height: 100%;
    }

    &:after {

      .ef-article-card--gallery & {
        content: "";
        position: absolute;
        top: spacing(2);
        right: spacing(2);
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11,12H1c-0.553,0-1-0.447-1-1V1c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1v10C12,11.553,11.553,12,11,12z'/%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M15,16H4v-2h10V4h2v11C16,15.553,15.553,16,15,16z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__image {
    @include transition;

    &:nth-child(2) {
      opacity: 0;
    }
  }

  &__video {
    opacity: 0;
    @include transition($property: opacity);
  }

  @include break(desktop-s) {

    &:hover {

      .ef-article-card__image {
        transform: scale(1.05);
      }

      .ef-article-card__image:nth-child(2),
      .ef-article-card__video {
        opacity: 1;
      }
    }
  }

  &__video-icon {
    @include transition($duration: $duration-slower, $easing: $ease-out-back);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    // right: spacing(4);
    // bottom: spacing(4);
    z-index: 1;

    // * {
    //   @include transition($duration: $duration-faster);
    // }

    // .ef-article-card:hover & {
    //   color: var(--color-accent);
    // }


    &:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: rgba($color--black, .4);
    }

    svg {
      position: relative;
      z-index: 1;
      width: 64px;
      height: 64px;
    }

    // * {
    //   @include transition($duration: $duration-faster);
    // }

    // use [data-color="color-2"] {
    //   fill: $color--white !important;
    // }

    @include break(desktop-s) {

      .ef-article-card__media:hover & {
        color: var(--color-accent);
        transform: scale(1.1);
      }
    }
  }

  &__text {
    @extend %text-container;
    padding-top: spacing(3);
    padding-right: spacing(3);
    padding-bottom: spacing(3);
  }

  &__heading {
    @include transition($duration-faster, $property: color);

    @include break(desktop-s) {

      .ef-article-card:not(.ef-article-card--video):hover &,
      .ef-article-card--video .ef-article-card__text:hover & {
        color: var(--color-accent);
      }
    }

    .ef-video-slider & {
      @include type-scale(450);
      margin-bottom: spacing(1);
    }

    .ef-article-card--promo & {
      @include type-scale(500);
    }

    span {
      @include card-blank-link;
    }
  }

  .ef-paragraph {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    .ef-video-slider & {
      -webkit-line-clamp: 2;
    }
  }
}

#ef-icon--play-16 polygon,
#ef-icon--play-32 polygon,
#ef-icon--play-48 polygon,
#ef-icon--play-64 polygon {
  stroke: var(--color-accent);
}
