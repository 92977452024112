%card {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  outline: none;
}

%card--has-line {

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    z-index: 2;
    height: 2px;
    background-color: var(--color-accent);
  }

  &:before {
    right: 0;
    width: 100%;
    background-color: var(--tone-back-600);
    @include transition($property: width);
  }

  &:after {
    left: 0;
    width: 0;
    @include transition($property: width, $easing: $ease-in-quad, $delay: .3s);
  }

  @include break(desktop-s) {

    &:hover:before {
      width: 0;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

%card--has-line-desktop {

  &:before,
  &:after {
    display: none;

    @include break(desktop-s) {
      display: block;
    }
  }
}

%card--has-line-subtle {

  &:before {
    background-color: var(--tone-back-600);
  }
}

.--card-no-link {
  pointer-events: none;

  &:before,
  &:after {
    display: none;
  }
}

@mixin card-blank-link {
  @include transition($property: background-image);

  [target="_blank"] &,
  [data-theme="dark"][data-theme-2="dbp"] [target="_blank"]:hover & {
    padding-right: calc(#{spacing-no-calc(2)} + 16px);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--white)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: right center;
  }

  [data-theme="light"] [target="_blank"] &,
  [data-theme-2="dbp"] [target="_blank"]:hover & {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--black)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
  }

  [target="_blank"]:hover & {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
  }
}

@mixin card-blank-link--image {
  @include transition($property: background-image);

  [target="_blank"] &,
  [data-theme-2="dbp"] [target="_blank"]:hover & {
    padding-right: calc(#{spacing-no-calc(2)} + 16px);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--white)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: right center;
  }

  [target="_blank"]:hover & {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><path data-color="color-2" d="M8,0v2h4.586L7.293,7.293l1.414,1.414L14,3.414V8h2V1c0-0.552-0.448-1-1-1H8z"></path> <path d="M16,16H1c-0.552,0-1-0.448-1-1V0h2v14h14V16z"></path></g></svg>');
  }
}
