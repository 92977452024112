.ef-data-table {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  //@include max-width;

  // &--link:after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: spacing(2);
  //   width: 12px;
  //   height: 12px;
  //   margin-top: -6px;
  //   background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
  //   background-repeat: no-repeat;
  //   background-size: 12px;
  // }

  a:not(.ef-link-button) {
    @extend .ef-text--link;
  }

  &.--from-desktop {
    display: table;
    border-collapse: collapse;
    max-width: none;
    @extend %boxed-padding;

    .ef-data-table__header-cell--sortable .ef-data-table__header-cell-label {
      cursor: pointer;
    }

    .ef-data-table__header-cell--sortable.--is-asc .ef-data-table__header-cell-label,
    .ef-data-table__header-cell--sortable.--is-desc .ef-data-table__header-cell-label {
      position: relative;
      padding-right: 20px;
    }

    .ef-data-table__header-cell--sortable.--is-asc .ef-data-table__header-cell-label:after,
    .ef-data-table__header-cell--sortable.--is-desc .ef-data-table__header-cell-label:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='#{url-encoded-color($color--white)}' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      @include transition($property: transform);
    }

    .ef-data-table__header-cell--sortable.--is-desc .ef-data-table__header-cell-label:after {
      transform: rotate(180deg);
    }

    .ef-data-table__header {
      display: table-header-group;
    }

    .ef-data-table__header-row,
    .ef-data-table__row {
      display: table-row;
    }

    .ef-data-table__row {
      padding: 0;

      &:nth-child(even) {
        background-color: transparent;

        @include theme(light) {
          background-color: transparent;
        }
      }

      &:nth-child(odd) {
        background-color: rgba($color--white, .05);

        @include theme(light) {
          background-color: rgba($color--black, .05);
        }
      }
    }

    .ef-data-table__row:not(:last-child) {
      //border-bottom: 1px solid var(--tone-back-600);
    }

    .ef-data-table__header-cell,
    .ef-data-table__cell {
      display: table-cell;
      padding-top: spacing(1.5);
    }

    .ef-data-table__header-cell {
      position: sticky;
      top: 0;
      z-index: 2;
      padding-top: 0;
    }

    .ef-data-table__cell {
      border: none;
      position: relative;
    }

    .ef-data-table__cell:before {
      display: none;
    }

    .ef-data-table__body {
      display: table-row-group;
    }
  }

  .ef-data-table__header-cell,
  .ef-data-table__cell {
    padding-right: spacing(1.5);
    padding-left: spacing(1.5);
  }

  &.--has-expansions {

    .ef-data-table__row .ef-data-table__cell:first-child {
      padding-right: calc(16px + #{spacing(4)});
    }

    @include break(desktop-s) {

      .ef-data-table__row .ef-data-table__cell:first-child {
        padding-right: 0;
      }

      .ef-data-table__row .ef-data-table__cell:last-child {
        padding-right: calc(16px + #{spacing(4)});
      }
    }
  }

  &__header {
    display: none;
  }

  &__header-cell,
  &__cell {
    padding-bottom: spacing(1.5);
  }

  &__header-cell,
  &__cell:before {
    color: var(--color-front);
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    @include type-scale(200);
  }

  &__header-cell {
    display: none;
    white-space: nowrap;
  }

  @include break(desktop-s) {

    &__header-cell--sortable .ef-data-table__header-cell-label {
      cursor: pointer;
    }

    &__header-cell--sortable.--is-asc .ef-data-table__header-cell-label,
    &__header-cell--sortable.--is-desc .ef-data-table__header-cell-label {
      position: relative;
      padding-right: 20px;
    }

    &__header-cell--sortable.--is-asc .ef-data-table__header-cell-label:after,
    &__header-cell--sortable.--is-desc .ef-data-table__header-cell-label:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='#{url-encoded-color($color--white)}' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      @include transition($property: transform);
    }

    &__header-cell--sortable.--is-desc .ef-data-table__header-cell-label:after {
      transform: rotate(180deg);
    }
  }

  &__cell {
    @include type-scale(300);

    &:before {
      content: attr(data-name);
      display: block;
    }
  }

  &__body,
  &__row,
  &__cell {
    display: block;
  }

  &__row {
    padding-top: spacing(2);

    &:nth-child(odd) {
      background-color: rgba($color--white, .05);

      @include theme(light) {
        background-color: rgba($color--black, .05);
      }
    }

    &--link .ef-data-table__cell:last-child {
      padding-right: calc(12px + #{spacing-no-calc(2)});
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: right spacing(2) center;
    }

    @include break(desktop-s) {

      &:nth-child(odd) {
        background-color: transparent;

        @include theme(light) {
          background-color: transparent;
        }
      }

      &:nth-child(even) {
        background-color: rgba($color--white, .05);

        @include theme(light) {
          background-color: rgba($color--black, .05);
        }
      }
    }

    &--link {
      @include transition($property: background-color);
    }

    &--link:hover {
      background-color: var(--color-back);

      @include theme(light) {
        background-color: rgba($color--black, .08);
      }
    }

    &--highlight {
      position: relative;
      // background-color: rgba($color--red-400, .1) !important;
      //
      // @include theme(light) {
      //   background-color: rgba($color--red-400, .1) !important;
      // }

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        bottom: 2px;
        width: 4px;
        background: var(--color-accent);

        @include break(desktop-s) {
          display: none;
        }
      }

      .ef-data-table__cell {
        border: none;
        position: relative;

        @include break(desktop-s) {

          &:first-child:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            bottom: 2px;
            width: 4px;
            background: var(--color-accent);
          }
        }
      }
    }

    &.--has-expansion {
      cursor: pointer;
    }

    &.--has-expansion.--is-open {
      border-color: transparent !important;
    }

    &.--has-expansion .ef-data-table__cell:first-child {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="8,12.6 0.3,4.9 1.7,3.4 8,9.7 14.3,3.4 15.7,4.9 "></polygon></g></svg>');
      background-repeat: no-repeat;
      background-position: right spacing(2) top;
    }

    &.--has-expansion.--is-open .ef-data-table__cell:first-child {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="14.3,12.6 8,6.3 1.7,12.6 0.3,11.1 8,3.4 15.7,11.1 "></polygon></g></svg>');
    }

    @include break(desktop-s) {

      &.--has-expansion .ef-data-table__cell:first-child,
      &.--has-expansion.--is-open .ef-data-table__cell:first-child {
        background-image: none;
      }

      &.--has-expansion .ef-data-table__cell:last-child {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="8,12.6 0.3,4.9 1.7,3.4 8,9.7 14.3,3.4 15.7,4.9 "></polygon></g></svg>');
        background-repeat: no-repeat;
        background-position: right spacing(2) center;
      }

      &.--has-expansion.--is-open .ef-data-table__cell:last-child {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><g fill="#{url-encoded-color($color--red-400)}"><polygon points="14.3,12.6 8,6.3 1.7,12.6 0.3,11.1 8,3.4 15.7,11.1 "></polygon></g></svg>');
      }
    }

    &--expansion {
      display: none;
      border: none !important;
      background-color: var(--tone-back-700);
    }

    &--expansion.--is-open {
      display: table-row;
    }

    &--expansion .ef-data-table__cell {
      padding-top: spacing(2);
    }

    // &:not(:first-child) {
    //   //padding-top: spacing(2);
    //   //border-top: 1px solid var(--tone-700);
    // }

    &:not(:last-child) {
      //padding-bottom: spacing(2);
    }
  }

  @include break(desktop-s) {
    display: table;
    border-collapse: collapse;
    max-width: none;

    &__header {
      display: table-header-group;
    }

    &__header-row,
    &__row {
      display: table-row;
    }

    &__row {
      padding: 0;

      &--expansion {
        display: none;
      }

      &--expansion.--is-open {
        display: table-row;
      }
    }

    &__row:not(:last-child) {
      //border-bottom: 1px solid var(--tone-back-600);
    }

    &__header-cell,
    &__cell {
      display: table-cell;
      padding-top: spacing(2);
    }

    &__header-cell {
      position: sticky;
      top: 0;
      z-index: 2;
      padding-top: 0;
    }

    &__cell {
      border: none;
      position: relative;
    }

    &__cell:before {
      display: none;
    }

    &__body {
      display: table-row-group;
    }
  }
}
