@keyframes shop-hero-branding-bg {
  100% { opacity: 1; }
}

section.ef-shop-hero {
  @include boxed-margin;
}

.ef-shop-hero {
  position: relative;
  height: 120vw;
  margin-bottom: spacing(12);
  overflow: hidden;

  @include break(tablet) {
    height: 56.25vw;
  }

  @include break(desktop-s) {
    height: 42.553191vw;
  }

  + .ef-shop-hero {
    //margin-top: spacing(-12);
    //border-top: 4px solid var(--color-accent);
  }

  &--small {
    height: 50vw;

    .ef-shop-hero__video {
      display: none;
    }

    @include break(tablet) {
      height: 28.125vw;

      &.ef-shop-hero--video picture {
        display: none;
      }

      .ef-shop-hero__video {
        display: block;
      }
    }
  }

  &__media,
  &__media > div {
    @include fill;
    overflow: hidden;
  }

  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    //animation: ken-burns 19s ease-in-out forwards;

    .--has-branding & {
      //opacity: .3;
      animation: shop-hero-branding-bg 4s ease-in-out forwards;
    }
  }

  &__image {
    //animation: hero-ken-burns 19s ease-in-out forwards;
  }

  &__link {
    @include fill;
  }

  &__content {
    @extend %boxed-padding;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: spacing(4);

    @include break(tablet) {
      padding-bottom: spacing(8);
    }

    .ef-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      height: 100%;

      @include break(tablet) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }

  .ef-ticket-logos {
    margin-bottom: spacing(2);
  }

  &__preheader,
  &__heading,
  &__cta {
    // opacity: 0;
    // transform: translateY(24px);

    // .--is-in-view & {
    //   animation: ef-slide-fade-in-btt .7s ease-in-out forwards;
    // }
  }

  &__preheader {
    display: flex;
    //margin-bottom: spacing(2.25);
    animation-delay: .5s;
  }

  .ef-shop-kicker {
    max-width: spacing(64);
    height: spacing(7);
    margin-right: spacing(1);
    line-height: spacing(7) !important;
  }

  &__heading {
    @include transition($property: color);
    max-width: spacing(64);
    padding-top: spacing(1);
    animation-delay: .8s;

    @include break(tablet) {
      margin-bottom: 0;
    }
  }

  &__cta {
    animation-delay: 1.1s;

    .ef-button {
      padding-right: calc(#{spacing-no-calc(3)} + 12px);
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: right spacing(2) center;
      white-space: nowrap;
    }
  }

  &__buttons {

    @include break(tablet) {
      text-align: right;
    }

    .ef-button:not(:first-child),
    .ef-link-button:not(:first-child) {
      margin-left: spacing(4);
    }

    .ef-button:not(:last-child),
    .ef-link-button:not(:last-child) {
      margin-bottom: spacing(4);
    }
  }

  .ef-shop-badge--competition-white {
    position: absolute;
    bottom: 0;
    right: 0;

    @include break(tablet) {
      top: spacing(4);
      right: auto;
      bottom: auto;
      left: 0;
    }
  }
}
