@keyframes formation-player-home {
  0% {
    opacity: 0;
    transform: scale(.5);
  } 100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes formation-player-secondary-mobile {
  0% {
    opacity: 0;
    transform: rotate(-180deg) translateY(#{spacing(-2)}) scale(.5);
  } 100% {
    opacity: 1;
    transform: rotate(-180deg) translateY(#{spacing(-2)}) scale(1);
  }
}

@keyframes formation-player-away-desktop {
  0% {
    opacity: 0;
    transform: rotate(-180deg) translateY(#{spacing(-2)}) scale(.5);
  } 100% {
    opacity: 1;
    transform: rotate(-180deg) translateY(#{spacing(-2)}) scale(1);
  }
}

@for $i from 0 through 10 {

  $number: $i + 1;

  .ef-formation__player[data-position="#{$i}"] {
    grid-area: p#{$number};
    animation-delay: calc(#{$number} * 100ms) !important;
  }
}

.ef-formation {
  position: relative;
  background-image: repeating-linear-gradient(
    -45deg,
    var(--tone-back-700),
    var(--tone-back-700) 2px,
    transparent 2px,
    transparent 10px
  );

  @include ie11() {
    display: none;
  }

  &__field {
    display: block;
    width: 100%;
    height: auto;
    border: 2px solid var(--tone-back-600);
    color: var(--tone-back-600);
  }

  &__field--vertical {

    @include break(desktop-s) {
      display: none;
    }
  }

  &__field--horizontal {
    display: none;

    @include break(desktop-s) {
      display: block;
    }
  }

  &__team {
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    right: 0;
    left: 0;
    padding: spacing(2) spacing(4);

    .ef-formation--minimal & {
      padding: spacing(4);
    }
  
    @include break(desktop-s) {
      top: 0;
      right: auto;
      bottom: 0;
      left: auto;
      width: 50%;
      padding: spacing(8) spacing(8) spacing(8) spacing(2);

      .ef-formation--minimal & {
        width: 100%;
        padding: spacing(8) spacing(8) spacing(8) spacing(4);
      }
    }
  }

  &__team--primary {
    top: 0;
    bottom: 50%;
    color: var(--color-white);
    fill: var(--color-black);

    .ef-formation--minimal & {
      bottom: 0;
    }

    @include break(desktop-s) {
      bottom: 0;
    }
  }

  &__team--secondary {
    top: 50%;
    bottom: 0;
    color: var(--color-front);
    fill: var(--tone-back-500);
    transform: rotate(180deg);

    .ef-formation--minimal & {
      top: 0;
    }

    .ef-formation__player {
      transform: rotate(-180deg) translateY(#{spacing(-2)});
    }

    @include break(desktop-s) {
      top: 0;
      transform: none;

      .ef-formation__player {
        transform: none;
      }
    }
  }

  &__team--home {

    @include break(desktop-s) {
      left: 0;
    }

    .ef-formation__player {

      .--is-in-view & {
        animation: formation-player-home .4s $ease-out-back forwards;
      }
    }

    &.ef-formation__team--secondary .ef-formation__player {

      .--is-in-view & {
        animation: formation-player-secondary-mobile .4s $ease-out-back forwards;

        @include break(desktop-s) {
          animation: formation-player-home .4s $ease-out-back forwards;
        }
      }
    }
  }

  &__team--away {

    &.ef-formation__team--primary .ef-formation__player {

      .--is-in-view & {
        animation: formation-player-home .4s $ease-out-back forwards;
      }
    }

    &.ef-formation__team--secondary .ef-formation__player {

      .--is-in-view & {
        animation: formation-player-secondary-mobile .4s $ease-out-back forwards;
      }
    }

    @include break(desktop-s) {
      right: 0;
      transform: rotate(180deg);

      &.ef-formation__team--primary .ef-formation__player,
      &.ef-formation__team--secondary .ef-formation__player {
        transform: rotate(-180deg) translateY(#{spacing(-2)}) scale(.5);

        .--is-in-view & {
          animation: formation-player-away-desktop .4s $ease-out-back forwards;
        }
      }
    }
  }

  &__team--empty {
    transform: none;

    @include break(desktop-s) {
      padding: spacing(8);
    }
  }

  &__player {
    position: relative;
    margin-top: spacing(-2);
    padding: spacing(1) 0;
    opacity: 0;
    text-align: center;

    @include break(desktop-s) {
      padding: 0;
    }
  }

  &__player-jersey {
    width: 100%;
    height: 32px;

    @include break(phablet) {
      height: 48px;
    }
  }

  &__player-jersey .ef-icon {
    width: 32px;
    height: 32px;

    @include break(phablet) {
      width: 48px;
      height: 48px;
    }
  }

  &__player-jersey {
    display: block;
    position: relative;
  }

  &__player-number {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    font-family: font($font--subline);
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    transform: translateY(-50%);

    @include break(tablet) {
      font-size: 18px;
    }
  }

  &__player-name {
    @include type-scale(200);
    position: absolute;
    top: calc(100% - #{spacing-no-calc(1)});
    width: spacing(12);
    left: 50%;
    padding-top: spacing(.5);
    color: var(--color-front);
    line-height: spacing(2) !important;
    font-weight: 700;
    word-wrap: break-word;
    transform: translateX(-50%);

    @include break(desktop-s) {
      top: 100%;
    }
  }

  &__player-link {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &__empty-state {
    @include type-scale(300);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: spacing(1) spacing(2);
    background-color: var(--tone-back-700);
    color: var(--tone-front-600);
    text-align: center;
  }
}

.ef-formation {
  --color-jersey-2023-home: transparent;
  --color-jersey-2024-home: transparent;
  --color-jersey-2024-away: transparent;
  --color-jersey-2023-away-1: transparent;
  --color-jersey-2023-away-2: transparent;
  --color-jersey-2024-third: transparent;
}

.ef-formation__team[data-jersey="home"],
.ef-formation__team[data-jersey="third"],
.ef-formation__team[data-jersey="special"],
.ef-formation__team[data-jersey="euro"],
.ef-formation__team[data-jersey="final"] {
  color: var(--color-white);
  fill: var(--color-black);
}

.ef-formation__team[data-jersey="away"] {
  color: var(--color-black);
  fill: var(--color-white);
}

[data-season="2023"] .ef-formation__team[data-jersey="home"] {
  --color-jersey-2023-home: var(--color-red-400);
}

[data-season="2023"] .ef-formation__team[data-jersey="away"] {
  --color-jersey-2023-away-1: var(--color-red-400);
  --color-jersey-2023-away-2: var(--color-white);
  color: var(--color-white);
  fill: var(--color-black);
}

[data-season="2023"] .ef-formation__team[data-jersey="third"] {
  color: var(--color-black);
  fill: var(--color-white);
}

[data-season="2023"] .ef-formation__team[data-jersey="special"] {
  color: var(--color-125-gold);
  fill: var(--color-black);
}

[data-season="2024"] .ef-formation__team[data-jersey="home"] {
  --color-jersey-2024-home: var(--color-red-400);
  color: var(--color-black);
  fill: var(--color-white);
}

[data-season="2024"] .ef-formation__team[data-jersey="away"] {
  color: var(--color-red-400);
  fill: var(--color-black);

  .ef-formation__player-number {
    color: var(--color-white);
  }
}

[data-season="2024"] .ef-formation__team[data-jersey="third"] {
  --color-jersey-2024-third: var(--color-red-400);
  color: var(--color-black);
  fill: #F95B15;
}

[data-season="2024"] .ef-formation__team[data-jersey="euro"] {
  color: var(--color-black);
  fill: var(--color-white);
}

.ef-formation__team--442 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    "p7  .   p4  .   p8  .   p11"
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p9  .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   p11 .   .  "
      ".   .   .   .   .   .   .  "
      ".   .   p6  .   p8  .   p9 "
      "p1  .   .   .   .   .   .  "
      ".   .   p5  .   p4  .   p10"
      ".   .   .   .   .   .   .  "
      ".   .   p2  .   p7  .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--41212 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto auto auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   .   p4  .   .   .  "
    ".   p7  .   .   .   p11 .  "
    ".   .   .   p8  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p9  .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr 1fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto auto auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .   .   .  "
      ".   .   .   .   .   p11 .   .   .  "
      ".   .   p6  .   .   .   .   .   p9 "
      "p1  .   .   .   p4  .   p8  .   .  "
      ".   .   p5  .   .   .   .   .   p10"
      ".   .   .   .   .   p7  .   .   .  "
      ".   .   p2  .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr 1fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--433 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   p7  .   p4  .   p8  .  "
    ".   .   .   .   .   .   .  "
    ".   p10 .   p9  .   p11 .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .  "
      ".   .   .   .   p8  .   p11"
      ".   .   p6  .   .   .   .  "
      "p1  .   .   .   p4  .   p9 "
      ".   .   p5  .   .   .   .  "
      ".   .   .   .   p7  .   p10"
      ".   .   p2  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--451 {
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto auto auto;
  grid-template-areas:
    ".   .   .   .   p1  .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   p2  .   p5  .   p6  .   p3  .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p4  .   p10 .   p8  .   .  "
    "p7  .   .   .   .   .   .   .   p11"
    ".   .   .   .   p9  .   .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr 1fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto auto auto;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      ".   .   .   .   .   p11 .  "
      ".   .   p3  .   .   .   .  "
      ".   .   .   .   p8  .   .  "
      ".   .   p6  .   .   .   .  "
      "p1  .   .   .   p10 .   p9 "
      ".   .   p5  .   .   .   .  "
      ".   .   .   .   p4  .   .  "
      ".   .   p2  .   .   .   .  "
      ".   .   .   .   .   p7  .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr 1fr 1fr;
    // }
  }
}

.ef-formation__team--4411 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    "p7  .   p4  .   p8  .   p11"
    ".   .   .   .   .   .   .  "
    ".   .   .   p10 .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   p11 .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   p8  .   .   .   .  "
      "p1  .   .   .   .   .   p10 .   p9 "
      ".   .   p5  .   p4  .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p2  .   p7  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4141 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   .   p4 .   .   .  "
    ".   .   .   .   .   .   .  "
    "p7  .   p8  .   p10 .   p11"
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   p11 .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   .   .   p10 .   .  "
      "p1  .   .   .   p4  .   .   .   p9 "
      ".   .   p5  .   .   .   p8  .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p2  .   .   .   p7  .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4231 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   p8  .   p4  .   .  "
    ".   .   .   .   .   .   .  "
    ".   p7  .   p10 .   p11 .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .   .   .  "
      ".   .   .   .   .   .   p11 .   .  "
      ".   .   p6  .   p4  .   .   .   .  "
      "p1  .   .   .   .   .   p10 .   p9 "
      ".   .   p5  .   p8  .   .   .   .  "
      ".   .   .   .   .   .   p7  .   .  "
      ".   .   p2  .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4321 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    "p8  .   .   p4  .   .   p7 "
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p11 .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   p7  .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   .   .   p11 .   .  "
      "p1  .   .   .   p4  .   .   .   p9 "
      ".   .   p5  .   .   .   p10 .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p2  .   p8  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--532 {
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   .   p1  .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    "p2  .   p6  .   p5  .   p4  .   p3 "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p7  .   p8 .    p11 .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   .   p10 .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .  "
      ".   .   .   .   .   .   .  "
      ".   .   p4  .   p11 .   .  "
      ".   .   .   .   .   .   p9 "
      "p1  .   p5  .   p8  .   .  "
      ".   .   .   .   .   .   p10"
      ".   .   p6  .   p7  .   .  "
      ".   .   .   .   .   .   .  "
      ".   .   p2  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--541 {
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   .   p1  .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    "p2  .   p6  .   p5  .   p4  .   p3 "
    ".   .   .   .   .   .   .   .   .  "
    ".   p7  .   p8  .   p10 .   p11 .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   .   .   p9  .   .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .  "
      ".   .   .   .   p11 .   .  "
      ".   .   p4  .   .   .   .  "
      ".   .   .   .   p10 .   .  "
      "p1  .   p5  .   .   .   p9 "
      ".   .   .   .   p8  .   .  "
      ".   .   p6  .   .   .   .  "
      ".   .   .   .   p7  .   .  "
      ".   .   p2  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--352 {
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto auto auto;
  grid-template-areas:
    ".   .   .   .   p1  .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p6  .   p5  .   p4  .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p7  .   p11 .   p8  .   .  "
    "p2  .   .   .   .   .   .   .   p3 "
    ".   .   .   p10 .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr 1fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto auto auto;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      ".   .   .   .   .   p3  .  "
      ".   .   .   .   .   .   .  "
      ".   .   p4  .   p8  .   .  "
      ".   .   .   .   .   .   p9 "
      "p1  .   p5  .   p11 .   .  "
      ".   .   .   .   .   .   p10"
      ".   .   p6  .   p7  .   .  "
      ".   .   .   .   .   .   .  "
      ".   .   .   .   .   p2  .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr 1fr 1fr;
    // }
  }
}

.ef-formation__team--343 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p7  .   p8  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   p10 .   p9  .   p11 .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   p3  .   .  "
      ".   .   p4  .   .   .   p11"
      ".   .   .   .   p8  .   .  "
      "p1  .   p5  .   .   .   p9 "
      ".   .   .   .   p7  .   .  "
      ".   .   p6  .   .   .   p10"
      ".   .   .   .   p2  .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4222 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   p4  .   p8  .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   p7  .   p11 .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p9  .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   p8  .   p11 .   p9 "
      "p1  .   .   .   .   .   .   .   .  "
      ".   .   p5  .   p4  .   p7  .   p10"
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p2  .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3511 {
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   .   p1  .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p6  .   p5  .   p4  .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   p7  .   p11 .   p8  .   .  "
    "p2  .   .   .   .   .   .   .   p3 "
    ".   .   .   .   p10 .   .   .   .  "
    ".   .   .   .   .   .   .   .   .  "
    ".   .   .   .   p9  .   .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
      ".   .   .   .   .   p3  .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p4  .   p8  .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      "p1  .   p5  .   p11 .   p10 .   p9 "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   p7  .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   .   .   .   p2  .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3421 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p7  .   p8  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p11 .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   p3  .   .   .   .  "
      ".   .   p4  .   .   .   .   .   .  "
      ".   .   .   .   p8  .   p11 .   .  "
      "p1  .   p5  .   .   .   .   .   p9 "
      ".   .   .   .   p7  .   p10 .   .  "
      ".   .   p6  .   .   .   .   .   .  "
      ".   .   .   .   p2  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3412 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p7  .   p8  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   p10 .   p11 .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   p3  .   .   .   .  "
      ".   .   p4  .   .   .   .   .   .  "
      ".   .   .   .   p8  .   .   .   p11"
      "p1  .   p5  .   .   .   p9  .   .  "
      ".   .   .   .   p7  .   .   .   p10"
      ".   .   p6  .   .   .   .   .   .  "
      ".   .   .   .   p2  .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3142 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p8  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p7  .   p11 .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   p9  .   p10 .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   .   .   p3  .   .  "
      ".   .   p4  .   .   .   .   .   .  "
      ".   .   .   .   .   .   p11 .   p10"
      "p1  .   p5  .   p8  .   .   .   .  "
      ".   .   .   .   .   .   p7  .   p9 "
      ".   .   p6  .   .   .   .   .   .  "
      ".   .   .   .   .   .   p2  .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--343d {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto auto auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p8  .   .   .  "
    ".   p2  .   .   .   p3  .  "
    ".   .   .   p7  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p10 .   p9  .   p11 .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr 1fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto auto auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p4  .   .   p3  .   .   p11"
      ".   .   .   .   .   .   .   .   .  "
      "p1  .   p5  .   p8  .   p7  .   p9 "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   .   p2  .   .   p10"
      ".   .   .   .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr 1fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4132 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   .   p4  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p7  .   p8  .   p11 .  "
    ".   .   .   .   .   .   .  "
    ".   .   p9  .   p10 .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .   .   .  "
      ".   .   .   .   .   .   p11 .   .  "
      ".   .   p6  .   .   .   .   .   p10"
      "p1  .   .   .   p4  .   p8  .   .  "
      ".   .   p5  .   .   .   .   .   p9 "
      ".   .   .   .   .   .   p7  .   .  "
      ".   .   p2  .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4240 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   .   p4  .   p8  .   .  "
    ".   .   .   .   .   .   .  "
    "p7  .   p9  .   p10 .   p11";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   p10"
      ".   .   .   .   .   .   .  "
      ".   .   p6  .   p8  .   p11"
      "p1  .   .   .   .   .   .  "
      ".   .   p5  .   p4  .   p9 "
      ".   .   .   .   .   .   .  "
      ".   .   p2  .   .   .   p7 ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--4312 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    "p2  .   p5  .   p6  .   p3 "
    ".   .   .   .   .   .   .  "
    ".   p7  .   p4  .   p11 .  "
    ".   .   .   .   .   .   .  "
    ".   .   .   p8  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   .   p9  .   p10 .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   p3  .   .   .   .   .   .  "
      ".   .   .   .   p11 .   .   .   .  "
      ".   .   p6  .   .   .   .   .   p10"
      "p1  .   .   .   p4  .   p8  .   .  "
      ".   .   p5  .   .   .   .   .   p9 "
      ".   .   .   .   p7  .   .   .   .  "
      ".   .   p2  .   .   .   .   .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3241 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    ".   .   p2  .   p3  .   .  "
    ".   .   .   .   .   .   .  "
    "p10 .   p7  .   p8  .   p11"
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   .   .   p11 .   .  "
      ".   .   p4  .   .   .   .   .   .  "
      ".   .   .   .   p3  .   p8  .   .  "
      "p1  .   p5  .   .   .   .   .   p9 "
      ".   .   .   .   p2  .   p7  .   .  "
      ".   .   p6  .   .   .   .   .   .  "
      ".   .   .   .   .   .   p10 .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}

.ef-formation__team--3331 {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr auto 1fr auto 1fr auto 1fr auto;
  grid-template-areas:
    ".   .   .   p1  .   .   .  "
    ".   .   .   .   .   .   .  "
    ".   p6  .   p5  .   p4  .  "
    ".   .   .   .   .   .   .  "
    ".   p2  .   p8  .   p3  .  "
    ".   .   .   .   .   .   .  "
    "p10 .   .   p7  .   .   p11"
    ".   .   .   .   .   .   .  "
    ".   .   .   p9  .   .   .  ";

  @include ios {
    grid-template-rows: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
  }

  @include break(desktop-s) {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      ".   .   .   .   .   .   p11 .   .  "
      ".   .   p4  .   p3  .   .   .   .  "
      ".   .   .   .   .   .   .   .   .  "
      "p1  .   p5  .   p8  .   p7  .   p9 "
      ".   .   .   .   .   .   .   .   .  "
      ".   .   p6  .   p2  .   .   .   .  "
      ".   .   .   .   .   .   p10 .   .  ";

    // @include ios {
    //   grid-template-columns: 1fr .75fr 1fr .75fr 1fr .75fr 1fr .75fr 1fr;
    // }
  }
}
