.ef-line-up {
  position: relative;

  &:before {

    @include break(desktop-s) {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 1px);
      width: 2px;
      background-color: var(--color-front);
    }

    @include ie11() {
      display: none;
    }
  }

  &--minimal:before {
    display: none;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    max-width: none;
    background-color: var(--tone-back-800);
    text-transform: uppercase;
    @include type-scale(200);

    @include theme(light) {
      background-color: var(--color-back);
    }

    @include break(desktop-s) {
      display: block;
      margin: 0;
      padding: spacing(2) 0;
      text-align: center;

      .ef-line-up--minimal & {
        text-align: left;
      }

      @include ie11() {
        text-align: left; 
      }
    }
  }

  &__toggle {

    @include break(desktop-s) {
      display: none;
    }
  }

  &__list {
    margin-bottom: spacing(4);

    @include break(desktop-s) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: spacing(8);

      .ef-line-up--minimal & {
        display: block;
      }
    }
  }

  &__home,
  &__away {
    display: none;
    @include ie11() {
      margin-top: spacing(12);
    }

    &.--is-active {
      display: block;
    }

    @include break(desktop-s) {
      display: block;
    }
  }

  &__empty-state {
    @include type-scale(300);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: spacing(1) spacing(2);
    background-color: var(--tone-back-700);
    color: var(--tone-front-600);
    text-align: center;
  }

  &__player {
    display: flex;
    align-items: center;
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);

    &:not(:last-child) {
      border-bottom: 1px solid var(--tone-back-600);
    }
  }

  &__player-number,
  &__player-position,
  &__player-name {
    margin-right: spacing(4);
    @include type-scale(300);
  }

  &__player-number,
  &__player-position {
    flex-basis: spacing(4);
  }

  &__player-number {
    font-weight: 700;
  }

  &__player-name {
    flex: 1;
  }

  &__player-events {
    display: flex;
    align-items: center;
    @include ie11() {
      align-items: flex-start;
    }
  }

  &__player-event {
    display: flex;
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }

  &__text {
    @include type-scale(300);

    &--referee {

      @include break(desktop-s) {
        position: relative;
        padding-top: spacing(.25);
        background-color: var(--tone-back-800);
        text-align: center;

        .ef-line-up--minimal & {
          text-align: left;
        }

        @include ie11() {
          text-align: left;
        }
      }
    }

    .ef-line-up__home & {

      @include break(desktop-s) {
        text-align: right;

        .ef-line-up--minimal & {
          text-align: left;
        }

        @include ie11() {
          text-align: left;
        }
      }
    }
  }
}
