@keyframes ef-badge {
  0% {
    opacity: 0;
    transform: scale(.5);
  } 100% {
    opacity: 1;
    transform: none;
  }
}

.ef-badge {
  width: 16px;
  height: 16px;
  opacity: 0;
  border-radius: 50%;
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  transform: scale(.5);
  animation: ef-badge 1s $ease-out-back 1s forwards;

  .ef-icon-card & {
    opacity: 1;
    transform: none;
    animation: none;
  }
}
