.ef-dates-week {

  @include break(tablet) {
    display: flex;
    align-items: baseline;
  }

  &:not(:last-child) {
    margin-bottom: spacing(3);
    padding-bottom: spacing(3);
    border-bottom: 1px solid var(--tone-medium);
  }

  &__heading {
    flex-basis: spacing(16);
    margin-bottom: spacing(4);
  }

  &__dates {
    flex: 1;
  }
}

.ef-dates {

  &__cell {

    &--description {
      color: var(--color-front);
    }
  }

  &__date {
    color: var(--color-front);
    font-weight: 700;
  }

  &__match-team {
    color: var(--color-front);
    font-weight: 700;
    //@include type-scale(400);
  }
}

@include break(desktop-s) {

  .ef-dates {

    &__header-cell,
    &__cell {

      &--date {
        width: 17.5%;
      }

      &--description {
        width: 40%;
      }

      &--location {
        width: 25%;
      }

      &--type {
        width: 17.5%;
      }
    }
  }
}
