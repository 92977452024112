.ef-shop-highlight-card {
  @extend %card;
  @extend %card--has-line;
  @extend %card--has-line-desktop;

  &__media {
    @extend .ef-media--3x2;
  }

  &__image {
    @include transition;
  }

  @include break(desktop-s) {

    &:hover {

      .ef-shop-highlight-card__image {
        transform: scale(1.05);
      }
    }
  }

  &__text {
    @extend %text-container;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 66.666666%;
    padding-bottom: spacing(3);
    padding-left: spacing(3);
  }

  &__heading {
    @include secondary-heading;
    @include transition($duration-faster, $property: color);
    padding-top: 0;

    @include break(desktop-s) {

      .ef-shop-highlight-card:hover & {
        color: var(--color-accent);
      }
    }
  }

  &__badges {
    position: absolute;
    right: spacing(3);
    bottom: spacing(3);
  }

  .ef-shop-badge:not(:last-child) {
    margin-bottom: spacing(1);
  }

  .ef-ticket-logos {
    margin-bottom: spacing(2);
  }

  .ef-bookmark {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
  }
}
