.ef-goals {
  margin-bottom: spacing(2);
}

.ef-goal {
  @include type-scale(200);
  display: flex;

  &__home,
  &__away {
    display: flex;
    flex: 1;
    padding-top: .1em;
    color: var(--color-front);
  }

  &__home {
    justify-content: flex-end;
  }

  &__result {
    /* Temporary fallback styles for goal icon */
    display: flex;
    justify-content: center;
    height: spacing(3);
    /* End temporary fallback styles for goal icon */
    align-items: center;
    flex-basis: spacing(8);
    color: var(--color-accent);
    font-weight: 700;
    text-align: center;
  }

  &__time {
    flex-basis: spacing(8);
    font-weight: 700;
  }

  &__home .ef-goal__time {
    text-align: right;
  }
}
