.ef-historical-person-card {
  @extend %card;
  @extend %card--has-line;
  cursor: pointer;

  &__media-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--tone-back-600);

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 1px;
      height: 0;
      padding-top: 100%;
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    bottom: spacing(2);
    left: spacing(2);
    overflow: hidden;

    @include break(tablet) {
      top: spacing(3);
      right: spacing(3);
      bottom: spacing(3);
      left: spacing(3);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__text {
    @extend %text-container;
    padding-top: spacing(3);
    padding-right: spacing(3);
    padding-bottom: spacing(3);
  }

  .ef-heading {
    @include transition($property: color);
    font-family: font($font--subline);
    letter-spacing: normal;
    text-transform: uppercase;
  }

  @include break(desktop-s) {

    &:hover .ef-heading--4 {
      color: var(--color-accent);
    }
  }
}
