@font-face {
  font-family: "EintrachtDisplay-Headline";
  src: url("../fonts/Eintracht-Font/EintrachtDisplay-Headline.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EintrachtDisplay-Subline";
  src: url("../fonts/Eintracht-Font/EintrachtDisplay-Subline.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.ef-hl-teaser {
  @extend %boxed-padding;
  padding-top: spacing(12);
  padding-bottom: spacing(12);
  background-color: var(--color-black);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;

  @include break(desktop-s) {
    padding-top: spacing(16);
    padding-bottom: spacing(16);
    background-size: contain;
  }

  &--mainpay {

  }

  &__header {

    @include break(desktop-s) {
      display: grid;
      grid-template-columns: 1fr spacing(52);
      grid-gap: spacing(8);
    }
  }

  &__headline {
    font-family: "EintrachtDisplay-Headline", sans-serif;
    font-weight: normal;
    letter-spacing: normal;
  }

  &__subline {
    font-family: "EintrachtDisplay-Subline", sans-serif;
    font-weight: normal;
    letter-spacing: normal;
  }

  &__mastercard-mainpay-logos {
    display: flex;
    align-items: flex-end;
    padding: spacing(2) 0 spacing(8);

    @include break(desktop-s) {
      padding: 0;
    }
  }

  &__mastercard-logo {
    width: spacing(14);
    margin-right: spacing(12);

    @include break(tablet) {
      width: spacing(16);
    }
  }

  &__mainpay-logo {
    width: spacing(20);

    @include break(tablet) {
      width: spacing(24);
    }
  }
}
