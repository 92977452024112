.ef-article {
  //position: relative;
  //z-index: 2;

  &--low-level {
    //padding-top: spacing(8);
  }

  &__header {
    @extend %boxed-padding;
    position: relative;
    //padding-top: spacing(6);

    .ef-content__block--perpetual-content-teaser & {
      padding-top: 0;
    }
  }

  &__header-title,
  &__header-media,
  &__header-intro {
    margin-bottom: spacing(4);
  }

  &__header-media {

    .ef-content__block--gallery-slider {
      margin-bottom: 0;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__intro {
    padding-top: spacing(20);
    padding-bottom: spacing(10);
    text-align: center;
  }
}

.ef-article--magazine {

  .ef-article__header {
    margin-top: spacing(-12);
    margin-bottom: spacing(12);
    padding-top: 45.5vw;

    @include break(desktop-s) {
      position: relative;
      height: 50vw;
      max-height: 100vw;
      padding-top: spacing(16);
      overflow: hidden;
      background-color: var(--color-back);
    }

    @include break(desktop-m) {
      padding-top: spacing(24);
    }

    @include break(desktop-l) {
      padding-top: spacing(32);
    }

    .ef-kicker,
    .ef-heading--1,
    .ef-heading--sub {
      opacity: 0;
      transform: translateY(24px);
      animation: ef-slide-fade-in-btt .7s ease-in-out forwards;
    }

    .ef-kicker {
      animation-delay: .5s;
    }

    .ef-heading--1 {
      animation-delay: .8s;
    }

    .ef-heading--sub {
      animation-delay: 1.1s;
    }

    .ef-media__image {
      opacity: 0;
      animation: ef-fade-in 5s ease-in-out forwards;
    }
  }

  .ef-article__header-media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: spacing(10); // live fix
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;

    @include break(desktop-s) {
      bottom: 0;
      width: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 31.8%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(to top, $dark-gray-800, rgba($dark-gray-800, 0));

      @include break(desktop-s) {
        display: none;
      }
    }

    .ef-media {
      height: 100vw;
      padding-bottom: 0;

      @include break(desktop-s) {
        height: auto;
        @include fill;
      }
    }
  }

  .ef-article__header-title,
  .ef-article__header-intro {
    position: relative;
    margin: 0;

    @include break(desktop-s) {
      width: 50%;
      padding-right: spacing(8);
    }
  }

  .ef-article__header-title {
    z-index: 2;
    margin: 0;
  }

  .ef-heading--sub {
    margin-top: 0;
  }

  .ef-article__header-intro {
    z-index: 2;
  }
}

.ef-article--person {

  .ef-article__header {
    margin-top: 0;
    padding-top: 0;

    @include break(desktop-s) {
      height: auto;
      margin-bottom: spacing(12);
      padding-top: spacing(12);
    }
  }

  .ef-article__header > .ef-container {

    @include break(desktop-s) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: spacing(9);
      align-items: center;
    }
  }

  .ef-article__header .ef-paragraph {

    @include break(desktop-s) {
      margin-bottom: 0;
    }
  }

  .ef-article__header-text {

    @include break(desktop-s) {
      order: 0;
    }
  }

  .ef-article__header-person {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-bottom: spacing(6);
    // padding: spacing(4);
    // border: 1px solid var(--tone-back-600);

    position: relative;
    width: 100%;
    margin-bottom: spacing(6);
    overflow: hidden;
    border: 1px solid var(--tone-back-600);

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 1px;
      height: 0;
      padding-top: 100%;
    }

    @include break(desktop-s) {
      order: 1;
      margin-bottom: 0;
    }
  }

  .ef-article__header-person-media {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(4);
    right: spacing(4);
    bottom: spacing(4);
    left: spacing(4);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .ef-article__header-title,
  .ef-article__header-intro {
    width: 100%;
  }

  .ef-article__body {

    @include break(desktop-s) {
      margin-bottom: spacing(12);
    }
  }
}
