@keyframes ef-accordion-overflow {
  0% {
    overflow-y: hidden;
  } 50% {
    overflow-y: hidden;
  } 100% {
    overflow: visible;
  }
}

.ef-accordion {
  //margin-bottom: spacing(3);

  &:not(:last-child) {
    margin-bottom: spacing(2);
    border-bottom: 1px solid var(--tone-back-600);
  }

  &[aria-expanded="true"] > .ef-accordion__header::after {
    transform: rotate(-180deg);
  }

  &__header {
    position: relative;
    padding-right: calc(#{spacing-no-calc(2)} + 32px);
    padding-top: spacing(1);
    padding-bottom: spacing(3);
    cursor: pointer;
    // font-weight: 700;
    // @include type-scale(500);

    &:after {
      content: "";
      position: absolute;
      top: spacing(1);
      right: 0;
      bottom: spacing(3);
      width: 32px;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center center;
      @include transition($duration-slower, $property: transform, $easing: $ease-in-out-back);
    }
  }

  &__heading {
    margin-bottom: 0;
    font-weight: 400;
  }

  &__container {
    height: 0;
    overflow-y: hidden;
    will-change: height;
    @include transition($property: height, $easing: $ease-in-quad);

    .ef-accordion[aria-expanded="true"] & {
      animation: ef-accordion-overflow 1s linear forwards;
    }
  }

  &__body {
    padding-bottom: spacing(4);

    h6 {
      padding-left: 0;
    }

    h6:before {
      display: none;
    }
  }

  &__block {

    &:not(:last-child) {
      margin-bottom: spacing(4);
    }
  }
}
