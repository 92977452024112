.ef-icon-and-text {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;

    &:last-child {
      padding-left: 8px;
    }

    * {
      margin-bottom: 0;
    }
  }
}
