.ef-footer-grid {
  display: grid;
  gap: spacing(2);
  margin-bottom: spacing(6);

  &--main {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .ef-footer-grid__item:first-child {
      grid-column: span 3;
      width: calc((100% - #{spacing-no-calc(4)}) / 3);
      margin-bottom: spacing(4);
      padding-bottom: calc(33.333333% / 3);
    }

    @include break(tablet) {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .ef-footer-grid__item:first-child {
        grid-column: span 4;
        width: calc((100% - #{spacing-no-calc(6)}) / 4);
        padding-bottom: calc(33.333333% / 4);
      }
    }

    @include break(desktop-s) {
      grid-template-columns: repeat(6, minmax(0, 1fr));

      .ef-footer-grid__item:first-child {
        grid-column: span 6;
        width: calc((100% - #{spacing-no-calc(10)}) / 6);
        padding-bottom: calc(33.333333% / 6);
      }
    }

    @include break(desktop-l) {
      grid-template-columns: repeat(8, minmax(0, 1fr));

      .ef-footer-grid__item:first-child {
        grid-column: span 8;
        width: calc((100% - #{spacing-no-calc(14)}) / 8);
        padding-bottom: calc(33.333333% / 8);
      }
    }
  }

  &--premium {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include break(tablet) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @include break(desktop-s) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    @include break(desktop-l) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }

  &__item {
    @extend .ef-media;
    @extend .ef-media--3x1;
    border: 1px solid var(--color-front);
    border-radius: 12px;
  }
  
  &__image {
    position: absolute;
    top: 10%;
    right: 10%;
    bottom: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    object-fit: contain;

    @include break(tablet) {

      .ef-footer-grid--main & {
        top: spacing(1.5);
        right: spacing(1.5);
        bottom: spacing(1.5);
        left: spacing(1.5);
        width: calc(100% - #{spacing-no-calc(3)});
        height: calc(100% - #{spacing-no-calc(3)});
      }
    }
  }
}


