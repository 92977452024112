.ef-partner-grid {
  display: grid;
  //grid-column-gap: spacing(4);
  justify-content: center;
  color: var(--color-front);

  @include ie11() {
    display: flex;
    align-items: space-around;
    justify-content: space-around;
  }

  &--main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: spacing(3);
  }

  &--premium {
    display: flex;
    flex-wrap: wrap;
    //grid-template-columns: repeat(3, 1fr);
    //grid-row-gap: spacing(6);

    @include ie11() {
      display: flex;
      flex-wrap: wrap;
    }
    //margin-bottom: spacing(4);

    // @include break(tablet) {
    //   grid-template-columns: repeat(4, 1fr);
    // }
    //
    // @include break(desktop-s) {
    //   grid-template-columns: repeat(6, 1fr);
    // }
    //
    // @include break(desktop-xl) {
    //   grid-template-columns: repeat(8, 1fr);
    // }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 104px;
    flex-basis: 20%;
    margin-bottom: spacing(6);
    padding-right: spacing(2);
    padding-left: spacing(2);

    .ef-partner-grid--main & {
      flex-basis: percentage(1/2);
      height: spacing(6);
      margin-bottom: spacing(6);

      @include break(tablet) {
        flex-basis: percentage(1/3);
        flex: auto;
      }

      @include ie11() {
        height: 100px;
      }
    }

    &:first-child {

      .ef-partner-grid--main & {
        flex-basis: 100% !important;
      }
    }

    .ef-partner-grid--premium & {
      flex-basis: percentage(1/3);
      height: spacing(4);
      max-width: 128px;

      @include break(tablet) {
        flex-basis: percentage(1/4);
      }

      @include break(desktop-s) {
        flex-basis: percentage(1/7);
      }

      @include break(desktop-xl) {
        flex-basis: percentage(1/7);
      }
    }

    &--size-plus .ef-partner-grid--premium & {
      height: spacing(5);
    }

    &--size-minus .ef-partner-grid--premium & {
      height: spacing(3.5);
    }
  }
}

.ef-partner {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  //transform: translateX(-50%);

  .ef-partner-grid--premium .ef-partner-grid__item--size-plus & {
    max-height: spacing(5);
    transform: translateY(#{spacing(-.5)});
  }

  .ef-partner-grid--premium .ef-partner-grid__item--size-minus & {
    max-height: spacing(3);
    transform: translateY(#{spacing(.25)});
  }

  .ef-partner-grid--main & {
    max-width: 90%;
    max-height: spacing(6);
  }

  // .ef-partner-grid--main .ef-partner-grid__item:first-child & {
  //
  //   @include break(desktop-s) {
  //     transform: translateX(calc(#{spacing-no-calc(4)} + 33.333333%));
  //   }
  // }
  //
  // .ef-partner-grid--main .ef-partner-grid__item:last-child & {
  //
  //   @include break(desktop-s) {
  //     transform: translateX(calc((#{spacing-no-calc(4)} + 33.333333%) * -1));
  //   }
  // }

  .ef-partner-grid--premium & {
    max-width: 100%;
    max-height: spacing(4);
  }
}
