.ef-perpetual-calendar-card {
  //padding-top: spacing(4);
  padding-right: spacing(3);

  &__header {
    padding-left: spacing(1.5);

    .ef-heading {
      @include type-scale(200);
      margin-bottom: spacing(1);
      text-transform: uppercase;
    }
  }

  &__days {
    display: flex;
    flex-wrap: wrap;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 14.285714%;
    height: spacing(5);
    //margin-right: spacing(2);
    //margin-bottom: spacing(2);
    //padding: spacing(2) spacing(2) spacing(2) 0;
    color: var(--color-front);
    //font-weight: 700;
    @include type-scale(300);
    @include transition($property: color);

    @include ie11() {
      margin-right: 5px;
    }

    // @include break(desktop-s) {
    //   flex-basis: 11.111111%;
    // }
    //
    // @include break(desktop-m) {
    //   flex-basis: 8.333333%;
    // }

    @include break(desktop-s) {
      
      &:hover {
        color: var(--color-accent);
      }
    }

    &.--is-current {
      position: relative;
      z-index: 1;
      color: var(--color-accent-contrast);
      font-weight: 700;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: spacing(4);
        height: spacing(4);
        //border: 2px solid var(--color-accent);
        border-radius: 50%;
        background-color: var(--color-accent);
        transform: translate(-50%, -50%);
      }
    }

    // &.--is-current:hover {
    //   color: var(--color-front);
    // }
  }
}
