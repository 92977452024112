.ef-tracking {
  &-item {
    display: flex;
    position: relative;
    @include type-scale(300);
    padding-left: calc(24px + #{spacing-no-calc(2)});
    padding-bottom: spacing(6);

    &:not(:last-child) {
      &:before,
      &:after {
        position: absolute;
        width: 1px;
        content: '';
        left: 11px;
        height: calc(100% - #{spacing-no-calc(2.2)} - 24px);
        top: calc(24px + #{spacing-no-calc(1.2)});
        border-left: 1px solid var(--tone-back-300);
      }

      &:after {
        transform: scaleX(0);
        transform-origin: 0 100%;
        border-color: var(--color-accent);
      }

      .ef-tracking.--is-in-view & {
        @for $i from 1 through 5 {
          &:nth-last-child(#{$i}){
            &:after {
              animation: track-line-show .5s $ease-out-quad forwards;
              animation-delay: .1s + ($i/2);
            }
          }
        }
      }
    }

    &__date, &__info {
      flex: 1 1 100%;
    }

    &__date {
      flex: 1 1 spacing(20) !important;
      @include break(tablet) {
        flex: 1 1 spacing(24) !important;
      }
    }

    &__icon {
      @extend .ef-checkout-progress__icon;
      position: absolute !important;
      left: 0;
      top: 0;
      flex: none;
      border-color: var(--color-accent) !important;

      &:after {
        .ef-tracking-item.--checked & {
          animation: circle-inner-pointer .4s $ease-out-quad forwards;
        }
        .ef-tracking-item.--selected & {
          display: none;
        }
      }

      .ef-tracking-item.--selected & {
        border-color: var(--color-accent);
        background-color: var(--color-accent);
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px;
        animation: none;
        opacity: 1;
      }
    }
  }
}

@keyframes track-line-show {
  0% {
    transform: scaleY(0);
  } 100% {
    transform: scaleY(1);
  }
}
