.ef-hamburger {
  display: flex;
  align-items: center;
  z-index: z(hamburger);
  cursor: pointer;
  @include transition($property: transform);

  @include break(desktop-s) {
    //align-items: center;
    position: absolute;
    left: spacing(4);
    //bottom: 0;
    //z-index: 999999;
  }

  &__icon {
    width: rem(24);
  }

  &__icon:before,
  &__icon:after,
  &__icon-line {
    content: "";
    display: block;
    height: 2px;
    background-color: var(--color-front);
    @include transition($easing: $ease-in-out-expo);

    [data-theme-2="shop"] & {
      background-color: var(--color-white) !important;
    }
  }

  &__icon:after {

  }

  &__icon-line {
    width: 50%;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  @include break(desktop-s) {

    &:hover .ef-hamburger__icon:before {
      transform: translateY(-2px);
    }

    &:hover .ef-hamburger__icon:after {
      transform: translateY(2px);
    }

    &:hover .ef-hamburger__icon-line {
      transform: translateX(50%) scaleX(2);
    }
  }

  &.--is-active {
    transform: scale(.75);

    .ef-hamburger__icon:before,
    .ef-hamburger__icon:after,
    .ef-hamburger__icon-line {
      background-color: var(--color-white);

      @include theme(light) {
        background-color: var(--color-black);

        @include break(tablet) {
          background-color: var(--color-white);
        }
      }

      [data-theme-2="shop"] & {
        background-color: var(--color-white) !important;
      }
    }

    .ef-hamburger__icon:before {
      transform: translateY(9px) rotate(135deg);
    }

    .ef-hamburger__icon:after {
      width: 100%;
      transform: translateY(-9px) rotate(-135deg);
    }

   .ef-hamburger__icon-line {
      transform: scale(0);
    }
  }
}
