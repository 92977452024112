.ef-copy-block {
  //@extend %ef-max-width-l-center;

  > :last-child {
    margin-bottom: 0;
  }

  &--left {
    margin-left: 0;
  }
}

a {
  @include word-break(false);
}

.ef-copy-block p,
.ef-paragraph {
  margin-bottom: spacing(4);
  color: var(--tone-200);
  @include max-width(m);
  @include type-scale(400);

  .ef-article--magazine .ef-article__body & {
    color: var(--tone-front-600);
    font-family: font($font--serif);
  }
}

.ef-paragraph {

  &--featured {
    color: var(--color-front);
    font-weight: 500;
    @include type-scale(700);
  }

  &--lead {
    color: var(--color-front);
    font-weight: 500;
    @include type-scale(500);
  }

  &--full {
    max-width: none;
  }
}

b, strong {
  color: var(--color-front);
  font-weight: 700;
}

em {
  font-style: italic;
}

mark,
.ef-text--mark {
  background-color: rgba(var(--color-accent), .5);
}

.ef-text--center {
  text-align: center;
}

small,
.ef-text--s,
.ef-copy-block .ef-text--s {
  @include type-scale(300);
}

.ef-text--xs {
  @include type-scale(200);
}

.ef-text--regular {
  font-weight: 400;
}

.ef-text--accent {
  color: var(--color-accent);
}

.ef-text--accent-strong {
  color: var(--color-accent);
  font-weight: 700;
}

.ef-text--highlight {
  color: var(--color-accent);
  font-weight: 700;

  .ef-icon-and-text__item {
    font-family: font($font--subline);
    font-weight: 400;
  }
}

.ef-text--secondary {
  font-family: font($font--subline);
  font-weight: 400;
}

.ef-copy-block a,
.ef-text--link {
  //position: relative;
  //overflow: hidden;
  //border-bottom: 1px solid var(--color-front);
  //font-weight: 400;
  cursor: pointer;
  color: var(--color-front);
  text-decoration: underline;
  @include transition;

  &:visited {
    color: var(--color-front);
  }

  &:hover {
    //border-color: var(--color-accent);
    color: var(--color-front);
    text-decoration-color: var(--color-accent);
  }
}
