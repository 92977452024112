$size-circles: 24px;
$delay-count: .5s;
$hidden-grey: var(--tone-back-300);

@mixin anim-initial-steps($delay, $animation) {
  animation-delay: $delay;
  animation-duration: .5s;
  animation-name: $animation;
  animation-fill-mode: forwards;
}

.ef-checkout-progress {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: spacing(4);

  &__line {
    position: relative;
    height: 1px;
    flex: 1;
    margin: 0 spacing(2);
    background-color: $hidden-grey;
    transform: scale(0);
    transform-origin: 0 0;

    &:after {
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
      left: 0;
      transform: scale(0);
      transform-origin: 0 0;
      background-color: var(--color-accent);
    }
  }

  &__item:first-child > &__circle:after {
    @include anim-initial-steps($delay-count, line-show);
  }
  &__item:nth-child(2) > &__circle:after  {
    @include anim-initial-steps($delay-count*2, line-show);
  }
  &__item:nth-child(2) {
    opacity: 0;
    @include anim-initial-steps($delay-count*1.5, items-show);
  }
  &__item:nth-child(3) {
    opacity: 0;
    @include anim-initial-steps($delay-count*2.75, items-show);
  }

  &__item {

    &.--selected {
      animation: none;
      opacity: 1;

      .ef-checkout-progress__circle:after {
        animation: none;
        transform: scaleX(1);
      }
      .ef-checkout-progress__circle:before {
        animation: line-show .5s $ease-out-quad forwards;
      }

      ~.--checked {
        animation: none;
        opacity: 1;
      }
    }

    &:last-child {
      .ef-checkout-progress__circle {
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  &__circle {
    display: block;

    &:before, &:after {
      position: absolute;
      height: 1px;
      top: 12px;
      content: '';
      display: block;
      margin-left: calc(1.5% + 24px);
      transform-origin: 0 0;
      transform: scaleX(0);
      background-color: $hidden-grey;
      width: calc(47% - 36px);
    }

    &:before {
      z-index: 5;
      background-color: var(--color-accent);
    }
  }

  &__icon {
    position: relative;
    width: $size-circles;
    height: $size-circles;
    display: block;
    border-radius: 100%;
    margin: 0 auto;
    border: 2px solid $hidden-grey;

    .ef-checkout-progress__item.--checked & {
      animation: circle-border .4s $ease-out-quad forwards;
    }

    .ef-checkout-progress__item.--selected & {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 24px;
      animation: none;
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-accent);
      border-radius: 100%;
      opacity: 0;

      .ef-checkout-progress__item.--checked & {
        animation: circle-inner-pointer .4s $ease-out-quad forwards;
      }
    }
  }

  &__label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: spacing(5);
    line-height: 1;
    color: $hidden-grey;
    display: none;
    width: 33%;
    min-width: 20px;
    @include type-scale(200);

    @include break(desktop-s) {
      display: inline-block;
    }

    .ef-checkout-progress__item:first-child &,
    .ef-checkout-progress__item:last-child & {
      width: auto;
      min-width: inherit;
    }

    .ef-checkout-progress__item:first-child & {
      left: 0;
      right: auto;
    }

    .ef-checkout-progress__item:last-child & {
      left: auto;
      right: 0;
    }

    .ef-checkout-progress__item.--checked & {
      display: block;
      animation: text-color .4s $ease-out-quad forwards;
    }

    .ef-checkout-progress__item.--selected & {
      animation: none;
    }
  }
}

// Active States

@keyframes circle-inner-pointer {
  0% {
    opacity: 0;
    transform: scale(0);
  } 100% {
    opacity: 1;
    transform: scale(.35);
  }
}

@keyframes circle-border {
  0% {
    border-color: $hidden-grey;
  } 100% {
    border-color: var(--color-accent);
  }
}

@keyframes text-color {
  0% {
    color: $hidden-grey;
  } 100% {
    color: var(--color-front);
  }
}

// Initial States

@keyframes line-show {
  0% {
    transform: scaleX(0);
  } 100% {
    transform: scaleX(1);
  }
}

@keyframes items-show {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}
