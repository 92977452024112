.ef-shop-grid {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: spacing(1);

  @include break(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    grid-column: span 1;
  }
}
