.ef-content__block--video-xmas {
  padding-top: spacing(3);
  padding-bottom: spacing(3);

  .ef-media {
    z-index: 1;
    overflow: visible;
    background-color: $color--black;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: spacing(-3);
      right: spacing(-3);
      bottom: spacing(-3);
      left: spacing(-3);
      background: url(../img/xmas/xmas_videoframe.jpg) no-repeat center;
      background-size: cover;
    }
  }
}

.ef-xmas-logo {
  @extend %boxed-padding;
  margin-bottom: spacing(4);

  @include break(desktop-s) {
    position: absolute;
    top: spacing(6);
    right: 0;
  }

  &__image {
    width: spacing(28);
    height: spacing(28 / 571.91 * 189.68);
  }
}

.ef-xmas-cta-banner {
  height: 120vw;
  margin-bottom: spacing(8);

  @include break(phablet) {
    height: 75vw;
  }

  @include break(tablet) {
    height: 75vw;
  }

  @media (min-width: 768px) {
    height: 42.553191vw;
  }

  @include break(desktop-s) {
    height: 42.553191vw;
  }

  @media (min-width: 1170px) {
    height: 28.125vw;
  }

  .ef-shop-hero__content {
    padding-bottom: spacing(4);
  }

  .ef-shop-hero__content .ef-container {
    height: auto;

    &:not(:last-child) {
      padding-bottom: spacing(4);
      border-bottom: 1px solid rgba(#dec267, .5);
    }

    &:last-child {
      align-items: baseline;
    }
  }

  .ef-shop-hero__title {
    padding-top: spacing(4);
  }

  .ef-shop-hero__heading {

    @include break(tablet) {
      margin-bottom: spacing(3);
    }

    .ef-heading__text {
      border: 1px solid #dec267;
    }
  }

  .ef-shop-hero__description {

    @include break(tablet) {
      margin-bottom: 0;
    }
  }

  .ef-paragraph {
    margin-bottom: spacing(2);

    @include break(desktop-s) {
      margin-bottom: 0;
    }
  }

  // .ef-button {
  //   background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--black)}" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
  // }

  // .ef-button--primary {
  //   border-color: #dec267;
  //   background-color: #dec267;
  //   color: $color--black;
  // }
  //
  // .ef-button--secondary {
  //   border-color: $color--white;
  //   background-color: $color--white;
  //   color: $color--black;
  // }
}
