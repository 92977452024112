@keyframes ef-125-welcome-visual {
  0% {
    transform: scale(2);
  }
  100% {
    transform: none;
  }
}


@keyframes ef-125-welcome-bg {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}

@keyframes ef-125-welcome-logo-mobile {
  0% {
    opacity: 0;
    transform: scale(.5);
  } 10% {
    opacity: 0;
    transform: scale(.5);
  } 33.333333% {
    opacity: 1;
  } 100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ef-125-welcome-logo {
  0% {
    opacity: 0;
    transform: scale(.7);
  } 10% {
    opacity: 0;
    transform: scale(.7);
  } 33.333333% {
    opacity: 1;
  } 100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ef-125-welcome-title {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ef-125-welcome-buttons {
  0% {
    opacity: 0;
    transform: scale(.5);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.ef-125-welcome {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  z-index: 999999999;

  &__visual {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: var(--color-back);
    transform: scale(2);
    animation: ef-125-welcome-visual 10s $ease-in-out-quad forwards;
  }

  &__background,
  &__logo {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__background {
    animation: ef-125-welcome-bg 1s $ease-in-out-quad 3s forwards;
  }

  &__logo {
    opacity: 0;
    transform: scale(.5);
    animation: ef-125-welcome-logo-mobile 10s $ease-in-out-quad forwards;

    @media (orientation: landscape) {
      transform: scale(.7);
      animation: ef-125-welcome-logo 10s $ease-in-out-quad forwards;
    }
  }

  &__title {
    @extend %boxed-padding;
    display: flex;
    justify-content: center;
    flex: 1;
    padding-top: spacing(12);
    text-align: center;
    opacity: 0;
    animation: ef-125-welcome-title 1s ease-in-out 1s forwards;
  }

  &__buttons {
    @extend %boxed-padding;
    display: flex;
    flex-wrap: wrap;
    gap: spacing(3);
    justify-content: center;
    padding-bottom: spacing(12);
    opacity: 0;
    animation: ef-125-welcome-buttons 1s $ease-out-back 3s forwards;

    .ef-button {
      width: 100%;

      @include break(phablet) {
        width: auto;
      }
    }
  }
}
