.ef-contract-cards {
  @include max-width(m);
  display: grid;
  gap: spacing(6);
}

.ef-contract-card {
  border-radius: spacing(1);
  background-color: var(--tone-front-600);

  &__inner {
    position: relative;
    padding: spacing(3) calc(#{spacing-no-calc(6)} + 24px) spacing(3) spacing(3);
    border: 1px solid var(--color-front);
    border-radius: spacing(1);
    background-color: var(--color-back);
  }

  &__content {
    display: grid;
    gap: spacing(3);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: spacing(3);
  }

  &__icon {
    display: flex;
    align-items: center;
    color: var(--color-front);
  }

  &__title {
    flex: 1;
  }

  &__label {
    @include type-scale(300);
  }

  &__value {
    @include type-scale(400);
    font-weight: 700;

    &--s {
      @include type-scale(300);
    }
  }

  &__seat {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: spacing(3);

    @include break(phablet) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: spacing(3);
  }

  &__link {
    @include fill;

    &:hover + .ef-contract-card__arrow {
      color: var(--color-accent);
    }
  }

  &__arrow {
    @include transition($property: color);
    position: absolute;
    top: 50%;
    right: spacing(3);
    transform: translateY(-50%);
    color: var(--color-front);
  }

  &__status {
    @include type-scale(300);
    padding: spacing(2) spacing(3);
    color: var(--color-back);
    font-weight: 700;
  }
}
