.ef-ticket-grid {
  grid-template-columns: repeat(1, 1fr);

  .ef-content__block--grid-list & {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: spacing(2);
  }

  @include break(phablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include break(desktop-xl) {
    grid-template-columns: repeat(6, 1fr);
  }

  &__item {
    grid-column: span 1;
  }
}
