@keyframes ef-pulse {
  0% {
    transform: translateY(-66.666666%) scale(0);
  } 100% {
    transform: translateY(-66.666666%) scale(1);
  }
}

@keyframes ef-live-spinner-full {
  0% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(180deg);
  }
}

@keyframes ef-live-spinner-fill {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  } 20% {
    opacity: 1;
  } 80% {
    opacity: 1;
  } 100% {
    opacity: 0;
    transform: rotate(180deg);
  }
}

.ef-match-meta {
  position: relative;
  z-index: z(match-meta);
  background-color: var(--color-back);
  transform: translateY(#{spacing(-12)});

  .ef-show-more {
    padding-bottom: 0;
    border: 0;
  }
}

.ef-match-header {
  position: relative;
  background-color: var(--color-back);
  // @include row-gap(m);
  // border-bottom: 1px solid var(--tone-back-700);

  @include break(desktop-s) {
    //@include row-gap(s);
    //padding-top: spacing(2);
  }

  &__link {
    @include fill;
  }

  &__grid {
    @extend %boxed-padding;
    @include row-gap(s);
    position: relative;
    z-index: 999;
    background-color: var(--color-back);

    // @include break(desktop-s) {
    //   @include row-gap(s);
    // }
  }

  &__grid > .ef-container {
    display: grid;
    grid-template-columns: 1fr spacing(12) 1fr;
    grid-template-areas:
      "details  details details"
      "logoHome result  logoAway"
      "nameHome .       nameAway"
      "service  service service";
    grid-gap: spacing(1) spacing(2);
    align-items: center;

    @include ie11() {
      display: flex;
      justify-content: space-between;
      padding: spacing(8) 0 spacing(4) 0;
    }
    @include break(desktop-s) {
      grid-template-columns: 1.666666fr 1fr spacing(5) spacing(8) spacing(5) 1fr 1.666666fr;
      grid-template-areas:
        "details nameHome logoHome result logoAway nameAway service";
      grid-column-gap: spacing(2);
    }
  }

  &__details {
    grid-area: details;
    display: flex;
    justify-content: center;
    margin-bottom: spacing(1);
    //justify-content: center;
    //flex-wrap: wrap;
    // margin-bottom: spacing(1);
    // padding-bottom: spacing(2);
    // border-bottom: 1px solid var(--tone-back-700);
    color: var(--color-front);
    //text-align: center;
    @include type-scale(200);
    line-height: spacing(2.5) !important;

    @include break(desktop-s) {
      justify-content: flex-start;
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
      text-align: left;
    }

    @include ie11() {
      order: 1;
    }
  }

  &__details-competition {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: spacing(2);
    padding-right: spacing(2);
    border-right: 1px solid var(--tone-back-600);
    color: var(--color-front);
  }

  &__details-competition-logo {
    max-width: 24px;
    max-height: 24px;

    use[href="#ef-competition--bl"] {
      color: #c12a24;
    }

    @include break(desktop-s) {
      max-width: spacing(6);
      max-height: spacing(6);
    }
  }

  &__detail {
    display: inline;

    @include break(desktop-s) {
      display: block;
    }

    &:not(:last-child):after {
      content: "·";
      padding-right: spacing(.5);
      padding-left: spacing(1);
      color: var(--color-front);

      @include break(desktop-s) {
        display: none;
      }
    }
  }

  &__team-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: spacing(6);
    height: spacing(6);

    @include break(desktop-s) {
      width: spacing(5);
      height: spacing(5);
    }

    &--home {
      grid-area: logoHome;
      @include ie11() {
        order: 3;
      }
    }

    &--away {
      grid-area: logoAway;
      @include ie11() {
        order: 5;
      }
    }
  }

  &__team-logo-image {
    max-width: 100%;
    max-height: 100%;
  }

  &__team-name {
    align-self: flex-start;
    color: var(--color-front);
    font-weight: 700;
    text-align: center;
    @include type-scale(300);
    line-height: spacing(2.5);

    @include break(desktop-s) {
      @include type-scale(400);
      align-self: center;
      line-height: spacing(3) !important;
    }

    &--home {
      grid-area: nameHome;

      @include ie11() {
        order: 2;
        text-align: right;
      }

      @include break(desktop-s) {
        text-align: right;
      }
    }

    &--away {
      grid-area: nameAway;

      @include ie11() {
        order: 6;
        text-align: left;
      }

      @include break(desktop-s) {
        text-align: left;
      }
    }
  }

  &__result {
    grid-area: result;
    position: relative;
    text-align: center;
    @include ie11() {
      order: 4;
    }
  }

  &__result-time {
    @extend .ef-kicker;
    margin-bottom: 0;

    .ef-match-header__result--live & {
      color: var(--color-accent);
    }
  }

  &__result-primary {
    color: var(--color-front);
    font-family: font($font--headline);
    font-weight: 400;
    @include type-scale(800);
    line-height: spacing(5);

    @include break(desktop-s) {
      @include type-scale(600);
      line-height: spacing(4) !important;
    }

    .ef-match-header__result--live & {
      color: var(--color-accent);
    }
  }

  &__result-secondary {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    color: var(--color-front);
    white-space: nowrap;
    @include type-scale(200);

    @include break(desktop-s) {
      margin-right: spacing(-4);
      margin-left: spacing(-4);
    }
  }

  &__services {
    grid-area: service;
    display: flex;
    justify-content: center;
    align-items: baseline;
    //margin-top: spacing(-1);
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-self: flex-end;

    @include break(desktop-s) {
      display: block;
      align-self: center;
      margin-top: 0;
      text-align: right;
    }

    > :not(:first-child):before {
      content: "·";
      padding-right: spacing(1);
      padding-left: spacing(1);
      color: var(--color-front);

      @include break(desktop-s) {
        display: none;
      }
    }
  }

  &__service {

    &--broadcaster {
      display: flex;
      justify-content: flex-end;

      .--is-live & {

        @include break(desktop-s) {
          margin-right: calc(32px + #{spacing-no-calc(1)});
        }
      }
    }
  }

  .ef-broadcaster {

    .--is-live & {
      transform: scale(.8);
    }
  }

  &__live {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-accent);

    @include break(desktop-s) {
      justify-content: flex-end;
    }
  }

  &__live-time {
    @include type-scale(300);
    padding-left: 22px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z"%3E%3C/path%3E%3Cpolygon fill="#{url-encoded-color($color--red-400)}" data-color="color-2" points="9,4 7,4 7,9 12,9 12,7 9,7 "%3E%3C/polygon%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left center;
    // line-height: spacing(2.5);
    font-weight: 700;
  }

  &__live-reload {
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: spacing(1);
    cursor: pointer;

    .ef-icon {
      @include transition($property: transform, $easing: $ease-out-back);
      position: absolute;
      top: 8px;
      left: 8px;
    }

    &:hover .ef-icon {
      transform: scale(1.05);
    }
  }

  &__live-spinner,
  &__live-spinner-circle-full,
  &__live-spinner-circle-half,
  &__live-spinner-circle-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__live-spinner {
    //background-color: var(--tone-back-600);
  }

  &__live-spinner-circle-full,
  &__live-spinner-circle-half {
    clip: rect(0px, 32px, 32px, 16px);
  }

  &__live-spinner-circle-fill {
    clip: rect(0px, 16px, 32px, 0px);
  }

  &__live-spinner-circle-full {

    .ef-match-header__live-spinner.--is-running & {
      animation: ef-live-spinner-full ease-in-out infinite;
    }
  }

  &__live-spinner-circle-fill {

    .ef-match-header__live-spinner.--is-running & {
      animation: ef-live-spinner-fill ease-in-out infinite;
    }
  }

  &__live-spinner-circle-full,
  &__live-spinner-circle-fill {
    animation-duration: var(--live-reload-time) !important;
    //transform: rotate(180deg);
  }

  &__live-spinner-circle-fill {
    background-color: var(--color-accent);
  }

  &__live-spinner-circle-inside {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: var(--color-back);
  }

  // &__live {
  //   display: inline-block;
  //   position: relative;
  //   padding-top: spacing(2.5);
  //   color: var(--color-accent);
  //   font-weight: 700;
  //   //text-transform: uppercase;
  //   @include type-scale(300);
  //   line-height: spacing(2.5);
  //
  //   @include break(desktop-s) {
  //     padding-top: 0;
  //     padding-left: 20px;
  //
  //     &:before {
  //       content: "";
  //       position: absolute;
  //       top: 50%;
  //       left: 0;
  //       width: 12px;
  //       height: 12px;
  //       border-radius: 50%;
  //       background-color: var(--color-accent);
  //       transform: translateY(-75%);
  //       animation: ef-pulse 1s ease-in-out alternate-reverse infinite;
  //     }
  //   }
  // }

  .ef-kicker {
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}

.ef-match-header__expansion {
  @extend %boxed-padding;
  @include transition($duration-extra-slow, $property: max-height);
  position: relative;
  max-height: 0;
  overflow: hidden;
  background-color: var(--tone-back-800);

  .ef-match-header.--is-open & {
    max-height: 2500px;

    @include break(desktop-s) {
      @include transition($property: max-height);
      max-height: 500px;
      overflow: visible;
    }
  }

  .ef-container {
    @include row-gap(s);
    @include transition($property: opacity, $delay: .3s);
    opacity: 0;

    .ef-match-header.--is-open & {
      opacity: 1;
    }
  }
}

@keyframes match-header-expansion-toggle {
  0% {
    transform: translateY(#{spacing-no-calc(-4.5)});
  } 15% {
    transform: translateY(0);
  } 85% {
    transform: translateY(0);
  } 100% {
    transform: translateY(#{spacing-no-calc(-4.5)});
  } 
}

.ef-match-header__expansion-toggle {
  @include transition;
  position: absolute;
  z-index: 998;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(#{spacing-no-calc(4.5)} + 18px);
  overflow: hidden;
  cursor: pointer;
  pointer-events: none;
  transform: translateY(#{spacing(-4.5)});

  &.--is-initial {
    animation: match-header-expansion-toggle 6s ease-in-out .5s;
  }

  @include hover {
    transform: translateY(0);
  }

  .ef-match-header.--is-open & {
    transform: translateY(calc(#{spacing-no-calc(-4.5)} - 18px));
    animation: none;
  }

  &:after {
    @include type-scale(200);
    @include transition($property: opacity);
    content: "Timeline + Spieldaten";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: spacing(1);
    background-color: var(--color-accent);
    color: var(--color-accent-contrast);
    font-family: font($font--subline);
    text-transform: uppercase;
    text-align: center;

    .ef-match-header.--is-open & {
      opacity: 0;
    }
  }
}

.ef-match-header__expansion-toggle-button {
  @include transition;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  padding-top: 31px;
  pointer-events: all;
  border-radius: 50%;
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);

  // &:hover {
  //   transform: translateY(-6px);
  // }

  .ef-match-header.--is-open & {
    padding-top: 3px;
    // background-color: var(--color-back);
    // color: var(--color-front);
    transform: translateY(30px);
  }

  .ef-icon {
    @include transition($property: transform);

    .ef-match-header.--is-open & {
      transform: rotate(180deg);
    }
  }
}
