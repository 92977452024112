.ef-video-grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include break(tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &__item {
    grid-column: span 1;
  }
}
