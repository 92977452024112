.ef-shop-product {
  //@include row-gap(l);
  display: flex;
  flex-wrap: wrap;

  &__view {
    position: relative;
    width: 100%;
    margin-bottom: spacing(4);

    @include break(desktop-s) {
      display: flex;
      width: 58.333333%;
      margin-bottom: 0;
      padding-left: spacing(12);
    }
    @include break(desktop-m) {
      padding-left: spacing(17);
    }
  }

  &__details {
    position: relative;
    width: 100%;
    @include break(desktop-s) {
      padding-left: spacing(6);
      width: 41.666666%;
    }
    @include break(desktop-m) {
      padding-left: spacing(10);
    }

    &-sample {
      font-size: 1rem;
      line-height: 1.4;
      p, a {
        color: var(--grayscale-400);
      }
      p {
        margin-bottom: spacing(4);
      }
      a {
        text-decoration: underline;
      }
      > strong {
        display: block;
        margin-bottom: spacing(.85);
      }
    }

    &-container {
      position: relative;
    }
  }

  &__details-header {
    position: relative;
    padding-right: calc(#{spacing-no-calc(2)} + 16px);

    .ef-bookmark {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__presenter {
    margin-bottom: spacing(4);
    color: var(--color-front);

    &--nike {
      width: spacing(16.666666);
      height: spacing(6);
    }
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: spacing(1);

    .ef-shop-label {
      margin-bottom: spacing(1);
    }

    .ef-shop-label:not(:last-child) {
      margin-right: spacing(1);
    }
  }

  &__badges {
    position: absolute;
    top: spacing(2);
    left: spacing(2);
  }

  &__title,
  &__subline {
    padding-top: 0;
  }

  &__subline {
    @include secondary-heading;
  }

  &__price {
    @include type-scale(500);
    margin-bottom: spacing(1);
    color: var(--color-front);
    font-weight: 700;
  }

  &__thumbs {
    width: 100%;
    position: absolute;
    left: 0;
    max-width: spacing(10);
    display: none;

    @include break(desktop-s) {
      display: block;
      //transform: translateX(calc(#{spacing-no-calc(-12)}));
    }
    @include break(desktop-m) {
      max-width: spacing(13);
      //transform: translateX(calc(#{spacing-no-calc(-17)}));
    }

    li {

      &:not(:last-child) {
        margin-bottom: spacing(2);
      }

      position: relative;
      display: block;
      &:after, &:before {
        position: absolute;
        width: 100%;
        height: 2px;
        overflow: hidden;
        content: '';
        bottom: 0;
        left: 0;
        background-color: var(--grayscale-200);
      }
      &:before {
        transition: transform .35s;
        transform: scaleX(0);
        background-color: var(--color-red-400);
      }

      &.--selected {
        &:before {
          transform: scaleX(1);
          transform-origin: 50% 0;
          background-color: var(--color-red-400);
          z-index: 5;
        }
      }
    }

    img {
      vertical-align: top;
    }
  }

  &__imagelist {
    width: 100%;

    li {
      position: relative;
      display: block;
    }

    li:not(:last-child) {
      padding-bottom: spacing(4);
    }

    video {
      max-width: 100%;
      height: auto;
    }

    picture {
      margin: 0;
      width: 100%;
      display: block;
      @include break(desktop-s) {
        background-position: 50% 50%;
        background-size: 150% 150%;
        cursor: url('data:image/svg+xml;utf8,<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <polygon id="Path" fill="#000000" fill-rule="nonzero" points="32 14.4875622 17.5124378 14.4875622 17.5124378 1.8647328e-15 14.4875622 1.8647328e-15 14.4875622 14.4875622 -5.99520433e-15 14.4875622 -5.99520433e-15 17.5124378 14.4875622 17.5124378 14.4875622 32 17.5124378 32 17.5124378 17.5124378 32 17.5124378"></polygon> </g> </svg>') 24 24, auto;
      }

      &.--zoom-in {
        overflow-x: auto;
        overflow-y: auto;
        overflow-x: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */

        &::-webkit-scrollbar {
          display: none;  /* Chrome Safari */
        }

        img {
          @include transition;
          transform: scale(2);
        }
      }

      a {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: top;
        transition: opacity .5s;

        &:hover {
          @include break(desktop-s) {
            opacity: 0;
          }
        }
      }
    }
  }

  @keyframes product-image-zoom {
    0% { opacity: 0; transform: scale(.8); }
    100% { opacity: 1; transform: none; }
  }

  @keyframes product-image-zoom-close {
    0% { opacity: 0; transform: scale(.8) translateX(-50%); }
    100% { opacity: 1; transform: scale(1) translateX(-50%); }
  }

  &__image-zoom {
    @include fill(fixed);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--color-white);
    transform: scale(.8);
    overflow-x: auto;
    overflow-y: auto;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    &::-webkit-scrollbar {
      display: none;  /* Chrome Safari */
    }

    &.--is-active {
      display: flex;
      animation: product-image-zoom .4s $ease-out-quad forwards;
    }

    img {
      @include transition($property: transform);
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &.--zoom-in img {
      transform: scale(3);
    }
  }

  &__image-zoom-close {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999999;
    bottom: spacing(3);
    left: 50%;
    width: 32px;
    height: 32px;
    opacity: 0;
    border-radius: 50%;
    background-color: var(--color-accent);
    color: var(--color-white);
    transform: scale(.8) translateX(-50%);

    &.--is-active {
      display: flex;
      animation: product-image-zoom-close .6s $ease-out-back .3s forwards;
    }
  }

  &__add {
    width: 100%;
    max-width: 450px;
    margin-bottom: 25px;

    .ef-form-item--select {
      width: 100%;

      @include break(phablet) {
        max-width: spacing(24);
      }
    }
  }
}

.ef-shop-product-slider {
  @extend .ef-slider;
}

.ef-shop-product-slideitem {

  .ef-media--jersey {
    padding-bottom: 0 !important;
  }

  .ef-media__image {
    position: relative !important;
  }
}

.ef-slider--show-product {
  position: relative;
  overflow: hidden;
  width: 100%;
}
