.ef-pagination-wrapper {
  padding-top: spacing(4);

  &--search-results {
    @include max-width(m);
  }
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
}

.ais-Pagination-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: spacing(1) spacing(2);
  color: var(--color-front);
  font-weight: 700;

  @include type-scale(300);
  @include transition($property: color);

  &:hover{
    color: var(--color-accent);
  }

  &--firstPage,
  &--previousPage,
  &--nextPage,
  &--lastPage {

    .ais-Pagination-link {
      @include hide-text;
      @include transition;
      width: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &--firstPage,
  &--lastPage {
    padding-right: 0;
    padding-left: 0;
  }

  &--firstPage .ais-Pagination-link {
    padding-right: 0;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--white)}'><path d='M11.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L7.414,6l4.293-4.293A1,1,0,0,0,11.707.293Z'></path> <path d='M2.414,6,6.707,1.707A1,1,0,0,0,5.293.293l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,1,0,1.414-1.414Z'></path></g></svg>");

    @include theme(light) {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--black)}'><path d='M11.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L7.414,6l4.293-4.293A1,1,0,0,0,11.707.293Z'></path> <path d='M2.414,6,6.707,1.707A1,1,0,0,0,5.293.293l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,1,0,1.414-1.414Z'></path></g></svg>");
    }
  }

  &--previousPage .ais-Pagination-link {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--white)}'><path d='M8.625.219A1,1,0,0,0,7.219.375l-4,5a1,1,0,0,0,0,1.25l4,5a1,1,0,0,0,1.562-1.25L5.281,6l3.5-4.375A1,1,0,0,0,8.625.219Z'></path></g></svg>");

    @include theme(light) {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--black)}'><path d='M8.625.219A1,1,0,0,0,7.219.375l-4,5a1,1,0,0,0,0,1.25l4,5a1,1,0,0,0,1.562-1.25L5.281,6l3.5-4.375A1,1,0,0,0,8.625.219Z'></path></g></svg>");
    }
  }

  &--nextPage .ais-Pagination-link {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--white)}'><path d='M4.781.375a1,1,0,0,0-1.562,1.25L6.719,6l-3.5,4.375a1,1,0,0,0,1.562,1.25l4-5a1,1,0,0,0,0-1.25Z'></path></g></svg>");

    @include theme(light) {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--black)}'><path d='M4.781.375a1,1,0,0,0-1.562,1.25L6.719,6l-3.5,4.375a1,1,0,0,0,1.562,1.25l4-5a1,1,0,0,0,0-1.25Z'></path></g></svg>");
    }
  }

  &--lastPage .ais-Pagination-link {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--white)}'><path d='M6.707,5.293l-5-5A1,1,0,0,0,.293,1.707L4.586,6,.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,6.707,5.293Z'></path> <path d='M6.707.293A1,1,0,0,0,5.293,1.707L9.586,6,5.293,10.293a1,1,0,1,0,1.414,1.414l5-5a1,1,0,0,0,0-1.414Z'></path></g></svg>");

    @include theme(light) {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewbox='0 0 12 12'><g fill='#{url-encoded-color($color--black)}'><path d='M6.707,5.293l-5-5A1,1,0,0,0,.293,1.707L4.586,6,.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,6.707,5.293Z'></path> <path d='M6.707.293A1,1,0,0,0,5.293,1.707L9.586,6,5.293,10.293a1,1,0,1,0,1.414,1.414l5-5a1,1,0,0,0,0-1.414Z'></path></g></svg>");
    }
  }

  &--selected {
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: var(--color-accent);
    }
  }
}

.ais-Pagination-item--disabled {
  opacity: .3;
  pointer-events: none;
}


.ef-pagination {
  display: flex;
  justify-content: center;

  &__arrow,
  &__page,
  &__dots {
    display: flex;
    align-items: center;
    padding: spacing(1) spacing(2);
    color: var(--color-front);
    font-weight: 700;

    @include type-scale(300);
    @include transition($property: color);
  }

  &__icon {
    margin-top: -2px;
    @include transition($property: color);
  }

  &__arrow:hover .ef-pagination__icon,
  &__page:hover {
    color: var(--color-accent);
  }

  &__arrow {
    cursor: pointer;

    &.--is-disabled {
      opacity: .3;
      pointer-events: none;
    }
  }

  &__pages {
    display: flex;
    align-items: center;
  }

  &__page {
    position: relative;
    cursor: pointer;

    &--neighbor {
      display: none;

      @include break(tablet) {
        display: flex;
      }
    }

    &.--is-current:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: var(--color-accent);
    }
  }
}
