.ef-teaser-card {
  @extend %card;
  @extend %card--has-line;
  @extend %card--has-line-desktop;

  &__media {
    @extend .ef-media--1x1;

    @include break(tablet) {
      padding-bottom: 66.666666%;
    }
  }

  &__media:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to top, rgba($grayscale--800, 1), rgba($grayscale--800, 0));

    @include break(tablet) {
      top: 31.8%;
    }
  }

  &__image {
    @include transition;
  }

  &:hover {

    @include break(desktop-s) {

      .ef-teaser-card__image {
        transform: scale(1.05);
      }
    }
  }

  &__heading {
    @include transition($duration-faster, $property: color);
    //font-family: font($font--subline-condensed);
    hyphens: auto;

    @include break(desktop-s) {

      .ef-teaser-card:hover & {
        color: var(--color-accent);
      }
    }

    span {
      @include transition($duration-faster, $property: color);
      @include card-blank-link--image;

      @include break(desktop-s) {

        .ef-teaser-card:hover & {
          color: var(--color-accent);
        }
      }
    }
  }

  &__text {
    @extend %text-container;
    position: absolute;
    top: calc(100% - #{spacing-no-calc(25)});
    right: spacing(3);
    left: spacing(3);

    @include break(phablet) {
      top: calc(100% - #{spacing-no-calc(19.5)});
      //right: 31.8%;
    }

    @include break(desktop-s) {
      top: calc(100% - #{spacing-no-calc(20.5)});
    }

    * {
      color: $color--white;
    }
  }
}
