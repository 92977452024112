.ef-player-stats {
  //@extend %ef-max-width-l-center;

  // @include break(tablet) {
  //   display: grid;
  //   grid-template-columns: 1.5fr 1fr;
  //   grid-column-gap: spacing(8);
  //   grid-row-gap: spacing(4);
  // }
  //
  // @include break(desktop-s) {
  //   grid-template-columns: 1fr 1fr;
  // }

  &--grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: spacing(6) spacing(4);

    @include break(desktop-s) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &--grid-featured {

    @include break(desktop-s) {
      grid-template-columns: repeat(9, minmax(0, 1fr));
      align-items: center;

      .ef-player-stats__unit:first-child {
        grid-column: span 3;
      }

      .ef-player-stats__unit:not(:first-child) {
        grid-column: span 2;
      }
    }
  }

  &__general {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: spacing(6);

    @include break(tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__position {

    @include break(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: spacing(8);
      grid-row-gap: spacing(4);
    }
  }

  &__unit {

    &--general {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: spacing(4);
    }

    &--single {
      display: flex;
      flex-direction: column;
      gap: spacing(4);
      align-items: center;

      @include break(tablet) {
        display: grid;
        grid-template-columns: spacing(32) 1fr;
        align-items: start;
      }
    }

    &--position:not(:last-child) {
      margin-bottom: spacing(4);

      @include break(tablet) {
        margin-bottom: 0;
      }
    }

    .ef-player-stats--grid & {
      display: flex;
      align-items: center !important;
    }
  }

  &__chart {
    position: relative;
    width: spacing(12);
    height: spacing(12);
    margin-bottom: spacing(2);

    .ef-player-stats__unit--single & {
      width: spacing(32);
      height: spacing(32);
      margin-bottom: 0;
    }

    .ef-player-stats--grid-featured .ef-player-stats__unit--single:first-child & {

      @include break(desktop-s) {
        width: spacing(36);
        height: spacing(36);
      }
    }

    .ef-player-stats--grid .ef-player-stats__unit--single & {
      width: spacing(24);
      height: spacing(24);
      margin-bottom: 0;
    }
  }

  &__chart-label {
    @include fill;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-front);
    text-align: center
  }

  &__chart-label-primary {
    @include type-scale(800);
    font-family: font($font--headline);

    .ef-player-stats--grid-featured .ef-player-stats__unit--single:first-child & {

      @include break(desktop-s) {
        font-size: spacing(9.765623);
        line-height: spacing(9.5);
      }
    }
  }

  &__chart-label-secondary {
    @include type-scale(300);
  }

  &__list {

    .ef-player-stats__general & {
      width: 100%;
      //padding-left: spacing(4);
    }

    .ef-player-stats__position & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: spacing(6);
    }
  }

  &__list-item {
    display: flex;
    position: relative;
    padding: spacing(1) 0;
    border-bottom: 1px solid var(--tone-back-600);
    @include type-scale(200);

    .ef-player-stats__general & {
      padding-left: 16px;
    }

    &--primary {
      @include type-scale(300);
      padding-top: 0;
      font-weight: 700;
      color: var(--color-front);

      .ef-player-stats__general & {
        padding-left: 0;
      }

      .ef-player-stats__position & {
        grid-column: span 2;
      }
    }

    &--zero {
      //color: var(--tone-back-300);
    }
  }

  &__legend {
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    width: 4px;

    &--primary {
      background-color: var(--color-accent);
    }

    &--secondary {
      background-color: var(--color-front);
    }

    &--success {
      background-color: var(--color-green);
    }

    &--failure {
      background-color: var(--color-red-400);
    }

    &--yellow-card,
    &--2nd-yellow-card {
      background-color: var(--color-yellow);
    }

    &--2nd-yellow-card:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: var(--color-red-400);
    }

    &--red-card {
      background-color: var(--color-red-400);
    }
  }

  &__label {
    flex: 1;
    padding-right: spacing(2);
  }

  &__value {
    flex-basis: spacing(6);
    text-align: right;
  }
}
