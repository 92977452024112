@keyframes ef-holz-overlay-visual {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: none;
  }
}


@keyframes ef-holz-overlay-bg {
  0% {
    opacity: .4;
  }
  100% {
    opacity: .2;
  }
}

@keyframes ef-holz-overlay-logo-mobile {
  0% {
    opacity: 0;
    transform: scale(.5);
  } 10% {
    opacity: 0;
    transform: scale(.5);
  } 33.333333% {
    opacity: 1;
  } 100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ef-holz-overlay-logo {
  0% {
    opacity: 0;
    transform: scale(.7);
  } 10% {
    opacity: 0;
    transform: scale(.7);
  } 33.333333% {
    opacity: 1;
  } 100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ef-holz-overlay-title {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ef-holz-overlay-buttons {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.ef-holz-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  z-index: 999999999;

  &__visual {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: var(--color-back);
    transform: scale(1.2);
    animation: ef-holz-overlay-visual 10s $ease-in-out-quad forwards;
  }

  &__background,
  &__foreground {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__background {
    opacity: .4;
    animation: ef-holz-overlay-bg 3s $ease-in-out-quad 2s forwards;

    &--mobile {
      display: none;

      @media (orientation: portrait) {
        display: block;
      }
    }

    &--desktop {
      display: none;

      @media (orientation: landscape) {
        display: block;
      }
    }
  }

  &__foreground {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 spacing(16) spacing(16);
    opacity: 0;
    transform: scale(.5);
    animation: ef-holz-overlay-logo-mobile 10s $ease-in-out-quad forwards;

    @media (orientation: landscape) {
      transform: scale(.7);
      animation: ef-holz-overlay-logo 10s $ease-in-out-quad forwards;
    }
  }

  &__foreground-image {
    max-width: 80vw;

    @include break(desktop-s) {
      max-width: 400px;
      max-height: 476px;
    }

    @include break(desktop-s) {
      max-width: 480px;
      max-height: 571px;
    }
  }

  &__content {
    @extend %boxed-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing(6);
    padding-bottom: spacing(12);

    @media (min-width: 1280px) and (orientation: landscape) {
      gap: spacing(24);
    }
  }

  &__title {
    @extend %boxed-padding;
    text-align: center;
    opacity: 0;
    color: var(--color-white);
    font-family: font($font--serif);
    animation: ef-holz-overlay-title 1s ease-in-out 2s forwards;
  }

  &__title-name {
    @include type-scale(600);
    display: block;
    margin-bottom: spacing(2);
    font-weight: 700;
  }

  &__title-subline {
    @include type-scale(400);
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(3);
    opacity: 0;
    animation: ef-holz-overlay-buttons 1s $ease-out-back 4s forwards;

    .ef-button {
      width: 100%;

      @include break(phablet) {
        width: auto;
      }
    }
  }
}
