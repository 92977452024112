.ef-jobfinder {
  @include ad-banner;
  display: block;

  @media (min-width: 768px) {
    display: flex;
  }

  &__header {
    margin-bottom: spacing(2);
    padding-top: spacing(.75);

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  // &__header {
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-end;
  //   margin-bottom: spacing(4);
  // }

  &__header-logo {
    width: spacing(20);
    height: spacing(8.7774993);
    color: var(--color-front);

    @media (min-width: 768px) {
      //margin-top: spacing(-2);
      margin-right: spacing(8);
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    align-items: flex-end;
    //font-weight: 700;

    @include break(tablet) {
      flex-wrap: nowrap;
      flex: 1;
      //padding-right: spacing(4);
      margin-bottom: 0;
    }
  }

  &__form-item {
    // display: flex;
    // align-items: baseline;
    position: relative;
    width: 100%;
    margin-bottom: spacing(1);

    @include break(tablet) {
      flex: 1;
      padding-right: spacing(1);
      margin-bottom: 0;
    }
  }

  &__form-item-label {
    @include type-scale(500);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: spacing(1);
    font-family: font($font--indeed);
    font-weight: 700;

    @include break(tablet) {
      display: block;
    }
  }

  &__form-item-label-text:first-letter {
    text-transform: uppercase;
  }

  &__form-item-label-subline {
    @include type-scale(200);
    color: var(--tone-front-300);
    font-weight: 400;
  }

  &__form-item-field {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: spacing(1.5) spacing(2);
    border: 1px solid var(--tone-back-600);
    border-radius: 8px;
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: font($font--indeed);
    @include type-scale(300);
    @include transition($property: border-color);

    @include break(tablet) {
      width: 100%;
    }

    &:focus {
      border-color: $color--indeed;
    }

    &:focus + .ef-jobfinder__form-icon {
      display: none;
    }
  }

  &__form-icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: spacing(3);
    bottom: spacing(2);
    height: spacing(3);
    pointer-events: none;
    color: var(--color-black);

    @include break(tablet) {
      //right: spacing(2);
    }
  }

  &__cta {
    width: 100%;
    padding-top: spacing(1);
    text-align: center;

    @include break(tablet) {
      width: auto;
      padding-top: 0;
    }
  }

  &__cta-button {
    @include type-scale(300);
    width: 100%;
    padding: spacing(1.5) spacing(3);
    cursor: pointer;
    border: 1px solid $color--indeed;
    border-radius: 8px;
    background-color: $color--indeed;
    color: var(--color-white);
    font-family: font($font--indeed);
    font-weight: 700;
    @include transition($property: background-color);

    &:hover {
      background-color: $color--indeed-shade;
    }
  }
}
