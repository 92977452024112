.ef-matches {

  &__earlier {
    max-height: 0;
    overflow: hidden;
    @include transition($duration-slower, $property: max-height);

    &.--is-active {
      max-height: 9999px;
    }
  }

  &__earlier-button {
    display: none;
    margin-bottom: spacing(2);
    text-align: center;

    &.--is-active {
      display: block;
    }
  }

  &__day {
    margin-bottom: spacing(2);
  }

  &__heading {
    @include type-scale(200);
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding-right: calc(16px + #{spacing-no-calc(2)});
    color: var(--tone-front-300);
    text-align: center;

    @include break(desktop-s) {
      padding-right: 0;
    }

    @include ie11() {
      text-align: left;
      margin-left: 0;
      padding-right: 0;
    }

    .ef-content--match-matches & {
      padding-right: 0;
    }
  }
}

.ef-match {
  //@extend %card--has-line-subtle;
  display: grid;
  grid-template-columns: 1fr spacing(3.5) spacing(6) spacing(3.5) 1fr 16px;
  grid-template-areas:
    "details  details  details details  details  ."
    "nameHome logoHome result  logoAway nameAway arrow"
    "cta      cta      cta     cta      cta      .";
  grid-column-gap: spacing(2);
  align-items: center;
  position: relative;
  padding: spacing(1) spacing(2);
  border-bottom: 1px solid var(--tone-back-600);

  @include ie11() {
    display: flex;
  }

  @include break(desktop-s) {
    // grid-template-columns: spacing(32) 1fr spacing(5) spacing(4) spacing(5) 1fr spacing(32);
    // grid-template-areas:
    //   "details nameHome logoHome result  logoAway nameAway button";
    grid-template-columns: 1fr 1fr spacing(3.5) spacing(6) spacing(3.5) 1fr 1fr;
    grid-template-areas:
      "details nameHome logoHome result logoAway nameAway cta";
    //grid-column-gap: spacing(4);
  }

  &--ticketing {
    grid-template-columns: spacing(3.5) 1fr spacing(3.5);
    grid-template-areas:
      "details  details   details"
      "logoHome eventName logoAway"
      "cta      cta       cta";

    @include break(desktop-s) {
      grid-template-columns: 1fr spacing(3.5) 2fr spacing(3.5) 1fr;
      grid-template-areas:
        "details logoHome eventName logoAway cta";
    }
  }

  .ef-content--match-matches & {
    grid-template-columns: 1fr spacing(3.5) spacing(6) spacing(3.5) 1fr;
    grid-template-areas:
      "details  details  details details  details"
      "nameHome logoHome result  logoAway nameAway"
      "cta      cta      cta     cta      cta";
  }

  &--no-link,
  &--parallel {
    grid-template-columns: 1fr spacing(3.5) spacing(6) spacing(3.5) 1fr 16px;
    grid-template-areas:
      "nameHome logoHome result logoAway nameAway arrow";

    @include break(desktop-s) {
      grid-template-columns: 1fr spacing(3.5) spacing(6) spacing(3.5) 1fr;
      grid-template-areas:
        "nameHome logoHome result logoAway nameAway";
    }
  }

  &.--is-current {
    position: relative;
    background-color: var(--color-back);
  }

  &.--is-current:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: var(--color-accent);
  }

  &__link {
    // @extend %card;
    // @extend %card--has-line;
    @include fill;

    &:after {
      @include transition($property: background-color);
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
    }

    &:hover:after {
      background-color: var(--color-accent);
    }
  }

  &__details {
    grid-area: details;
    margin-bottom: spacing(1);
    text-align: center;
    @include type-scale(200);

    @include break(desktop-s) {
      margin-bottom: 0;
      padding-top: 0;
      text-align: left;
    }

    @include ie11() {
      order: 1;
    }
  }

  &__detail {
    display: inline;

    @include break(desktop-s) {
      display: block;
      margin-right: spacing(2);
    }

    &:not(:last-child):after {
      content: "·";
      padding-right: spacing(.5);
      padding-left: spacing(1);
      color: var(--color-front);

      @include break(desktop-s) {
        display: none;
      }
    }
  }

  //&__team-logo,
  // &__team-name {
  //   margin-bottom: spacing(2);
  //
  //   @include break(desktop-s) {
  //     margin-bottom: 0;
  //   }
  // }

  &__team-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: spacing(3.5);
    height: spacing(3.5);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    @include ie11() {
      margin: 0;
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: spacing(4);
    }

    @include break(desktop-s) {
      width: auto;
    }

    &--home {
      grid-area: logoHome;
      @include ie11() {
        order: 3;
      }
    }

    &--away {
      grid-area: logoAway;
      @include ie11() {
        order: 5;
      }
    }
  }

  &__team-logo-image {
    max-width: spacing(3.5);
    max-height: spacing(3.5);
    object-fit: contain;
  }

  &__team-name {
    align-self: center;
    //font-weight: 700;
    //text-align: center;

    @include ie11() {
      margin: 0;
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: spacing(4);
    }

    @include type-scale(300);

    // @include break(desktop-s) {
    //   align-self: center;
    // }

    &:before {
      content: attr(data-shortname);

      @include break(tablet) {
        display: none;
      }
    }

    .ef-match.--is-current & {
      color: var(--color-front);
      font-weight: 700;
    }

    .ef-match--parallel & {
      font-weight: 400;
    }

    &--home {
      grid-area: nameHome;
      text-align: right;

      @include break(desktop-s) {
        text-align: right;
      }

      @include ie11() {
        order: 2;
      }

    }

    &--away {
      grid-area: nameAway;
      text-align: left;

      @include break(desktop-s) {
        text-align: left;
      }

      @include ie11() {
        order: 6;
      }
    }

    &--event {
      grid-area: eventName;
      text-align: center;
    }
  }

  &__team-name-text {
    display: none;

    .ef-match__team-name--event & {
      display: block;
    }

    @include break(tablet) {
      display: block;
    }
  }

  &__result {
    grid-area: result;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: spacing(6);
    color: var(--color-front);
    font-weight: 700;
    text-align: center;
    @include type-scale(500);

    &--empty {
      @include type-scale(200);
      font-weight: 400;
    }

    .ef-match--live & {
      color: var(--color-accent);
    }

    @include ie11() {
      margin: 0;
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: spacing(4);
      order: 4;
    }
  }

  &__result-extra {
    @include type-scale(200);
    display: block;
    margin-top: spacing(-.5);
    color: var(--tone-front-600);
    font-weight: 400;
  }

  &__button,
  &__info,
  &__broadcasters {
    grid-area: cta;
    text-align: center;

    @include break(desktop-s) {
      margin-right: calc(16px + #{spacing-no-calc(4)});
      text-align: right;

      .ef-match--ticketing & {
        margin-right: 0;
      }
    }

    @include ie11() {
      order: 8;
    }
  }

  &__info {
    @include type-scale(200);
  }

  &__broadcasters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: spacing(1);
    padding-top: spacing(1);

    @include break(desktop-s) {
      flex-direction: column;
      margin-right: calc(16px + #{spacing-no-calc(8)});
      padding-top: 0;
    }
  }

  &__arrow {
    grid-area: arrow;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--color-accent);

    @include break(desktop-s) {
      position: absolute;
      top: 0;
      right: spacing(2);
      bottom: 0;
      width: 16px;
    }

    @include ie11() {
      order: 7;
    }
  }
}
