.ef-media {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  //background-color: var(--tone-back-700);
  @include image-filter;

  &--3x1 {
    padding-bottom: 33.333333%;
  }

  &--super-wide {
    padding-bottom: 42.553191%;
  }

  &--16x9 {
    padding-bottom: 56.25%;
  }

  &--3x2 {
    padding-bottom: 66.666666%;
  }

  &--4x3 {
    padding-bottom: 75%;
  }

  &--1x1 {
    padding-bottom: 100%;
  }

  &--3x4 {
    padding-bottom: 133.333333%;
  }

  &--2x3 {
    padding-bottom: 150%;
  }

  &--9x16 {
    padding-bottom: 177.777777%;
  }

  &--original {
    height: auto;

    .ef-media__image {
      position: static;
    }
  }

  // &--m {
  //   @include max-width(m);
  // }
  //
  // &--l {
  //   @extend %ef-max-width-l-center;
  // }

  &__image,
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
    // left: 50%;
    // width: auto;
    // transform: translateX(-50%);

    &--contain {
      object-fit: contain;
    }

    &--auto {
      left: 50%;
      width: auto;
      transform: translateX(-50%);
    }
  }

  &__video {
    object-fit: cover;
  }
}
