.ef-image-and-text {
  @include clearfix;

  &__image {
    margin-bottom: spacing(8);

    @include break(tablet) {
      width: 38.2%;
      margin-bottom: spacing(4);

      &--left {
        float: left;
        margin-right: spacing(4);
      }

      &--right {
        float: right;
        margin-left: spacing(4);
      }
    }
  }
}

.ef-content__block--image-and-text-full-width {
  position: relative;
  background: black;
  background-size: cover;

  .ef-container__narrow-m {
    max-width: none;
  }

  .ef-container--l {
    @extend %ef-max-width-xl-center;
  }
}

.ef-image-and-text--full-width {
  display: flex;
  flex-direction: column;

  .ef-image-and-text {

    &__image {
      order: 1;
      position: relative;
      z-index: 1;
      float: none !important;
      margin: 0 spacing(-3) !important;
      overflow: hidden;
  
      @include break(tablet) {
        width: calc(100% + #{spacing-no-calc(12)});
        margin: 0 spacing(-6) !important;
      }
  
      + .ef-image-and-text__text {
        order: 2;
      }
    }
  
    &__image--left {
      left: 0;
    }
  
    &__image--right {
      right: 0;
    }
  
    &__text {
      position: relative;
      order: 0;
      padding-top: spacing(6);
      padding-bottom: spacing(6);
    }
  }

  p a {
    @extend .ef-link-button;
    @extend .ef-link-button--more;
    text-decoration: none;
  }
  
  @include break(desktop-s) {
    flex-direction: row;
    align-items: center;
    min-height: 28.125vw;

    .ef-container {
      position: static;
    }

    .ef-image-and-text__image {
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      margin: 0 !important;

      + .ef-image-and-text__text {
        margin-left: 50%;
        padding-left: spacing(9);
        padding-right: 0;
      }

      &--right {
        + .ef-image-and-text__text {
          margin-left: 0;
          padding-left: spacing(0);
          padding-right: spacing(9);
        }
      }

    }

    .ef-container__narrow-m,
    .ef-media {
      @include fill;
      height: 100%;
    }

    .ef-image-and-text__text {
      width: 50%;
      padding-top: spacing(12);
      padding-right: spacing(9);
      padding-bottom: spacing(12);
    }
  }
}
