@keyframes ef-live-ticker-item {
  0% {
    opacity: 0;
    max-height: 0;
  } 100% {
    opacity: 1;
    max-height: 2000px;
  }
}

.ef-live-ticker {

  &__item {

    &:not(:last-child) {
      margin-bottom: spacing(2);
      padding-bottom: spacing(2);
      border-bottom: 1px solid var(--tone-back-600);
    }

    &--highlight,
    &--highlight p {
      color: var(--color-front);
      font-weight: 700;
    }

    .--is-live & {
      overflow: hidden;
      animation: ef-live-ticker-item 1s ease-in-out forwards;
    }
  }

  &__item-content {
    display: grid;
    grid-template-columns: spacing(13) minmax(0, 1fr);
    grid-column-gap: spacing(4);
    // display: flex;
    // align-items: baseline;
    @include max-width(m);

    @include break(tablet) {
      grid-template-columns: spacing(15) minmax(0, 1fr);
    }

    .ef-live-ticker__item--ad & {
      align-items: flex-start;
    }

    video {
      width: 100% !important;
      height: auto !important;
    }
  }

  &__meta {
    //text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: spacing(3.5);
    //flex-direction: column;

    @include break(desktop-s) {
      height: spacing(4);
    }

    @include ie11() {
      order:1 ;
      margin-right: spacing(4);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    flex: 1;
    order: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    //display: block;
    //padding-top: spacing(.5);
    //text-align: right;

    @include break(tablet) {
      flex: 1;
      order: 1;
      padding-top: 0;
      text-align: left;
    }
  }

  &__time {
    order: 2;
    display: inline-block;
    color: var(--color-front);
    font-family: font($font--subline);
    font-weight: 400;
    text-align: right;
    text-transform: uppercase;
    @include type-scale(300);

    .ef-live-ticker__item--highlight & {
      padding-right: spacing(1);
      padding-left: spacing(1);
      background-color: var(--color-accent);
      color: var(--color-accent-contrast);
    }

    @include break(tablet) {
      order: 2;
    }
  }

  &__text {

    .ef-live-ticker__item--ad & {
      @include ad;
    }

    @include ie11() {
      order: 3;
    }
  }

  &__banner {
    @include ad-banner;
  }

  p {
    @include type-scale(300);
  }
}
