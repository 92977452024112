.ef-hl-block--media-and-text-pinned {
  //height: 100vh;
  //margin-bottom: 0;
  //overflow: hidden;
}

.ef-hl-media-and-text-pinned {
  @extend %boxed-padding;
  display: flex;
  flex-direction: column;

  @include break(desktop-s) {
    flex-direction: row;
    align-items: flex-start;

    &--half {
      padding-bottom: spacing(8);
    }
  }

  &__description {
    //padding-top: 100vh;

    @media (max-width: 989px) {
      order: 1;
    }

    @include break(desktop-s) {
      flex-basis: 50%;
      padding-left: spacing(12);
    }
  }

  &__description-item {

    @include break(desktop-s) {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      padding: spacing(8);

      &:first-child {
        //margin-bottom: 500px;

        .ef-hl-media-and-text-pinned--half & {
          padding-top: 50vh;
        }
      }

      &:not(:first-child) {

        .ef-hl-media-and-text-pinned--half & {
          height: auto;
          min-height: 50vh;
        }
      }
    }
  }

  &__pins {
    position: relative;

    @media (max-width: 989px) {
      order: 0;
      margin-bottom: spacing(4);
    }

    @include break(desktop-s) {
      // display: flex;
      // align-items: center;
      flex-basis: 50%;
      height: 100vh;
      margin-bottom: 0;

      // .ef-hl-media-and-text-pinned--half & {
      //   height: 50vh;
      // }
    }
  }

  &__pin {
    @include transition($property: opacity);
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-align: center;

    @include break(desktop-s) {
      position: absolute;
      top: spacing(16);
      right: 0;
      bottom: spacing(16);
      left: 0;

      &:not(:first-child) {
        opacity: 0;
      }
    }

    &.--is-active {
      opacity: 1;
    }
  }

  &__media {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}


// .black {
//   display: flex;
//   height: 100vh;
//   justify-content: center;
//   align-items: center;
// }
//
// .text-wrap {
//   position: relative;
//   overflow: hidden;
//   //width: 450px;
//   max-width: spacing(88);
//   height: 80vh;
//
//   @include break(desktop-s) {
//     flex-basis: 50%;
//   }
// }
//
// .panel-text {
//   display: flex;
//   align-items: center;
//   position: absolute;
//   left: spacing(12);
//   top: 0%;
//   right: spacing(12);
//   bottom: 0%;
//   z-index: 1;
//   //width: 100%;
//   height: 100%;
//   // font-size: 40px;
//   // text-transform: uppercase;
//   // font-weight: 900;
//   // text-align:center;
//   // background-color: #070707;
//
//   transform:translateY(100%);
//   opacity: 0;
// }
//
// // .panel-text.blue-text {
// //   color: blue;
// // }
// //
// // .panel-text.red-text {
// //   color: red;
// // }
// //
// // .panel-text.purple-text {
// //   color: purple;
// // }
// //
// // .panel-text.orange-text {
// //   color: orange;
// // }
//
// .p-wrap {
//   position: relative;
//   overflow: hidden;
//   max-width: spacing(88);
//   //width: 450px;
//   height: 80vh;
//
//   @include break(desktop-s) {
//     flex-basis: 50%;
//   }
// }
//
// .panel {
//   position: absolute;
//   left: 0%;
//   top: 0%;
//   right: 0%;
//   bottom: 0%;
//   z-index: 1;
//   width: 100%;
//   height: 100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: contain;
//   // background-image: url("../images/5ed12171d9d512cb2feead83_5.jpg");
//   // background-position: 50% 50%;
//   // background-size: cover;
//   // background-repeat: no-repeat;
// }
//
// .panel._2 {
//   z-index: 1;
//   background-image: url("../images/5f5a5b3515c4dd0c2c455925_110642301_938622823267359_7859124022958180678_n201.jpg");
// }
//
// .panel.blue {
//   //background-color: blue;
//   z-index: auto;
// }
//
// .panel.red {
//   z-index: auto;
//   //background-color: red;
// }
//
// .panel.orange {
//   z-index: auto;
//   background-color: #cf5d00;
// }
//
// .panel.purple {
//   z-index: auto;
//   background-color: #808;
// }
