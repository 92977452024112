.ef-form-items {
  @include max-width(m);

  @include break(phablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: spacing(1);
  }

  &--salutation-names {

    @include break(phablet) {
      display: block;
    }

    @include break(tablet) {
      display: grid;
      grid-template-columns: 2fr 3fr 3fr;
    }
  }

  &--street-number {

    @include break(phablet) {
      grid-template-columns: 2fr 1fr;
    }

    @include break(tablet) {
      grid-template-columns: 3fr 1fr;
    }
  }

  &--zip-city {

    @include break(phablet) {
      grid-template-columns: 1fr 2fr;
    }

    @include break(tablet) {
      grid-template-columns: 1fr 3fr;
    }
  }

  &--field-and-button {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0;

    @include break(phablet) {
      grid-template-columns: 1fr auto;
    }

    .ef-form-item__field-wrapper {
      display: flex;
    }
  }
}
