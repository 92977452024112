@keyframes ef-modal-show {
  0% {
    opacity: 0;
    transform: scale(.9);
  } 33.333333% {
    opacity: 0;
    transform: scale(.9);
  } 75% {
    transform: scale(1.04);
    opacity: 1;
  } 100% {
    transform: scale(1);
  }
}

@keyframes ef-modal-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  } 100% {
    opacity: 0;
    transform: scale(.9);
  }
}

.ef-modal {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  z-index: z(overlay);
  // cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewbox='0 0 48 48'><path fill='white' d='M37.293,7.595L23.858,21.03L10.423,7.595c-0.391-0.39-1.024-0.391-1.414,0L7.594,9.009 c-0.391,0.391-0.391,1.024,0,1.414L21.03,23.858L7.594,37.293c-0.391,0.391-0.391,1.024,0,1.414l1.414,1.414 c0.391,0.39,1.024,0.39,1.414,0l13.435-13.435l13.435,13.435c0.391,0.39,1.024,0.391,1.414,0l1.414-1.414 c0.391-0.391,0.391-1.024,0-1.414L26.686,23.858l13.435-13.435c0.391-0.391,0.391-1.024,0-1.414l-1.414-1.414 C38.317,7.204,37.683,7.204,37.293,7.595z' /></svg>") 24 24, auto;
  background: rgba($color--black, .7);

  @include fill(fixed);

  @include break(tablet) {
    padding: spacing(4);
  }

  &--gallery {
    background: rgba($color--black, .9);
  }

  &.--is-open {
    display: flex;
    animation: ef-fade-in .3s ease-in-out forwards;
  }

  &--gallery.--is-open {
    animation: none;
  }

  &.--is-closed {
    animation: ef-fade-out .3s ease-in-out forwards;
  }

  &__inner {
    //display: none;
    position: relative;
    width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    cursor: auto;
    overflow-y: scroll;
    //box-shadow: $drop-shadow--heavy;
    background-color: var(--color-white);
    color: var(--color-black);
    padding: spacing(4);

    .ef-modal--m & {
      max-width: spacing(75);
    }

    .ef-modal--l & {
      max-width: spacing(120);
    }

    .ef-modal--gallery & {
      background-color: transparent;
    }

    .ef-modal.--is-open & {
      display: flex;
      flex-direction: column;
      animation: ef-modal-show .9s $ease-in-back forwards;
    }

    .ef-modal--gallery.--is-open & {
      animation: none;
    }

    .ef-modal.--is-closed & {
      animation: ef-modal-hide .3s ease-in-out forwards;
    }
  }

  &__close {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    z-index: 2;
    color: var(--color-front);
  }
}
