.ef-presenter {
  display: flex;
  align-items: center;

  &--center {
    justify-content: center;
  }

  &__label {
    @include type-scale(300);
    margin-right: spacing(2);
    color: var(--color-front);
    font-weight: 700;
  }

  &__logo {
    position: relative;
    flex-basis: spacing(16);
    height: spacing(4);
  }

  &__logo-image {
    display: none;
    max-height: 100%;
  }

  &__logo-image--dark {

    [data-theme="dark"] & {
      display: inline-block;
    }
  }

  &__logo-image--light {

    [data-theme="light"] & {
      display: inline-block;
    }
  }
}
