.ef-status {
  @include type-scale(400);
  padding: spacing(3) spacing(4);
  background-color: var(--tone-back-700);
  color: var(--color-front);
  text-align: center;

  &--success,
  &--alert {
    color: $color--white;

    p,
    strong,
    .ef-text--link,
    .ef-text--link:hover,
    .ef-text--link:visited {
      color: $color--white;
    }
  }

  &--success {
    background-color: $color--green;
  }

  &--info {
    background-color: $color--yellow;
    color: $color--black;
  }

  &--alert {
    background-color: $color--red-400;
  }
}
