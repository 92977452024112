.ef-table-card {
  display: flex;
  align-items: baseline;
  //display: none;
  position: relative;
  width: 100%;
  padding-bottom: spacing(2);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    height: 2px;
    background-color: var(--tone-back-600);
  }

  // @include break(desktop-m) {
  //   display: block;
  // }

  &--all,
  &--current {
    @extend %card--has-line;
    //display: block;

    &:before,
    &:after {
      z-index: 3;
    }

    &:before {
      right: 0;
      left: auto;
    }
  }

  &--all {
    padding-bottom: spacing(1);
  }

  &--current:before {
    background-color: var(--color-front);
  }

  &--neighbor {

    // @include break(desktop-s) {
    //   display: block;
    // }
  }

  &__link {
    @include fill;
    z-index: 1;
  }

  &__team {
    display: flex;
    //justify-content: center;
    align-items: center;
    color: var(--color-front);
    @include type-scale(300);
  }

  &__team-position {
    padding-right: spacing(1);
    color: var(--color-front);
    font-weight: 700;

    .ef-table-card--live & {
      color: var(--color-accent);
    }
  }

  &__team-logo {
    width: spacing(2.5);
    height: spacing(2.5);
  }

  &__team-name {
    padding-left: spacing(1);
  }

  &__stats {
    display: flex;
    //justify-content: center;
    padding-left: spacing(2);
    @include type-scale(200);
  }

  &__goals {

    &:after {
      content: " /";
    }
  }

  &__points {

    &:before {
      content: "\00a0";
    }
  }

  .ef-link-button {

    &:before,
    &:after {
      display: none;
    }
  }
}
