.ef-stats-card {
  @extend %card;
  position: relative;
  padding-right: spacing(4);
  padding-bottom: spacing(3);

  &__value {
    color: var(--color-front);
    font-family: font($font--subline);
    @include type-scale(700);
  }

  &__label {
    color: var(--color-front);
    @include type-scale(300);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: spacing(8);
    height: 4px;
    background-color: var(--color-accent);
  }
}
