.ef-tags {
  display: flex;
  flex-wrap: wrap;
}

.ef-tag {
  margin-bottom: spacing(2);
  padding: spacing(.5) spacing(2);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: var(--tone-200);
  border-radius: spacing(1.75);
  font-weight: 700;
  @include type-scale(200);
  @include transition($duration-faster);

  &:not(:last-child) {
    margin-right: spacing(2);
  }

  &:hover,
  &.--is-active {
    border-color: var(--color-accent);
  }
}
