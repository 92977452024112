.ef-download-card {
  @extend %card;
  @extend %card--has-line;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: spacing(2);
  align-items: center;
  padding-bottom: spacing(3);

  &__icon {
    display: flex;
    align-items: center;
  }

  &__heading {
    margin-bottom: 0;
    padding-top: 0;
  }

  &__meta {
    @include type-scale(300);
    color: var(--tone-front-300);
  }
}
