// width: spacing(5);
// height: spacing(3);
// padding: spacing(.5);
// border-radius: spacing(1.5);




/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: spacing(5);
    height: spacing(3);
}

.cm-app-input:checked + .cm-app-label .slider {
    background-color: var(--color-red-400);
}

.cm-app-input.required:checked + .cm-app-label .slider {
    opacity: 0.8;
    background-color: var(--color-red-400);
    cursor: not-allowed;
}

.slider {
    // box-shadow: $box-shadow;
}

.cm-app-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: spacing(5);
    height: spacing(3);
}

.cm-app-label {
    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: spacing(5);
        display: inline-block;
    }

    .slider:before {
        position: absolute;
        content: "";
        width: spacing(2);
        height: spacing(2);
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        @media(min-width: 990px){
            bottom: 4px;
        }
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 30px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    input:focus + .slider {
        // box-shadow: 0 0 1px $blue1;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
    }
}

.cm-app-input:focus + .cm-app-label .slider {
    // box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.cm-app-input:checked + .cm-app-label .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
    @media(min-width: 990px){
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
}
