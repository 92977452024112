.ef-contract-details-wrapper {
  display: grid;
  gap: spacing(6);
}

.ef-contract-details {
  @include max-width(m);

  &__status {
    @include type-scale(300);
    padding: spacing(2) spacing(3);
    border-top-right-radius: spacing(1);
    border-top-left-radius: spacing(1);
    background-color: var(--tone-front-600);
    color: var(--color-back);
    font-weight: 700;
  }

  &__inner {
    padding: spacing(3);
    background-color: var(--tone-back-750);
  }
}

.ef-contract-detail {
  position: relative;
  padding-right: calc(#{spacing-no-calc(3)} + 24px);

  &:not(:last-child) {
    margin-bottom: spacing(3);
    padding-bottom: spacing(3);
    border-bottom: 1px solid var(--tone-back-600);
  }

  &__items {
    display: grid;
    gap: spacing(3);

    &--changed {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__item {
    display: grid;
    gap: spacing(1);

    &--changed {
      opacity: .6;
    }
  }

  &__label {
    @include type-scale(300);
    font-weight: 700;
  }

  &__value {
    @include type-scale(300);
  }

  &__link {
    @include fill;

    &:hover + .ef-contract-detail__arrow {
      color: var(--color-accent);
    }
  }

  &__arrow {
    @include transition($property: color);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--color-front);
  }
}
