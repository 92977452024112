.ef-player-profile {
  grid-column: span 6;

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: spacing(4);

    @include break(tablet) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include ie11() {
      display: flex;
      margin-top: 50px;
    }
  }

  &__item {
    color: var(--color-white);
    @include type-scale(300);
    @include ie11() {
      margin:0 30px 0 0;
    }
  }

  &__social-links {
    display: flex;
  }

  &__social-link {
    @include transition($property: color);

    &:not(:last-child) {
      margin-right: spacing(4);
    }

    &:hover {
      color: var(--color-accent);
    }
  }

  strong {
    color: var(--color-white);
  }
}
