@keyframes global-calendar-filters {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  } 100% {
    opacity: 1;
    transform: none;
  }
}

.ef-global-calendar {

  &__header,
  &__nav,
  &__nav-options,
  &__content {
    @extend %boxed-padding;
  }

  &__header {
    position: relative;
    z-index: 1000;
    background-color: var(--tone-back-800);
  }

  &__header-items {

    @include break(phablet) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      gap: spacing(3);
      align-items: center;
    }
  }

  &__header .ef-heading {
    margin-bottom: 0;
  }

  &__nav-wrapper {
    position: relative;
    z-index: 999;
    height: spacing(17.25);

    @include break(desktop-s) {
      height: spacing(22.75);
    }
  }

  &__nav {
    @include transition($property: transform);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--tone-back-600);
    background-color: var(--tone-back-800);

    @include theme(light) {
      background-color: var(--color-back);
    }

    &.--is-sticky {
      position: fixed;

      .ef-page.--has-submenu & {
        padding-top: spacing(6.5);
      }
    }

    &.--header-visible {
      transform: translateY(calc(#{spacing-no-calc(10)} + 3px));
    }
  }

  &__nav-bar > .ef-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  &__nav-buttons {
    display: flex;
    gap: spacing(3);
  }

  &__nav-options {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    background-color: var(--tone-back-800);

    > .ef-container {
      display: grid;
      gap: spacing(3);
    }
  }

  &__months {
    @extend .ais-MenuSelect;
  }

  &__months-select {
    @extend .ais-MenuSelect-select;
    @include type-scale(500);
    font-family: font($font--subline);
  }

  &__content {
    padding-top: spacing(3);
  }

  &__earlier-button,
  &__more-button {
    @extend .ef-matches__earlier-button;
  }

  &__dates {
    display: grid;
    gap: spacing(3);

    &:not(:last-child) {
      margin-bottom: spacing(2);
    }
  }

  &__dates-heading {
    margin-bottom: 0;
    padding-top: spacing(1);
    font-family: font($font--subline);
    font-weight: 400;
  }

  &__date {
    display: grid;
    grid-template-columns: calc(#{spacing-no-calc(12)} + 32px) 1fr auto;
    gap: spacing(3);
    align-items: center;
    padding-top: spacing(3);
    border-top: 1px solid var(--tone-back-600);

    &:last-child {
      padding-bottom: spacing(3);
      border-bottom: 1px solid var(--tone-back-600);
    }

    &--today {
      border-color: var(--color-accent);
    }
  }

  &__date-header {
    display: grid;
    grid-template-columns: spacing(10) 24px;
    gap: spacing(2);
    align-items: center;
    color: var(--color-front);
  }

  &__date-day {
    @include type-scale(600);
    border-right: 1px solid var(--tone-back-600);
    color: var(--color-front);
    font-family: font($font--subline);
    text-align: center;
  }

  &__date-icon,
  &__date-competition {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }

  &__date-content {
    display: grid;
    gap: spacing(2);
    
    @include break(desktop-s) {
      display: flex;
      gap: spacing(4);
      align-items: center;
    }
  }

  &__date-title {
    font-weight: 700;

    @include break(desktop-s) {
      flex: 1;
    }
  }

  &__date-title-row {
    @include type-scale(400);
    color: var(--color-front);
  }

  &__date-infos {
    display: flex;
    flex-wrap: wrap;
    column-gap: spacing(1);
    padding-top: spacing(1);
    font-weight: 400;
  }

  &__date-info {
    @include type-scale(200);
    color: var(--tone-front-300);

    &.ef-text--link {
      color: var(--color-front);
    }

    &:not(:last-child):after {
      content: "•";
      padding-left: spacing(1);
    }
  }

  &__date-actions {
    display: flex;
    gap: spacing(2);

    @include break(desktop-s) {
      flex-direction: column;
      flex-basis: 33.333333%;
      align-items: flex-start;
      gap: spacing(1);
    }
  }

  &__date-logos {
    display: grid;
    grid-template-columns: auto 24px;
    grid-template-areas:
      "result logoHome"
      "result logoAway";
    gap: spacing(1);

    @include break(desktop-s) {
      grid-template-columns: 24px spacing(8) 24px;
      grid-template-areas: "logoHome result logoAway";
    }
  }

  &__date-result {
    @include type-scale(500);
    display: grid;
    gap: spacing(1);
    grid-area: result;
    color: var(--color-front);
    font-family: font($font--subline);
    line-height: 24px !important;

    @include break(desktop-s) {
      display: flex;
      justify-content: center;
      gap: 0;
    }

    &.--is-live {
      color: var(--color-accent);
    }
  }

  &__date-result-number {
    text-align: center;

    &--home {

      @include break(desktop-s) {
        text-align: right;

        &:after {
          content: "-";
        }
      }
    }

    &--away {

      @include break(desktop-s) {
        text-align: left;
      }
    }
  }

  &__date-result-empty:after {

    @include break(desktop-s) {
      content: "—";
    }
  }

  &__date-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    &--home {
      grid-area: logoHome;
    }

    &--away {
      grid-area: logoAway;
    }
  }

  &__date-logo-image {
    max-width: 100%;
    max-height: 100%;
  }
}
