.ef-gallery-card {
  @extend %card;

  .ef-media {
    background-color: transparent;
  }

  &__image {
    object-fit: contain !important;
  }
}
