// body.ef-dark-mode {
//   background-color: $color--black;
//   color: $grayscale--200;
// }

[data-theme="dark"] {

  // h2, h3, h4, h5, h6,
  // .ef-heading,
  // .ef-kicker {
  //   color: $color--white;
  // }

  // h1,
  // .ef-heading--1 {
  //   color: $color--red-400;
  // }

  .ef-kicker__date:after {
    color: $grayscale--600;
  }

  // blockquote,
  // .ef-blockquote {
  //   color: $color--white;
  // }

  // mark,
  // .ef-text--mark {
  //   background-color: rgba($color--red-400, .5);
  //   color: $color--white;
  // }

  // .ef-icon {
  //
  //   &--default {
  //     color: $color--white;
  //   }
  // }

  // .ef-caption {
  //   color: $grayscale--400;
  // }

  // .ef-media {
  //   background-color: $grayscale--800;
  // }

  // %card--has-line {
  //
  //   &:before {
  //     background-color: $color--white;
  //   }
  // }

  // .ef-article-card {
  //
  //   &__media {
  //     background-color: $grayscale--800;
  //   }
  // }

  // .ef-product-card {
  //   background-color: $grayscale--800;
  // }

  // .ef-slider {
  //
  //   &__arrow--next {
  //     background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M9,31.414L7.586,30l14-14l-14-14L9,0.586l14.707,14.707c0.391,0.391,0.391,1.023,0,1.414L9,31.414z" /%3E%3C/svg%3E');
  //   }
  //
  //   @include break(desktop-s) {
  //
  //     &__arrow--prev {
  //       background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M23,31.414L8.293,16.707c-0.391-0.391-0.391-1.023,0-1.414L23,0.586L24.414,2l-14,14l14,14L23,31.414z" /%3E%3C/svg%3E');
  //     }
  //   }
  //
  //   &__arrow {
  //     //background-color: rgba($color--black, .6);
  //   }
  // }
  //
  // .ef-gallery-slider--dots {
  //
  //   .ef-slider__arrow--prev {
  //     background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M23,31.414L8.293,16.707c-0.391-0.391-0.391-1.023,0-1.414L23,0.586L24.414,2l-14,14l14,14L23,31.414z" /%3E%3C/svg%3E');
  //   }
  // }

  // .ef-accordion {
  //
  //   &__header:after {
  //     background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="white" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  //   }
  // }

  .ef-hero {

    &__media:before {
      background-image: linear-gradient(to bottom, $dark-gray-800, rgba($dark-gray-800, 0));
    }

    &__media:after {
      background-image: linear-gradient(to top, $dark-gray-800, rgba($dark-gray-800, 0));
    }
  }
}
