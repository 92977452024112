.ef-story-grid {
  grid-template-columns: repeat(3, 1fr);

  @include break(phablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include break(tablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(6, 1fr);
  }

  .ef-card-grid__item {
    display: flex;
    justify-content: center;
    grid-column: span 1;
  }
}

.ef-featured-story-grid {
  grid-template-columns: repeat(1, 1fr);

  .ef-card-grid__item {
    display: flex;
    justify-content: center;
    grid-column: span 1;
  }
}
