.ef-page-embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__iframe {
    width: 100%;
    height: 100%;
  }
}
