.ef-player-hero {
  @extend %boxed-padding;
  margin-top: spacing(-12);
  margin-bottom: spacing(12);
  padding-top: spacing(6);
  background-color: var(--tone-back-750);

  &__container {
    position: relative;
    height: 100vw;

    @include break(tablet) {
      display: grid;
      grid-template-columns: 38.2fr 61.8fr;
      gap: spacing(4);
      align-items: center;
      height: auto;
    }

    @include break(desktop-s) {
      grid-template-columns: 38.2fr 61.8fr;
    }
  }

  &__content {
    width: 61.8%;

    @include break(tablet) {
      width: 100%;
      padding-bottom: spacing(6);
    }
  }

  &__media {
    position: absolute;
    bottom: 0;
    right: -25vw;
    width: 90vw;

    @include break(tablet) {
      position: relative;
      right: 0;
      width: 100%;
    }
  }
  
  .ef-player-profile__items {
    grid-template-columns: 1fr;
    gap: spacing(2);
  }

  .ef-player-profile__item,
  .ef-player-profile__item strong {
    color: var(--color-front);
  }
}
