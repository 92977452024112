.ef-broadcaster {
  display: block;
  width: spacing(7);
  height: spacing(3);

  &__label {
    @include type-scale(100);
    display: none;
    margin-bottom: spacing(.5);
    color: var(--tone-front-300);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  &__logo {
    @include transition;
    width: spacing(7);
    height: spacing(3);
    margin: 0 auto;
    opacity: .7;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: grayscale(1);

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    &--black {

      [data-theme="dark"] & {
        display: none;
      }

      .ef-match-meta[data-theme="light"] & {
        display: block;
      }
    }

    &--white {

      [data-theme="light"] & {
        display: none;
      }
    }
  }
}
