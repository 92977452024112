.ef-shop-card {
  @extend %card;
  @extend %card--has-line;
  @extend %card--has-line-desktop;
  display: flex;
  flex-direction: column;

  &__media {
    @extend .ef-media--super-wide;
  }

  &__image {
    @include transition;
  }

  @include break(desktop-s) {

    &:hover {

      .ef-shop-card__image {
        transform: scale(1.05);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: spacing(2) spacing(3);
    background-color: var(--color-black);
  }

  &__heading {
    @include secondary-heading;
    @include transition($duration-faster, $property: color);
    margin-bottom: 0;
    padding-top: 0;
    color: var(--color-white);

    @include break(desktop-s) {

      .ef-shop-card:hover & {
        color: var(--color-accent);
      }
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    color: var(--color-accent);
  }
}
