.ef-countdown-block {
  @extend %boxed-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: spacing(6);
  position: relative;
  z-index: 1;
  min-height: 33.333333vw;
  padding-top: spacing(12);
  padding-bottom: spacing(12);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    @include fill;
    content: "";
    z-index: -1;
    background-color: rgba($color--black, .5);
  }

  &__media {
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  &__heading {
    margin-bottom: 0;
    font-family: font($font--subline);
    font-weight: 400;
    text-align: center;
  }

  &__info {
    color: var(--color-front);

    [data-theme="light"] .ef-content--match-center-initial & {
      color: var(--color-white);
    }
  }
}

.ef-countdown {
  display: inline-grid;
  grid-gap: spacing(3) spacing(4);
  position: relative;
  margin: 0 auto;

  .ef-countdown-block & {
    transform: none;
  }

  &--m {
    grid-template-columns: repeat(4, 1fr);
  }

  &--l {
    grid-template-columns: repeat(2, 1fr);

    @include break(tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include ie11() {
  display: flex;
  align-items: center;
  justify-content: center;
  }

  &__digits {
    //background-color: var(--color-front);
    color: var(--color-front);
    font-family: font($font--headline);
    font-weight: 700;
    text-align: center;

    [data-theme="light"] .ef-content--match-center-initial & {
      color: var(--color-white);
    }

    .ef-countdown--m & {
      width: spacing(10);
      //padding-top: spacing(1);
      @include type-scale(700);

      @include break(tablet) {
        width: spacing(10);
      }
    }

    .ef-countdown--l & {
      width: spacing(20);
      padding: spacing(2.5) 0 spacing(.5) 0;
      @include type-scale(900);

      @include break(tablet) {
        width: spacing(24);
      }
    }

    .ef-countdown-block & {
      padding: 0;
    }
  }

  &__label {
    //margin: 0 spacing(-2);
    //padding-top: spacing(.625);
    //padding-bottom: spacing(.375);
    //background-color: var(--color-accent);
    color: var(--color-front);
    //font-weight: 700;
    text-align: center;
    //text-transform: uppercase;
    @include type-scale(300);

    [data-theme="light"] .ef-content--match-center-initial & {
      color: var(--color-white);
    }
  }
}

.ef-countdown-backdrop {
  @include fill;
  @include image-filter;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    @include fill;
    content: "";
    background-color: rgba($color--black, .7);

    @include theme(light) {
      background-color: rgba($color--white, .8);
    }
  }
}
