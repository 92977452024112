.ef-shop-label {
  @include type-scale(200);
  display: inline-block;
  padding: spacing(.5) spacing(1);
  border: 2px solid var(--color-back);
  background-color: var(--color-front);
  color: var(--color-back);
  font-family: font($font--subline-condensed);
  line-height: 1.1;
  font-weight: 400;
  text-transform: uppercase;
  hyphens: auto;

  &--color-1 {
    background-color: var(--color-green);
    color: var(--color-white);
  }
}
