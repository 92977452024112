.ef-sport-card {
  @extend %card;
  @extend %card--has-line;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    @include transition($property: background-color);
    position: relative;
    width: 100%;
    margin-bottom: spacing(2);
    padding-top: 100%;
    background-color: var(--tone-back-750);

    @include break(desktop-s) {

      .ef-sport-card:hover & {
        background-color: var(--color-front);
      }
    }
  }

  &__icon-image {
    @include fill;
    @include transition;
    width: 100%;
    height: 100%;
    color: var(--color-front);
    transform: scale(.9);

    @include break(desktop-s) {

      .ef-sport-card:hover & {
        color: var(--color-back);
        transform: scale(1);
      }
    }
  }

  &__heading {
    @include type-scale(300);
    padding-top: 0;
    font-weight: 400;
    text-align: center;
  }
}
