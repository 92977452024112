.ef-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.ef-search-filterbar {
  // position: sticky;
  // top: 0;
  position: relative;
  height: spacing(4);
  margin-bottom: spacing(2);
}

.ef-search-filters {
  display: flex;
  align-items: baseline;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include break(desktop-s) {
    position: relative;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.ef-search-filter,
.ef-filter {
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: spacing(3);
  }

  &:last-child {

    .ef-submenu__filters & {
      margin-right: spacing(3);

      @include break(desktop-s) {
        margin-right: 0;
      }
    }
  }

  @include break(desktop-s) {
    position: relative;
    //margin-right: 0;
  }

  // ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: var(--color-front);
  //   opacity: 1; /* Firefox */
  // }
  //
  // :-ms-input-placeholder { /* Internet Explorer 10-11 */
  //   color: var(--color-front);
  // }
  //
  // ::-ms-input-placeholder { /* Microsoft Edge */
  //   color: var(--color-front);
  // }

  &__button {
    margin-bottom: spacing(2);
    padding: spacing(.5) spacing(2);
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: var(--tone-200);
    background-color: transparent;
    color: var(--color-front);
    font-weight: 700;
    @include type-scale(200);
    @include transition($duration-faster);

    &:hover {
      border-color: var(--color-accent);
      color: var(--color-front);
    }

    &.--is-active {
      // border-color: var(--color-front);
      // background-color: var(--color-front);
      // color: var(--color-back);
      border-color: var(--color-accent);
    }

    &.--is-active:hover {
      // border-color: var(--tone-200);
      // background-color: var(--tone-200);
    }

    &.--is-open {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
      color: var(--color-accent-contrast);
    }

    &.--is-open:hover {
      border-color: var(--color-red-500);
      background-color: var(--color-red-500);
    }
  }

  &__settings,
  .flatpickr-calendar {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    padding: spacing(2);
    //background-color: var(--color-back);
    //color: var(--color-back);
  }

  &__settings {
    display: none;

    &.--is-open {
      display: block;
    }

    .ef-search-filter--page-type & {
      min-width: spacing(48);
    }

    .ef-search-filter--tags & {
      min-width: spacing(48);
    }
  }

  .ais-SearchBox {
    position: relative;
  }

  .ais-SearchBox:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 12px;
    pointer-events: none;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--white)}" d="M11.707,10.293l-2.54-2.54A5.015,5.015,0,1,0,7.753,9.167l2.54,2.54a1,1,0,0,0,1.414-1.414ZM2,5A3,3,0,1,1,5,8,3,3,0,0,1,2,5Z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: left center;

    [data-theme="light"] & {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--black)}" d="M11.707,10.293l-2.54-2.54A5.015,5.015,0,1,0,7.753,9.167l2.54,2.54a1,1,0,0,0,1.414-1.414ZM2,5A3,3,0,1,1,5,8,3,3,0,0,1,2,5Z" /%3E%3C/svg%3E');
    }
  }

  .ais-SearchBox-input {
    padding-left: calc(#{spacing-no-calc(1)} + 12px);
  }
}

@include placeholder(".ais-SearchBox-input") {
  color: var(--tone-front-300);
  opacity: 1;
}

.ef-filter {

  &--prev-next {
    display: flex;
    //align-items: center;
  }

  &__prev,
  &__next {
    @include transition($property: color);
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--tone-front-300);

    &:hover {
      color: var(--color-accent);
    }
  }

  &__prev {
    margin-right: spacing(1);
    margin-left: -4px;
  }

  &__next {
    margin-right: -4px;
    margin-left: spacing(1);
  }
}

.ef-search-filter__button,
.ef-filter .ais-SearchBox-input,
.ais-MenuSelect-select,
.ais-SortBy-select,
.ais-ClearRefinements-button {
  padding-top: calc(#{spacing(.5)} + 2px);
  padding-bottom: spacing(.5);
  background-color: transparent;
  color: var(--color-front);
  font-family: font($font--primary);
  //font-weight: 700;
  @include type-scale(300);
  @include transition($duration-faster);

  .ef-submenu:not(.ef-submenu--search) & {
    color: var(--color-black);
  }
}

.ais-MenuSelect,
.ais-SortBy {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(#{spacing-no-calc(2)} + 16px);
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='#{url-encoded-color($color--white)}' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;

    [data-theme="light"] &,
    .ef-submenu:not(.ef-submenu--search) & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewbox='0 0 16 16'%3E%3Cpolygon fill='#{url-encoded-color($color--black)}' points='8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6' /%3E%3C/svg%3E");
    }
  }
}

.ais-MenuSelect-select,
.ais-SortBy-select {
  padding-right: calc(#{spacing-no-calc(.5)} + 16px);
  //padding-left: spacing(2);
  appearance: none;
  border: none;
  border-bottom: 2px solid var(--color-front);
  border-radius: 0;
  //background-color: var(--tone-back-600);

  .ef-submenu & {
    //background-color: var(--tone-front-600);
  }

  &:focus {
    outline: 0;
    border-color: var(--color-accent);
  }

  &.--is-current {
    border-color: var(--color-accent) !important;
  }

  .ef-submenu:not(.ef-submenu--search) & {
    border-color: var(--color-black);
  }
}

.ais-MenuSelect-select {

  .ef-content--news .ef-content__header & {
    width: spacing(32);
  }
}

.flatpickr-wrapper {
  position: static;

  @include break(desktop-s) {
    position: relative;
  }
}

.flatpickr-calendar {
  min-width: spacing(48);
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-family: font($font--primary);

  &:before,
  &:after {
    display: none;
  }
}

span.flatpickr-weekday {
  @include type-scale(200);
}

.ef-search-filter-settings {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  padding-top: spacing(8);
  overflow-y: scroll;
  //background-color: var(--tone-back-700);
  transform: translateX(-100%);

  @include break(tablet) {
    width: spacing(42);
    transform: translateX(#{spacing(-42)});
  }

  &.--is-active {
    animation: show-menu-section-primary .3s $ease-in-out-expo .15s forwards;
  }

  &__inner {
    @extend %boxed-padding;
    min-height: 100%;
  }

  &__button {
    @extend .ef-link-button;
    display: inline;
  }

  &__close {
    position: absolute;
    top: spacing(2);
    left: spacing(2);
  }
}

.ais-RefinementList {
  margin-bottom: spacing(4);
}

.ais-RefinementList-item {
  margin-bottom: spacing(1);
  color: var(--tone-medium);
  @include type-scale(300);

  &--selected {
    color: var(--color-front);
  }
}

.ais-RefinementList-count {
  //margin-left: spacing(1);
  //font-weight: 700;
  @include type-scale(200);

  &:before {
    content: " (";
  }

  &:after {
    content: ")";
  }
}
