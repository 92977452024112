.ef-testimonials {
  display: grid;
  gap: spacing(6) spacing(8);

  &--grid {
    @include break(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.ef-testimonial {
  @include max-width(m);

  &.--has-avatar {
    display: grid;
    grid-template-columns: spacing(20) 1fr;
    gap: spacing(4);
  }

  &__avatar {
    position: relative;
    width: spacing(20);
    height: spacing(20);
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  &__quote {
    padding: 0;
    color: var(--color-front);
    font-family: font($font--primary);
    font-style: normal;
    text-indent: -.4em;

    &:before,
    &:after {
      content: "„";
      position: relative;
      right: auto;
      bottom: auto;
      left: auto;
      height: auto;
      background: transparent;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    &:before {
      content: "„";
    }

    &:after {
      content: "“";
    }
  }

  &__cite {
    padding: 0 0 0 spacing(10);
    color: var(--color-front);

    &:before {
      content: "";
      position: absolute;
      top: calc(#{spacing-no-calc(1.75)} - 2px);
      left: 0;
      width: spacing(8);
      height: 4px;
      background-color: var(--color-accent);
    }
  }
}
