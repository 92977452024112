.ef-match-table-link {
  @extend %boxed-padding;
  pointer-events: none;
  //@include row-gap(m);
  @include fill;

  .ef-content__block--table-slider-after-matches & {
    padding-top: 0 !important;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: calc(100vw - calc((.375rem + .1vw) * 12));
    height: 100%;

    &--matches {
      grid-column-gap: spacing(1);
    }

    @include break(tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include break(desktop-s) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1440px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include break(desktop-xl) {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .ef-match-card,
  .ef-table-card {
    pointer-events: auto;
    background-color: var(--tone-back-800);
  }
}
