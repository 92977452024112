.ef-match-preview {
  @extend %boxed-padding;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: spacing(-12);
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 720px;
  padding-bottom: spacing(24);
  overflow-y: scroll;
  text-align: center;

  &__backdrop {
    @include fill;
    @include image-filter;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:after {
      @include fill;
      content: "";
      background-color: rgba($color--black, .7);
    }
  }

  &__content {
    position: relative;
  }
}
