@keyframes tdp-map {
  0% {
    transform: none;
  } 100% {
    transform: scale(1.5);
  }
}

#Laufstrecke {
  display: none;
}

// #tdp-1 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 56% 55%;
// }

// #tdp-2 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 56% 59%;
// }

// #tdp-3 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 57% 57%;
// }
//
// #tdp-4 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 56% 47%;
// }
//
// #tdp-5 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 60% 10%;
// }
//
// #tdp-6 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 66% 2%;
// }
//
// #tdp-7 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 54% 23%;
// }
//
// #tdp-8 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 26% 24%;
// }
//
// #tdp-9 {
//   animation: tdp-map 1s ease-in-out 0s infinite alternate-reverse;
//   transform-origin: 26% 49%;
// }

.ef-content__block {

  &--tdp {
    padding-bottom: spacing(6);
    background-color: black;
  }

  &--boxed {
    @extend %boxed-padding;
  }

  &--mb-0 {
    margin-bottom: 0 !important;
  }
}

.ef-tdp-map {
  position: relative;
  width: 100%;
  overflow: hidden;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 1px;
    height: 0;
    padding-top: 100%;

    @include break(tablet) {
      display: none;
    }
  }

  svg {
    @include fill;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @include break(tablet) {
      position: relative;
    }
  }
}

.ef-tdp-map__zoom {
  position: absolute;
  z-index: 2;
  right: spacing(3);
  bottom: spacing(3);
}

.ef-tdp-map__zoom-in,
.ef-tdp-map__zoom-out {
  @include type-scale(500);
  @include transition($property: background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: spacing(6);
  height: spacing(6);
  cursor: pointer;
  border-radius: 3px;
  background-color: var(--color-white);
  color: var(--color-black);
  font-weight: 700;

  &:hover {
    background-color: var(--tone-back-600);
  }
}

.ef-tdp-map__zoom-in {
  margin-bottom: spacing(1);
}

.ef-tdp-legend {

  &--mobile {

    @include break(tablet) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include break(tablet) {
      display: block;
    }
  }
}
