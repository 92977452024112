$add-box-height: spacing(6.65);

.ef-product-add {
  z-index: 5;
  position: relative;
  margin-bottom: spacing(4);

  &__box {
    position: relative;
    display: block;
    margin-bottom: spacing(.5);
    overflow-x: scroll;
    overflow-y: hidden;

    &.--is-active {
      .ef-product-add__sizes:after {
        opacity: 1;
      }
      .ef-product-add__sizes {
        overflow: hidden;
      }
      .ef-product-add__submit {
        pointer-events: all;

        button {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &.--is-added {
      .ef-product-add__submit {
        background-color: #6FCF97;
        pointer-events: none;
        button {
          opacity: 0;
        }
        svg {
          polyline {
            animation: checked .55s ease-in forwards;
            stroke-width: 4;
            stroke-dashoffset: 0;
          }
        }
      }
      .ef-product-add__sizes-select {
        pointer-events: none;
        &.--is-selected {
          label:before {
            border-color: #6FCF97;
          }
        }
      }
    }
  }

  &__label {
    @extend .ef-form-item__label;
  }

  &__sizetable {
    display: block;
  }

  .ef-product-add__sizes {
    display: flex;
    position: relative;
    padding-bottom: $add-box-height;

    &:after {
      position: absolute;
      width: 105%;
      height: 105%;
      content: '';
      left: 0;
      top: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity .25s;
      background-color: rgba(white, .85);
      z-index: 5;
    }
  }

  &__submit {
    position: absolute;
    top: 0;
    left: spacing(10);
    width: calc(100% - #{spacing(10)});
    height: 101%;
    z-index: 5;
    overflow: hidden;
    pointer-events: none;

    button {
      position: absolute;
      width: 100%;
      height: 100%;
      border: none;
      font-weight: 700;
      color: white;
      line-height: 1.8;
      text-align: center;
      font-size: spacing(2.3);
      opacity: 0;
      padding: spacing(.5);
      background-color: var(--color-red-400);
      transform: translateX(100%);
      transition: all .25s;
    }

    svg {
      width: 40px;
      height: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;

      polyline {
        stroke-dasharray: 110;
        stroke-dashoffset: 110;
        stroke-width: 0;
      }
    }
  }

  &__sizes-select {
    width: spacing(10);
    height: 100%;
    position: absolute;
    transition: all .3s;

    @for $i from 1 through 6 {
      &:nth-child(#{$i+1}) {
        transform: translateX(spacing(9.7*$i));
      }
    }

    &.--is-selected {
      transform: translateX(0);
      z-index: 15;
    }

    label {
      position: relative;
      width: 100%;
      font-size: spacing(2.3);
      padding: spacing(1.35) 0;
      display: inline-block;
      text-align: center;
      transition: all .3s;
      line-height: 1.8;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: $add-box-height;
        left: 0;
        top: 0;
        box-sizing: border-box;
        transition: border .35s;
        border: 2px solid black;
        z-index: 1;
      }
    }
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
      margin: 0;

      &:checked + label {
        left: 0;
        position: absolute;
        background-color: white;
        &:before {
          border-color: var(--color-red-400);
        }
      }
      &[disabled] + label {
        z-index: -1;
        color: var(--grayscale-300);
        &:before {
          border: 2px solid var(--grayscale-300);
        }
      }
    }
  }
}

.ef-product-submit {
  button {
    transition: all .3s;

    > span {
      transition: opacity .2s;
    }

    &.--is-added {
      background-color: #6FCF97;
      border-color: #6FCF97;
      pointer-events: none;

      > span {
        opacity: 0;
      }

      svg {
        polyline {
          animation: checked .55s ease-in forwards;
          stroke-width: 4;
          stroke-dashoffset: 0;
        }
      }
    }

    svg {
      width: 40px;
      height: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;

      polyline {
        stroke-dasharray: 110;
        stroke-dashoffset: 110;
        stroke-width: 0;
      }
    }
  }
}


@keyframes checked {
  0% {
    stroke-dashoffset: 110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
