.ef-content__block--jersey-config {
  @extend %boxed-padding;
  position: relative;
}

.ef-jersey-config {
  position: relative;
  margin-bottom: spacing(6);
  // position: absolute;
  // top: 0;
  // display: block;
  // opacity: 0;
  // z-index: -1;
  // visibility: hidden;
  // pointer-events: none;
  // &.--is-active {
  //   position: relative;
  //   opacity: 1;
  //   visibility: visible;
  //   pointer-events: all;
  //   z-index: 0;
  // }

  &-grid {
    @include break(tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: spacing(6);
    }

    &__item {
      position: relative;
      overflow-x: hidden;
    }
  }

  &-wording {
    &__item {
      display: none;
      margin: spacing(4.5) 0 spacing(3);
      &.--is-active {
        display: block;
      }
    }

    h4 {
      font-family: font($font--primary);
    }
  }

  &__selection {
    .ef-select-jersey {
      margin-bottom: spacing(2);
    }
  }

}

.ef-media--jersey {
  background-color: transparent;
  height: auto;
  padding-bottom: calc(100% + #{spacing-no-calc(2)});
}

.ef-jersey-config__selection-customize {
  display: none;
  &.--is-active {
    display: block;
  }
}

.ef-slider-jersey {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .65s;
  display: block;
  grid: unset;

  &.--is-active {
    position: relative;
    height: auto;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &-container {
    position: relative;
    overflow: hidden;
    padding-bottom: spacing(5);
  }

  img {
    width: 100%;
  }

  &__arrow {
    .ef-slider__arrow--next {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M20.768,31.395L10.186,16.581c-0.248-0.348-0.248-0.814,0-1.162L20.768,0.605l1.627,1.162L12.229,16 l10.166,14.232L20.768,31.395z" /%3E%3C/svg%3E');
    }
  }
}

.ef-jersey-thumb {
  @extend .ef-card-grid;
  grid-template-columns: repeat(4, 1fr);

  &__item {
    @extend .ef-product-card;
    padding: spacing(1.5);
    transition: background .45s;
    &:hover, &.--is-active {
      background-color: var(--grayscale-100);
      &:before {
        width: 0;
      }
      &:after {
        width: 100%;
      }
    }
    &:before, &:after {
      top: 0;
    }
  }
}


.ef-product__price.--jersey-config {
  text-align: left;
  margin: spacing(2.8) 0 spacing(1);
  .ef-product__price-label {
    font-size: spacing(4);
    line-height: 1.4;
  }
}

.ef-hints--jersey-config {
  margin-bottom: spacing(2.8);

  .ef-hint {
    font-size: spacing(2.3);
    margin-bottom: 0;
    a {
      line-height: 1.5;
    }
  }
}

.ef-slider-jersey__arrow {
  &[class*=--prev],
  &[class*=--next] {
    width: 30px;
    background-color: transparent !important;
    background-position: center center;
  }
  &[class*=--prev] {
    right: auto;
    left: 0;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M20.768,31.395L10.186,16.581c-0.248-0.348-0.248-0.814,0-1.162L20.768,0.605l1.627,1.162L12.229,16 l10.166,14.232L20.768,31.395z" /%3E%3C/svg%3E');
  }
  &[class*=--next] {
    right: 0;
    left: auto;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M11.232,31.395l-1.627-1.162L19.771,16L9.605,1.768l1.627-1.162l10.582,14.813 c0.248,0.348,0.248,0.814,0,1.162L11.232,31.395z" /%3E%3C/svg%3E');
  }
}

li {
  &[data-select2-id*="patch"] {
    i {
      border-radius: 0;
      vertical-align: top;
      display: inline;
    }
  }
}

.ef--tabbed {
  @extend .ef-content__block--match-stats;
}

.jersey-select {

  &__control {
    border-radius: 0 !important;
    border-color: rgba($color--black, .6);
  }

  &__value-container {
    padding: spacing(1) spacing(2) spacing(1) !important;
  }

  &__single-value {
    margin: 0 !important;
  }

  &__indicator {
    padding: spacing(1) calc(#{spacing-no-calc(2)} - 4px) spacing(1) 0 !important;
  }

  &__indicator-separator {
    display: none !important;
  }
}
