.ef-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(3);
}

.ef-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  font-weight: 700;
  text-align: center;

  &:disabled,
  &[disabled] {
    opacity: .4;
    pointer-events: none;
    cursor: default;
  }

  &--primary,
  &--secondary,
  &--tertiary {
    @include transition($duration: $duration-slower, $easing: $ease-out-back);

    &:hover {
      transform: scale(1.02);
    }

    &:disabled:hover,
    &[disabled]:hover {
      transform: none;
    }
  }

  &--primary, &--primary:visited {
    --color-button-icon: var(--color-accent-contrast);
    border-color: var(--color-accent);
    background-color: var(--color-accent);
    color: var(--color-accent-contrast);
    font-family: font($font--subline);
    font-weight: 400;

    &:hover {
      border-color: var(--color-accent-shade);
      background-color: var(--color-accent-shade);
    }
  }

  &--secondary, &--secondary:visited,
  &--tertiary, &--tertiary:visited {
    --color-button-icon: var(--color-front);
    background-color: transparent;
    color: var(--color-front);
    font-family: font($font--subline);
    font-weight: 400;
  }

  &--secondary, &--secondary:visited {
    border-color: var(--color-accent);

    &:hover {
      border-color: var(--color-accent-shade);
    }
  }

  &--tertiary.--is-active,
  &--tertiary:visited.--is-active {
    --color-button-icon: var(--color-back);
    background-color: var(--color-front);
    color: var(--color-back);
  }

  &--tertiary-filled,
  &--tertiary-filled:visited {
    border-color: var(--color-front);
    background-color: var(--color-front);
    color: var(--color-back);
  }

  &--alternate, &--alternate:visited {
    border-color: var(--color-front);
    background-color: var(--color-front);
    color: var(--color-accent);
  }

  &--large {
    padding: spacing(1) spacing(3) spacing(1);
    //text-transform: uppercase;
    @include type-scale(400);
    line-height: spacing(3);
  }

  &--large.ef-button--rounded {
    border-radius: calc(#{spacing-no-calc(3)} + 2px);
  }

  &--small {
    padding: spacing(.75) spacing(2) spacing(.75);
    //text-transform: uppercase;
    @include type-scale(300);
    line-height: spacing(3);
  }

  &--small.ef-button--rounded {
    border-radius: calc(#{spacing-no-calc(2.5)} + 2px);
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--has-icon {
    display: inline-flex;
    align-items: center;
    padding: spacing(1) spacing(2);
    gap: spacing(1);
  }

  &:not(:last-child) {

    .ef-buttons & {
      margin-right: spacing(1);
    }
  }

  &__label {

    &--more-top,
    &--more-bottom.--is-active {
      padding-right: 20px;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="var(--color-button-icon)"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>');
      background-repeat: no-repeat;
      background-position: right center;
    }

    &--more-bottom {
      padding-right: 20px;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="white"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>');
      background-repeat: no-repeat;
      background-position: right center;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: spacing(.75);

    &--right {
      margin-right: 0;
      margin-left: spacing(.75);
    }
  }

  &--checkbox {
    transition: background .3s;

    svg {
      width: 30px;
      height: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;

      polyline {
        stroke-dasharray: 110;
        stroke-dashoffset: 110;
        stroke-width: 0;
      }
    }

    &.--active {
      background-color: #6FCF97;
      border-color: #6FCF97;
      pointer-events: none;

      span {
        opacity: 0;
      }

      svg {
        polyline {
          animation: checked .55s ease-in forwards;
          stroke-width: 4;
          stroke-dashoffset: 0;
        }
      }
    }
  }
}
