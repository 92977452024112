.ef-traditional-team-dates {

  &__header {

    &:after {
      top: auto;
      bottom: spacing(3);
      height: 32px;
    }
  }

  &__heading {
    display: flex;
    align-items: flex-end;
    padding-top: 0;
  }

  &__date {
    flex-basis: spacing(20);
    margin-bottom: spacing(1);
    @include type-scale(300);
  }

  &__title {
    flex: 1;
  }

  &__result {
    flex-basis: spacing(8);
    color: var(--color-accent);
    font-weight: 700;
    @include type-scale(500);
  }
}
