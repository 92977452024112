.ef-section-header {
  @extend %ef-max-width-l-center;
  //padding-top: spacing(2);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: spacing(3);
  text-align: left;

  .ef-content--shop & {
    margin-bottom: spacing(4);
  }

  &--toggle {
    justify-content: space-between;
    align-items: center;
  }

  // &--spacing {
  //   margin-bottom: spacing(4);
  // }

  &--center {
    justify-content: center;
  }

  &__heading {
    position: relative;
    margin-bottom: 0;
    padding-right: spacing(2);
  }

  &__heading:before {
    //content: "";
    position: absolute;
    bottom: calc(100% + #{spacing-no-calc(2)});
    left: calc(50% - 1px);
    width: 2px;
    height: spacing(4);
    background-color: var(--color-accent);

    @include break(tablet) {
      right: calc(100% + #{spacing-no-calc(2)});
      bottom: calc(50% - 1px);
      left: auto;
      width: spacing(4);
      height: 2px;
    }
  }

  &__more {
    @include type-scale(300);
  }

  &__more:before {
    content: "/";
    padding-right: spacing(2);
    color: var(--tone-medium);
  }

  &__toggle {
    display: flex;
  }

  &__toggle-item {
    @include transition($property: color);
    cursor: pointer;
    color: var(--color-front);

    &:not(:last-child) {
      margin-right: spacing(2);
    }

    &.--is-active {
      opacity: .4;
      pointer-events: none;
    }

    &:hover {
      color: var(--color-accent);
    }
  }
}
