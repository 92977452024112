// Base variables with data attributes
:root,
[data-flock-label-font-size] {
  --flock-label-font-size: 5vw;
  --flock-label-font-size-configurator-desktop: 24px;
}

:root,
[data-flock-number-font-size] {
  --flock-height: 15vw;
  --flock-height-configurator: 70px;
}

// Container layout
.ef-flocks {
  position: absolute;
  top: 12%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scale(1.2) translateY(9%);

  .--flock-125 & {
    top: 14%;
  }

  @include break(tablet) {
    transform: scale(1.3) translateY(11.5%);
  }

  @include break(desktop-s) {
    transform: scale(1) translateY(0);
  }

  @include break(desktop-m) {
    transform: scale(1.05) translateY(2%);
  }

  @include break(desktop-xl) {
    transform: scale(1.1) translateY(2%);
  }

  .ef-content__block--jersey-config & {
    transform: scale(1.37) translateY(14%);

    @include break(tablet) {
      transform: scale(1.6) translateY(19%);
    }

    @include break(desktop-s) {
      transform: scale(1.3) translateY(11.5%);
    }

    @include break(desktop-m) {
      transform: scale(1.37) translateY(14%);
    }

    @include break(desktop-xl) {
      transform: scale(1.45) translateY(16%);
    }
  }
}

// Inner container
.ef-flocks__inner {
  .--flock-kids & {
    transform: scale(.8);
  }
}

// Label and name shared styles
.ef-flock--label,
.ef-flock--name {
  position: relative;
  margin-bottom: 1vw;
  color: var(--flock-color);
  font-size: var(--flock-label-font-size) !important;
  line-height: var(--flock-label-font-size) !important;
  text-align: center;
  text-transform: uppercase;

  span {
    font-weight: normal;
  }

  .--flock-away & {
    color: var(--color-white);
  }

  .--flock-third & {
    color: var(--color-black);
  }

  .--flock-euro & {
    color: #8C8E8F;
  }

  .--flock-125 &,
  &[data-flock-label-font-name="gelatoLuxe"],
  &[data-flock-name-font-name="gelatoLuxe"] {
    font-family: "gelato-luxe", sans-serif;
    font-weight: 400;
    text-transform: none;
  }

  @include break(tablet) {
    .ef-content__block--jersey-config & {
      margin-bottom: .5vw;
      font-size: calc(var(--flock-label-font-size) / 5 * 2) !important;
      line-height: calc(var(--flock-label-font-size) / 5 * 2) !important;
    }
  }

  @include break(desktop-s) {
    margin-bottom: 1vw;
    font-size: calc(var(--flock-label-font-size) / 5 * 3) !important;
    line-height: calc(var(--flock-label-font-size) / 5 * 3) !important;

    .ef-content__block--jersey-config & {
      margin-bottom: spacing(1);
      font-size: var(--flock-label-font-size-configurator-desktop) !important;
      line-height: var(--flock-label-font-size-configurator-desktop) !important;
    }
  }
}

// Label specific styles
.ef-flock--label {
  display: none;
  font-family: font($font--jersey);
  
  &.--is-active {
    display: block;
  }

  .--flock-125 &,
  &[data-flock-label-font-name="gelatoLuxe"] {
    padding-top: 2%;
    transform: scale(1.3);
  }
}

// Name specific styles
.ef-flock--name {
  font-family: font($font--jersey);
}

// Number icon width calculations
@mixin number-icon-width($number, $width-ratio) {
  width: calc(var(--flock-height) / 156.58 * #{$width-ratio} * 1.6);

  @include break(tablet) {
    .ef-content__block--jersey-config & {
      width: calc(var(--flock-height-configurator) / 156.58 * #{$width-ratio});
    }
  }

  @include break(desktop-s) {
    width: calc(var(--flock-height) / 156.58 * #{$width-ratio});

    .ef-content__block--jersey-config & {
      width: calc(var(--flock-height-configurator) / 156.58 * #{$width-ratio} * 1.7);
    }
  }
}

// Number styles
.ef-flock--number {
  width: 100%;
  height: calc(var(--flock-height) * 1.6);
  margin-bottom: 1.5vw;
  text-align: center;

  // 125 variant styles
  .--flock-125 &,
  &[data-flock-number-font-name="gelatoLuxe"]:before {
    color: var(--color-125-gold);
    font-family: "gelato-luxe", sans-serif;
    font-size: calc(var(--flock-height) * 2);
    line-height: calc(var(--flock-height) * 1.8);
    font-weight: 400;

    @include break(tablet) {
      font-size: calc(var(--flock-height) * 1.8);
      line-height: calc(var(--flock-height) * 1.7);
    }

    @include break(desktop-s) {
      font-size: calc(var(--flock-height) * 1.2);
      line-height: calc(var(--flock-height) * 1.1);
    }
  }

  &[data-flock-number-font-name="gelatoLuxe"]:before {
    content: attr(data-flock-number);
  }

  &[data-flock-number-font-name="gelatoLuxe"] svg {
    display: none;
  }

  svg {
    position: relative;
    display: inline-block;
    height: calc(var(--flock-height) * 1.6);
    margin: 0 spacing(.75);
    color: var(--flock-color);
    vertical-align: bottom;

    .--flock-home &,
    .--flock-third & {
      color: var(--color-black);
    }

    .--flock-away & {
      color: var(--color-white);
    }

    .--flock-euro & {
      color: #8C8E8F;
    }

    // Number icon widths
    &.ef-icon--0 { @include number-icon-width(0, 82.78); }
    &.ef-icon--1 { @include number-icon-width(1, 41.61); }
    &.ef-icon--2 { @include number-icon-width(2, 77.41); }
    &.ef-icon--3 { @include number-icon-width(3, 81.25); }
    &.ef-icon--4 { @include number-icon-width(4, 83.22); }
    &.ef-icon--5 { @include number-icon-width(5, 79.50); }
    &.ef-icon--6 { @include number-icon-width(6, 80.15); }
    &.ef-icon--7 { @include number-icon-width(7, 81.25); }
    &.ef-icon--8 { @include number-icon-width(8, 83.22); }
    &.ef-icon--9 { @include number-icon-width(9, 79.93); }
  }

  @include break(tablet) {
    .ef-content__block--jersey-config & {
      height: var(--flock-height-configurator);
      margin-bottom: .5vw;
    }

    .ef-content__block--jersey-config .--flock-125 & {
      height: 10vw;
      margin-bottom: -.5vw;
      font-size: 10vw;
      line-height: 10vw;
    }

    svg {
      .ef-content__block--jersey-config & {
        height: var(--flock-height-configurator);
      }
    }
  }

  @include break(desktop-s) {
    height: var(--flock-height);
    margin-bottom: 1vw;

    .ef-content__block--jersey-config & {
      height: calc(var(--flock-height-configurator) * 1.7);
      margin-bottom: spacing(1);
    }

    .--flock-125 & {
      height: var(--flock-height);
      margin-bottom: -.5vw;
      font-size: calc(var(--flock-height) * 1.1);
      line-height: var(--flock-height);
    }

    .ef-content__block--jersey-config .--flock-125 & {
      height: calc(var(--flock-height-configurator) * 1.7);
      margin-bottom: -.5vw;
      font-size: calc(var(--flock-height-configurator) * 1.7);
      line-height: calc(var(--flock-height-configurator) * 1.7);
    }

    svg {
      height: var(--flock-height);

      .ef-content__block--jersey-config & {
        height: calc(var(--flock-height-configurator) * 1.7);
        margin: 0 spacing(.375);
      }
    }
  }
}

// Outline styles
.ef-flock__outline {
  fill-opacity: .2;

  [data-flock-outline-color="black"] & {
    fill: var(--color-black);
  }

  [data-flock-outline-color="white"] & {
    fill: var(--color-white);
  }

  &--home {
    fill-opacity: .2;
  }
}

// Outline colors
[data-flock-outline-color="black"] {
  --color-jersey-logo: var(--color-black);
}

[data-flock-outline-color="white"] {
  --color-jersey-logo: var(--color-white);
}
