.ef-media-rich-text {
  display: grid;
  row-gap: spacing(6);
  margin-right: spacing(-3);
  margin-left: spacing(-3);
  padding-right: spacing(3);
  padding-left: spacing(3);

  @include break(tablet) {
    margin-right: spacing(-6);
    margin-left: spacing(-6);
    padding-right: spacing(6);
    padding-left: spacing(6);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    margin-right: spacing(-9);
    margin-left: spacing(-9);
    padding-right: spacing(9);
    padding-left: spacing(9);
  }

  &[data-theme] {
    background-color: var(--color-back);
  }

  &[data-theme="standard"],
  &[data-theme="dark"] {
    background-color: var(--tone-back-800);
  }

  &--black,
  &--black[data-theme="standard"],
  &--black[data-theme="dark"] {
    background-color: $color--black;
  }

  &__media {
    position: relative;
    margin-right: spacing(-3);
    margin-left: spacing(-3);

    @include break(tablet) {
      margin-right: 0;
      margin-left: spacing(-6);
    }

    @include break(desktop-s) {
      margin-left: spacing(-9);
    }

    .ef-media-rich-text--rt-left & {

      @include break(tablet) {
        margin-left: 0;
        margin-right: spacing(-6);
      }

      @include break(desktop-s) {
        margin-right: spacing(-9);
      }
    }

    &--gradient:after {
      content: "";
      position: absolute;
      inset: -1px;

      [data-theme="dark"] .ef-media-rich-text[data-theme="standard"] &,
      .ef-media-rich-text[data-theme="dark"] & {
        background-image: linear-gradient(to top, rgba($grayscale--800, 1), rgba($grayscale--800, 0));

        @include break(desktop-s) {
          background-image: linear-gradient(to left, rgba($grayscale--800, 1), rgba($grayscale--800, 0));
        }
      }

      [data-theme="dark"] .ef-media-rich-text--rt-left[data-theme="standard"] &,
      .ef-media-rich-text--rt-left[data-theme="dark"] & {

        @include break(desktop-s) {
          background-image: linear-gradient(to right, rgba($grayscale--800, 1), rgba($grayscale--800, 0));
        }
      }

      [data-theme] .ef-media-rich-text--black[data-theme="standard"] &,
      [data-theme] .ef-media-rich-text--black[data-theme="dark"] &,
      [data-theme="light"] .ef-media-rich-text[data-theme="inverted"] & {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

        @include break(desktop-s) {
          background-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        }
      }

      [data-theme] .ef-media-rich-text--black.ef-media-rich-text--rt-left[data-theme="standard"] &,
      [data-theme] .ef-media-rich-text--black.ef-media-rich-text--rt-left[data-theme="dark"] &,
      [data-theme="light"] .ef-media-rich-text--rt-left[data-theme="inverted"] & {

        @include break(desktop-s) {
          background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        }
      }
    }
  }

  &__content {
    @include max-width(m);

    @include break(desktop-s) {
      padding-right: spacing(8);
      padding-left: spacing(8);
    }

    [data-theme="dark"] .ef-media-rich-text[data-theme]:not([data-theme="standard"]) &,
    [data-theme="light"] .ef-media-rich-text[data-theme]:not([data-theme="light"]) & {
      margin-bottom: spacing(12);

      @include break(desktop-s) {
        padding-top: spacing(6);
        margin-bottom: spacing(6);
      }
    }

    .ef-media-rich-text--rt-left[data-theme] & {
      order: 2;

      @include break(desktop-s) {
        order: 0;
      }
    }
  }

  .ef-copy-block:not(:last-child) {
    margin-bottom: spacing(4);
  }
}
