.ef-hl-hero {
  margin-bottom: spacing(16);
  padding-top: spacing(10);
  text-align: center;

  &--static {
    margin-bottom: spacing(12);
    padding-top: 0;
  }

  @include break(desktop-s) {
    margin-bottom: spacing(40);
    padding-top: spacing(18);

    &--compact {
      margin-bottom: spacing(16);
    }

    &--static {
      margin-bottom: spacing(12);
      padding-top: spacing(6);
    }

    &--newsletter {
      margin-bottom: 0;
    }
  }

  &__presenter-logo {
    width: auto;
    height: spacing(9);
    margin-bottom: spacing(2);

    &--100000 {
      height: spacing(18);
    }
  }

  &__heading {
    @extend %boxed-padding;
    @include type-scale(900);
    max-width: none !important;
    margin-right: auto;
    margin-left: auto;

    &--m {
      @include type-scale(800);

      @include break(tablet) {
        @include type-scale(900);
      }
    }

    .ef-hl-hero--static & {

      @include break(desktop-s) {
        transform: translateY(#{spacing(6)});
      }
    }
  }

  &__subheading {
    @extend %boxed-padding;
    margin-right: auto;
    margin-left: auto;

    .ef-hl-hero--static & {
      margin-left: 0;
      padding: 0 !important;
    }
  }

  &__heading-text {

    &:first-child {
      position: relative;
      z-index: 1;
      background-color: var(--tone-back-800);
    }
  }

  &__heading-sub {
    font-family: font($font--subline-condensed);
    font-size: spacing(4.607999);
    font-weight: 400;
    line-height: spacing(5.5);
    letter-spacing: -.03em;

    .ef-hl-hero__heading--m & {
      font-size: spacing(3.839999);
      line-height: spacing(4.5);
    }

    @include break(desktop-s) {
      font-size: spacing(6.510415);
      line-height: spacing(7);

      .ef-hl-hero__heading--m & {
        font-size: spacing(5.208332);
        line-height: spacing(6);
      }
    }
  }

  &__media {
    @extend %ef-max-width-xl-center;
    //background: blue;
    position: relative;
    margin-top: -9vw;
    opacity: 0;

    .ef-hl-hero--static & {
      margin-top: spacing(6);
      opacity: 1;
    }

    @include break(desktop-s) {
      margin-top: -11vw;

      .ef-hl-hero--static & {
        margin-top: 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: spacing(36);
      background-image: linear-gradient(to top, $grayscale--800, rgba($grayscale--800, .001));

      .ef-hl-hero--mainpay & {
        display: none;
      }
    }

    &--full-width {
      z-index: -1;
      max-width: none;
      margin-top: -16vw;

      .ef-hl-hero--static & {
        margin-top: spacing(6);
      }

      &:before {
        content: "";
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        height: spacing(24);
        background-image: linear-gradient(to bottom, $grayscale--800, rgba($grayscale--800, .001));

        .ef-hl-hero--static & {
          display: none;
          height: spacing(12);

          @include break(desktop-s) {
            display: block;
          }
        }
      }

      @include break(desktop-s) {
        margin-top: -16vw;

        .ef-hl-hero--static & {
          margin-top: 0;
        }

        &:before,
        &:after {
          height: spacing(72);
        }
      }
    }

    &--front {
      z-index: 1;
    }

    .ef-hl-hero--mainpay & {
      margin-bottom: spacing(12);

      @include break(desktop-s) {
        margin-bottom: spacing(20);
      }
    }
  }

  &__video {
    max-width: 100%;
  }

  &__description {
    @extend %boxed-padding;
    position: relative;
    z-index: 2;
    margin-top: -10vw;
    text-align: center;

    .ef-hl-hero--static & {
      text-align: left;
    }

    &--no-media {
      margin-top: -3vw;
      padding-bottom: spacing(8);
    }

    &--inderregion {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &__paragraph {
    @include type-scale(500);
    margin-right: auto;
    margin-left: auto;
    font-weight: 700;

    .ef-hl-hero--static & {
      margin-left: 0;
    }

    &--m {
      @include type-scale(400);
      font-weight: 400;
    }

    &--s {
      @include type-scale(300);
      font-weight: 400;
    }

    &--left {
      text-align: left !important;
    }
  }

  .ef-cta-badges {
    justify-content: center;
  }

  &--static .ef-cta-badges {
    justify-content: flex-start;
  }
}

.ef-text--highlight {
  color: var(--color-accent);
}

.ef-dynamic-text {
  height: 80px;
  margin-top: -20px;
  overflow: hidden;
  line-height: 80px !important;

  @include break(tablet) {
    margin-top: -16px;
  }

  @include break(desktop-s) {
    margin-top: 0;
  }
}

.ef-cta-badges {
  display: flex;
}

.ef-cta-badge {
  @include transition($property: transform);

  &:not(:last-child) {
    margin-right: spacing(3);
  }

  &:hover {
    transform: scale(1.05);
  }

  &__image {
    width: auto;
    height: spacing(7);
  }
}
