.ef-product-detail {
  display: grid;
  gap: spacing(6);

  @include break(tablet) {
    padding-right: spacing(6);
    padding-left: spacing(6);
  }

  @include break(desktop-s) {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: spacing(9);
    align-items: start;
    padding-right: spacing(9);
    padding-left: spacing(9);
  }

  &__gallery,
  &__content {
    @include transition($property: padding-top);

    @include break(desktop-s) {
      padding-top: calc(#{spacing-no-calc(22.5)} + 3px) !important;
    }

    &.--header-scroll-down {

      @include break(desktop-s) {
        padding-top: calc(#{spacing-no-calc(12.5)} + 3px) !important;
      }
    }
  }

  &__gallery {
    padding-top: calc(#{spacing-no-calc(19.5)} + 3px);
  }

  &__gallery-grid {
    display: grid;
    gap: spacing(3);
    position: relative;

    @include break(desktop-s) {
      grid-template-columns: 1fr 6fr;
      align-items: start;
      gap: 0;
    }
  }

  &__gallery-view {
    position: relative;

    @include break(desktop-s) {
      order: 2;
    }
  }

  &__gallery-thumbs-wrapper {
    position: relative;
  }

  &__gallery-thumbs {
    padding-left: spacing(3);

    @include break(desktop-s) {
      padding-left: 0;
      aspect-ratio: 1 / 6;
      overflow-y: auto !important;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__gallery-arrow-up,
  &__gallery-arrow-down {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    width: calc(100% - #{spacing-no-calc(1)});
    height: 96px;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;

    @include break(desktop-s) {
      display: flex;
      justify-content: center;

      &.--is-visible {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__gallery-arrow-up {
    align-items: flex-start;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(white, 1), rgba(white, 0));
  }

  &__gallery-arrow-down {
    align-items: flex-end;
    bottom: 0;
    background-image: linear-gradient(to top, rgba(white, 1), rgba(white, 0));
  }

  .ef-slide {
    padding-right: spacing(1);
    padding-bottom: spacing(1);
  }

  &__gallery-thumb {
    width: 100%;
    padding: spacing(1);
    cursor: pointer;
    border: 1px solid var(--tone-back-600);

    &.--is-current {
      border-color: var(--color-accent);
    }
  }

  &__badges {
    display: grid;
    gap: spacing(1);
    position: absolute;
    top: spacing(3);
    left: spacing(3);
  }

  &__content {
    padding-right: spacing(3);
    padding-left: spacing(3);

    @include break(desktop-s) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__options {
    display: grid;
    gap: spacing(3);
    margin-bottom: spacing(6);
  }

  &__supplier {
    display: flex;
    gap: spacing(3);
    margin-bottom: spacing(6);
  }

  &__supplier-image {
    max-width: spacing(20);
    height: auto;
  }

  &__price-label {
    @include type-scale(500);
    color: var(--color-front);
    font-family: font($font--subline);

    &--reduced {
      color: var(--color-accent);
    }
  }

  &__price-details {
    @include type-scale(300);
  }

  &__old-price {
    text-decoration: line-through;
  }

  &__reduction {
    color: var(--color-accent);
  }

  &__cta {

    .ef-button {
      width: 100%;

      @include break(tablet) {
        width: auto;
      }
    }
  }

  &__infos {
    display: grid;
    gap: spacing(1);
  }

  &__info {
    @include type-scale(300);
  }
}
