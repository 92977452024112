.ef-ticket-logos {

  &__image {
    width: 120px;
    height: 120px;
    vertical-align: bottom;

    .ef-shop-hero &,
    .ef-shop-highlight-card &,
    .ef-content__block--grid-list & {
      width: 60px;
      height: 60px;

      @include break(desktop-s) {
        width: 80px;
        height: 80px;
      }
    }

    .ef-content__block--grid-list & {
      width: 30px;
      height: 30px;
    }

    .ef-product & {
      width: 100%;
      height: auto;
    }
  }
}

// .ef-ticket-logo {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: spacing(9);
//   height: spacing(9);
//
//   &--home {
//     margin-right: spacing(1);
//   }
//
//   &__image {
//     max-width: 100%;
//     max-height: 100%;
//   }
// }
