.ef-hl-media-and-text-2cols {
  @extend %boxed-padding;
  display: flex;
  flex-direction: column;

  @include break(desktop-s) {
    flex-direction: row;
    align-items: center;
  }

  &__description {

    @media (max-width: 989px) {
      order: 1;
    }

    @include break(desktop-s) {
      flex-basis: 50%;
      padding-right: spacing(12);
    }
  }

  &__media {

    @media (max-width: 989px) {
      order: 0;
      margin-bottom: spacing(4);
    }

    @include break(desktop-s) {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  &__media-image {

    &--1 {
      padding-left: spacing(12);
    }

    &--2 {
      position: relative;
      z-index: 1;
      margin-top: spacing(-6);
      padding-right: spacing(4);
    }
  }

  &__video {
    width: 100%;
    height: auto;
  }

  &__image {
    vertical-align: bottom;
  }
}
