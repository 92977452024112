#ef-rts-story{
  @include fill(fixed);
  z-index: 9999998;
}

#ef-rts-story-open{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  cursor: pointer;
}

#ef-rts-story-close{
  position: fixed;
  top: 0;
  right: 0;
  width: spacing(20);
  height: spacing(20);
  z-index: 9999999;
  cursor: pointer;
}

#ef-rts-story,
#ef-rts-story-close,
#ef-rts-story-open{
  &.--is-hidden{
    display: none;
  }
}

