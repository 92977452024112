.ef-gallery-slider {

  @include break(tablet) {
    @include max-width(m);
  }

  @include break(desktop-s) {
    margin-right: auto;
    margin-left: auto;
    @include max-width(l);
  }

  &--dots {
    overflow: hidden !important;
  }
}
