.ef-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  color: var(--color-front);
  @include type-scale(200);
}

.ef-breadcrumb {
  display: block;
  position: relative;

  // &:first-child {
  //   font-weight: 700;
  // }

  &:not(:first-child) {
    padding-left: spacing(4);
  }

  &:not(:first-child):before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: spacing(4);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><polygon fill="#{url-encoded-color($grayscale--medium)}" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8"></polygon></svg>');
    background-position: center center;
    background-repeat: no-repeat;
  }

  &:nth-child(n+4) {
    display: none;
  }
}
