.ef-marquee {
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));

  &__row {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    --speed: '0s';
    transform: translate3d(var(--move-initial), 0, 0);
    animation: b-marquee var(--speed) linear infinite;
  
    &--reverse {
      animation-direction: reverse;
    }
  }
  
  &__item {
    @include type-scale(1000);
    padding: 0 .125ch;
    font-family: font($font--headline);
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
  
    &--filled {
      color: var(--color-front);

      [data-theme-2="gold"] & {
        color: var(--color-accent);
      }
    }
  
    &--outlined {
      color: transparent;
      -webkit-text-stroke: 1px var(--color-front);
      text-stroke: 1px var(--color-front);

      [data-theme-2="gold"] & {
        -webkit-text-stroke-color: var(--color-accent);
        text-stroke-color: var(--color-accent);
      }

      @include break(desktop-s) {
        -webkit-text-stroke-width: 2px;
        text-stroke-width: 2px;
      }
    }
  
    .ef-marquee--l & {
      @include type-scale(1100);
    }
  }
}
