$box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
$border-radius: 0;
$border-dark: 1px solid #555;
$font-color-dark: var(--color-white);
$font-color-dark-deemphasized: var(--dark-gray-300);
$bg-dark: var(--color-white);
$red2: #B24E14;
$red1: #BA1E04;
$yellow1: #F8C200;
$yellow2: #D4D026;
$blue3: #005091;
$blue2: #003F8A;
$blue1: #0885BA;
$blue4: #9AC3D4;
$green1: #00AA3E;
$green2: #006A4E;
$green3: #01440C;

[data-theme-2="dbp"] .klaro .cookie-notice:after {
  width: spacing(40);
  background-image: url(https://design.eintracht.de/assets/img/dbp/logo_dbp.svg);
}

[data-theme-2="s59"] .klaro .cookie-notice:after {
    background-image: url(https://design.eintracht.de/assets/img/s59/s59-logo-black.svg);
}

[data-theme-2="s59"] .cm-btn,
[data-theme-2="s59"] .cm-btn:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.klaro {
  @include fill(fixed);
  z-index: 9999999;
  pointer-events: none;

    .cookie-modal, .cookie-notice {

        @import 'switch.scss';
        font-family: font($font--primary);
        font-weight: 400;

        @include type-scale(300);

        .slider {
            // box-shadow: $box-shadow;
        }

        a {
            text-decoration: underline;
        }

        p, strong, h1, h2, ul, li {
            font-family: inherit;
            color: var(--color-black);
            @include theme(light) {
              color: var(--color-black);
            }
        }

        p, h1, h2, ul, li {
            display: block;
            text-align: left;
            margin: 0;
            // margin-top: 0.7em;
        }

        .cm-link {
            vertical-align: middle;
        }

        .cm-btn {
          width: 100%;
          color: inherit;
          border: 0;
          margin: 0;
          @extend .ef-button;
          @extend .ef-button--primary;

            &:disabled {
                opacity: 0.5;
            }

            &.cm-btn-sm {
                font-size: 1em;
            }

            &.cm-btn-close {
              background: var(--color-black);
              color: var(--color-black);
                @extend .ef-button--secondary;

            }

            &.cm-btn-success {
                background: var(--color-red-400);
            }

            &.cm-btn-info {
                background: var(--color-red-400);
            }

            &.cm-btn-right {
                float: right;
            }
        }
    }

    .cookie-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow: hidden;
        left: 0;
        top: 0;
        z-index: 9999991;
        pointer-events: all;

        p, strong, h1, h2, ul, li {
            color: var(--color-black);
        }

        .cm-bg {
            background: rgba(0,0,0,0.5);
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
        }

        .cm-modal {
            z-index: 9999991;
            box-shadow: $box-shadow;

            @media(min-width: 1024px){
                border-radius: $border-radius;
                position: relative;
                margin: 0 auto;
                max-width: 640px;
                height: auto;
                width: auto;
                max-height: 95%;
            }

            width: 100%;
            max-height: 100%;
            top: 50%;
            transform: translateY(-50%);
            position: fixed;
            overflow: auto;
            background: $bg-dark;
            color: $font-color-dark;

            .hide   {
                border: none;
                background: none;
                svg {
                    stroke: var(--color-black);
                }
                position: absolute;
                top: spacing(4);
                right: spacing(4);
                // Avoid getting overlapped by the heading, if external CSS sets:
                // h1 { position: relative }
                // See: https://github.com/KIProtect/klaro/issues/135
                z-index: 9999991;
            }

            .cm-footer {
              border-top: 1px solid var(--grayscale-200);
              margin-left: spacing(4);
              margin-right: spacing(4);
              padding-bottom: spacing(4);
              padding-top: spacing(4);

              .cm-btn-success {
                color: white;
                font-size: 1em;
                padding: spacing(1) spacing(3) spacing(1) spacing(3);
                @extend .ef-button;
                @extend .ef-button--primary;
                // &:before {
                //   content: "";
                //   padding-right: 20px;
                //   background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
                //   background-repeat: no-repeat;
                //   background-position: left center;
                // }
              }

              .cm-btn-decline {
                color: var(--color-black);
                padding: spacing(1) spacing(3) spacing(1) spacing(3);
                @extend .ef-button;
                @extend .ef-button--secondary;
              }

                &-buttons {
                  display: flex;
                  flex-wrap: wrap;
                  gap: spacing(2);
                }

                .cm-powered-by {
                  display: none!important;
                    text-align: center;

                    a {
                        color: $font-color-dark-deemphasized;
                    }
                }
            }

            .cm-header {
                margin-bottom: spacing(4);
                border-bottom: 1px solid var(--grayscale-200);
                margin-right: spacing(4);
                margin-left: spacing(4);
                padding-top: spacing(2);
                padding-bottom: spacing(4);
                h1 {
                    margin: 0;
                    font-size: 1.3em;
                    display: block;
                }
            }

            .cm-body {


                ul {
                    display: block;
                }
                span {
                    display: inline-block;
                    width: auto;
                }
                ul.cm-apps {
                    margin: 0;
                    margin-left: spacing(4);
                    li.cm-app {
                        &:first-child{
                            margin-top: 0;
                        }

                        position: relative;
                        line-height: 16px;
                        vertical-align: middle;
                        min-height: 40px;

                        .switch {
                            position: absolute;
                            left: 0;
                        }
                        p {
                            margin-top: 0;
                            margin-left: spacing(8);
                        }

                        p.purposes {
                            font-size: 0.8em;
                            color: $font-color-dark-deemphasized;
                            margin-left: spacing(8);
                        }

                        &.cm-toggle-all {
                            border-top: 1px solid var(--grayscale-200);
                            margin-right: spacing(4);
                            padding-top: spacing(4);
                        }

                        span.cm-app-title {
                            font-weight: 600;
                            margin-left: spacing(8);
                        }

                        span.cm-opt-out, span.cm-required {
                            font-size: 0.8em;
                            color: $font-color-dark-deemphasized;
                        }
                    }
                }
            }
        }
    }

    .cookie-notice {
        background: var(--color-white) ;
        color: var(--color-black);
        z-index: 1;
        position: fixed;
        z-index: 1;
        top: auto;
        right: spacing(1.5);
        bottom: spacing(1.5);
        left: spacing(1.5);
        padding: spacing(12) spacing(4) spacing(4);
        box-shadow: 0 0 spacing(8) var(--color-black);
        margin-top: translateY(-50%);
        pointer-events: all;

        @include theme(light) {
          box-shadow: 0 0 spacing(8) rgba($color--black, .25);
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
          background-color: var(--color-accent);
        }

        &:after {
          content: "";
          position: absolute;
          top: spacing(4);
          left: spacing(4);
          width: spacing(28.782226);
          height: spacing(6);
          background-image: url(https://design.eintracht.de/assets/img/general/logo-2022_full_black.png);
          background-repeat: no-repeat;
          background-size: contain;
        }

        @media(min-width: 990px){
          // box-shadow: $box-shadow;
          border-radius: $border-radius;
          position: fixed;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          max-width: 35vw;
          transform: translate(-50%, -50%);
        }

        @media(max-width: 989px){
            border: none;
            border-radius: 0;
        }

        .cn-body {
            margin-bottom:0;
            margin-right: 0;
            bottom: 0;

            p {
                margin-bottom: 0.5em;
            }

            p.cn-changes {
                text-decoration: underline;
            }

            .cn-learn-more {
                display: inline-block;
            }

            p.cn-ok {
                margin: spacing(2) 0 0 0;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                gap: spacing(2);
                // justify-content: flex-end;


                .cm-btn-danger {
                  order: 1;
                  color: white;
                  padding: spacing(1) spacing(3) spacing(1) spacing(3);
                  @extend .ef-button;
                  @extend .ef-button--primary;
                }

                .cm-btn-success {
                  order: 0;
                  color: white;
                  padding: spacing(1) spacing(3) spacing(1) spacing(3);
                  @extend .ef-button;
                  @extend .ef-button--primary;

                  // &:before {
                  //   content: "";
                  //   padding-right: 20px;
                  //   background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{url-encoded-color($color--white)}' d='M11.1,4.6l1.4,1.4L7.6,11L4,7.5l1.4-1.4l2.1,2.1L11.1,4.6z'/%3E%3C/svg%3E");
                  //   background-repeat: no-repeat;
                  //   background-position: left center;
                  // }
                }

                .cm-learn-more {
                  order: 2;
                  text-decoration: none;
                  @extend .ef-link-button;
                  @extend .ef-link-button--more;
                  color: var(--color-black) !important;

                  &:before {
                    background-color: var(--color-black);
                  }

                  &:hover {
                    color: inherit;
                  }
                }
            }
        }

    }

    .cookie-notice-hidden {
        display: none !important;
    }
}

.cm-app {
  margin-bottom: spacing(3)!important;
}
