.ef-touchdown-presenter {
  margin-bottom: spacing(6);
  padding-left: spacing(3);

  @include break(tablet) {
    padding-left: spacing(6);
  }

  .ef-container {
    position: relative;
  }

  .ef-logo-card {
    max-width: spacing(16);

    @include break(desktop-s) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .ef-logo-card__title {
    @include type-scale(200);
    margin-bottom: spacing(1);
    text-align: center;
  }
}

[data-theme-2="touchdown"] {

  --tone-back-800: #242424;

  .ef-touchdown-logo {
    position: absolute;
    z-index: z(hamburger);
    top: spacing(4);
    left: spacing(3);
    width: spacing(18);
    height: spacing(12);
    background-image: url(../img/touchdown/logo.svg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;

    @include break(tablet) {
      left: spacing(6);
      width: spacing(18);
      height: spacing(12);
    }

    &--s {
      width: spacing(12);
      height: spacing(9);

      @include break(tablet) {
        width: spacing(12);
        height: spacing(9);
      }

      @include break(desktop-s) {
        width: spacing(16);
        height: spacing(12);
      }
    }
  }

  // .ef-header {
  //   display: none;
  //   background: transparent;

  //   &__bar {
  //     max-width: none;
  //   }
  // }

  // .ef-hamburger,
  // .ef-header__items--primary,
  .ef-header__partners
  .ef-header__cart,
  .ef-header__search
  {
    display: none !important;
  }

  .ef-header__login,
  .ef-header__account {
    // display: none;
    margin-right: 0;
  }

  .ef-footer {
    position: sticky;
    padding-top: spacing(72);

    &:after {
      content: "";
      position: absolute;
      top: spacing(12);
      right: spacing(9);
      left: spacing(9);
      height: spacing(48);
      background-image: url(../img/touchdown/logo.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__bar {

      @include break(desktop-s) {
        justify-content: center;
      }
    }
  }

  .ef-search {
    z-index: 105;
  }

  .ef-content__block--search-filters {
    padding-top: spacing(6);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: spacing(4);
      background-color: var(--color-black);
    }
  }

  .ef-page {
    padding-top: 0;
  }

  // .ef-article__header,
  .ef-article__body .ef-section:nth-of-type(2) {
    padding-top: spacing(12);
  }

  h1, h2, h3, h4 {
    font-family: font($font--touchdown);
    letter-spacing: normal !important;
  }

  h1 {
    @include type-scale(920);
    max-width: none;
    margin-bottom: spacing(6);
  }

  .ef-article--magazine h1 {
    @include type-scale(920);
    max-width: none;
    // hyphens: auto;
    word-wrap: break-word;
  }

  .ef-fullscreen-hero h1 {
    @include type-scale(920);
  }

  .ef-content__header {
    padding-top: spacing(16);
  }

  .ef-content__header h1 {
    @include type-scale(900);
  }

  h2,
  .ef-article--magazine .ef-article__body h2 {
    @include type-scale(900);
    margin-bottom: spacing(6);
    font-family: font($font--touchdown);
  }

  h3,
  .ef-article--magazine .ef-article__body h3 {
    @include type-scale(800);
    margin-bottom: spacing(6);
    font-family: font($font--touchdown);

    .ef-article-card & {
      @include type-scale(600);
    }
  }

  h4 {
    @include type-scale(700);
  }

  .ef-menu__items-heading {
    @include type-scale(500);
    font-family: font($font--headline-condensed);
  }

  .ef-article--magazine .ef-article__body h4 {
    font-family: font($font--touchdown);
  }

  .ef-kicker {
    @include type-scale(500);
    padding-top: spacing(1);
    padding-bottom: spacing(2);
    padding-left: spacing(2);
    background: url(../img/touchdown/paint-edge-horizontal.png) no-repeat left center;
    background-size: contain;
    font-family: font($font--primary);
    font-weight: 700;
    letter-spacing: normal !important;
    text-transform: none;

    &:before {
      display: none;
    }
  }

  .ef-search-card__kicker {
    color: white;
  }

  .ef-button {
    font-family: font($font--primary);
    font-weight: 700;
    letter-spacing: normal !important;
    text-transform: none;

    &--large {
      @include type-scale(500);
    }

    &--small {
      @include type-scale(400);
    }
  }

  .ef-separator {
    width: 100%;
    height: spacing(4);
    background: url(../img/touchdown/paint-edge-horizontal.png) no-repeat left center;
    background-size: contain;
  }

  .ef-content__block {
    margin-bottom: spacing(9);
  }

  .ef-content__block--image-and-text {

    .ef-container,
    .ef-container__narrow-m {
      max-width: none;
    }
  }

  .ef-image-and-text {
    @extend %reset-boxed-padding;

    &__image {

      @include break(tablet) {
        float: none;
        width: 100%;
        margin: 0;
      }
    }

    &__text {
      @extend %boxed-padding;
      padding-top: spacing(3);
      padding-bottom: spacing(3);
      background: url(../img/touchdown/paint-edge-vertical.svg) no-repeat center;
      background-size: contain;

      @include break(tablet) {
        position: absolute;
        top: spacing(12);
        left: 0;
      }
    }
  }

  .ef-fullscreen-hero {
    // min-height: 56.25vw;
    background-color: var(--tone-back-800);
  }

  .ef-fullscreen-hero__content {
    grid-template-areas:
      "title title title title title"
      "text  text  text  text  text";
    padding-bottom: 0;
  }

  .ef-fullscreen-hero__title {
    max-width: none !important;
  }

  .ef-fullscreen-hero__media {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      z-index: 105;
      top: 0;
      right: 0;
      left: 0;
      height: spacing(42);
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(#000, .7), rgba(#242424, .0001));
    }

    &:after {
      background-image: linear-gradient(to top, #242424, rgba(#242424, .0001));
    }
  }

  .ef-article--magazine .ef-article__header {

    @include break(desktop-s) {
      height: auto;
      max-height: none;
      margin-bottom: spacing(12);
      padding-bottom: spacing(12);
    }
  }

  .ef-article--magazine .ef-article__header .ef-content__block--padded {
    margin-bottom: 0;
  }

  .ef-article--magazine .ef-article__header-title,
  .ef-article--magazine .ef-article__header-intro {

    @include break(desktop-s) {
      width: auto;
    }
  }

  .ef-article--magazine .ef-article__header-media:before {

    @include break(desktop-s) {
      @include fill;
      content: "";
      z-index: 1;
      right: 50%;
      background-image: linear-gradient(to right, black, rgba(black, .0001));
    }
  }

  .ef-article--magazine .ef-article__header-media:after {
    top: 0;
    background-image: linear-gradient(to top, #242424, rgba(#242424, .0001));
  }

  .ef-article--magazine .ef-article__body .ef-copy-block p,
  .ef-article--magazine .ef-article__body .ef-copy-block ul,
  .ef-article--magazine .ef-article__body .ef-copy-block ol,
  .ef-article--magazine .ef-article__body .ef-copy-block .ef-list,
  .ef-article--magazine .ef-article__body .ef-paragraph {
    font-family: font($font--primary);
  }

  .ef-article-card .ef-kicker,
  .ef-magazine-card .ef-kicker {
    @include type-scale(300);
  }

  .ef-article-card .ef-heading--3,
  .ef-magazine-card .ef-heading--3 {
    @include type-scale(700);
    margin-bottom: spacing(2);
  }

  .ef-info-box {
    margin: spacing(-4);
    background-color: rgba(white, .1);

    h1, h2, h3, h4, h5, h6 {
      color: var(--color-white);
    }

    p, ul, strong, a, a:visited, a:hover, a:active {
      color: var(--color-white);
    }
  }

  .ef-grid .ef-info-box {
    margin: 0;
  }

  .ef-teaser-card .ef-heading--2 {
    @include type-scale(700);
  }

  .ef-info-box p {
    @include type-scale(400);
  }

  .klaro .cookie-notice {
    padding: spacing(4);
  }

  .klaro .cookie-notice:after {
    display: none;
  }

  .ef-touchdown-hero-button {
    @include type-scale(400);
    position: absolute;
    z-index: z(hamburger);
    top: spacing(4);
    right: spacing(3);

    @include break(tablet) {
      @include type-scale(500);
      right: spacing(6);
    }

    @include break(desktop-s) {
      right: spacing(9);
    }
  }

  .ef-stadium-ad {
    display: none;
  }

  .ef-404 .ef-paragraph {
    margin-right: auto;
    margin-left: auto;
  }
}
