.ef-switch {

  &:not(:last-child) {
    margin-bottom: spacing(3);
    padding-bottom: spacing(3);
    border-bottom: 1px solid var(--tone-back-600);
  }

  &--s:not(:last-child) {
    margin-bottom: spacing(2);
    padding-bottom: spacing(2);
  }

  &__label {
    @include type-scale(500);
    display: grid;
    grid-template-columns: 1fr spacing(7);
    grid-gap: spacing(4);
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--color-front);
    font-weight: 700;

    .ef-switch--s & {
      @include type-scale(300);
      font-weight: 400;
    }

    &--main {

      .ef-switch--s & {
        font-weight: 700;
      }
    }

    .ef-content--shop &,
    [data-theme-2="shop"] & {
      @include secondary-heading;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .ef-switch__button {
      background-color: var(--color-accent);
    }

    &:checked + .ef-switch__button:after {
      transform: translateX(#{spacing(3)});
    }
  }

  &__button {
    @include transition($property: color);
    position: relative;
    flex-basis: spacing(7);
    width: spacing(7);
    height: spacing(4);
    border-radius: spacing(2);
    background-color: var(--tone-back-600);

    &:after {
      @include transition($property: transform);
      content: "";
      position: absolute;
      top: spacing(.5);
      left: spacing(.5);
      width: spacing(3);
      height: spacing(3);
      border-radius: 50%;
      background-color: var(--color-white);
    }
  }

  &__description {
    @include type-scale(300);
    padding-top: spacing(1);

    @include break(tablet) {
      padding-right: spacing(11);
    }
  }
}
