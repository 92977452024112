.ef-caption {
  padding-top: spacing(1);
  color: var(--tone-400);
  @include max-width(m);
  @include type-scale(300);

  .ef-gallery-card & {
    margin: 0 auto;
    text-align: center;
  }
}
