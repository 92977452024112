.ef-table-slider {
  @include skeleton(8);

  .ef-slider__arrow {
    top: spacing(-1);
    bottom: spacing(1);
  }

  .ef-slide {
    padding-right: 0;
    padding-left: 0;
  }
}
