.ef-hl-text-2cols {
  @extend %boxed-padding;

  @include break(desktop-s) {
    display: flex;
  }

  &__left,
  &__right {
    margin-bottom: spacing(8);

    @include break(desktop-s) {
      flex-basis: 50%;
    }
  }

  &__left {
    @include break(desktop-s) {
      padding-right: spacing(6);
    }
  }

  &__right {
    @include break(desktop-s) {
      padding-left: spacing(6);
    }
  }
}
