.ef-spinner {
  width: 100%;
  height: spacing(6);
  background-repeat: no-repeat;
  background-position: center;
  background-size: spacing(6);
  animation: ef-spinner-logo 1.5s linear infinite;

  &--result {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="196" height="69.85" viewbox="0 0 196 69.85"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M21.62,69.83Q12.86,69.47,7.27,65a28.14,28.14,0,0,1-3-3,18.79,18.79,0,0,1-3.23-5.81c-2.11-6.34-1-14.64,3.11-23.76,3.55-7.81,9-15.55,18.55-26.28C24.12,4.61,28.3,0,28.33,0s-.22.39-.51.88a55.77,55.77,0,0,0-5.75,13.28c-1.86,6.8-1.64,12.63.65,17.15a16.76,16.76,0,0,0,7.33,7.31c5.33,2.61,13.13,2.83,22.66.63.66-.15,33.16-8.78,72.24-19.17S196,1.18,196,1.19s-90.78,38.86-137.91,59c-7.46,3.19-9.46,4-13,5.23C36.15,68.58,28.12,70.08,21.62,69.83Z" /%3E%3C/svg%3E');
  }
}
