.ef-newsflash-grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include break(tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include break(desktop-m) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include break(desktop-xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &--small {
    @extend %ef-max-width-l-center;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include break(tablet) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    // @include break(desktop-xl) {
    //   grid-template-columns: repeat(8, 1fr);
    // }
  }

  &--wide {
    grid-gap: spacing(1);

    @include break(tablet) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @include break(desktop-xl) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &--l {

    @include break(tablet) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @include break(desktop-m) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include break(desktop-xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__item {
    grid-column: span 1;
  }
}
