.ef-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: spacing(4);

  @include break(tablet) {
    grid-template-columns: repeat(8, 1fr);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(12, 1fr);
  }

  &--2 {
    grid-template-columns: 1fr;

    @include break(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--2-from-tablet {
    grid-template-columns: 1fr;

    @include break(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {

    &--1-of-12 {
      grid-column: span 1;
    }

    &--2-of-12 {
      grid-column: span 2;
    }

    &--3-of-12 {
      grid-column: span 2;

      @include break(desktop-s) {
        grid-column: span 3;
      }
    }

    &--4-of-12 {
      grid-column: span 2;

      @include break(tablet) {
        grid-column: span 4;
      }
    }

    &--5-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }

      @include break(desktop-s) {
        grid-column: span 5;
      }
    }

    &--6-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }

      @include break(desktop-s) {
        grid-column: span 6;
      }
    }

    &--7-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }

      @include break(desktop-s) {
        grid-column: span 7;
      }
    }

    &--8-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }
    }

    &--9-of-12 {
      grid-column: span 4;

     @include break(tablet) {
       grid-column: span 8;
     }

     @include break(desktop-s) {
       grid-column: span 9;
     }
    }

    &--10-of-12 {
      grid-column: span 4;

     @include break(tablet) {
       grid-column: span 8;
     }

     @include break(desktop-s) {
       grid-column: span 10;
     }
    }

    &--11-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }

      @include break(desktop-s) {
        grid-column: span 11;
      }
    }

    &--12-of-12 {
      grid-column: span 4;

      @include break(tablet) {
        grid-column: span 8;
      }

      @include break(desktop-s) {
        grid-column: span 12;
      }
    }
  }
}
