.ef-transfers {

  &__header-cell,
  &__cell {

    @include break(desktop-s) {

      &--player {
        width: 25%;
      }

      &--position {
        width: 15%;
      }

      &--age {
        width: 10%;
      }

      &--nationality {
        width: 25%;
      }

      &--club {
        width: 25%;
      }
    }
  }

  &__cell {

    &--player {
      color: var(--color-front);
      //font-weight: 700;
    }

    &--nationality {
      position: relative;
    }
  }

  &__nationality-flag {
    position: absolute;
    top: spacing(1.75);
    left: 0;
    width: 24px;
    height: 24px;
  }

  &__nationality-name {
    padding-left: calc(#{spacing-no-calc(2)} + 24px);
  }
}
