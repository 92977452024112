%ef-max-width-m-center {
  margin-right: auto;
  margin-left: auto;
  padding-right: spacing(34);

  @include max-width(m);
}

%ef-max-width-l-center {
  margin-right: auto;
  margin-left: auto;

  @include max-width(l);

  // .ef-content--shop & {
  //   max-width: none;
  // }
}

%ef-max-width-xl-center {
  margin-right: auto;
  margin-left: auto;

  @include max-width(xl);
}

%ef-max-width-slider {
  max-width: calc(100vw - #{spacing(12)});
  // margin-right: auto;
  // margin-left: auto;
}

%boxed-padding {
  padding-right: spacing(3);
  padding-left: spacing(3);

  @include break(tablet) {
    padding-right: spacing(6);
    padding-left: spacing(6);
  }

  @include break(desktop-s) {
    padding-right: spacing(9);
    padding-left: spacing(9);
  }
}

@mixin boxed-padding {
  padding-right: spacing(3);
  padding-left: spacing(3);

  @include break(tablet) {
    padding-right: spacing(6);
    padding-left: spacing(6);
  }

  @include break(desktop-s) {
    padding-right: spacing(9);
    padding-left: spacing(9);
  }
}

@mixin boxed-margin {
  margin-right: spacing(3);
  margin-left: spacing(3);

  @include break(tablet) {
    margin-right: spacing(6);
    margin-left: spacing(6);
  }

  @include break(desktop-s) {
    margin-right: spacing(9);
    margin-left: spacing(9);
  }
}

%reset-boxed-padding {
  margin-right: spacing(-3);
  margin-left: spacing(-3);

  @include break(tablet) {
    margin-right: spacing(-6);
    margin-left: spacing(-6);
  }

  @include break(desktop-s) {
    margin-right: spacing(-9);
    margin-left: spacing(-9);
  }
}

%boxed-padding-left {
  padding-right: spacing(3);
  padding-left: spacing(3);

  @include break(tablet) {
    padding-right: spacing(4);
    padding-left: spacing(6);
  }

  @include break(desktop-s) {
    padding-right: spacing(4);
    padding-left: spacing(9);
  }
}

%text-container {

  > :first-child {
    padding-top: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}
