.swiper-button {
  &-disabled {
    display: none;
  }

  &-next, &-prev {
    right: 0;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M11.232,31.395l-1.627-1.162L19.771,16L9.605,1.768l1.627-1.162l10.582,14.813 c0.248,0.348,0.248,0.814,0,1.162L11.232,31.395z" /%3E%3C/svg%3E');
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;

    &:after {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  &-prev {
    left: 0;
    right: auto;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M20.768,31.395L10.186,16.581c-0.248-0.348-0.248-0.814,0-1.162L20.768,0.605l1.627,1.162L12.229,16 l10.166,14.232L20.768,31.395z" /%3E%3C/svg%3E');
  }
}
