@keyframes tl-line {
  to {
    transform: scaleY(1);
  }
}

@keyframes tl-event {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// @for $i from 1 through 150 {
//   .ef-timeline__event:nth-child(#{$i}) {
//     order: 150 - $i;
//   }
// }

// .ef-timeline-wrapper {
//   @include row-gap(m);
// }

.ef-timeline {
  position: relative;
  margin-bottom: spacing(3);

  @include break(desktop-s) {
    height: spacing(2.5);
    margin-top: spacing(7);
    margin-bottom: spacing(7);

    &:before,
    &:after {
      @include type-scale(200);
      position: absolute;
      right: calc(100% + #{spacing-no-calc(2)});
      color: var(--tone-medium);
      line-height: 16px !important;
      font-weight: 700;
      text-transform: uppercase;
    }

    &:after {
      content: attr(data-away);
      top: calc(100% + 12px);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    width: 2px;
    border-left: 2px dotted var(--tone-back-400);

    @include break(desktop-s) {
      content: attr(data-home);
      top: auto;
      left: auto;
      bottom: calc(100% + 12px);
      width: auto;
      border: none;
    }
  }

  &__bar,
  &__events {

    @include break(desktop-s) {
      padding: 0 spacing(1) 0;
    }
  }

  &__bar {
    display: none;
    position: relative;
    background-color: var(--tone-back-400);

    @include break(desktop-s) {
      display: block;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   bottom: 0;
      //   left: 50%;
      //   width: 4px;
      //   background-color: var(--color-back);
      // }
    }
  }

  &__progress {
    @include transition($duration: $duration-extra-slow, $delay: .5s, $easing: $ease-in-out-expo);
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-front);
    transform: scaleX(0);
    transform-origin: left;

    .ef-match-header.--is-open & {
      transform: none;
    }

    .-is-live &,
    .--is-live & {
      background-color: var(--color-accent);
    }

    .-is-fulltime &,
    .--is-fulltime & {
      background-color: var(--color-front);
    }

    @include break(desktop-s) {
      display: block;
    }
  }

  &__progress:after {

    .-is-live &,
    .--is-live & {
      content: "";
      position: absolute;
      right: 0;
      top: spacing(-4);
      bottom: spacing(-4);
      width: 2px;
      background-color: var(--color-accent);
      transform: scaleY(0);
      animation: tl-line .4s $ease-out-quad 1s forwards;
    }

  }

  &__markers {
    display: none;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    color: var(--color-white);
    //mix-blend-mode: difference;
    font-weight: 700;
    @include type-scale(200);

    @include break(desktop-s) {
      display: flex;
    }

    .-is-fulltime &,
    .--is-fulltime & {
      color: var(--tone-back-300);
    }
  }

  &__marker {
    flex-basis: spacing(4);

    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    &:last-child {
      text-align: right;
    }
  }

  &__events {
    display: grid;
    grid-template-columns: 1fr spacing(6) 1fr;
    grid-row-gap: spacing(2);

    @include break(desktop-s) {
      display: block;
      @include fill;
    }
  }

  &__kickoff-icon {
    display: flex;
    align-items: center;
    margin-right: spacing(.5);
    color: var(--color-front);
  }

  &__event {
    display: grid;
    grid-gap: spacing(2) spacing(1);

    &--kickoff {
      grid-column: span 3;

      @include break(desktop-s) {
        display: none !important;
      }

      .ef-timeline__event-minute {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--home {
      grid-template-columns: 1fr spacing(6);
      grid-column: 1 / 3;
    }

    &--away {
      grid-template-columns: spacing(6) 1fr;
      grid-column: span 2 / 4;
    }

    @include break(desktop-s) {
      display: flex;
      position: absolute;
      margin-left: -8px;
      cursor: pointer;

      &--home {
        align-items: flex-start;
        bottom: spacing(4.5);
      }

      &--away {
        align-items: flex-end;
        top: spacing(4.5);
      }

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        width: 1px;
        height: spacing(1);
        margin-left: -1px;
        background-color: var(--tone-back-400);
        transform: scaleY(0);
        animation: tl-line .2s $ease-out-quad 1.2s forwards;

        .-is-live &,
        .--is-live & {
          background-color: var(--color-accent);
        }
      }

      &--home:before {
        top: calc(100% + #{spacing-no-calc(0.5)});
        transform-origin: bottom;
      }

      &--away:before {
        bottom: calc(100% + #{spacing-no-calc(0.5)});
        transform-origin: top;
      }
    }
  }

  &__event-minute {
    align-self: start;
    position: relative;
    background-color: var(--tone-back-800);
    color: var(--color-front);
    font-weight: 700;
    text-align: center;
    @include type-scale(200);
    padding-top: spacing(.5);
    padding-bottom: spacing(.5);
    line-height: spacing(2.5);

    @include break(desktop-s) {
      display: none;
    }

    .ef-timeline__event--home & {
      order: 2;
    }

    .ef-timeline__event:last-child & {
      align-self: stretch;
    }
  }

  &__event-icon {
    display: none;
    width: 16px;
    height: 28px;
    color: var(--color-front);

    @include break(desktop-s) {
      display: flex;
      opacity: 0;
      transform: scale(.5);
      animation: tl-event .4s $ease-out-back 1.4s forwards;

      .ef-timeline__event--home & {
        margin-bottom: -12px;
      }

      .ef-timeline__event--away & {
        align-items: flex-end;
        margin-top: -12px;
      }
    }
  }

  &__event-details {
    @include type-scale(200);
    padding-top: .25em;
    line-height: spacing(2.5);
    //text-align: center;

    .ef-timeline__event--home & {
      text-align: right;

      @include break(desktop-s) {
        text-align: center;
      }
    }

    .ef-timeline__event--away & {

      @include break(desktop-s) {
        text-align: center;
      }
    }

    @include break(desktop-s) {
      display: none;
      position: absolute;
      bottom: calc(100% + 12px);
      left: 50%;
      width: spacing(24);
      padding: spacing(1.4) spacing(2) spacing(1);
      background-color: var(--color-back);
      color: var(--color-front);
      transform: translateX(-50%);

      // .ef-timeline__event--home & {
      //   bottom: 100%;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
      //
      // .ef-timeline__event--away & {
      //   top: 100%;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }

      .ef-timeline__event-icon:hover + & {
        display: table;
        animation: tooltip-top-bottom .4s $ease-out-quad forwards;
      }

      // &__item {
      //   display: table;
      // }
    }
  }

  &__event-detail-icon {
    display: grid;
    grid-gap: spacing(1);
    grid-template-columns: 1fr 16px 1fr;
    align-items: center;
    margin-bottom: spacing(1);
    color: var(--color-front);

    .ef-timeline__event--home & {
      grid-template-areas: ". icon line";
    }

    .ef-timeline__event--away & {
      grid-template-areas: "line icon .";
    }

    @include break(desktop-s) {
      display: none;
    }

    .ef-icon {
      grid-area: icon;
    }
  }

  &__event-multiple-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding-top: 2px;
    border-radius: 50%;
    background-color: var(--color-back);
    color: var(--color-front);
    font-weight: 700;
    @include type-scale(200);
  }

  &__event-detail-icon-line {
    grid-area: line;
    height: 1px;
    background-color: var(--tone-back-600);

    .-is-live &,
    .--is-live & {
      background-color: var(--color-accent);
    }
  }

  &__event-detail-minute {
    display: none;
    font-weight: 700;

    @include break(desktop-s) {
      display: inline;
    }
  }

  &__event-detail-text {
    color: var(--tone-front-300);

    @include break(desktop-s) {
      color: var(--color-front);
    }
  }

  // &__event-detail-text {
  //
  //   &:not(:first-child) {
  //
  //     &:before {
  //       content: "/";
  //       padding-right: spacing(1);
  //       padding-left: spacing(1);
  //       opacity: .5;
  //       color: var(--color-back);
  //     }
  //   }
  // }
}
