.ef-floating-gallery {
  display: grid;

  @include break(tablet) {
    grid-template-columns: repeat(8, 1fr);
    align-items: end;
    column-gap: spacing(1);
    row-gap: spacing(16);
    padding-bottom: spacing(6);
  }

  @include break(desktop-s) {
    grid-template-columns: repeat(12, 1fr);
  }

  &__item {
    margin-bottom: spacing(12);

    &--offset {
      margin-bottom: 0;
    }
    
    @include break(tablet) {
      margin-bottom: 0;

      &--1 {
        grid-column: span 1;
      }

      &--2 {
        grid-column: span 2;
      }

      &--3 {
        grid-column: span 3;
      }

      &--4 {
        grid-column: span 4;
      }

      &--5 {
        grid-column: span 5;
      }

      &--6  {
        grid-column: span 6;
      }

      &--7 {
        grid-column: span 7;
      }

      &--8 {
        grid-column: span 8;
      }

      &--9 {
        grid-column: span 9;
      }

      &--10 {
        grid-column: span 10;
      }

      &--11 {
        grid-column: span 11;
      }

      &--offset {
        margin-bottom: 0;
      }
    }

    @include break(desktop-s) {
      margin-bottom: spacing(24);

      &--5 {
        margin-bottom: 0;
      }
    } 
  }

  .ef-paragraph {
    margin-bottom: spacing(12);
  }
}
