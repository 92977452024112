mycrocast-sticky-footer-player {
  position: fixed;
  z-index: z(fm-live);
  bottom: 0;
  left: 0;
}

mycrocast-sticky-footer-player {
  --mcc-player-background-color: var(--tone-back-750);
  --mcc-player-font-color: var(--color-front);

  --mcc-player-overlay-color: var(--tone-back-750);  
  --mcc-player-overlay-font-color: var(--color-front);

  --mcc-player-button-disabled-color: var(--tone-back-400);

  --mcc-player-delay-bar-background: var(--color-red-400);
  --mcc-player-delay-bar-buffer: var(--color-red-500);  
  --mcc-player-delay-bar-current: var(--color-red-500);
}

mycrocast-sticky-footer-player::part(mcc-player-initial-button) {
  @include type-scale(400);
  justify-content: center;
  gap: spacing(1);
  width: 50vw;
  padding: spacing(2) spacing(3);
  box-shadow: none;
  border-radius: 0;
  background-color: var(--color-red-400);
  color: var(--color-white);
  font-weight: 400;

  @include break(phablet) {
    width: auto;
  }
}

mycrocast-sticky-footer-player::part(mcc-player-initial-button-title) { 
  position: relative; 
  color: var(--color-white);
}

mycrocast-sticky-footer-player::part(mcc-player-initial-button-live-dot) { 
  --color: var(--color-white);
}

mycrocast-sticky-footer-player::part(mcc-player-content-wrapper) {
  @extend %boxed-padding;
  gap: spacing(2);
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  color: var(--color-front);
  font-family: font($font--primary);

  @include break(desktop-s) {
    gap: spacing(1);
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  @include theme(dark) {
    box-shadow: 0 0 spacing(8) spacing(2) rgba($color--black, .3);
  }

  @include theme(light) {
    box-shadow: 0 0 spacing(8) spacing(2) rgba($color--black, .1);
    background-color: var(--color-white);
  }
}

mycrocast-sticky-footer-player::part(mcc-player-volume-button) {  
  display: none;
  
  @include break(desktop-s) {
    position: absolute;
    top: spacing(2);
    left: 0;
  }
}

mycrocast-sticky-footer-player::part(mcc-player-volume-slider) {  
  --color: var(--color-red-400);
  border: none;
  background-color: var(--tone-back-700);
}

mycrocast-sticky-footer-player::part(mcc-player-initial-button-title) {  
  font-family: font($font--primary);
}

mycrocast-sticky-footer-player::part(mcc-stream-language) {  
  display: none;
}

mycrocast-sticky-footer-player::part(mcc-stream-title) {
  @include type-scale(400);
  position: absolute;
  top: spacing(4);
  right: spacing(3);
  left: spacing(3);
  height: spacing(5);
  // background-color: var(--tone-back-750);
  font-weight: 700;

  @include break(desktop-s) {
    position: absolute;
    top: auto;
    right: spacing(9);
    bottom: spacing(2);
    left: spacing(9);
    height: auto;
    margin-left: 0;
    line-height: 48px;
  }
}

mycrocast-sticky-footer-player::part(mcc-player-next-stream-button) {  
  display: none;
}

mycrocast-sticky-footer-player::part(mcc-delay-current) {  
  @include type-scale(300);
  width: spacing(6);
}

mycrocast-sticky-footer-player::part(mcc-delay-bar-wrapper) {
  background-color: var(--color-red-600);
}

mycrocast-sticky-footer-player::part(mcc-delay-bar-buffered) {
  background-color: var(--color-red-400);
}

mycrocast-sticky-footer-player::part(mcc-delay-bar-current) {
  background-color: var(--color-front);
}

mycrocast-sticky-footer-player::part(mcc-minimize-player-button) {  
  @include type-scale(400);
  position: relative;
}

mycrocast-sticky-footer-player::part(mcc-message-info) { 
  @include type-scale(300);
  bottom: 100%;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  background: var(--tone-back-700);
  color: var(--color-front);
  font-weight: 400;
}

// Floating button player

mycrocast-floating-button-player {
  position: fixed;
  z-index: z(snackbar);
  bottom: spacing(2);
  left: spacing(2);
}

mycrocast-floating-button-player {
  --mcc-player-background-color: var(--tone-back-750);
  --mcc-player-font-color: var(--color-front);
  --mcc-player-delay-bar-background: var(--color-red-400);
  --mcc-player-delay-bar-buffer: var(--color-red-500);  
  --mcc-player-delay-bar-current: var(--color-red-500);
}

mycrocast-floating-button-player::part(mcc-player-initial-button) {
  @include transition($property: transform);
  width: 48px;
  height: 48px;
  box-shadow: none;
  background-color: var(--color-red-400);
  color: var(--color-white);

  @include hover {
    transform: scale(1.2);
  }
}

mycrocast-floating-button-player::part(mcc-player-content-wrapper) {
  @include type-scale(400);
  // @extend %boxed-padding;
  // display: grid;
  // grid-template-columns: 80px minmax(0, 1fr);
  // gap: spacing(4);
  top: auto !important;
  right: auto !important;
  bottom: spacing(2) !important;
  left: spacing(2) !important;
  width: auto;
  width: spacing(32);
  padding: spacing(4);
  // padding-top: spacing(3);
  // padding-bottom: spacing(3);
  border: none;
  border-radius: 0;
  font-family: font($font--primary) !important;
  transform: none !important;

  @include theme(dark) {
    box-shadow: 0 0 spacing(8) spacing(2) rgba($color--black, .3);
  }

  @include theme(light) {
    box-shadow: 0 0 spacing(8) spacing(2) rgba($color--black, .1);
    background-color: var(--color-white);
  }
}

mycrocast-floating-button-player::part(mcc-stream-title) {
  color: var(--color-front);
}

mycrocast-floating-button-player::part(app-club-logo) {
  display: none;
}

