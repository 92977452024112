@keyframes show-user-area {
  0% {
    transform: translateX(100%);
  } 100% {
    transform: none;
  }
}

.ef-account {
  display: none;
  position: fixed;
  //top: calc(#{spacing-no-calc(10)} + 4px);
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: z(overlay);
  pointer-events: auto;
  background-color: rgba($color--black, .7);

  &.--is-active {
    display: flex;
    justify-content: flex-end;
    animation: ef-fade-in .3s ease-in-out forwards;
  }

  &__close {
    position: fixed;
    top: spacing(2);
    right: spacing(2);
    z-index: 2;
    color: var(--color-front);
  }

  &__content {
    @extend %boxed-padding;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--color-back);

    @include break(tablet) {
      width: spacing(96);
    }

    &.--is-active {
      animation: show-user-area .3s $ease-in-out-expo forwards;
    }
  }

  &__inner {
    flex: 1;
    width: 100%;
    padding-top: spacing(6);
  }

  &__header {
    margin-bottom: spacing(6);
    //text-align: center;
  }

  &__header-title {
    display: grid;
    gap: spacing(2);
    margin-bottom: spacing(6);

    @include break(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ef-heading {
      margin-bottom: 0;
    }
  }

  &__header-options {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(1);
  }

  &__section {
    margin-bottom: spacing(6);
  }

  .ef-kicker {
    margin-bottom: spacing(2);
  }

  &__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: spacing(1);
  }

  &__grid-item {
    grid-column: span 1;
  }

  &__actions {
    @extend %boxed-padding;
    @extend %reset-boxed-padding;
    // display: grid;
    // gap: spacing(2);
    // position: sticky;
    // z-index: 2;
    // bottom: 0;
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    background-color: var(--tone-back-750);
  }

  &__list {
    @include type-scale(300);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: spacing(1);

    @include break(phablet) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__list-item {
    @include transition($property: border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: spacing(2);
    padding-bottom: spacing(1);
    cursor: pointer;
    border-bottom: 1px solid var(--tone-back-600);
    color: var(--color-front);

    &:visited {
      color: var(--color-front);
    }

    &:hover {
      border-color: var(--color-accent);
    }
  }
}

@keyframes slide-fade-right {
  0% {
    opacity: 0;
    transform: translateX(24px);
  } 100% {
    opacity: 1;
    transform: none;
  }
}
