@keyframes fm-pulse {
  0% {
    transform: scale(.75);
  } 100% {
      transform: scale(1);
    }
}

.ef-header__fm {
  display: flex;
  align-items: center;
  transform: scale(1);
  @include transition;

  &.--is-muted {
    //animation: fm-pulse 1s ease-in-out alternate-reverse infinite;
    // animation-delay: 6s;
  }

  .ef-button {
    @include transition($easing: $ease-out-back);
    display: flex;
    justify-content: left;
    position: relative;
    z-index: 1;
    max-width: 48px;
    height: 48px;
    padding-left: 10px;
    cursor: pointer;
    border-radius: 24px;
    animation: maxw 6s ease 1s;

    &:hover {
      transform: scale(1.1) !important;
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    &__label {
      display: inline-block;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      animation: reveal 6s ease 1s;
      color: var(--color-white);
      padding-left: 0;
      @include type-scale(300);
      font-weight: 700;
    }

    .ef-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes maxw {
  0% { max-width: 48px; }
  25% { max-width: 240px; }
  75% { max-width: 240px; }
  100% { max-width: 48px; }
}

@keyframes reveal {
  0% { width: 0; padding-left: 0; }
  25% { width: 100%; padding-left: spacing(1); }
  75% { width: 100%; padding-left: spacing(1); }
  100% { width: 0; padding-left: 0; }
}
