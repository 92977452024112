.ef-product-card {
  @extend %card;
  @extend %card--has-line;
  overflow: visible;

  &--featured {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--sold-out {

    .ef-product-card__media,
    .ef-product-card__text {
      @include transition;
      opacity: .4;
    }

    @include break(desktop-s) {

      &:hover .ef-product-card__media,
      &:hover .ef-product-card__text {
        opacity: 1;
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--ad {
    @include ad;
  }

  &--search {
    display: flex;
    flex-direction: column;
    padding-right: spacing(3);
    padding-left: spacing(3);
  }

  &__banner {
    @include ad-banner(320, 480);
  }

  &__media {
    @extend .ef-media--1x1;
    @include transition;
    overflow: visible;
    background-color: transparent;
    transform: scale(.95);
    @include reset-image-filter;

    @include break(desktop-s) {

      .ef-product-card--branding:hover & {
        background-color: var(--tone-back-800);
      }
    }

    .ef-product-card--featured & {
      padding-bottom: 120%;
      overflow: hidden;
      transform: scale(1);
    }
  }

  &__image {
    @include transition;

    &:not(:first-of-type) {
      opacity: 0;
    }
  }

  @include break(desktop-s) {

    &:hover {

      .ef-product-card__image {
        transform: scale(1.05);
      }

      .ef-product-card__image:first-child:not(:last-of-type) {
        opacity: 0;
        //transition-delay: .3s;
      }

      .ef-product-card__image:nth-of-type(2) {
        opacity: 1;
        transform: scale(1.05);
      }

      .ef-heading {
        color: var(--color-accent);
      }
    }

    &--branding:hover {

      .ef-product-card__image:not(:last-of-type) {
        opacity: 1;
      }

      .ef-product-card__image:nth-of-type(2) {
        opacity: 0;
      }
    }

    &--featured:hover {

      .ef-product-card__image {
        transform: scale(1.025);
      }
    }
  }

  &__text {
    @extend %text-container;
    padding: spacing(2);

    .ef-product-card--search & {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .ef-heading {
    margin-bottom: 0;
    @include transition($duration-faster, $property: color);
  }

  .ef-paragraph {
    margin-bottom: 0;
  }

  .ef-breadcrumbs {
    justify-content: center;
    padding-top: spacing(2);
  }

  &__badges {
    position: absolute;
    right: spacing(2);
    bottom: spacing(2);
  }

  .ef-shop-badge:not(:last-child) {
    margin-bottom: spacing(1);
  }

  &__labels {
    position: absolute;
    top: spacing(2);
    left: spacing(2);
    padding-right: calc(16px + #{spacing-no-calc(3)});
  }

  &__sold-out-label {
    @include type-scale(300);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: spacing(1.5) spacing(2);
    background-color: var(--color-front);
    color: var(--color-back);
    font-weight: 700;
    text-align: center;
  }

  .ef-shop-label:not(:last-child) {
    margin-bottom: spacing(1);
  }

  .ef-bookmark {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
  }
}
