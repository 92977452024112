.ef-order-service-cta {
  @extend %boxed-padding;
  position: relative;
  padding-top: spacing(8);
  padding-bottom: spacing(8);
  margin-top: spacing(10);
  margin-bottom: spacing(10);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:after {
    @include fill;
    content: "";
    background-color: var(--tone-back-800);
  }

  &__text {

    @include break(desktop-s) {
      flex: 1;
      padding-right: spacing(8);
    }
  }

  &__action {
    @include ie11 {
    }
  }

  .ef-container {
    position: relative;
    z-index: 1;

    @include break(desktop-s) {
      display: flex;
      align-items: center;
    }
  }

  &__heading {
    color: var(--color-black);
  }

  .ef-paragraph {
    color: var(--color-black);
  }

  .ef-text {
    @include break(desktop-s, true) {
      margin-bottom: spacing(4);
    }
  }

  .ef-form-item__field {
    @include type-scale(300);
    background-color: var(--color-white);
    color: var(--color-black);
  }
}
