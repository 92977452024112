.ef-info-box {
  padding: spacing(6);
  height: 100%;

  @include ie11 {
    margin-right: spacing(4);
  }

  @include theme(dark) {
    background-color: var(--tone-back-750);
  }

  @include theme(light) {
    background-color: var(--tone-back-800);
  }

  &--form {
    margin-bottom: spacing(3);
  }

  .ef-content__block--secondary & {
    padding: 0;
  }

  h6 {
    @extend .ef-kicker;
    font-weight: 400;
  }

  p, ul {
    @extend .ef-text--s;
  }

}
