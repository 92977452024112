.ef-login {
  display: flex;
  width: 100%;
  height: 100vh;

  &--proficamp {
    position: relative;

    &:after {
      @include fill;
      content: "";
      bottom: auto;
      height: spacing(48);
      background-image: linear-gradient(to bottom, rgba(black, .4), rgba(black, .0001));
    }
  }

  &__content {
    @extend %boxed-padding;
    @extend %ef-max-width-m-center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    padding-top: calc(#{spacing-no-calc(10)} + 4px);
    //margin-right: auto;
    //margin-left: auto;
    background-color: var(--color-back);

    @include break(desktop-s) {
      flex-basis: spacing(80);
      padding-top: 0;
    }
  }

  &__inner {
    width: 100%;
  }

  &__title {
    margin-bottom: spacing(4);
  }

  &__infos {
    margin-bottom: spacing(4);
  }

  &__info {

    &:not(:last-child) {
      margin-bottom: spacing(2);
    }

    .ef-paragraph {
      margin-bottom: 0;
    }
  }

  &__spacer {
    width: spacing(2);
  }

  .ef-heading--4,
  .ef-heading--6,
  .ef-kicker {
    margin-bottom: 0;
  }

  .ef-heading--5 {
    padding-top: 0;
    margin-bottom: 0;
  }

  // .ef-list__item {
  //   @include type-scale(300);
  // }

  .ef-form-item {
    margin-bottom: spacing(2);

    &:nth-child(2) {
      margin-bottom: 0;
    }

    &__check-label {
      @include type-scale(300);
      padding-top: 4px;

      @include break(tablet) {
        padding-top: 3px;
      }

      @include break(desktop-s) {
        padding-top: 2px;
      }

      @include break(desktop-s) {
        padding-top: 1px;
      }
    }
  }

  .ef-form-submit {
    display: flex;
    //padding-top: spacing(2);

    &--stacked {
      display: block;

      .ef-button {
        display: block;
        width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          margin-right: spacing(1);
        }
      }
    }

    .ef-button {
      flex: 1;

      &:not(:last-child) {
        margin-right: spacing(1);
      }
    }
  }

  &__form {
    margin-bottom: spacing(4);
  }

  &__text-links {
    display: flex;
    justify-content: space-around;
  }

  &__verimi {
    margin-top: spacing(4);
    padding-top: spacing(4);
    border-top: 1px solid var(--tone-back-600);
    color: var(--color-front);
  }

  &__verimi-button {
    display: block;
    width: rem(256);
  }

  &__branding {
    display: none;
    flex: 1;
    position: relative;
    // opacity: .3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // @include fill;

    // &:after {
    //   @include fill;
    //   content: "";
    //   background-color: rgba($color--black, .6);
    // }

    @include break(desktop-s) {
      display: block;
      position: relative;
      opacity: 1;
    }
  }

  &__close {
    position: fixed;
    top: spacing(2);
    right: spacing(2);
    z-index: 2;
    color: var(--color-black);
  }
}
