.ef-status-info {
  @include type-scale(300);
  position: relative;
  padding-left: spacing(3);

  &:before {
    content: "";
    position: absolute;
    top: spacing(.625);
    left: 0;
    width: spacing(2);
    height: spacing(2);
    border-radius: 50%;
  }

  &--success:before {
    background-color: var(--color-green);
  }

  &--warning:before {
    background-color: var(--color-yellow);
  }

  &--alert:before {
    background-color: var(--color-red-400);
  }
}
