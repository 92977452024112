.ef-card-grid {
  display: grid;
  grid-column-gap: spacing(1);
  grid-row-gap: spacing(4);

  @include ie11() {
    display: flex;
    flex-wrap: wrap;
    padding-left: 3%;
  }

  &__item {
    display: flex;

    @include ie11() {
      flex-basis: 24%;
      padding: spacing(0.5);
    }
  }
}
