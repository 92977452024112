.ef-legend {
  display: grid;
  gap: spacing(1);

  &:not(:first-child) {
    padding-top: spacing(3);
  }

  &__item {
    @include type-scale(300);
    position: relative;
    padding-left: spacing(3);
  }

  &__item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: spacing(2);
    height: spacing(2);
    border-radius: 50%;
    background-color: var(--tone-back-600);
  }

  &__item--success:before {
    background-color: var(--color-green);
  }

  &__item--success-alternate:before {
    background-color: var(--color-green-tint);
  }
}
