.ef-footer {
  @extend %boxed-padding;
  padding-top: spacing(12);
  opacity: 1;
  border-bottom: 8px solid var(--color-accent);
  background-color: var(--tone-back-750);
  color: var(--tone-front-300);
  transition: .3s opacity ease-in-out;

  @include break(tablet) {
    position: sticky;
    z-index: z(footer);
    bottom: 0;

    &--shop {
      position: relative;
    }
  }

  @include theme(light) {
    background-color: var(--tone-back-800);
  }

  .--footer-nearby &,
  .ef-page--forum & {
    opacity: 1;
  }

  &--unstick {
    position: static;
  }

  .ef-container--l {
    max-width: none;
    margin: 0;
  }

  &__section,
  &__block,
  &__world {
    padding-bottom: spacing(8);

    // &--contact {
    //   position: relative;
    // }
    //
    // &--contact:before {
    //   content: "";
    //   position: absolute;
    //   top: spacing(-6);
    //   left: 0;
    //   width: 100%;
    //   height: spacing(1);
    //   background-color: var(--color-accent);
    // }

    &--partners,
    &--logos,
    &--grid {
      padding-bottom: spacing(2);
    }

    &--claim-women {
      text-align: center;
    }
  }

  &__visual {
    @extend %reset-boxed-padding;
    padding-bottom: spacing(8);
  }

  &__claim {
    color: transparent;
    font-family: font($font--headline);
    text-align: center;
  }

  &__claim-topline {
    @include type-scale(900);
    color: var(--color-front);
  }

  &__claim-headline {
    @include type-scale(950);
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--color-front);
  }

  &__brands {
    display: grid;
    gap: spacing(2);
    margin-bottom: spacing(6);

    &--primary {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @include break(tablet) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include break(desktop-s) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    &--secondary {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @include break(tablet) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @include break(desktop-s) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  &__brand {
    @extend .ef-media;
    @extend .ef-media--3x1;
    border: 1px solid var(--color-front);
    border-radius: 12px;
  }

  &__brand-image {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    bottom: spacing(2);
    left: spacing(2);
    width: calc(100% - #{spacing-no-calc(4)});
    height: calc(100% - #{spacing-no-calc(4)});
    object-fit: contain;

    .ef-footer__brands--secondary & {
      top: spacing(1);
      right: spacing(1);
      bottom: spacing(1);
      left: spacing(1);
      width: calc(100% - #{spacing-no-calc(2)});
      height: calc(100% - #{spacing-no-calc(2)});

      @include break(tablet) {
        top: spacing(1.5);
        right: spacing(1.5);
        bottom: spacing(1.5);
        left: spacing(1.5);
        width: calc(100% - #{spacing-no-calc(3)});
        height: calc(100% - #{spacing-no-calc(3)});
      }
    }
  }

  &__contact-grid {
    text-align: center;

    .ef-footer--shop & {
      text-align: left;
    }

    @include break(tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: spacing(4);
      text-align: left;
      @include ie11() {
        display: flex;
        align-items: space-between;
        justify-content: space-between;
      }
    }
  }

  &__contact-grid-item {
    grid-column: span 1;
    @include type-scale(300);

    &:not(:last-child) {
      //grid-column: span 2;
      margin-bottom: spacing(4);

      @include break(tablet) {
        margin-bottom: 0;
      }
    }
  }

  &__dbp-logo {
    margin-bottom: spacing(2);
    padding-right: spacing(8);
    padding-left: spacing(8);

    @include break(tablet) {
      padding-right: spacing(4);
      padding-left: 0;
    }
  }

  &__address {
    margin-bottom: spacing(2);
  }

  &__social-links {
    display: flex;
    justify-content: center;

    .ef-footer--shop & {
      justify-content: flex-start;
    }

    @include break(tablet) {
      justify-content: flex-start;
    }
  }

  &__social-link {

    &:not(:last-child) {
      margin-right: spacing(4);
    }
  }

  &__bar {
    @include type-scale(300);

    @include break(desktop-s) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__menu,
  &__languages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .ef-footer--shop & {
      justify-content: flex-start;
    }

    @include break(desktop-s) {
      justify-content: flex-start;
    }
  }

  &__menu {
    margin-bottom: spacing(4);

    @include break(desktop-s) {
      margin-bottom: 0;
    }
  }

  &__menu-item,
  &__language {

    &:not(:last-child) {
      margin-right: spacing(4);
    }
  }

  &__social-link,
  &__menu-link,
  &__language-link {
    @include transition($property: color);

    &:hover {
      color: var(--color-front);
    }
  }

  &__copyright {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: spacing(2);
    text-align: center;

    .ef-footer--shop & {
      text-align: left;
    }

    @include break(tablet) {
      flex-basis: auto;
      margin-right: spacing(4);
      margin-bottom: 0;
    }
  }

  &__claim-women {
    width: 75vw;
    height: 28vw;

    @include break(phablet) {
      width: spacing(48);
      height: spacing(18);
    }
  }
}
