@keyframes ef-show-cart-wrapper {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

@keyframes ef-show-cart {
  100% {
    transform: translateX(0);
  }
}

.ef-cart-wrapper {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  opacity: 0;
  pointer-events: auto;

  @include break(tablet) {
    background-color: rgba($color--black, .7);
  }

  &.--is-active {
    display: block;
    animation: ef-show-cart-wrapper .3s ease-in-out forwards;
  }
}

.ef-cart {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  width: 100%;
  max-width: spacing(72);
  background-color: var(--color-white);
  transform: translateX(100%);

  .ef-cart-wrapper.--is-active & {
    animation: ef-show-cart .3s $ease-in-out-expo forwards;
  }

  &__inner {
    @extend %boxed-padding;
    flex: 1;
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    overflow-y: scroll;

    .ef-cart--empty & {
      display: flex;
      flex-direction: column;
    }
  }

  &__heading {
    margin-bottom: spacing(4);
  }

  &__close {
    @include transition($easing: $ease-out-back);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: spacing(4);
    right: spacing(4);
    width: spacing(4);
    height: spacing(4);
    cursor: pointer;
    color: var(--color-front);

    &:hover {
      transform: scale(1.25);
    }
  }

  &__content {

    .ef-cart--empty & {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: spacing(4);
    }
  }

  &__empty-state {
    text-align: center;
  }

  &__empty-state-icon {
    width: 166px;
    height: 166px;
    margin-bottom: spacing(4);
  }

  &__checkout {
    @extend %reset-boxed-padding;
    @extend %boxed-padding;
    @include row-gap(m);
    margin-bottom: spacing(4);
    background-color: var(--tone-back-800);
  }

  &__footer {
    @extend %boxed-padding;
    position: relative;
    padding-top: spacing(2);
    padding-bottom: spacing(4);

    // &:before {
    //   content: "";
    //   position: absolute;
    //   right: 0;
    //   bottom: 100%;
    //   left: 0;
    //   height: spacing(4);
    //   background-image: linear-gradient(to top, $color--white, rgba($color--white, .0001));
    // }
  }

  .ef-cta-group__buttons {
    // @extend %boxed-padding;
    // @include row-gap(m);
    // position: fixed;
    // bottom: 0;
    // right: 0;
    // left: 0;
    //background-color: var(--color-back);
  }
}
