@keyframes story-reveal {
  0% {
    opacity: 0;
    transform: scale(.9);
  } 100% {
    opacity: 1;
    transform: none;
  }
}

.ef-story {
  @include fill(fixed);
  display: flex;
  flex-direction: column;
  z-index: z(story);
  z-index: 9999999; // Check hamburger z-index
  overflow: hidden;
  background-color: var(--tone-back-800);

  &.--is-hidden {
    display: none;
  }

  &.--is-visible {
    display: block;
    opacity: 0;
    transform: scale(.9);
    animation: story-reveal .5s $ease-out-back forwards;
  }

  &--special .ef-story__media:after {
    content: "";
    position: absolute;
    top: 33.333333%;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, $color--black, rgba($color--black, .001));

    @include break(desktop-s) {
      top: 50%;
    }
  }

  &--special .ef-story__media img {
    animation: hero-ken-burns 19s ease-in-out forwards;
  }

  &--special .ef-kicker,
  &--special .ef-heading--1,
  &--special .ef-heading--3,
  &--special .ef-button {
    opacity: 0;
    transform: translateY(24px);
    animation: ef-slide-fade-in-btt .7s ease-in-out forwards;
  }

  &--special .ef-kicker {
    animation-delay: .5s;
  }

  &--special .ef-heading--1 {
    animation-delay: .8s;
  }

  &--special .ef-heading--3 {
    animation-delay: 1.1s;
  }

  &--special .ef-button {
    animation-delay: 1.4s;
  }

  &--special .ef-button:not(:first-child) {
    animation-delay: 1.7s;
  }
}

.ef-story__header,
.ef-story__footer {
  @extend %boxed-padding;
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
}

.ef-story__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  top: 0;
  padding-top: calc(#{spacing-no-calc(3)} + 4px);
  padding-bottom: spacing(6);
  background-image: linear-gradient(to bottom, black, rgba(black, .001));
}

.ef-story__footer {
  bottom: 0;
  padding-top: spacing(6);
  padding-bottom: spacing(3);
  background-image: linear-gradient(to top, black, rgba(black, .001));

  .ef-story--special & {
    background: transparent;
  }
}

.ef-story__steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-column-gap: 4px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@keyframes story-step-current {
  from { width: 0; } to { width: 100%; }
}

.ef-story__step {
  height: 8px;
  background-color: var(--tone-back-600);

  &.--is-complete {
    background-color: var(--color-accent);
  }

  &.--is-current {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0;
      background-color: var(--color-accent);
      animation: story-step-current 15s linear forwards;
    }
  }
}

.ef-story__title {
  @include type-scale(400);
  padding-right: calc(#{spacing-no-calc(8)} + 16px);
  color: var(--color-front);
  font-family: font($font--subline);
}

.ef-story__close {
  @include transition($property: transform);
  display: flex;
  align-items: center;
  cursor: pointer;

  @include break(desktop-s) {

    &:hover {
      transform: scale(1.2);
    }
  }
}

.ef-story__chapter {
  @extend %boxed-padding;
  @include fill;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: spacing(13);

  &.--is-active {
    display: flex;
  }
}

.ef-story__trophy {
  position: absolute;
  top: 0;
  right: spacing(-8);
  bottom: 0;
  z-index: 1;
  width: auto;
  height: 100%;
  opacity: .15;
  pointer-events: none;

  @include break(tablet) {
    right: 0;
  }
}

.ef-story__main {
  position: relative;
  z-index: 10;
}

.ef-story__main-title {
  margin-bottom: spacing(6);

  .ef-heading {
    @include max-width(l);
  }

  .ef-heading--1 {
    @include type-scale(900);
    hyphens: none;
  }
}

.ef-story__main-buttons {

  .ef-button {
    display: block;

    @include break(tablet) {
      display: inline-block;
    }
  }

  .ef-button:not(:last-child) {
    margin-bottom: spacing(3);

    @include break(tablet) {
      margin-right: spacing(3);
      margin-bottom: 0;
    }
  }
}

.ef-story__match-logos {
  display: grid;
  grid-template-columns: spacing(6) spacing(9) spacing(6);
  grid-template-areas: "home result away";
  grid-gap: spacing(1);
  align-items: center;
  margin-bottom: spacing(6);

  @include break(desktop-s) {
    grid-template-columns: spacing(8) spacing(8);
    grid-template-areas:
      "home away"
      "result result";
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }
}

.ef-story__match-logo {
  width: spacing(6);
  height: spacing(6);
  object-fit: contain;
  object-position: center;

  @include break(desktop-s) {
    width: spacing(8);
    height: spacing(8);
  }

  &:first-child {
    grid-area: home;
  }

  &:last-child {
    grid-area: away;
  }
}

.ef-story__match-result {
  @include type-scale(700);
  grid-area: result;
  color: var(--color-white);
  font-family: font($font--headline);
  text-align: center;
}

.ef-story__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: calc(#{spacing-no-calc(13)} + 4px) 0 spacing(16);

  .ef-story--fullscreen & {
    justify-content: space-between;
  }
}

.ef-story__headline,
.ef-story__caption {
  @extend %boxed-padding;
  position: relative;
}

.ef-story__headline {

  .ef-heading {
    padding-top: 0;
  }
}

.ef-story__cta {
  @extend %boxed-padding;
  position: absolute;
  z-index: 2;
  bottom: spacing(10);
  left: 0;
  width: 100%;
  text-align: center;

  .ef-button {
    display: block;

    @include break(tablet) {
      display: inline-block;
    }
  }
}

.ef-story__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ef-story__pages {
  @include type-scale(300);
  padding-right: spacing(4);
  padding-left: spacing(4);
}

.ef-story__arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ef-story__media {

  &--boxed {
    @extend %boxed-padding;

    .ef-story--article & {
      margin-bottom: spacing(3);
    }

    > .ef-container {
      display: flex;
      justify-content: center;
    }

    img,
    video {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &--full-width {
    @include fill;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.ef-story__text {
  @extend %boxed-padding;

  .ef-heading--1 {
    @include type-scale(900);
  }

  .ef-paragraph {
    @include type-scale(700);
  }
}

.ef-story__embed {
  @extend %boxed-padding;
  width: 100%;

  > .ef-container {
    display: flex;
    justify-content: center;
  }
}

.ef-story__nav{
  position: absolute;
  height: 100%;
  width: 30%;
  top: 0;
  bottom: 0;
  z-index: 5;

  &--next{
    right: 0;
  }

  &--prev{
    left: 0;
  }
}
