.ef-magazine-card {
  @extend %card;
  @extend %card--has-line;
  cursor: pointer;
  background-color: var(--dark-gray-800);

  &__media {
    @extend .ef-media--1x1;
    @include reset-image-filter;
    filter: none !important;

    &:before {
      display: none !important;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(to top, $dark-gray-800, rgba($dark-gray-800, 0));
    }
  }

  @include break(desktop-s) {

    &:hover {

      .ef-magazine-card__image {
        transform: scale(1.05);
      }
    }
  }

  &__image {
    @include transition;
    flex-basis: 50%;
  }

  &__text {
    @extend %text-container;
    flex-basis: 50%;
    position: relative;
    z-index: 1;
    margin-top: calc(#{spacing-no-calc(3)} - 43.75%);
    padding: 0 spacing(3) spacing(3);
  }

  &__heading {
    @include transition($duration-faster, $property: color);
    color: var(--color-white);
    font-family: font($font--subline-condensed);
    font-weight: 400;
    letter-spacing: normal;
    //text-transform: uppercase;

    @include break(desktop-s) {

      .ef-magazine-card:hover & {
        color: var(--color-accent);
      }
    }
  }

  .ef-kicker,
  .ef-paragraph {
    color: var(--color-white);
  }
}
