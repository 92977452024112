%heading {
  font-weight: 400;
}

@mixin secondary-heading {
  font-family: font($font--subline-condensed);
  font-weight: 400;
  letter-spacing: normal !important;
  text-transform: uppercase;
}

.ef-headings {

  > :first-child {
    margin-bottom: 0;
  }
}

.ef-heading {
  font-weight: 400;

  &--pre {
    margin-bottom: 0 !important;
  }

  &--shop-display {
    @include secondary-heading;
    margin-top: .08em;
    margin-bottom: .08em;

    @include break(tablet) {
      margin-bottom: spacing(1.625);
    }

    .ef-heading__text {
      display: inline;
      padding: .08em spacing(2);
      background-color: var(--color-black);
      color: var(--color-white);

      /* Needs prefixing */
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }
}

h1, h2, h3, h4, h5, h6,
.ef-heading {
  @extend %heading;
  @include max-width(m);
  color: var(--color-front);
}

h3, h4, h5, h6,
.ef-heading--3,
.ef-heading--4,
.ef-heading--5,
.ef-heading--6 {
  font-weight: 700;
}

.ef-heading--0 {
  margin-bottom: spacing(4);
  color: var(--color-front);
  font-family: font($font--headline);
  @include type-scale(1000);

  .ef-shop-hero & {
    @include secondary-heading;
  }

  .ef-content--shop &,
  [data-theme-2="shop"] & {
    @include secondary-heading;
  }
}

h1,
.ef-heading--1 {
  margin-bottom: spacing(4);
  color: var(--color-front);
  font-family: font($font--headline-condensed);
  //hyphens: auto;
  @include type-scale(800);

  .ef-shop-hero & {
    @include secondary-heading;
  }

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(4);
  }

  .ef-article--magazine & {
    @include type-scale(900);
  }

  .ef-content--shop &,
  [data-theme-2="shop"] & {
    @include secondary-heading;
  }
}

h2,
.ef-heading--2 {
  margin-bottom: spacing(4);
  font-family: font($font--headline-condensed);
  //hyphens: auto;
  @include type-scale(700);

  .ef-content__block--newsletter-cta &,
  .ef-content__block--launch-cta & {
    hyphens: none;
  }

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(4);
  }

  .ef-content--match-article .ef-article__body & {
    @include type-scale(600);
  }

  * + & {
    padding-top: spacing(3);
  }

  //.ef-article--magazine .ef-article__header &,
  // .ef-article--magazine .ef-article__body &,
  // .ef-content--shop &,
  // [data-theme-2="shop"] & {
  //   @include secondary-heading;
  // }
}

h3,
.ef-heading--3 {
  margin-bottom: spacing(2);
  @include type-scale(600);

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(2);
  }

  // .ef-content--match-article .ef-article__body & {
  //   @include type-scale(400);
  // }

  .ef-content--match-article .ef-article__body .ef-article-card &,
  .ef-content--magazine-article .ef-article__body .ef-article-card {
    @include type-scale(600);
  }

  &.ef-heading--sub {
    //.ef-article--magazine .ef-article__header &,
    .ef-article--magazine .ef-article__body & {
      //font-weight: 700;
    }

    .ef-article__header & {
      @include type-scale(500);
      font-family: font($font--primary);
      font-weight: 500;
    }
  }

  * + & {
    padding-top: spacing(2);
  }

  .ef-article--magazine .ef-article__header &,
  .ef-article--magazine .ef-article__body &,
  .ef-content--shop &,
  [data-theme-2="shop"] & {
    @include secondary-heading;
    @include type-scale(600);
    font-weight: normal;
    letter-spacing: normal !important;
  }
}

h4,
.ef-heading--4 {
  margin-bottom: spacing(2);
  @include type-scale(500);

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(2);
  }

  // .ef-content--match-article .ef-article__body & {
  //   @include type-scale(400);
  // }

  * + & {
    padding-top: spacing(2);
  }

  //.ef-article--magazine .ef-article__header &,
  .ef-article--magazine .ef-article__body & {
    @include secondary-heading;
  }
}

h5,
.ef-heading--5 {
  margin-bottom: spacing(2);
  @include type-scale(450);

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(2);
  }

  * + & {
    padding-top: spacing(2);
  }

  //.ef-article--magazine .ef-article__header &,
  .ef-article--magazine .ef-article__body & {
    @include secondary-heading;
  }
}

h6,
.ef-heading--6 {
  margin-bottom: spacing(2);
  @include type-scale(300);

  .ef-content__block--header-text .ef-copy-block & {
    margin-bottom: spacing(2);
  }

  * + & {
    //padding-top: spacing(3);
  }

  //.ef-article--magazine .ef-article__header &,
  .ef-article--magazine .ef-article__body & {
    @include secondary-heading;
    @include type-scale(400);
  }
}

.ef-heading {

  &--title {
    color: var(--color-accent) !important;
  }

  &--has-kicker {
    padding-top: 0;
  }

  &--has-subheading {
    margin-bottom: 0;
  }

  &--on-image {
    color: var(--color-white) !important;
  }

  &--margin {
    margin-bottom: spacing(6);
  }
}

.ef-heading--subline,
.ef-heading--sub {
  padding-top: 0;
  font-family: font($font--subline-condensed);
  font-weight: 400;
}

.ef-heading--sub {
  margin-top: spacing(-2);
}

.ef-kicker {
  display: inline-block;
  position: relative;
  width: 100%;
  //margin-bottom: spacing(1);
  //padding-bottom: spacing(1);
  padding-left: spacing(6);
  color: var(--color-front);
  font-family: font($font--subline);
  //font-weight: 700;
  //letter-spacing: .05em;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;

  @include type-scale(300);
  //letter-spacing: .05em;

  @include ie11() {
    overflow: visible!important;
    // margin: spacing(4);
    padding-left: 0;
  }

  &--heading-1 {
    margin-bottom: spacing(3);
  }

  &--heading-2 {
    margin-bottom: spacing(2);
  }

  &:before {
    content: "";
    position: absolute;
    top: spacing(.9);
    left: 0;
    width: spacing(4.5);
    height: spacing(1.7);
    background-color: var(--color-accent);
  }

  &--simple {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  &__item {
    float: left;
  }

  &__item:not(:first-child) {
    //font-weight: 400;

    &:before {
      content: "/";
      padding-right: spacing(1);
      padding-left: spacing(1);
      opacity: .5;
      color: var(--color-front);
    }
  }


}

.ef-shop-kicker {
  @include type-scale(400);
  display: inline-block;
  margin-bottom: spacing(1);
  padding: 0 spacing(2);
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: font($font--subline-condensed);
  font-weight: 400;
  letter-spacing: normal !important;
  text-transform: uppercase;

  &--s {
    @include type-scale(300);
  }

  &--l {
    @include type-scale(400);
  }
}
