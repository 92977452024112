.ef-content__block--match-stats {

  .Opta {
    font-family: font($font--primary);
  }

  .Opta > .Opta_W {
    background: transparent;
  }

  .Opta > .Opta_W > .Opta-Cf {
    background: transparent;
    color: var(--tone-front-700);
  }

  .Opta_W > h2,
  .Opta .Opta-MatchHeader {
    display: none;
  }

  .Opta a, .Opta abbr, .Opta canvas, .Opta dd, .Opta div, .Opta dl, .Opta dt, .Opta fieldset, .Opta figcaption, .Opta figure, .Opta form, .Opta h2, .Opta h3, .Opta h4, .Opta img, .Opta label, .Opta legend, .Opta li, .Opta ol, .Opta p, .Opta span, .Opta table, .Opta tbody, .Opta td, .Opta tfoot, .Opta th, .Opta thead, .Opta tr, .Opta ul {
    @include type-scale(300);
  }

  .Opta .Opta-Nav {
    margin-bottom: spacing(2);
    background-color: transparent;
  }

  .Opta .Opta-Tabs .Opta-Nav > ul {
    border-bottom: 1px solid var(--tone-back-600);
    background-color: transparent;
  }

  .Opta .Opta-Tabs.Opta-TabsMore > .Opta-Nav > ul {
    height: auto;
  }

  .Opta .Opta-Tabs .Opta-Nav > ul.Opta-Overflowed,
  .Opta .Opta-Tabs .Opta-Nav > ul.Opta-Overflowed:hover {
    background-color: transparent;
  }

  .Opta .Opta-Nav > ul.Opta-Overflowed {
    cursor: auto;
  }

  .Opta .Opta-Nav li {
    margin-right: spacing(3);
    border: none;
  }

  .Opta .Opta-Nav li.Opta-On a {
    border-bottom: 4px solid var(--color-accent);
    background-color: transparent;
    color: var(--color-front);
  }

  .Opta .Opta-Nav a,
  .Opta.Opta-Narrow .Opta-Nav a {
    @include type-scale(300);
    @include transition($property: color);
    height: auto;
    padding: spacing(1) 0;
    border-bottom: 4px transparent;
    background-color: transparent;
    color: var(--tone-front-300);
    //font-weight: 700;

    &:hover {
      color: var(--color-front);
    }
  }

  .Opta .Opta-Nav li.Opta-On a:hover,
  .Opta .Opta-Nav a:hover {
    background-color: transparent;
  }

  .Opta .Opta-Nav > ul.Opta-Overflowed:after {
    top: calc(50% - 11px);
    right: 0;
  }

  .Opta .Opta-Nav > ul li.Opta-Overflow {
    top: 100% !important;
    width: auto !important;
    margin-right: 0;

    a {
      padding-right: spacing(2);
      padding-left: spacing(2);
      background-color: var(--color-front);
      color: var(--color-back);
    }
  }

  .Opta .Opta-TabbedContent > li.Opta-On > div {
    margin-bottom: spacing(2);
    padding-bottom: spacing(2);
    border-bottom: 1px solid var(--tone-back-600);
  }

  .Opta table {
    margin-bottom: 0;
  }

  .Opta .Opta-Stats-Bars th {
    height: auto;
    padding: 0;
    color: var(--color-front);
    font-weight: 700;
  }

  .Opta .Opta-Stats-Bars td.Opta-Outer {
    @include type-scale(300);
    width: spacing(12);
    color: var(--color-front);
  }

  .Opta td, .Opta th {
    padding: 0 spacing(1);
  }

  .Opta td {
    padding-bottom: spacing(1);
  }

  .Opta td:first-child {
    padding-left: 0;
    text-align: right;
  }

  .Opta td:last-child {
    padding-right: 0;
    text-align: left;
  }

  .Opta .Opta-Stats-Bars .Opta-Bars-Full {
    height: auto;
    margin: 0;
    background-color: transparent;
  }

  .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent {
    height: spacing(1);
    background: var(--color-accent);
  }

  .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent.Opta-Away {
    background: var(--color-front);
  }

  .Opta .Opta-TimeControls {
    background-color: transparent;
  }

  .Opta .Opta-TimeControls .Opta-Timestrip {
    margin-bottom: spacing(2);
    padding-top: 0;
    padding-bottom: spacing(2);
    border-bottom: 1px solid var(--tone-back-600);
    text-align: center;
  }

  .Opta .Opta-TimeControls .Opta-TimeBox rect {
    fill: var(--color-front);
  }

  .Opta .Opta-TimeControls .Opta-TimeBox text {
    @include type-scale(200);
    fill: var(--color-back);
    line-height: 18px !important;
  }

  .Opta .Opta-TimeControls .Opta-Regulation {
    fill: var(--color-front);
  }

  .Opta .Opta-TimeControls .Opta-InjuryTime {
    fill: var(--tone-medium);
  }

  .Opta .Opta-TimeControls .Opta-Dragger path {
    fill: var(--color-accent);
  }

  .Opta .Opta-TimeControls .Opta-TimeSpan {
    fill: var(--color-white);
  }

  .Opta .Opta-TimeControls .Opta-PeriodButtons {
    padding: 0;
  }

  .Opta .Opta-TimeControls .Opta-PeriodButtons button {
    @extend .ef-button;
    @extend .ef-button--small;
    @extend .ef-button--secondary;

    &:hover {
      background-color: inherit;
    }
  }

  .Opta .Opta-TimeControls .Opta-PeriodButtons.Opta-Periods-2 button {
    height: auto;
  }

  .Opta .Opta-TimeControls .Opta-PeriodButtons .Opta-Full {
    background-color: transparent;

    &:hover {
      background-color: var(--color-accent);
    }
  }

  .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On,
  .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On:hover {
    background-color: var(--color-accent);
  }

  .Opta p.Opta-Load {
    @include skeleton(64);
    @include bottom-gap(m);
    @include hide-text;
    position: relative;
    height: spacing(64);
    margin: 0;
    padding: 0;
    background: transparent;
  }
}
