.ef-cart--checkout-review {
  position: initial;
  left: inherit;
  right: inherit;
  transform: none;
  @include max-width(m);
}

.ef-cart--checkout-review {
  .ef-cart__inner {
    padding: 0;
  }
}

.ef-cart__checkout--checkout-review {
  background: transparent;
  padding: 0;
  margin: 0;
  margin-bottom: spacing(5);
}

.ef-cart__content--checkout-review {
  .ef-product {
    max-width: inherit;
  }
}

.ef-content__block--checkout-review {
  @extend %boxed-padding;
}

.ef-cart__footer--checkout-review {
  padding: 0;

  .ef-cta-group__top {
    text-align: left;
    margin-bottom: spacing(4);
  }

  .ef-cta-group__buttons {
    .ef-button {
      width: auto;
      margin-right: spacing(2.5);
    }
  }
}

.ef-product--checkout-review {

  .ef-product {

    &__infos {
      @include type-scale(300);
    }
    &__summary {
      grid-template-columns: 1fr spacing(12);
      grid-column-gap: spacing(2);
      grid-row-gap: spacing(.5);
      grid-template-areas:
          "a a"
          "b c"
          "d e";
      @include break('tablet') {
        grid-template-areas:
            "a b"
            "d c"
            "d e";
      }
    }
    &__amount, &__heading {
      margin-bottom: spacing(2);
    }
    &__amount-field-wrapper {
      @include break('tablet') {
        width: 100%;
      }
    }
    &__actions {
      @include type-scale(300);
      font-weight: 400;
    }

    &__heading {
      grid-area: a;
    }
    &__amount {
      grid-area: b;
    }
    &__price {
      grid-area: c;
    }
    &__actions {
      grid-area: e;
    }
  }

  .ef-hints {
    grid-area: d;
  }
}
