.ef-icon {

  &--default {
    color: var(--color-front);
  }

  &--accent {
    color: var(--color-accent);
  }

  &--front {
    color: var(--color-front);
  }

  &--back {
    color: var(--color-back);
  }
}

#ef-icon {

  &--yellow-card-16 {
    color: var(--color-yellow);
  }

  &--2nd-yellow-card-16 {

    [data-color="color-1"] {
      fill: var(--color-yellow);
    }

    [data-color="color-2"] {
      fill: var(--color-red-400);
    }
  }

  &--red-card-16 {
    color: var(--color-red-400);
  }

  &--substitution-16,
  &--substitution-24 {

    [data-color="color-1"] {
      fill: var(--color-red-400);
    }

    [data-color="color-2"] {
      fill: var(--color-green);
    }
  }
}
