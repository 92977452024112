.ef-date-card {
  @extend %card;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: spacing(3);
  padding-right: spacing(4);
  padding-bottom: spacing(3);
  border-bottom: 1px solid var(--tone-back-600);

  &__content {
    display: grid;
    gap: spacing(2);
  }

  &__date {
    @include type-scale(700);
    color: var(--color-front);
    font-family: font($font--subline);
  }

  &__title {
    display: flex;
    align-items: start;
    gap: spacing(2);
    margin-bottom: spacing(2);
    color: var(--color-front);
  }

  &__competition,
  &__icon {
    display: flex;
    align-items: center;
    height: 32px;
  }

  &__heading {
    @include type-scale(400);
    line-height: spacing(3) !important;
    font-weight: 700;
    padding-top: calc(16px - #{spacing-no-calc(1.5)});
  }

  &__infos-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: spacing(1);
  }

  &__info {
    @include type-scale(200);
    color: var(--tone-front-300);

    &.ef-text--link {
      color: var(--color-front);
    }

    &:not(:last-child):after {
      content: "•";
      padding-left: spacing(1);
    }
  }
}
