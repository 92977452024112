@keyframes ef-fade-in {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

@keyframes ef-fade-out {
  0% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
}

@keyframes ef-slide-fade-in-btt {
  0% {
    opacity: 0;
    transform: translateY(24px);
  } 100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes hero-ken-burns {
  0% {
    transform: scale(1);
  } 25% {
    opacity: 1;
  } 100% {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes b-marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@keyframes ken-burns {
  0% {
    transform: scale(1);
  } 100% {
    transform: scale(1.07);
  }
}
