.ef-ticket-card {
  @extend %card;
  @extend %card--has-line;
  cursor: pointer;

  .ef-content__block--grid-list & {
    @include transition;
    display: grid;
    grid-template-columns: spacing(12) 1fr;
    grid-template-areas: "media text";
    grid-gap: spacing(3);
    padding-right: calc(16px + #{spacing-no-calc(3)});
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 12 12"><polygon fill="#{url-encoded-color($color--white)}" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>');
    background-repeat: no-repeat;
    background-position: right bottom spacing(2);
    font-weight: 400;
  }

  [data-theme="light"] .ef-content__block--grid-list & {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 12 12"><polygon fill="#{url-encoded-color($color--black)}" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>');
  }

  &:hover {

    @include break(desktop-s) {

      .ef-content__block--grid-list & {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 12 12"><polygon fill="#{url-encoded-color($color--red-400)}" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>');
      }
    }
  }

  &--sold-out {

    .ef-ticket-card__media,
    .ef-ticket-card__text {
      @include transition;
      opacity: .4;
    }

    @include break(desktop-s) {

      &:hover .ef-ticket-card__media,
      &:hover .ef-ticket-card__text {
        opacity: 1;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    background-image: none !important;
  }

  &--search {
    display: flex;
    flex-direction: column;
  }

  &__media {
    @extend .ef-media--3x2;
    background-color: var(--tone-back-700);

    @include theme(light) {
      background-color: var(--tone-back-800);
    }

    .ef-content__block--grid-list & {
      grid-area: media;
      margin-bottom: spacing(2);
    }
  }

  &__image {
    @include transition;
  }

  .ef-ticket-logos {
    @include center(absolute);
    @include transition;
  }

  &:hover {

    @include break(desktop-s) {

      .ef-ticket-card__image {
        transform: scale(1.05);
      }

      .ef-ticket-logos {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  &__text {
    @extend %text-container;
    padding: spacing(2) spacing(3) spacing(2) 0;

    .ef-ticket-card--search & {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-right: 0;
      padding-left: 0;
    }

    .ef-content__block--grid-list & {
      grid-area: text;
      display: flex;
      flex-direction: column;
      padding-top: 0;
      padding-right: 0;

      @include break(desktop-s) {
        flex-direction: row;
      }
    }
  }

  &__heading {
    @include type-scale(500);
    @include transition($duration-faster, $property: color);
    margin-bottom: spacing(1);
    padding-top: 0;

    .ef-ticket-card:hover & {

      @include break(desktop-s) {
        color: var(--color-accent);
      }
    }

    .ef-content__block--grid-list & {
      order: 2;

      @include break(desktop-s) {
        flex-basis: percentage(2/3);
        padding-right: spacing(3);
      }
    }

    .ef-heading {
      margin-bottom: 0;
    }
  }

  .ef-paragraph {
    margin-bottom: 0;

    .ef-content__block--grid-list & {

      @include break(desktop-s) {
        flex-basis: percentage(1/3);
        padding-right: spacing(3);
      }
    }
  }

  &__competition {
    display: flex;
    align-items: center;
  }

  &__competition-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: spacing(1);

    .ef-competition-logo {
      width: 16px;
      height: 16px;
    }
  }

  .ef-breadcrumbs {
    padding-top: spacing(2);
  }

  &__badges {
    position: absolute;
    right: spacing(2);
    top: spacing(2);
  }

  &__sold-out-label {
    @include type-scale(300);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: spacing(1.5) spacing(2);
    background-color: var(--color-front);
    color: var(--color-back);
    font-weight: 700;
    text-align: center;
  }

  .ef-shop-badge:not(:last-child) {
    margin-bottom: spacing(1);
  }

  .ef-bookmark {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
  }
}
