.ef-featured-quote {
  padding-top: spacing(12);
  padding-bottom: spacing(12);
  
  &__quote {
    @include type-scale(800);
    color: var(--color-front);
    text-indent: -.4em;

    &:before {
      content: "„";
    }

    &:after {
      content: "“";
    }
  }

  &__cite {
    @include type-scale(300);
    display: block;
    position: relative;
    margin-top: spacing(6);
    padding-left: spacing(10);
    color: var(--color-front);

    &:before {
      content: "";
      position: absolute;
      top: calc(#{spacing-no-calc(1.75)} - 2px);
      left: 0;
      width: spacing(8);
      height: 4px;
      background-color: var(--color-accent);
    }
  }
}
