.ef-share {
  position: fixed;
  right: spacing(2);
  bottom: spacing(10);
  z-index: z(share);
  transform: scale(.75);
  @include transition;
  transition-property: opacity, transform;

  .--mobile-sticky-ad & {
    @media (max-width: 900px) and (orientation: portrait) {
      bottom: calc(#{spacing-no-calc(4)} + 15.733333vw);
    }
  }

  &__button,
  &__option {
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      transform: scale(1.1) !important;
    }
  }

  &__button {
    position: relative;
    z-index: 1;
    width: 48px;
    height: 48px;
    background-color: var(--color-front);
    color: var(--color-accent);
    @include transition($easing: $ease-out-back);

    @include theme(light) {
      color: var(--color-back);
    }
  }

  &__button-android,
  &__button-ios,
  &__button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    @include fill;
    @include transition;
  }

  &__button-android,
  &__button-ios {
    @include transition($easing: $ease-out-back);

    .ef-share__button.--is-open & {
      opacity: 0;
      transform: scale(.375);
    }
  }

  &__button-ios {
    display: none;
  }

  /* Detect Mac Safari 6.1 or newer, hide any straggling Chrome references */
  // @media screen and (-webkit-max-device-pixel-ratio:1) and (min-color-index:0) {
  //
  //   &__button-android { display: none; }
  //   &__button-ios { display: block; }
  // }

  &__button-close {
    opacity: 0;
    transform: scale(.5);

    .ef-share__button.--is-open & {
      opacity: 1;
      transform: none;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 48px;
  }

  &__option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: spacing(2);
    background-color: var(--color-front);
    color: var(--color-back) !important;
    transform-origin: center;
    @include transition($easing: $ease-out-back);

    &:last-child {
      transform: translateX(calc(#{spacing-no-calc(3)} + 32px));
      transition-duration: .2s;
      //transition-delay: .4s;

      .ef-share__options.--is-open & {
        transform: none;
      }
    }

    &:nth-last-child(2) {
      transform: translateX(calc(#{spacing-no-calc(5)} + 64px));
      transition-duration: .4s;
      //transition-delay: .3s;

      .ef-share__options.--is-open & {
        transform: none;
      }
    }

    &:nth-last-child(3) {
      transform: translateX(calc(#{spacing-no-calc(7)} + 96px));
      transition-duration: .6s;
      //transition-delay: .2s;

      .ef-share__options.--is-open & {
        transform: none;
      }
    }

    &:nth-last-child(4) {
      transform: translateX(calc(#{spacing-no-calc(9)} + 128px));
      transition-duration: .9s;

      .ef-share__options.--is-open & {
        transform: none;
      }
    }
  }
}

@keyframes show-share-option {
  to { transform: none; }
}
