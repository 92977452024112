.ef-eintrachttv-live {
  @include type-scale(400);
  display: flex;
  justify-content: center;
  gap: spacing(1);
  position: fixed;
  z-index: z(tv-live);
  bottom: 0;
  right: 0;
  width: 50vw;
  padding: spacing(2) spacing(3);
  background-color: var(--color-front);
  color: var(--color-back);
  font-weight: 400;

  @include theme(dark) {
    color: var(--color-accent);
  }

  @include break(phablet) {
    width: auto;
  }

  &__icon {
    display: flex;
    align-items: center;
    animation: tv-pulse 1s ease-in-out infinite alternate-reverse;
  }
}
