blockquote,
.ef-blockquote {
  position: relative;
  padding-top: spacing(3);
  padding-left: spacing(6);
  @include max-width(m);

  .ef-copy-block & {
    margin-bottom: spacing(4);
  }

  &:before {
    content: "„";
    position: absolute;
    top: 0;
    left: 0;
    width: spacing(5);
    color: var(--color-accent);
    font-family: font($font--headline);
    font-size: spacing(6);
    line-height: 1;
    font-weight: 400;
    text-align: right;
    transform: translateY(#{spacing(-4.7)});
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: spacing(6);
    left: spacing(6);
    height: 2px;
    background-color: var(--color-accent);
  }

  p,
  &__quote {
    @include type-scale(600);
    position: relative;
    padding-right: spacing(6);
    padding-bottom: spacing(3);
    color: var(--color-front);
    font-family: font($font--subline-condensed);
    font-style: italic;
    letter-spacing: normal;

    .ef-copy-block & {
      margin-bottom: 0;
      @include type-scale(600);
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: spacing(6);
      left: 0;
      height: 2px;
      background-color: var(--color-accent);
    }

    &:after {
      content: "“";
      position: absolute;
      bottom: 0;
      right: 0;
      width: spacing(5);
      color: var(--color-accent);
      font-family: font($font--headline);
      font-size: spacing(6);
      line-height: 1;
      font-weight: 400;
      transform: translateY(#{spacing(3.7)});
    }

    .ef-blockquote--s & {
      //@include type-scale(500);
    }
  }

  cite,
  &__cite {
    display: block;
    position: relative;
    padding-top: spacing(3);
    padding-right: spacing(6);
    color: var(--color-front);
    //font-family: font($font--subline-condensed);
    @include type-scale(300);
  }
}
