.ef-match-card {
  @extend %card--has-line;
  //display: none;
  position: relative;
  width: 100%;
  padding-right: spacing(2);
  padding-bottom: spacing(2);
  cursor: pointer;

  // @include break(desktop-s) {
  //   display: block;
  // }

  &__link {
    @include fill;
    z-index: 1;
  }

  &--all {
    display: flex;
    align-items: center;
  }

  &--current {
    //display: block;

    &:before {
      background-color: var(--color-front);
    }
  }

  &__teams {
    display: flex;
    //justify-content: center;
    align-items: center;
    margin-bottom: spacing(1);
    color: var(--color-front);
    @include type-scale(300);
  }

  &__team {
    display: flex;
    align-items: center;
  }

  &__team-logo {
    width: spacing(2.5);
    height: spacing(2.5);
  }

  &__team-name {

    .ef-match-card__team--home & {
      padding-left: spacing(1);
    }

    .ef-match-card__team--away & {
      padding-right: spacing(1);
    }
  }

  &__result {
    padding-right: spacing(1);
    padding-left: spacing(1);
    font-weight: 700;

    .ef-match-card--live & {
      color: var(--color-accent);
    }
  }

  &__data {
    @include type-scale(200);

    @include break(tablet) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__competition {

    &:after {
      content: " /";
    }
  }

  &__date-time {

    &:before {
      content: "\00a0";
    }
  }

  &__data-cta {
    display: block;
    color: var(--color-front);
    font-weight: 700;
  }

  .ef-link-button {

    &:before,
    &:after {
      display: none;
    }
  }
}
