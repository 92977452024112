.ef-checkout-slider {
  max-width: initial;

  .ef-content--shopping-account-profile &,
  .ef-content__block--checkout-payments & {
    margin-bottom: spacing(4);
  }

  .ef-content__expansion {
    flex: 1 1 100%;
  }

  .ef-slide {
    flex-wrap: wrap;

    .ef-content__expansion {
      @extend %reset-boxed-padding;
      @include break('tablet') {
        display: none;
      }
    }
  }
}

.ef-content__block--checkout-address {
  @extend .ef-content__section--boxed;

  .ef-content__block--checkout-expansion-item {
    @extend %reset-boxed-padding;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ef-content__block--select-bill-address {
    padding-bottom: 0;
  }

  .ef-form-item--address {
    @extend .ef-newsflash-card;
    margin-bottom: 0;
    padding-top: spacing(3);
    padding-bottom: spacing(3);
    overflow: inherit;

    @include break('tablet') {
      padding-top: 0;
    }

    .ef-slide:first-child & {
      @include break('tablet', max-width) {
        padding-top: spacing(1.5);
      }
    }
  }

  .ef-form-item__check-label {
    p,a {
      @include type-scale(300);
    }
  }
}

.ef-content__block--checkout-slider {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
