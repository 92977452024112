.ef-wrapper {
  height: 100vh;
  overflow-y: scroll;
  transition: transform .3s $ease-in-out-expo .15s;

  &.--is-frozen {
    overflow: hidden;

    @include break(tablet) {
      transform: translateX(#{spacing(42)});
    }
  }
}

.ef-content {
  position: relative;
  z-index: z(content);
  padding-top: spacing(12);
  padding-bottom: spacing(2);
  background-color: var(--tone-back-800);
  overflow: hidden;

  &--reset-z {
    z-index: z(content) !important;
  }

  &--front {
    z-index: 999999;
  }

  &--shop {
    padding-top: spacing(12);
    padding-bottom: 0;

    > svg {
      display: none;
    }
  }

  &--product-detail {
    padding-top: spacing(3);
  }

  &--shopping-advent-calendar .ef-shop-hero__image {
    object-position: right center;
  }

  @include theme(dark) {
    box-shadow: 0 0 spacing(8) spacing(6) rgba($color--black, .8);
  }

  @include theme(light) {
    box-shadow: 0 spacing(4) spacing(8) rgba($color--black, .1);
    background-color: var(--color-back);
  }

  // &--match-article,
  // &--match-live-ticker,
  // &--match-matches,
  // &--match-league-table,
  // &--match-line-up,
  // &--match-stats,
  // &--match-center-initial {
  //   padding-top: 0;
  //
  //   .ef-content__header {
  //     padding-top: spacing(6);
  //   }
  // }

  &--shopping-home,
  &--shopping-fanshop-home,
  &--shopping-category,
  &--soccer-school-detail {
    padding-top: 0;
  }

  &--shopping-category {

    .ef-shop-hero {
      margin-bottom: 0;
    }

    .ef-content__header {
      padding-top: spacing(12);
    }
  }

  &--login {
    padding-top: 0;
    padding-bottom: 0;
  }

  // &--player {
  //
  //   .ef-content__block--section-header-top {
  //     display: none;
  //   }
  // }

  &--match-center-initial {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  &--tv-category {
    padding-bottom: 0;
  }

  &--forum {
    padding-top: spacing(6);
    overflow: visible;
  }

  &--match-preview {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  > .ef-content__block:first-of-type {
    //padding-top: spacing(8);
  }

  &__header,
  &__section-header {
    //@extend %boxed-padding;
    //margin-bottom: spacing(8);
    //padding-top: spacing(15);
    //padding-bottom: spacing(15);
    //background-color: var(--color-back);
    //text-align: center;

    h1.ef-heading {

      // @include break(desktop-s) {
      //   margin-bottom: 0;
      // }
    }

    .ef-heading {
      //margin-bottom: spacing(2);

      // @include break(desktop-s) {
      //   margin-bottom: 0;
      // }
    }
  }

  &__header {
    @extend %boxed-padding;
    position: relative;
    z-index: 1;
    animation: ef-fade-in .7s ease-in-out forwards;
    //padding-top: spacing(6);

    .ef-article--low-level &,
    .ef-content--perpetual-calendar & {
      padding-top: 0;
    }

    &--spacing {
      margin-bottom: spacing(4);
    }

    &--after-hero {
      padding-top: 0;
    }
  }

  &__section-header {
    @extend %ef-max-width-l-center;
    padding-top: spacing(2);
  }

  &__header-with-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: spacing(6);
    animation: ef-fade-in .7s ease-in-out forwards;

    @include break(tablet) {
      display: flex;
      justify-content: space-between;
    }

    .ef-heading {
      margin-bottom: spacing(1);
    }


    // width: 100%;
    //
    // @include break(tablet) {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: baseline;
    // }

    .ef-heading {
      // margin-bottom: spacing(2);
      //
      // @include break(tablet) {
      //   margin-bottom: 0;
      // }
    }
  }

  &__section {
    margin-bottom: spacing(10);

    &--boxed {
      @extend %boxed-padding;
    }
  }

  &__block {
    position: relative;
    z-index: 2;
    animation: ef-fade-in .7s ease-in-out forwards;
    @include bottom-gap(xl);

    .ef-content--dbp & {
      margin-bottom: spacing(12);
    }

    .ef-accordion &,
    .ef-article__header & {
      padding-right: 0;
      padding-left: 0;

      @include break(tablet) {
        padding-right: 0;
        padding-left: 0;
      }

      @include break(desktop-s) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &--no-margin {
      margin-bottom: 0;
    }

    &--header,
    &--header-text,
    &--match-preview {
      @extend %boxed-padding;
      margin-bottom: 0 !important;
    }

    &--match-preview {
      flex: 1;
    }

    &--partners {
      margin-top: spacing(12);
      margin-bottom: spacing(-6);
      padding-top: spacing(12);
      padding-bottom: spacing(6);
      // border-top: 1px solid var(--tone-back-600);
      background-color: var(--color-black);
    }

    // &--heading {
    //   padding-top: spacing(3);
    // }

    &--padded,
    &--section-header,
    &--rich-text,
    &--blockquote,
    &--list,
    &--image,
    &--image-and-text,
    &--video,
    &--gallery-slider,
    &--embed,
    &--separator,
    &--accordion,
    &--tags,
    &--league-table,
    &--line-up-goals,
    &--match-stats,
    &--match-table-grid,
    &--matches,
    &--match,
    //&--countdown-m,
    &--live-ticker,
    &--formation,
    &--match-stats,
    &--player-stats,
    &--pagination,
    &--perpetual-calendar,
    //&--perpetual-content-teaser,
    &--presenter,
    &--checkout-progress,
    &--checkout-payments,
    &--player-grid,
    &--person-grid,
    &--social-hub,
    &--file-previews,
    &--status {
      @extend %boxed-padding;
      //@include row-gap(m);
    }

    &--cta-button,
    &--info-box,
    &--info-box-grid,
    &--data-table,
    &--downloads,
    &--post-cards,
    &--line-up,
    &--shop-infos,
    &--sport-grid,
    &--icon-grid,
    &--story-grid,
    &--download-grid {
      @extend %boxed-padding;
      //@include row-gap(m);
    }

    &--data-table {
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
    }

    &--info-box {
      position: relative;
      z-index: 2;
    }

    &--info-box,
    &--info-box-grid {

      .ef-content--shop &,
      .ef-content--dbp & {
        margin-bottom: spacing(12);
      }
    }

    &--secondary {
      padding-top: spacing(12);
      padding-bottom: spacing(12);
      
      @include theme(dark) {
        background-color: var(--tone-back-750);
      }

      @include theme(light) {
        background-color: var(--tone-back-800);
      }
    }

    &--shop-product {
      @extend %boxed-padding;
      @include bottom-gap(xl);

      .ef-content--shop &,
      .ef-content--dbp & {
        margin-bottom: spacing(12);
      }

      .ef-container--l {
        max-width: none;
      }
    }

    // Grid
    &--article-grid,
    //&--newsflash-grid,
    &--magazine-grid,
    &--video-grid,
    &--newsroom-grid,
    //&--person-grid,
    &--product-grid,
    &--shop-grid,
    &--ticket-grid,
    &--sport-grid,
    &--icon-grid,
    &--teaser-grid,
    &--historical-person-grid {
      @extend %boxed-padding;
      @include bottom-gap(xl);
      //@include row-gap(m);

      .ef-content--shop &,
      .ef-content--dbp & {
        margin-bottom: spacing(12);
      }

      .ef-container--l {
        max-width: none;
      }
    }

    &--article-grid-boxed {

      .ef-container--l {
        @include max-width(l);
      }
    }

    &--grid-list {

      .ef-container--l {
        max-width: spacing(136) !important;
      }
    }

    // Slider
    &--article-slider,
    &--newsflash-slider,
    &--newsflash-grid,
    &--magazine-slider,
    &--video-slider,
    &--table-slider-after-matches,
    &--teaser-slider,
    &--player-slider,
    &--product-slider,
    &--ticket-slider,
    &--newsroom-grid-slider,
    &--sport-slider,
    &--story-slider,
    &--perpetual-calendar-slider,
    &--shop-slider,
    &--shop-highlight-slider,
    &--jersey-config,
    &--historical-person-slider {
      @extend %boxed-padding;
      position: relative;
      @include bottom-gap(xl);

      .ef-content--shop &,
      .ef-content--dbp & {
        margin-bottom: spacing(12);
      }

      //@include row-gap(m);

      // @include break(desktop-m) {
      //   padding-right: spacing(15);
      //   padding-left: spacing(15);
      // }
    }

    &--parallax {
      margin-bottom: spacing(24);
    }

    &--matches {

      .ef-content--shop &,
      .ef-content--dbp & {
        margin-bottom: spacing(12);
      }
    }

    &--events,
    &--switches {
      @extend %boxed-padding;
    }

    &--product-cta,
    &--tv-cta,
    &--newsletter-cta {

      .ef-content--shop &,
      .ef-content--dbp & {
        //@extend %boxed-padding;
        margin-bottom: spacing(12);
      }
    }

    &--rich-text {

      .ef-article__header & {
        @extend %reset-boxed-padding;
        padding-top: 0;
      }
    }

    &--checkout-progress {
      padding-top: spacing(6);
    }

    &--tv-cta:last-child,
    &--newsletter-cta:last-child {
      margin-bottom: 0;
    }

    &--match-slider,
    &--table-slider {
      @extend %boxed-padding;
      position: relative;
      @include bottom-gap(s);
    }

    &--search-filters {
      margin-bottom: 0 !important;
      padding: spacing(2) spacing(3) 0;
      background-color: var(--tone-back-800);

      @include theme(light) {
        border-bottom: 1px solid var(--tone-back-600);
        background-color: var(--color-white);
      }

      @include break(tablet) {
        padding-right: spacing(6);
        padding-left: spacing(6);
      }

      @include break(desktop-s) {
        padding-right: spacing(9);
        padding-left: spacing(9);
      }

      // @include break(desktop-s) {
      //   padding-top: spacing(7);
      //   padding-bottom: spacing(7);
      // }
    }

    &--countdown-m {
      @extend %boxed-padding;
      margin-bottom: 0;
      padding-top: spacing(3);
      padding-bottom: spacing(3);
      background-color: var(--tone-back-750);
      text-align: center;
      transform: translateY(#{spacing(-12)});

      .ef-content__block--match-preview &,
      .ef-content--match-center-initial & {
        background: transparent;
      }
    }

    &--countdown-backdrop {
      @include fill;
    }

    &--presenter {
      background-color: var(--tone-back-700);
    }

    &--jobfinder {
      @extend %boxed-padding;
      background-color: var(--tone-back-700);
      @include row-gap(l);

      @include theme(light) {
        background-color: var(--tone-back-800);
      }
    }

    &--search-results {
      @extend %boxed-padding;
      @include row-gap(m);
      flex: 1;
      margin-bottom: 0 !important;
      overflow-y: scroll;
      background-color: var(--color-white);
    }

    &--article-teaser {
      @include bottom-gap(m);
    }

    &--table-slider-after-matches {
      padding-top: 0 !important;
    }

    &--section-header {
      margin-bottom: 0 !important;
    }

    &--section-header-top {
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      @extend %boxed-padding;
    }

    &--countdown-m {
      text-align: center;
    }

    &--countdown-l {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    &--timeline {
      @extend %boxed-padding;
      @include row-gap(m);

      @include break(desktop-s) {
        padding-top: spacing(7);
        padding-bottom: spacing(7);
      }
    }

    &--article-grid-newsroom {
      @extend %boxed-padding;
      @include row-gap(m);
    }

    &--newsroom-grid,
    &--newsroom-overlay {
      height: 0;
      padding: 0;
    }

    &--newsroom-grid-slider {
      height: 0;
      padding: 0;
    }

    &--quotes {
      @extend %boxed-padding;
      @include bottom-gap(l);
    }

    &--quotes ~ .ef-content__block--quotes > .ef-container {
      padding-top: spacing(6);
      border-top: 1px solid var(--tone-700);
    }

    &--story-hero {
      height: 100vh;
      margin-bottom: spacing(12);
    }

    &--forum {
      @include bottom-gap(m);
      max-width: 1140px;
      margin-right: auto;
      margin-left: auto;
    }

    &--checkout-payment-options {
      margin-top: spacing(8);
    }
  }

  &__expansion {
    @include bottom-gap(l);
    position: relative;
    margin-top: spacing(-6);
    overflow: hidden;

    &.--full {
      overflow: inherit;
    }

    .ef-content__block--checkout-slider & {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__expansion-item {
    @extend %boxed-padding;
    max-height: 0;
    overflow-y: hidden;
    will-change: max-height;
    background-color: var(--tone-back-750);

    @include theme(light) {
      background-color: var(--tone-back-700);
    }
    //@include transition($duration: .5s, $property: max-height, $easing: $ease-in-quad);

    &.--full {
      background-color: transparent;
      overflow-y: inherit;
      padding: 0;

      > .ef-content__expansion-content {
        padding: 0;
      }
    }

    &.--is-expanded {
      max-height: spacing(500);
    }

    .ef-copy-block {
      margin-left: 0;
    }

    p {
      @extend .ef-text--s;
    }
  }

  &__expansion-content {
    @extend .ef-container;
    @extend .ef-container--l;
    // padding-top: spacing(8);
    // padding-bottom: spacing(8);
    opacity: 0;
    @include row-gap(m);
    @include transition($property: opacity);

    .ef-content__expansion-item.--is-expanded & {
      opacity: 1;
    }
  }

  &__expansion-close {
    position: absolute;
    top: spacing(2);
    right: spacing(2);
    color: var(--tone-500);
    transform: scale(1.33);
    @include transition;

    &:hover {
      color: var(--color-front);
      transform: scale(1.5);
    }
  }
}
