.ef-billboard {
  @include ad;
  display: flex;
  justify-content: center;

  &__banner {
    @include ad-banner;
    width: auto;

    img {
      vertical-align: bottom;
    }
  }
}
