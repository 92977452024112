.ef-show-more {
  //margin-bottom: spacing(4);
  padding-top: spacing(4);
  // padding-bottom: spacing(4);
  // border-bottom: 1px solid var(--tone-back-600);
  text-align: right;

  &--clipped {
    padding-top: 0;
    text-align: left;

    .ef-content__block--timeline & {
      text-align: center;
    }
  }

  &--timeline {
    padding-top: spacing(8);
  }
}
