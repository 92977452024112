.ef-product-cta {
  @extend %boxed-padding;
  position: relative;
  padding-top: spacing(8);
  padding-bottom: spacing(8);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  // &:after {
  //   @include fill;
  //   content: "";
  //   background-color: rgba($grayscale--800, .6);
  // }

  &__video {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    @include break(desktop-s) {
      display: block;
    }
  }

  &__text {

    @include break(desktop-s) {
      flex: 1;
      padding-right: spacing(8);
    }
  }

  .ef-container {
    position: relative;
    z-index: 1;

    // @include break(desktop-s) {
    //   display: flex;
    //   align-items: center;
    // }
  }

  &__heading {
    color: var(--color-white);
  }

  .ef-paragraph {
    color: var(--color-white);

    // @include break(desktop-s) {
    //   margin-bottom: 0;
    // }
  }
}
