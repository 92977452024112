// Modular scale
// Mobile: minor third (1.2)
// Desktop: major third (1.25)

$type-scale: (
  100: (
         "size-phone": #{spacing(1.54321)},
         "line-height-phone": #{spacing(2)},
         "size": #{spacing(1.54321)},
         "line-height": #{spacing(2)},
         "letter-spacing": "normal"
       ),
  200: (
         "size-phone": #{spacing(1.851851)},
         "line-height-phone": #{spacing(2.5)},
         "size": #{spacing(1.851851)},
         "line-height": #{spacing(2.5)},
         "letter-spacing": "normal"
       ),
  250: (
         "size-phone": #{spacing(2.037036)},
         "line-height-phone": #{spacing(3)},
         "size": #{spacing(2.037036)},
         "line-height": #{spacing(3)},
         "letter-spacing": "normal"
       ),
  300: (
         "size-phone": #{spacing(2.222222)},
         "line-height-phone": #{spacing(3.5)},
         "size": #{spacing(2.222222)},
         "line-height": #{spacing(3.5)},
         "letter-spacing": "normal"
       ),
  350: (
         "size-phone": #{spacing(2.444444)},
         "line-height-phone": #{spacing(3.5)},
         "size": #{spacing(2.444444)},
         "line-height": #{spacing(3.5)},
         "letter-spacing": "normal"
       ),
  400: (
         "size-phone": #{spacing(2.666666)},
         "line-height-phone": #{spacing(4)},
         "size": #{spacing(2.666666)},
         "line-height": #{spacing(4)},
         "letter-spacing": "normal"
       ),
  450: (
        "size-phone": #{spacing(2.666666)},
        "line-height-phone": #{spacing(3.5)},
        "size": #{spacing(2.666666)},
        "line-height": #{spacing(3.5)},
        "letter-spacing": "normal"
       ),
  500: (
         "size-phone": #{spacing(3.199999)},
         "line-height-phone": #{spacing(4)},
         "size": #{spacing(3.199999)},
         "line-height": #{spacing(4)},
         "letter-spacing": "normal"
       ),
  600: (
         "size-phone": #{spacing(3.839999)},
         "line-height-phone": #{spacing(4.5)},
         "size": #{spacing(4.166666)},
         "line-height": #{spacing(5)},
         "letter-spacing": "normal"
       ),
  700: (
         "size-phone": #{spacing(4.607999)},
         "line-height-phone": #{spacing(5.5)},
         "size": #{spacing(5.208332)},
         "line-height": #{spacing(6)},
         "letter-spacing": "normal"
       ),
  800: (
         "size-phone": #{spacing(5.529599)},
         "line-height-phone": #{spacing(6)},
         "size": #{spacing(6.510415)},
         "line-height": #{spacing(7)},
         "letter-spacing": "normal"
       ),
  900: (
         "size-phone": #{spacing(6.635519)},
         "line-height-phone": #{spacing(7)},
         "size": #{spacing(9.765623)},
         "line-height": #{spacing(9.5)},
         "letter-spacing": "normal"
       ),
  920: (
        "size-phone": #{spacing(7.962623)},
        "line-height-phone": #{spacing(8)},
        "size": #{spacing(12.207029)},
        "line-height": #{spacing(12)},
        "letter-spacing": "normal"
      ),
  950: (
        "size-phone": #{spacing(8.5)},
        "line-height-phone": #{spacing(8.5)},
        "size": #{spacing(17.531245)},
        "line-height": #{spacing(17)},
        "letter-spacing": "normal"
      ),
  1000: (
        "size-phone": #{spacing(17)},
        "line-height-phone": #{spacing(16)},
        "size": #{spacing(28.365554)},
        "line-height": #{spacing(26)},
        "letter-spacing": "normal"
  ),
  1100: (
        "size-phone": #{spacing(27.51)},
        "line-height-phone": #{spacing(24)},
        "size": #{spacing(45.9)},
        "line-height": #{spacing(42)},
        "letter-spacing": "normal"
  )
);

@mixin type-scale($key) {
  @if map-has-key($type-scale, $key) {
    font-size: #{inspect(map-get-deep($type-scale, $key, "size-phone"))};
    line-height: #{inspect(map-get-deep($type-scale, $key, "line-height-phone"))};
    letter-spacing: #{inspect(map-get-deep($type-scale, $key, "letter-spacing"))};

    @include break(desktop-s) {
      font-size: #{inspect(map-get-deep($type-scale, $key, "size"))};
      line-height: #{inspect(map-get-deep($type-scale, $key, "line-height"))};
    }
  } @else {
    @error "No value found for `#{$key}` in `$type-scale` map.";
  }
}

@mixin type-scale-important($key) {
  @if map-has-key($type-scale, $key) {
    font-size: #{inspect(map-get-deep($type-scale, $key, "size-phone"))} !important;
    line-height: #{inspect(map-get-deep($type-scale, $key, "line-height-phone"))} !important;
    letter-spacing: #{inspect(map-get-deep($type-scale, $key, "letter-spacing"))} !important;

    @include break(desktop-s) {
      font-size: #{inspect(map-get-deep($type-scale, $key, "size"))} !important;
      line-height: #{inspect(map-get-deep($type-scale, $key, "line-height"))} !important;
    }
  } @else {
    @error "No value found for `#{$key}` in `$type-scale` map.";
  }
}
