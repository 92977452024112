.ef-notification {
  display: flex;
  align-items: center;
  gap: spacing(3);
  padding: spacing(3);
  border-radius: spacing(1);
  background-color: var(--tone-back-750);

  &:not(:last-child) {
    margin-bottom: spacing(3);
  }

  &__icon {
    display: flex;
    align-items: center;
    color: var(--color-front);
  }
}
