.slick-slider {
  touch-action: auto !important;
}

.slick-slider,
.slick-list {
  overflow: visible;
}

.slick-track {
  display: flex;
  margin-left: 0;
}

// .slick-cloned[data-slick-index="-1"] {
//   opacity: 0;
// }

.ef-slider {
  @extend %ef-max-width-slider;
  position: relative;

  @include ie11() {
    height: 100%;
    padding: 30px 0;
  }

  // Apply skeleton styles on page load
  //@include skeleton(16);

  // Reset skeleton styles once slick is initialized
  &.slick-slider {
    @include reset-skeleton;
  }

  &--center {

    .slick-track {
      margin-left: auto;
    }
  }

  &__dots {
    @include fill;
    display: flex;
    justify-content: center;
    position: absolute;
    padding-top: calc(66.666666% - 12px + #{spacing-no-calc(-2)});

    li {
      padding: 0 spacing(.5);
    }

    button {
      @include hide-text;
      @include transition;
      display: block;
      width: 12px;
      height: 12px;
      opacity: .4;
      border-radius: 50%;
      background-color: var(--color-white);
      cursor: pointer;
    }

    .slick-active button,
    button:hover {
      opacity: 1;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 9999px;
    cursor: pointer;
    opacity: .8;
    background-color: rgba($grayscale--800, .7);
    background-repeat: no-repeat;
    background-size: 24px;
    @include transition;
    @include hide-text;

    @include theme(light) {
      background-color: rgba($color--white, .8);
    }

    @include break(desktop-s) {
      background-size: 32px;
    }

    @include theme(light) {
      background-color: rgba($color--white, .9);
    }

    .ef-gallery-slider--dots &,
    [data-theme="light"] .ef-gallery-slider--dots & {
      top: calc(50% - 32px);
      bottom: calc(50% - 32px);
      width: 64px;
      background-color: rgba($grayscale--800, .3);
    }

    &--prev {
      right: 100%;
      //opacity: 0;
      background-position: right spacing(2) center;

      @include break(desktop-s) {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M23,31.414L8.293,16.707c-0.391-0.391-0.391-1.023,0-1.414L23,0.586L24.414,2l-14,14l14,14L23,31.414z" /%3E%3C/svg%3E');
      }

      @include theme(light) {
        background-color: rgba($color--white, .9);

        @include break(desktop-s) {
          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M23,31.414L8.293,16.707c-0.391-0.391-0.391-1.023,0-1.414L23,0.586L24.414,2l-14,14l14,14L23,31.414z" /%3E%3C/svg%3E');
        }
      }

      .ef-gallery-slider--dots &,
      [data-theme="light"] .ef-gallery-slider--dots & {
        right: auto;
        left: 0;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M23,31.414L8.293,16.707c-0.391-0.391-0.391-1.023,0-1.414L23,0.586L24.414,2l-14,14l14,14L23,31.414z" /%3E%3C/svg%3E');
        background-position: spacing(2) center;
      }
    }

    &--next {
      left: 100%;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M9,31.414L7.586,30l14-14l-14-14L9,0.586l14.707,14.707c0.391,0.391,0.391,1.023,0,1.414L9,31.414z" /%3E%3C/svg%3E');
      background-position: left spacing(2) center;

      @include theme(light) {
        background-color: rgba($color--white, .9);
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="black" d="M9,31.414L7.586,30l14-14l-14-14L9,0.586l14.707,14.707c0.391,0.391,0.391,1.023,0,1.414L9,31.414z" /%3E%3C/svg%3E');
      }

      .ef-gallery-slider--dots &,
      [data-theme="light"] .ef-gallery-slider--dots & {
        right: 0;
        left: auto;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"%3E%3Cpath fill="white" d="M9,31.414L7.586,30l14-14l-14-14L9,0.586l14.707,14.707c0.391,0.391,0.391,1.023,0,1.414L9,31.414z" /%3E%3C/svg%3E');
        background-position: right spacing(2) center;
      }
    }

    &:hover {
      opacity: 1;

      @include theme(light) {
        background-color: rgba($color--white, .8);
      }

      .ef-gallery-slider--dots & {
        background-color: transparent;
        background-color: rgba($grayscale--800, .4);
      }
    }

    &.slick-disabled {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
}

.ef-slide,
.slick-slide {
  display: flex !important;
}

.slick-slide {
  height: auto;
  padding-right: spacing(.5);
  padding-left: spacing(.5);
  //opacity: .3;
  @include transition($property: opacity);

  // &.slick-active {
  //   opacity: 1;
  // }

  &--gap-m {
    padding-right: spacing(2);
    padding-left: spacing(2);
  }

  .ef-gallery-slider--dots & {
    padding-right: 0;
    padding-left: 0;
  }
}

.slick-slide {

  > div:not([class]) {
    display: flex;
    width: 100%;
  }
}

.slick-active {

  // .ef-slide {
  //   opacity: 1;
  // }
}
